<template>

    <div class="row my-1 justify-content-center">

      <div class="col-12 col-md-6 col-lg-3 my-2 d-flex justify-content-center">
        <div class="card card1" ref="card1" @click="flip('card1')">
          <div class="front">
            <div class="image-container">
              <img src="@/assets/image/card/gestionsocial.png" alt="Gestión social frente">
            </div>
          </div>
          <div class="back">
            <div class="image-container">
              <img src="@/assets/image/card/gestionsocialBACK.png" alt="Gestión social detrás">
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-3 my-2 d-flex justify-content-center">
        <div class="card card4" ref="card4" @click="flip('card4')">
          <div class="front">
            <div class="image-container">
              <img src="@/assets/image/card/Participantes.png" alt="Participantes frente">
            </div>
          </div>
          <div class="back">
            <div class="image-container">

              <img src="@/assets/image/card/Participanteback.png" alt="Participantes detrás">
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-3 my-2 d-flex justify-content-center">
        <div class="card card5" ref="card5" @click="flip('card5')">
          <div class="front">
            <div class="image-container">
              <img src="@/assets/image/card/PersonasAtendidas.png" alt="Personas atendidas frente">
            </div>
          </div>
          <div class="back">
            <div class="image-container">

              <img src="@/assets/image/card/PersonasAtendidasBack.png" alt="Personas atendidas detrás">
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-3 my-2 d-flex justify-content-center">
        <div class="card card6" ref="card6" @click="flip('card6')">
          <div class="front">
            <div class="image-container">
              <img src="@/assets/image/card/Recurso1.png" alt="Recurso frente">
            </div>
          </div>
          <div class="back">
            <div class="image-container">

              <img src="@/assets/image/card/PersonasAtendidasBAckBack.png" alt="Recurso detrás">
            </div>
          </div>
        </div>
      </div>

    </div>
  <!-- fila 2 -->
    <div class="row my-1 justify-content-center">

      <div class="col-12 col-md-6 col-lg-3 my-2 d-flex justify-content-center">
        <div class="card card8" ref="card8" @click="flip('card8')">
          <div class="front">
            <div class="image-container">
              <img src="@/assets/image/card/SociosNuevos.png" alt="Socios nuevos frente">
            </div>
          </div>
          <div class="back">
            <div class="image-container">

              <img src="@/assets/image/card/SociosNuevosBack.png" alt="Socios nuevos detrás">
            </div>
          </div>
        </div>
      </div>
      

      <div class="col-12 col-md-6 col-lg-3 my-2 d-flex justify-content-center">
        <div class="card card7" ref="card7" @click="flip('card7')">
          <div class="front">
            <div class="image-container">
              <img src="@/assets/image/card/SociosEncHile.png" alt="Socios en Chile frente">
            </div>
          </div>
          <div class="back">
            <div class="image-container">
              <img src="@/assets/image/card/SociosEncHileBack.png" alt="Socios en Chile detrás">
            </div>
          </div>
        </div>
      </div>

      

      <div class="col-12 col-md-6 col-lg-3 my-2 d-flex justify-content-center">
        <div class="card card2" ref="card2" @click="flip('card2')">
          <div class="front">
            <div class="image-container">
              <img src="@/assets/image/card/financiamiento.png" alt="Financiamiento frente">
            </div>
          </div>
          <div class="back">
            <div class="image-container">

              <img src="@/assets/image/card/financiamientoBACK.png" alt="Financiamiento detrás">
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-3 my-2 d-flex justify-content-center">
        <div class="card card3" ref="card3" @click="flip('card3')">
          <div class="front">
            <div class="image-container">
              <img src="@/assets/image/card/gastos.png" alt="Gastos frente">
            </div>
          </div>
          <div class="back">
            <div class="image-container">

              <img src="@/assets/image/card/gastosBack.png" alt="Gastos detrás">
            </div>
          </div>
        </div>
      </div>

    </div>

</template>

<script>
export default {
  data() {
    return {
      cardStates: {
        card1: false,
        card2: false,
        card3: false,
        card4: false,
        card5: false,
        card6: false,
        card7: false,
        card8: false,
      },
    };
  },
  methods: {
    flip(cardId) {
      const card = this.$refs[cardId];
      card.classList.toggle("is-flipped");
      this.cardStates[cardId] = !this.cardStates[cardId];
    },
  },
};
</script>




<style scoped>


.card {
  border: transparent;
  background-color: transparent;
  position: relative;
  min-width: 350px;
  min-height: 350px;
  max-width: 350px;
  max-height: 350px;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  cursor: pointer;
}

.card.is-flipped {
  border: transparent;
  transform: rotateY(180deg);
}

.front,
.back {
  border: transparent;
  position: absolute;
  min-width: 350px;
  min-height: 350px;
  max-width: 350px;
  max-height: 350px;

  backface-visibility: hidden;
}

.image-container {
  top: 0;
  left: 0;
  min-width: 350px;
  min-height: 350px;
  max-width: 350px;
  max-height: 350px;
  justify-content: center;
  align-items: center;
  height: auto;
}


img {
  max-height: 350px;

}

.back {
  transform: rotateY(180deg);
}

.card-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
  }

  .container {
    margin: 20px 0;
    /* Aumenta el margen para evitar que las tarjetas se junten */
    min-width: 120px;
    /* Disminuye el tamaño mínimo y máximo de las tarjetas */
    max-width: 120px;
    min-height: 120px;
    max-height: 120px;
  }
}

@media (max-width: 600px) {
  .container {
    min-width: 150px;
    max-width: 150px;
    min-height: 150px;
    max-height: 150px;
  }
}

@media (max-width: 480px) {
  .container {
    min-width: 120px;
    max-width: 120px;
    min-height: 120px;
    max-height: 120px;
  }
}

@media (max-width: 320px) {
  .container {
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
  }
}
</style>