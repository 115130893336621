<template>
    <div class="container-fluid my-5">
        <transition name="slide">
            <div v-if="isOpen" class="modal" @click.self="closeModal">
                <div class="pt-4 shadow" :class="{modalChico: !esGrande, modalGrande: esGrande}" :style="styleCard">
                    <div v-if="muestraNav" class="row">
                        <div v-if="otherModalRef" class="col-2 d-flex">
                            <tspan class="navIzquierdo text-gris font-dinn-bold cursor" @click="activarModalPrevio" x="0" y="0"> &#60;</tspan>
                        </div>
                        <div class="col">
                            <div class="col d-flex navDerecho">
                                <span @click="closeModal" class="font-dinn-bold cierreModal text-gris">X</span>
                            </div>
                        </div>

                    </div>
                    <div v-if="scrollBar" :style="styleDiv"> 
                        <slot />
                    </div>
                    
                    <div v-else>
                        <slot />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
import { defineProps, computed, ref, defineExpose } from 'vue';

const isOpen = ref(false);

const closeModal = () => {
    isOpen.value = false;
};

const openModal = () => {
    isOpen.value = true;
};

defineExpose({ closeModal, openModal, });

const props = defineProps({
    display: {
        type: String,
    },
    flexDirection: {
        type: String,
    },
    titulo: String,
    minHeight: String,
    minWidth: String,
    maxWidth: String,
    maxHeight: String,
    padding: String,
    muestraNav: {
        type: Boolean,
        default: false
    },
    backgroundColor: {
        type: String,
        default: '#3CB5DB'
    },
    otherModalRef: {
        type: Object,
        default: null
    },
    overflowY: {
        type: String,
        default: 'scroll'
    },
    scrollBar: {
        type: Boolean,
        default: false
    },
    esGrande: {
        type: Boolean,
        default: true
    }
});

const styleCard = computed(() => {
    return {
        display: props.display,
        flexDirection: props.flexDirection,
        minHeight: props.minHeight,
        minWidth: props.minWidth,
        maxWidth: props.maxWidth,
        maxHeight: props.maxHeight,
        borderRadius: '90px 0 90px 0',
        backgroundColor: props.backgroundColor,
        padding: props.padding,
        margin: 0,
        overflowX: 'hidden',
    }
});

const styleDiv = computed(() => {
    return {
        display: 'flex',
        flexDirection: 'column',
        overflowY: props.overflowY ? props.overflowY : 'inherit',
        overflowX: 'hidden'
    }
});

const activarModalPrevio = () => {
    closeModal();
    if (props.otherModalRef != null) props.otherModalRef.openModal();
};

</script>

<style scoped>
::-webkit-scrollbar {
    width: 10px;
    /* Ancho del scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.227);
    /* Color del track */
    border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.894);
    border-radius: 100px;
}

.cierreModal:hover {
    cursor: pointer;
}

.navDerecho {
    font-size: 32px;
    justify-content: flex-end;
    padding-inline-end: 30px;}

.navIzquierdo {
    font-size: 40px;
    justify-content: flex-start;
    padding-inline-end: 30px;
    transform: translate(25px, -5px);

}

.row-height {
    height: 0px;
}

.text-gris {
    color: #6E6E6E !important;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 1000;
}

.shadow {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.8);
}

.cls-1 {
    fill: #f6f6f6;
    font-family: DINNextRoundedLTPro-Light, 'DINNextRoundedLTPro Light';
    font-size: 62.69px;
    font-weight: 300;
}

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.5s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}

/*************400px/***************768/**********1366/*********1920/********/
.modalChico {
    min-width: 100% !important;
}

.modalGrande {
    min-width: 100% !important;
}

/* define media queries to testing class */
@media (max-width: 400px) {
    .modalGrande {
        min-width: 90% !important;
    }

    .modalChico {
        min-width: 60% !important;
        max-width: 90% !important;
    }
}
@media (min-width: 400px) {
    .modalGrande {
        min-width: 90% !important;
    }

    .modalChico {
        min-width: 50% !important;
        max-width: 90% !important
    }
}
@media (min-width: 768px) {
    .modalGrande {
        min-width: 50% !important;
    }

    .modalChico {
        min-width: 45% !important;
    }
}
@media (min-width: 1366px) {
    .modalGrande {
        min-width: 35% !important;
    }

    .modalChico {
        min-width: 40% !important;
    }
}
@media (min-width: 1920px) {
    .modalGrande {
        min-width: 30% !important;
    }

    .modalChico {
        min-width: 35% !important;
    }
}



</style>