<template>
    <NavbarInclusion />

    <div class="background-container">
        <div class="background-filter"></div>
    </div>

    <div class="text-center" style="position: relative;">
        <img class="img-fluid rounded-image" src="../../assets/image/Incidencia/Portada_incidencia.png" alt="Inclusion" />
        <div class="rounded-image-filter"></div>
    </div>

    <div class="container-fluid p-4">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-12">
                <div class="texto-estrategia">
                    En Hogar de Cristo trabajamos en conjunto con la sociedad y el Estado para la construcción de un Chile
                    más
                    justo
                    y
                    solidario.
                </div>
            </div>
        </div>
    </div>

    <AudioIncidencia />

    <div class="mt-5 font-dinn-bold container text-center tituloEstrategia">
        Nuestros Socios
    </div>

    <div class="container-fluid p-4">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-12">
                <div class="texto-estrategia">
                    Nuestro trabajo comprometido con los más desprotegidos solo es posible gracias a nuestros socios y
                    socias, quienes
                    nos permiten financiar los distintos programas de apoyo que realizamos en todo el territorio nacional.
                </div>
            </div>
        </div>
    </div>


    <!-- TOTAL SOCIOS -->

    <div class="mt-5 container text-center tituloEstrategia font-dinn-bold">
        Total Socios
    </div>
    <div class="titulo-svg container text-center mb-5">
        <img class="img-fluid w-100 w-md-75 w-lg-50" src="../../assets/image/Incidencia/recurso_total_socios.png"
            alt="Total socios" />
    </div>
    <!-- TOTAL SOCIOS -->

    <!-- DISTRIBUCIÓN DE SOCIOS -->
    <div class="titulo-svg container text-center my-5 font-dinn-bold">
        <span class="text-alt">
            Distribución socios/a por región
        </span>
        <img class="img-fluid mt-3" src="../../assets/image/Incidencia/recurso_distribucion_socios.png"
            alt="Distribución socios" />
    </div>

    <!-- DISTRIBUCIÓN DE SOCIOS -->

    <!-- NUEVOS SOCIOS CAPTADOS -->

    <div class="mt-5 container text-center titulo-svg fs-1 font-dinn-bold">
        <span>
            Nuevos socios
        </span>
        <br>
        <span>
            captados el 2022
        </span>
    </div>
    <div class="titulo-svg container text-center mb-5">
        <img class="img-fluid w-100 w-md-75 w-lg-50" src="../../assets/image/Incidencia/recurso_nuevos_socios.png"
            alt="Nuevos socios captados" />
    </div>


    <!-- NUEVOS SOCIOS CAPTADOS -->

    <!-- TRES TARJETAS HORIZONTALES -->


    <div class="container rowbloque">
        <div class="bloque1">
            <p>
                Eventos Solidarios
            </p>
            <span class="numero">$298.183.446</span>
            <div class="text-small">(Aportados por 67 empresas <br> a nivel nacional)</div>
        </div>
        <div class="bloque1">
            <p>Donaciones a través de Crowdfunding</p>
            <span class="numero">$12.616.461</span>
        </div>
        <div class="bloque1">
            <p>
                Donaciones de personas
            </p>
            <span class="numero">$369.922.371</span>
            <div class="text-small">(48,2% corresponde a NN y <br> 51,8% corresponde a Socios)</div>
        </div>
    </div>



    <!-- TRES TARJETAS HORIZONTALES -->

    <!-- ALIANZAS -->
    <div class="titulo-svg container d-fl text-center my-5">
        <span>
            Alianzas
        </span>
        <br />
        <div class="container col-12">
            <div class="row">
                <div class="col-12 my-2 col-md-3 cursor">
                    <img @click="openModalAlianza1" class="img-fluid" src="../../assets/image/Incidencia/Telesur.png"
                        alt="Telesur" />
                </div>
                <div class="col-12 my-2 col-md-3 cursor">
                    <img @click="openModalAlianza2" class="img-fluid" src="../../assets/image/Incidencia/Pucobre.png"
                        alt="Pucobre" />
                </div>
                <div class="col-12 my-2 col-md-3 cursor">
                    <img @click="openModalAlianza3" class="img-fluid" src="../../assets/image/Incidencia/CyD.png"
                        alt="CyD" />
                </div>
                <div class="col-12 my-2 col-md-3 cursor">
                    <img @click="openModalAlianza4" class="img-fluid" src="../../assets/image/Incidencia/J.p.morgan.png"
                        alt="PMorgan" />
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-3">
                </div>
                <div class="col-12 my-2 col-md-3 cursor">
                    <img @click="openModalAlianza5" class="img-fluid" src="../../assets/image/Incidencia/Aysen.png"
                        alt="Aysen" />
                </div>
                <div class="col-12 my-2 col-md-3 cursor">
                    <img @click="openModalAlianza6" class="img-fluid" src="../../assets/image/Incidencia/Caserones.png"
                        alt="Caserones" />
                </div>
                <div class="col-3">
                </div>
            </div>
        </div>
    </div>
    <!-- ALIANZAS -->

    <!-- BUENAS NOTICIAS INCIDENCIA PUBLICA RED DE ACCION SOLIDARIA -->
    <div class="container col-12 mb-5">
        <div class="row mb-5">
            <div class="col">
            </div>
            <div class="col-8 my-2 col-md-3 cursor">
                <img @click="openModal1" class="img-fluid" src="../../assets/image/Incidencia/btn_1-buenas_noticias.png"
                    alt="Buenas noticias" />
            </div>
            <div class="col">
            </div>
        </div>
        <div class="mt-5 font-dinn-bold text-center tituloEstrategia">
            INVOLUCRADOS POR LA CAUSA
        </div>

        <div class="mt-5 font-dinn-regular texto-estrategia container text-justify texto-estrategia">
            El 2022 fue el año más regular, desde el inicio de la pandemia: voluntarios/as, estudiantes en práctica,
            líderes/as de Acción Solidaria, red educacional y otras instituciones, suman más de 63 mil personas que viven
            experiencias transformadoras con Hogar de Cristo.
            <br>
        </div>
        <!-- PERSONAS INVOLUCRADAS CON HOGAR DE CRISTO DURANTE EL 2022 -->
        <div class="mt-5 font-dinn-bold container text-center tituloEstrategia">
            PERSONAS INVOLUCRADAS CON
            HOGAR DE CRISTO DURANTE EL 2022
        </div>
        <div class="titulo-svg container text-center mb-5">
            <img class="img-fluid w-100 w-md-75 w-lg-50" src="../../assets/image/Incidencia/PERSONASINVOLUCRADAS.png"
                alt="Personas involucradas" />
        </div>
        <!-- PERSONAS INVOLUCRADAS CON HOGAR DE CRISTO DURANTE EL 2022 -->
        <div class="titulo-svg container d-flex justify-content-center align-items-center text-center my-5">
            <div class="containerbloque">
                <div class="rowbloque">
                    <div class="bloque">
                        <p>
                            Voluntarios individuales
                        </p>
                        <span class="separator"></span>
                        <span class="numero">2.227</span>
                    </div>

                    <div class="bloque1">
                        <p>
                            Instituciones de educación superior
                        </p>
                        <span class="separator"></span>
                        <span class="numero">209</span>
                    </div>
                    <div class="bloque">
                        <p>
                            Estudiantes en práctica
                        </p>
                        <span class="separator"></span>
                        <span class="numero">2.461</span>
                    </div>
                </div>
                <div class="rowbloque">
                    <div class="bloque">
                        <p>
                            Establecimientos educacionales
                        </p>
                        <span class="separator"></span>
                        <span class="numero">212</span>
                    </div>
                    <div class="bloque1">
                        <p>
                            Involucrados en acciones de Promoción de la solaridad
                        </p>
                        <span class="separator"></span>
                        <span class="numero">58.927</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col">
            </div>
            <div class="col-8 my-2 col-md-3 cursor">
                <img @click="openModalBuenasNoticias" class="img-fluid"
                    src="../../assets/image/Incidencia/btn_1-buenas_noticias.png" alt="Buenas noticias" />
            </div>
            <div class="col">
            </div>
        </div>

        <div class="row mt-5">
            <div class="col">
            </div>
            <div class="col-5 my-2 col-md-3 cursor">
                <img @click="openModal2" class="img-fluid" src="../../assets/image/Incidencia/btn_2-incidencia_publica.png"
                    alt="Incidencia pública" />
            </div>
            <div class="col-5 my-2 col-md-3 cursor">
                <img @click="openModal3" class="img-fluid" src="../../assets/image/Incidencia/btn_3-red_solidaria.png"
                    alt="Red solidaria" />
            </div>
            <div class="col">
            </div>
        </div>
    </div>
    <!-- BUENAS NOTICIAS INCIDENCIA PUBLICA RED DE ACCION SOLIDARIA -->


    <!-- <CardsCentrales />-->


    <!-- MODALS -->

    <CardModalVerdeCompleto ref="modalAlianza1" background-color="#91D63C" class="text-white" :muestra-nav="false"
        overflowY="hidden" display="flex" flex-direction="column" padding="10px">
        <div class="col">
            <div class="row container-alianzas px-3 mb-5">
                <img @click="openModalAlianza1" class="img-redaccion-solidaria"
                    src="../../assets/image/Incidencia/Telesur.png" alt="Red acción solidaria" />
            </div>
            <div class="row mt-5">
                <span class="font-dinn-regular text-center text-alianzas fs-5">
                    Se firmó un acuerdo de colaboración de 3 años aportando con donaciones, captación
                    de socios, usos de canales comunicacionales y entrega de fibra óptica, entre otros.
                </span>
            </div>
        </div>
    </CardModalVerdeCompleto>
    <CardModalVerdeCompleto ref="modalAlianza2" background-color="#91D63C" class="text-white" :muestra-nav="false"
        overflowY="hidden" display="flex" flex-direction="column" padding="10px">
        <div class="row container-alianzas px-3 mt-4 mb-4">
            <img @click="openModalAlianza1" class="img-redaccion-solidaria" src="../../assets/image/Incidencia/Pucobre.png"
                alt="Pucobre" />
        </div>
        <div class="row ">
            <span class="font-dinn-regular text-center text-alianzas fs-4">
                Se impulsó, de manera corporativa, el plan 1+1 llegando casi al 80% de su planta como socios
            </span>
        </div>
    </CardModalVerdeCompleto>
    <CardModalVerdeCompleto ref="modalAlianza3" background-color="#91D63C" class="text-white" :muestra-nav="false"
        overflowY="hidden" display="flex" flex-direction="column" padding="10px">
        <div class="row container-alianzas px-3 mt-4 mb-4">
            <img @click="openModalAlianza1" class="img-redaccion-solidaria" src="../../assets/image/Incidencia/CyD.png"
                alt="CyD" />
        </div>
        <div class="row">
            <span class="font-dinn-regular text-center text-alianzas">
                A lo largo de los años han permanecido junto al Hogar aportando de distintas formas, además de
                fortalecer el 1+1 y patrocinio de proyectos específicos.
            </span>
        </div>
    </CardModalVerdeCompleto>
    <CardModalVerdeCompleto ref="modalAlianza4" background-color="#91D63C" class="text-white" :muestra-nav="false"
        overflowY="hidden" display="flex" flex-direction="column" padding="10px">
        <div class="row my-2"></div>
        <div class="row container-alianzas px-3 my-4">
            <img @click="openModalAlianza1" class="img-redaccion-solidaria"
                src="../../assets/image/Incidencia/J.p.morgan.png" alt="Red acción solidaria" />
        </div>

        <div class="row">
            <span class="font-dinn-regular text-center text-alianzas fs-4">
                Gestión de un nuevo proyecto con Fundación súmate y apoyo a programas de oficios.
            </span>
        </div>
    </CardModalVerdeCompleto>
    <CardModalVerdeCompleto ref="modalAlianza5" background-color="#91D63C" class="text-white" :muestra-nav="false"
        overflowY="hidden" display="flex" flex-direction="column" padding="10px">
        <div class="row my-2"></div>
        <div class="row container-alianzas px-3 my-5">
            <img @click="openModalAlianza1" class="img-redaccion-solidaria" src="../../assets/image/Incidencia/Aysen.png"
                alt="Aysen" />
        </div>
        <div class="row my-1"></div>
        <div class="row">
            <span class="font-dinn-regular text-center text-alianzas fs-4">
                Aumentó considerablemente su aporte anual.
            </span>
        </div>
    </CardModalVerdeCompleto>
    <CardModalVerdeCompleto ref="modalAlianza6" background-color="#91D63C" class="text-white" :muestra-nav="false"
        overflowY="hidden" display="flex" flex-direction="column" padding="10px">
        <div class="row my-2"></div>
        <div class="row container-alianzas px-3 my-5">
            <img @click="openModalAlianza1" class="img-redaccion-solidaria"
                src="../../assets/image/Incidencia/Caserones.png" alt="Caserones" />
        </div>
        <div class="row my-1"></div>
        <div class="row">
            <span class="font-dinn-regular text-center text-alianzas fs-4">
                Por segundo año nos apoya con donaciones.
            </span>
        </div>
    </CardModalVerdeCompleto>
    <!-- Modal noticias -->
    <CardModalVerdeCompleto ref="modal1" background-color="#91D63C" class="text-white" :muestra-nav="true"
        titulo="Buenas Noticias" overflow-y="scroll" display="flex" flex-direction="column" padding="30px">
        <div class="col">
            <div class="row">
                <span class="font-dinn-bold fs-titulo-buenasnoticias">
                    Campañas de donaciones:
                </span>
                <span class="font-dinn-bold text-center fs-titulo-buenasnoticias">
                    Inicio de año
                </span>
                <span class="font-dinn-regular text-center">
                    Campaña de Justicia Social
                </span>
            </div>

            <div class="row justify-content-center">
                <div class="imgCard-container col-12 col-md-8 mb-4">
                    <img class="img-fluid d-block mx-auto" src="../../assets/image/Incidencia/CamagnaJusticiaSocial.png" />
                </div>
            </div>

            <div class="row mt-4">
                <span class="font-dinn-bold text-center fs-titulo-buenasnoticias">
                    Invierno
                </span>
                <span class="font-dinn-regular text-center">
                    Campaña Situación de Calle y Hospederias
                </span>
            </div>
            <div class="row justify-content-center">
                <div class="imgCard-container col-12 col-md-8 mb-4">
                    <img class="img-fluid d-block mx-auto" src="../../assets/image/Incidencia/CampagnaInvierno.png" />
                </div>
            </div>

            <div class="row mt-4">
                <span class="font-dinn-bold text-center fs-titulo-buenasnoticias">
                    Fin de Año
                </span>
                <span class="font-dinn-regular text-center">
                    Alza de los Costos a Causa del IPC
                </span>
            </div>

            <div class="row justify-content-center">
                <div class="imgCard-container col-12 col-md-8 mb-4">
                    <img class="img-fluid d-block mx-auto" src="../../assets/image/Incidencia/CampagnaLuchaInflacion.png" />
                </div>
            </div>

            <div class="row">
                <span class="font-dinn-bold fs-titulo-buenasnoticias mt-5">
                    Recaudación Domiciliaria
                </span>
                <span class="font-dinn-regular text-justify mr-4">
                    Este año incorporamos un equipo de visitas domiciliarias permitiendo facilitar la recolección de
                    donaciones y nuestra cobertura. Además, se desarrolló una APP que ayudó a anticipar la recaudación, así
                    como una mayor eficiencia para el proceso administrativo de recaudación y mejoras en la gestión, en
                    general.

                </span>
            </div>
            <div class="row justify-content-center">
                <div class="imgCard-container col-12 col-md-8 mb-4">
                    <img class="img-fluid d-block mx-auto" src="../../assets/image/Incidencia/AumentoCobertura.png" />
                </div>
            </div>

            <div class="row mt-4">
                <span class="font-dinn-bold fs-titulo-buenasnoticias">
                    Tienda Solidaria
                </span>
                <span class="font-dinn-regular text-justify">
                    Lanzamos nuevo <span class="font-dinn-bold">sitio web</span> de Tienda Solidaria Hogar de Cristo e
                    incorporamos nuestros productos en los Marketplace de Ripley, Paris y Mercado Libre, mejorando los
                    tiempos de despacho e incorporando la entrega “Same Day” en la RM. Además, se aumentó el portafolio de
                    productos con proveedores con venta en verde, permitiendo aumentar la oferta sin costos asociados de
                    almacenaje. Para ingresar a nuestra tienda clickea en la imagen o <a
                        class="font-dinn-regular text-white" href="https://tiendahogardecristo.cl" target="_blank">aquí</a>.
                </span>

            </div>
            <div class="row justify-content-center">
                <div class="imgCard-container col-12 col-md-8 mb-4">
                    <a href="https://tiendahogardecristo.cl" target="_blank">
                        <img class="img-fluid d-block mx-auto" src="../../assets/image/Incidencia/Computador.png"
                            alt="Imagen computador" />
                    </a>
                </div>
            </div>



        </div>
    </CardModalVerdeCompleto>
    <!-- Modal noticias -->
    <!-- Modal noticias -->
    <CardModalVerdeCompleto ref="ModalBuenasNoticias" background-color="#91D63C" class="text-white" :muestra-nav="true"
        titulo="Buenas Noticias" overflow-y="scroll" display="flex" flex-direction="column" padding="30px">
        <div class="col">
            <div class="row">
                <span class="font-dinn-bold fs-titulo-buenasnoticias">
                    Dorapp web
                </span>
                <span class="font-dinn-regular text-justify">
                    Desarrollo del proyecto <span class="font-dinn-bold">“Dorapp Web”</span>, que buscó migrar los
                    contenidos de la comunidad de líderes y
                    lideresas sociales desde una app para smartphones a <a class="font-dinn-regular text-white"
                        href="https://www.dorapp.cl" target="_blank">www.dorapp.cl</a>
                </span>
            </div>

            <div class="row mt-5 justify-content-center">
                <div class="imgCard-container col-12 col-md-8">
                    <img class="img-fluid d-block mx-auto" src="../../assets/image/Incidencia/Dorapp.png"
                        alt="Imagen Dorapp" />
                </div>
            </div>

            <div class="row my-4">
                <span class="font-dinn-bold fs-titulo-buenasnoticias">
                    NotCo
                </span>
                <span class="font-dinn-regular text-justify">
                    Gestión de más de 15 mil productos de mercadería donados por NotCo para diversas organizaciones sociales
                    de la Red Acción Solidaria de RM
                </span>
            </div>

            <div class="row justify-content-center">
                <div class="imgCard-container col-12 col-md-8 mb-4">
                    <img class="img-fluid d-block mx-auto" src="../../assets/image/Incidencia/NotCo.png"
                        alt="Imagen NotCo" />
                </div>
            </div>

            <div class="row">
                <span class="font-dinn-bold fs-titulo-buenasnoticias">
                    Encuentro intergeneracional
                </span>
                <span class="font-dinn-regular text-justify">
                    Se organizó el Encuentro Intergeneracional de Curepto en donde participaron, de manera presencial,
                    clubes de adultos mayores de la zona, usuarios del PADAM, participantes de organizaciones sociales y los
                    estudiantes del Liceo Luis Edmundo Correa Rojas.
                    <br>
                    <br>
                </span>
            </div>

            <div class="row justify-content-center">
                <div class="imgCard-container col-12 col-md-8 mb-4">
                    <img class="img-fluid d-block mx-auto"
                        src="../../assets/image/Incidencia/EncuentroIntergeneracional.png"
                        alt="Encuentro intergeneracional" />
                </div>
            </div>

            <div class="row">
                <span class="font-dinn-bold fs-titulo-buenasnoticias">
                    Piensa en Grandes
                </span>
                <span class="font-dinn-regular text-justify">
                    Durante octubre de 2022, mes del adulto mayor, se llevó a cabo la Tercera Versión del Fondo de
                    Innovación Social Piensa en Grandes, el cual surge de una colaboración entre AFP Habitat, Vinson
                    Consulting y Hogar de Cristo. De 200 postulaciones, 15 fueron seleccionados y premiados 3 proyectos: 2
                    en categoría Escala: "Aluna, ropa interior ecológica", para la incontinencia urinaria y "Bondup", red
                    social para las personas mayores de 55 años. Así como un proyecto en la categoría Implementa:
                    "Actualízate App", promotores del buen trato tecnológico a los adultos mayores.
                    <br>
                    <br>
                </span>
            </div>

            <div class="row justify-content-center">
                <div class="imgCard-container col-12 col-md-8 mb-4">
                    <img class="img-fluid d-block mx-auto" src="../../assets/image/Incidencia/PiensaEnGrandes.png"
                        alt="Piensa en grandes" />
                </div>
            </div>

            <div class="row">
                <span class="font-dinn-bold fs-titulo-buenasnoticias">
                    Voto Inclusivo
                </span>
                <span class="font-dinn-regular text-justify">
                    Con el objetivo de cautelar que los y las participantes de los programas HDC puedan ejercer su derecho a
                    voto de manera informada en el plebiscito de salida, y en alianza con PNUD y otras organizaciones, se
                    realizaron 33 Onces Constituyentes a nivel nacional. Este proceso fue complementado por una campaña de
                    voluntarios que asistieron el voto a algunos participantes que así lo requerían. Todo, realizado en
                    sintonía con Comunidad, Dirección de Operación Social (DOS), Dirección Social Nacional (DSN).
                    <br>
                    <br>
                </span>
            </div>

            <div class="row">
                <span class="font-dinn-bold fs-titulo-buenasnoticias">
                    Debate Interescolar JUGAO
                </span>
                <span class="font-dinn-regular text-justify">
                    Debates Interescolares JUGAO, organizado por HDC y Fundación Padre Hurtado, es un proyecto que busca
                    entregar voz y visualización a jóvenes en edad escolar. El 2022 convocó a 15 equipos, con un total de
                    103 estudiantes de establecimientos escolares de todas las regiones el país. El tema central de este año
                    fue “enfoque de género” y los debates se desarrollaron a través de una nueva plataforma de transmisión y
                    contó con el apoyo de Base Pública y productora Serendi TV. El colegio ganador fue el Sagrado Corazón,
                    de Copiapó.
                    <br>
                    <br>
                </span>
            </div>

            <div class="row justify-content-center">
                <div class="imgCard-container col-12 col-md-8 mb-4">
                    <img class="img-fluid d-block mx-auto" src="../../assets/image/Incidencia/Jugao.png"
                        alt="Imagen Jugao" />
                </div>
            </div>
        </div>
    </CardModalVerdeCompleto>
    <!-- Modal noticias2 -->
    <!-- MODAL INCIDENCIA PÚBLICA -->
    <CardModalVerdeCompleto ref="modal2" class="text-white" background-color="#91D63C" :muestra-nav="true"
        titulo="Incidencia en Políticas Públicas" overflow-y="scroll" display="flex" flex-direction="column" padding="30px">

        <div class="col">
            <div class="row">
                <h4 class="text-start text-white font-dinn-bold text-white">
                    Mesas técnicas en las que participó Hogar de Cristo en 2022
                </h4>

                <span class="font-dinn-regular text-white text-justify">
                    Durante el 2022 las Direcciones Técnicas Nacionales y sus equipos Fundaciones Súmate y Emplea,
                    integraron activamente 17 mesas técnicas convocadas por entidades públicas y privadas. Predominaron las
                    mesas coordinadas por organizaciones de la sociedad civil, situación que evidencia la consolidación de
                    instituciones civiles en la incidencia de las políticas públicas.
                </span>
            </div>
            <img class="img-fluid w-100 w-md-75 w-lg-50" src="../../assets/image/Incidencia/IncidenciaPublica.png" />

        </div>
    </CardModalVerdeCompleto>

    <!-- MODAL RED ATENCIÓN SOLIDARIA -->
    <CardModalVerdeCompleto ref="modal3" class="text-white" background-color="#91D63C" :muestra-nav="true"
        titulo="Red de Acción Solidaria" overflow-y="scroll" display="flex" flex-direction="column" padding="30px">

        <div class="col">
            <div class="d-flex justify-content-center align-items-center">
                <img class="img-fluid responsive-image d-block mx-auto"
                    src="../../assets/image/Incidencia/RedAccionSolidaria.png" alt="Red acción solidaria" />
            </div>

            <div class="row text-justify font-dinn-regular text-white fs-5">
                <span>
                    Iniciativa que busca ser una red que promueve, conecta y fortalece a líderes sociales que trabajen por
                    diversas causas en nuestro país, generando una comunidad de iniciativas solidarias.
                </span>
                <span class="font-dinn-bold text-center fs-titulo-buenasnoticias mt-5">
                    170 organizaciones de la sociedad civil
                </span>
            </div>

            <div class="d-flex justify-content-center align-items-center">
                <img class="img-fluid responsive-image d-block mx-auto"
                    src="../../assets/image/Incidencia/GraficoRedAccionSolidaria.png" alt="Gráfico red acción solidaria" />
            </div>

            <div class="row text-justify font-dinn-regular text-white fs-5">
                Las cifras equivalen a la cantidad de organizaciones pertenecientes a la Red Solidaria.
            </div>
        </div>
    </CardModalVerdeCompleto>

    <!-- MODALS -->

    <FooterHC />
</template>

<script setup>
import { ref } from 'vue';


import FooterHC from '../FooterHC.vue';
import AudioIncidencia from './AudioIncidencia.vue';
import NavbarInclusion from '../Capitulo_Inclusion/NavbarInclusion.vue';

import CardModalVerdeCompleto from './Componentes_genericos/CardModalVerdeCompleto.vue';
const ModalBuenasNoticias = ref(null);
function openModalBuenasNoticias() {
    ModalBuenasNoticias.value.openModal();
}

const modalAlianza1 = ref(null);
const modalAlianza2 = ref(null);
const modalAlianza3 = ref(null);
const modalAlianza4 = ref(null);
const modalAlianza5 = ref(null);
const modalAlianza6 = ref(null);


const modal1 = ref(null);
const modal2 = ref(null);
const modal3 = ref(null);

const openModalAlianza1 = () => {
    modalAlianza1.value.openModal();
}

const openModalAlianza2 = () => {
    modalAlianza2.value.openModal();
}

const openModalAlianza3 = () => {
    modalAlianza3.value.openModal();
}

const openModalAlianza4 = () => {
    modalAlianza4.value.openModal();
}

const openModalAlianza5 = () => {
    modalAlianza5.value.openModal();
}

const openModalAlianza6 = () => {
    modalAlianza6.value.openModal();
}

const openModal1 = () => {
    modal1.value.openModal()
}

const openModal2 = () => {
    modal2.value.openModal()

}
const openModal3 = () => {
    modal3.value.openModal()
}

</script>
  
<style scoped>
.responsive-image {
    width: 75%;
    max-width: 100%;
}

@media screen and (min-width: 768px) {
    .responsive-image {
        width: 75%;
    }
}

@media screen and (min-width: 992px) {
    .responsive-image {
        width: 50%;
    }
}

.separator {
    width: 80%;
    height: 2px;
    background-color: gray;
    margin: 0;
}

.numero {
    color: #91D63C;
    font-size: 54px;
    font-family: DINNextRoundedLTPro-bold;
}

.rowbloque {
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
    /* Permite que los elementos hijos se ajusten en nuevas líneas si no hay suficiente espacio */
}

.containerbloque {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bloque,
.bloque1 {
    font-family: DINNextRoundedLTPro-Regular;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: gray;
    text-align: center;
    background-color: #fff;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    box-sizing: border-box;
    /* Incluye padding y border en el width y height del elemento */
}

.bloque {
    width: 400px;
    height: 220px;
}

.bloque1 {
    width: 400px;
    height: 220px;
}

.text-small {
    font-size: 20px;
}

/* Media Query para pantallas menores de 768px */
@media (max-width: 767px) {

    .bloque,
    .bloque1 {
        width: 100%;
        /* Ocupa el 100% del ancho disponible */
        padding: 20px;
        font-size: 22px;
    }

    .numero {
        font-size: 40px;
    }
}

.containerPc {
    max-width: 50%;
}

.imgCard {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.tituloEstrategia {
    font-size: 40pt;
    color: #fff;
    text-align: justify;
    margin-top: 20px;
}

.img-redaccion-solidaria {
    object-fit: cover;
}

.container-redaccion-solidaria {
    text-align: center;
    height: auto;
    width: 40%;
    transform: translateX(85%);
}

.container-redaccion-solidaria_dos {
    text-align: center;
    height: auto;
    width: 80%;
    transform: translateX(20%);
}

.container-alianzas {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: 250px;
}

.text-alianzas {
    font-size: 18px;
    font-weight: 300;
}

.fs-titulo-buenasnoticias {
    font-size: 25px;
    font-weight: 300;
}

.fs-cifras-buenasnoticias {
    font-size: 15px;
    font-weight: 300;
}

.scroll-container {
    position: relative;
}

.hr-separador {
    border-top: 3px solid #ffffff;
    margin-left: 20%;
    margin-right: 20%;
}

.container-imagenes-horizontales {
    padding-left: 10%;
    padding-right: 10%;
}

.titulo-svg {
    max-height: unset;
}

.titulo-svg {
    font-size: 50px;
}

.text-alt {
    font-size: 50px;
    /* Tamaño base para pantallas grandes */
}


.background-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../assets/image/Incidencia/Img_Incidencia.jpg");
    background-position: center;
    background-size: cover;
    z-index: -1;
}

.background-filter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(94, 255, 0, 0.5);
}

.rounded-image {
    border-bottom-left-radius: 100% 250px;
    border-bottom-right-radius: 100% 250px;
    position: relative;
    z-index: 1;
}

.rounded-image-filter {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(238, 255, 0, 0.24); */
    z-index: 2;
    border-bottom-left-radius: 100% 250px;
    border-bottom-right-radius: 100% 250px;
    pointer-events: none;
}



@media all and (min-width: 1024px) and (max-width: 1280px) {
    .text-alt {
        font-size: 60px;
    }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
    .tituloEstrategia {
        font-size: 50px;
    }

    .text-alt {
        font-size: 50px;
    }
}

@media all and (min-width: 480px) and (max-width: 768px) {}

@media all and (max-width: 480px) {}

.texto-estrategia {
    font-size: 2.5rem;
    /* Es similar a 36pt, pero es una unidad relativa. */
    color: #fff;
    text-align: justify;
    margin-top: 20px;
    font-family: DINNextRoundedLTPro-Regular;
}

/* Ajustes para pantallas más pequeñas */
@media (max-width: 768px) {
    .tituloEstrategia {
        font-size: 40px;
    }

    .text-alt {
        font-size: 40px;
    }
}

@media (max-width: 576px) {
    .texto-estrategia {
        font-size: 1.5rem;
    }

    .tituloEstrategia {
        font-size: 30px;
    }

    .text-alt {
        font-size: 30px;
    }
}
</style>