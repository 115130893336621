<template>
    <div class="container">
        <transition name="slide">
            <div v-if="isOpen" class="modal" @click.self="closeModal">
                <div class="shadow styleCardClass" :style="styleCard">
                    <div v-if="muestraNav" class="text-end">
                        <tspan @click="closeModal" class="iconoModal font-dinn-bold scroll-titulo" x="0" y="0">X</tspan>
                    </div>
                    <div :style="styleDiv" class="p-3 h-100">
                        <div v-if="muestraNav" class="row img-titulo d-flex justify-content-center align-items-center">
                            <img @click="openModalAlianza1" src="../../../assets/image/LogoHcMini.png" alt="Logo Hogar de cristo" />
                        </div>
                        <div class="col cls-1 font-dinn-medium text-center card-titulor">
                            {{ props.titulo }}
                        </div>

                        <slot />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
import { defineProps, computed, ref, defineExpose } from 'vue';

const props = defineProps({
    titulo: { String, default: null },
    minHeight: String,
    minWidth: String,
    maxWidth: String,
    maxHeight: String,
    padding: String,
    muestraNav: {
        type: Boolean,
        default: false
    },
    backgroundColor: {
        type: String,
        default: 'transparent'
    },
    display: {
        type: String,
    },
    flexDirection: {
        type: String,
    },
    overflowY: {
        type: String,
        default: 'scroll'
    },
    esCardGrande: {
        type: Boolean,
        default: false
    }
});

const isOpen = ref(false);

const closeModal = () => {
    isOpen.value = false;
};

const openModal = () => {
    isOpen.value = true;
};

defineExpose({ closeModal, openModal, });



const styleCard = computed(() => {
    return {
        display: props.display,
        flexDirection: props.flexDirection,
        minHeight: props.minHeight,
        minWidth: props.minWidth,
        maxWidth: props.maxWidth,
        maxHeight: props.maxHeight,
        borderRadius: '90px 0 90px 0',
        backgroundColor: props.backgroundColor,
        padding: props.padding,
        margin: 0,
    }
});

const styleDiv = computed(() => {
    return {
        display: 'flex',
        flexDirection: 'column',
        overflowY: props.overflowY ? props.overflowY : 'inherit',
        overflowX: 'hidden'
    }
});

</script>

<style scoped>
.img-titulo {
    display: block;
    margin: 0 auto;
    height: auto;
    width: 100px; 
}

.cls-1 {
    fill: #f6f6f6;
    font-size: 1rem; 
}

.iconoModal {
    font-size: 24px; 
    cursor: pointer;
}

.styleCardClass {
    min-width: 80%;
    min-height: 60%;
    max-width: 90%;
    max-height: 80%;
}

/* Tablets */
@media all and (min-width: 768px) {
    .img-titulo {
        width: 120px;
    }
    .cls-1, .scroll-titulo {
        font-size: 2rem;
    }
    .iconoModal {
        font-size: 28px;
    }
    .styleCardClass {
        min-width: 70%;
        max-width: 75%;
    }
}

/* Desktop pequeño */
@media all and (min-width: 1024px) {
    .img-titulo {
        width: 140px;
    }
    .cls-1, .scroll-titulo {
        font-size: 2.5rem;
    }
    .iconoModal {
        font-size: 32px;
    }
    .styleCardClass {
        min-width: 60%;
        max-width: 70%;
    }
}

/* Desktop grande */
@media all and (min-width: 1280px) {
    .cls-1, .scroll-titulo {
        font-size: 3rem;
    }
    .iconoModal {
        font-size: 36px;
    }
    .styleCardClass {
        min-width: 50%;
        max-width: 60%;
    }
}

/* Estilos globales que no cambian con las media queries */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.227);
    border-radius: 100px;
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.894);
    border-radius: 100px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.shadow {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.8);
}

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.5s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}
</style>