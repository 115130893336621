<template>
    <div class="contenedor">
      <div class="contenedor-imagen">
        <img :src="require('@/assets/image/organizacion.jpg')" alt="Imagen de organizacion">
      </div>
    </div>
  </template>
  
  <script >
  export default {
    name: 'OrganizacionComponent'
  };
  </script>
  <style scoped>
  body {
    min-height: 100vh;
    margin: 0;
  }
  .contenedor {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    overflow-y: auto; /* Habilita el scroll vertical */
  }
  
  .contenedor-imagen {
    width: 100%;
    height: 100vh;
    position: relative;
  }
  
  img {
    width: 100%;
    height: auto;
    min-height: 100vh;
    object-fit: cover; /* Ajusta la imagen para que cubra toda la pantalla */
    position: absolute; /* Posiciona la imagen de manera absoluta para que el contenedor pueda desplazarse */
    top: 0;
    left: 0;
  }
  </style>
  