<template>
  <div class="svg-container">
    <svg id="uuid-cadfa529-9d77-4966-9020-9c6197f22821" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" :viewBox="viewBox">

      <g id="uuid-bb33bcba-eaed-48b9-9e82-772017d80f2d" data-name="Capa 3">
        <g>
          <g class="uuid-09d341d0-796f-4e2a-afb9-230bf42f7440">
            <path class="uuid-82046482-66b1-4720-8d7b-3c21f05565bb" transform="scale(1.5,1) translate(-250,0)"
              d="m13.64,805c43.04-12,104.64-33.92,166.95-76.05,92.91-62.81,96.84-109.35,185.95-200.19,68.53-69.87,163.05-166.24,302.43-194.46,61.23-12.4,168.33-19.19,241.67-21.01,27.45-.68,46.8-.58,58.71-.58,97.84.03,277.66.09,449.97-30.4,113.45-20.07,218.17-38.6,332.99-106.1,91.27-53.65,151.65-116.34,188.05-160.64.97,314.91,1.94,629.81,2.91,944.72-41.89,8.38-103.43,26.03-161.58,67.76-64.67,46.41-88.39,96.21-136.25,167.81-61.6,92.16-111.75,167.19-181.3,220.73-162.89,125.41-397.72,106.4-449.46,102.21-113.17-9.16-184.28-41.29-366.64-42.05-39.21-.16-70.11-.71-113.07,4.09-184.56,20.63-303.59,88.77-333.72,106.67-86.87,51.6-144.68,110.14-179.37,150.97-2.74-311.16-5.49-622.33-8.23-933.49Z" />
          </g>
          <g class="uuid-09d341d0-796f-4e2a-afb9-230bf42f7440">
            <path class="uuid-82046482-66b1-4720-8d7b-3c21f05565bb" transform="scale(1.5,1) translate(-250,0)"
              d="m16.51,804.17c43.01-10.5,104.56-29.71,166.77-66.78,92.76-55.27,96.58-96.36,185.47-176.4,68.36-61.55,162.65-146.45,301.96-171.06,61.2-10.81,168.28-16.56,241.61-17.99,27.44-.54,46.8-.41,58.71-.38,97.83.26,277.65.73,449.88-25.81,113.4-17.47,218.07-33.59,332.72-92.94,91.14-47.18,151.37-102.4,187.67-141.45,1.7,278.15,3.4,556.3,5.1,834.45-41.87,7.31-103.37,22.75-161.42,59.48-64.56,40.84-88.16,84.78-135.85,147.91-61.39,81.26-111.36,147.42-180.78,194.55-162.6,110.39-397.45,93.05-449.2,89.23-113.19-8.36-184.37-36.9-366.72-38-39.21-.24-70.11-.79-113.05,3.35-184.5,17.8-303.37,77.7-333.46,93.44-86.75,45.37-144.42,96.94-179.01,132.93-3.47-274.85-6.93-549.7-10.4-824.55Z" />
          </g>
          <g class="uuid-09d341d0-796f-4e2a-afb9-230bf42f7440">
            <path class="uuid-82046482-66b1-4720-8d7b-3c21f05565bb" transform="scale(1.5,1) translate(-250,0)"
              d="m16.37,843.42c42.97-8.74,104.46-24.77,166.55-55.89,92.58-46.39,96.28-81.08,184.92-148.41,68.17-51.78,162.19-123.19,301.41-143.54,61.16-8.94,168.22-13.46,241.54-14.45,27.44-.37,46.79-.2,58.7-.14,97.83.52,277.63,1.47,449.78-20.4,113.34-14.4,217.95-27.7,332.42-77.47,90.99-39.56,151.05-86.02,187.22-118.88,2.56,234.93,5.12,469.85,7.68,704.78-41.85,6.04-103.29,18.9-161.23,49.73-64.43,34.29-87.89,71.33-135.39,124.5-61.13,68.44-110.9,124.16-180.17,163.75-162.24,92.73-397.14,77.36-448.9,73.98-113.21-7.41-184.47-31.74-366.81-33.22-39.21-.32-70.1-.88-113.04,2.48-184.44,14.46-303.11,64.69-333.15,77.89-86.6,38.05-144.11,81.43-178.59,111.72-4.32-232.14-8.63-464.29-12.95-696.43Z" />
          </g>
          <g>
            <g>
              <line class="uuid-15838f4c-fce5-4d5b-9477-9c88534681f8" x1="1001.09" y1="489.38" x2="1078.65" y2="489.38" />
              <line class="uuid-37fbdef4-905c-4612-b90f-456e1010e283" x1="1001.09" y1="489.38" x2="1078.65" y2="489.38" />
            </g>
            <text class="uuid-bdf83980-a804-4a90-8c9f-4542b7fafdac" transform="translate(993.18 454.47)">
              <tspan class="uuid-474ca0da-cb9b-4bd9-a875-b6253e9f1f0f" x="0" y="0">CAPÍTULOS</tspan>
            </text>
          </g>
          <g>
            <!-- ORGANIZACIÓN Y COLABORACIÓN -->
            <g @mouseover="onMouseOver" @mouseout="onMouseOut" class="text-group">
              <a href="/OrganizacionColaboracion">
                <g>
                  <path class="uuid-1d3bdd2e-3563-40f9-ba46-cb0586b6564d"
                    d="m645.24,1387.41c16.95,13.15,33.95,20.96,42.57,24.77,17.5,7.74,74.04,31.77,144.16,13.94,20.07-5.1,72.52-21.78,113.51-72.95,23.51-29.34,38.49-65.89,44.57-110.02v-240.16c-69.73-.02-139.47-.63-209.19.11-79.67.84-142.35,34.43-185.77,101.58-58.79,90.92-35.37,216.39,50.14,282.72Z" />
                  <text class="uuid-48d2dd65-8407-4b86-a22c-f98d2208b78e" transform="translate(575.76 1202.73)">
                    <tspan class="uuid-09bd236b-9cde-4143-b203-bbbbee1f6cf1" x="30" y="30">ORGANIZACIÓN</tspan>
                  </text>
                </g>
              </a>
            </g>
            <!-- INCLUSIÓN -->
            <g @mouseover="onMouseOver" @mouseout="onMouseOut" class="text-group">
              <a href="/Inclusion">
                <g>
                  <path class="uuid-0afca3d4-375a-4b16-b855-65f51f09fe42"
                    d="m551.14,513.34c21.63-16.78,43.33-26.75,54.34-31.62,22.33-9.88,94.5-40.55,184-17.8,25.61,6.51,92.57,27.8,144.88,93.11,30,37.45,49.13,84.1,56.89,140.42v306.53c-89,.02-178.01.8-267-.14-101.69-1.08-181.69-43.95-237.1-129.65-75.03-116.04-45.14-276.19,63.99-360.85Z" />
                  <text class="uuid-01178419-6efa-40a5-a5ec-ff77a7c3cf9e" transform="translate(510.89 775.8)">
                    <tspan class="uuid-01029098-270d-4b4e-9b2d-836cd9b9d8eb" x="0" y="0">INCLUSIÓN</tspan>
                  </text>
                </g>
              </a>
            </g>
            <!-- SOSTENIBILIDAD Y TRANSPARENCIA -->
            <g @mouseover="onMouseOver" @mouseout="onMouseOut" class="text-group">
              <a href="/Sostenibilidad">
                <g>
                  <path class="uuid-9f121ca1-d37e-4e61-83cc-230979153927"
                    d="m1423.17,1396.68c-21.4,16.6-42.88,26.47-53.77,31.29-22.1,9.78-93.52,40.12-182.08,17.61-25.34-6.44-91.6-27.51-143.37-92.14-29.69-37.06-48.61-83.22-56.3-138.95v-303.33c88.07-.02,176.15-.79,264.22.14,100.63,1.07,179.79,43.49,234.63,128.3,74.25,114.83,44.67,273.3-63.33,357.08Z" />
                  <text class="uuid-48d2dd65-8407-4b86-a22c-f98d2208b78e" transform="translate(1009.73 1165.41)">
                    <tspan class="uuid-ed94de87-9086-4128-83bc-657baee69e55" x="0" y="0">SOSTENIBILIDAD</tspan>
                    <tspan class="uuid-827ffb6b-36ed-4bd0-b236-b5d972bcb6cc" x="0" y="66">Y</tspan>
                    <tspan class="uuid-2d2ba52c-cd23-4a67-8b13-c667ec97c01c" x="44.44" y="66"> TRANSPARENCIA</tspan>
                  </text>
                </g>
              </a>
            </g>
            <g @mouseover="onMouseOver" @mouseout="onMouseOut" class="text-group">
              <a href="/Incidencia">
                <path class="uuid-0009aed6-b530-4a74-baaf-83ced14f16ea"
                  d="m1325.68,534.04c-16.59-12.87-33.24-20.52-41.68-24.25-17.13-7.58-72.48-31.1-141.12-13.65-19.65,4.99-71,21.32-111.12,71.41-23.01,28.73-37.68,64.5-43.63,107.7v235.1c68.26.02,136.53.61,204.79-.11,78-.83,139.35-33.71,181.85-99.44,57.55-89,34.62-211.83-49.08-276.76Z" />
                <text class="uuid-baa57283-e615-4976-a931-27e60d1426b7" transform="translate(1015 702) scale(0.9)">
                  <tspan x="0" y="0">INCIDENCIA</tspan>
                  <tspan x="0" y="60">Y COLABORACIÓN</tspan>
                </text>
              </a>
            </g>
          </g>
        </g>
      </g>
    </svg>

  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const viewBox = ref("-250 0 2491 1784");
const mobileViewBox = "368 256 1280 1320";

function setResponsiveViewBox() {
  if (window.innerWidth < 768) {
    viewBox.value = mobileViewBox;
  } else {
    viewBox.value = "-250 0 2491 1784"; // Default viewbox for larger screens
  }
}

function onMouseOver(event) {
  const allTextGroups = document.querySelectorAll('.text-group');
  allTextGroups.forEach((group) => {
    if (group !== event.currentTarget) {
      group.classList.add('not-hovered');
    }
  });
}

function onMouseOut() {
  const allTextGroups = document.querySelectorAll('.text-group');
  allTextGroups.forEach((group) => {
    group.classList.remove('not-hovered');
  });
}

onMounted(() => {
  setResponsiveViewBox();
  window.addEventListener('resize', setResponsiveViewBox);
});

onUnmounted(() => {
  window.removeEventListener('resize', setResponsiveViewBox);
});
</script>


<style scoped>
.not-hovered {
  opacity: 0.5;
}

a {
  text-decoration: none;

}

.uuid-b195b15f-f6bf-43f3-9199-cef10cf793a9,
.uuid-827ffb6b-36ed-4bd0-b236-b5d972bcb6cc {
  letter-spacing: .05em;
}

.uuid-fadcfccf-1f5c-4f5f-b662-ab01c71a947f,
.uuid-21c22a93-dc57-4712-9d98-1f64b04b18d4,
.uuid-5ec0c80d-598f-42cf-bd52-193b33e3d9c1,
.uuid-8c40d763-a9de-4228-bb36-c287811a2854,
.uuid-2d2ba52c-cd23-4a67-8b13-c667ec97c01c,
.uuid-827ffb6b-36ed-4bd0-b236-b5d972bcb6cc,
.uuid-82046482-66b1-4720-8d7b-3c21f05565bb,
.uuid-01178419-6efa-40a5-a5ec-ff77a7c3cf9e,
.uuid-bb8f2472-4871-4ffb-a87c-470f0f13ac54,
.uuid-86e8cb31-213c-4b95-af4e-b7a24c3f373d,
.uuid-48d2dd65-8407-4b86-a22c-f98d2208b78e,
.uuid-baa57283-e615-4976-a931-27e60d1426b7 {
  fill: #fff;
}


.uuid-0009aed6-b530-4a74-baaf-83ced14f16ea {
  fill: #9bcc5f;
}

.uuid-0afca3d4-375a-4b16-b855-65f51f09fe42 {
  fill: #40c0e8;
}

.uuid-9f121ca1-d37e-4e61-83cc-230979153927 {
  fill: #ffcf21;
}

.uuid-09bd236b-9cde-4143-b203-bbbbee1f6cf1 {
  letter-spacing: .05em;
}

.uuid-e9c5995f-3333-4fdf-97e6-27f02aac03fb {
  letter-spacing: .03em;
}

.uuid-5ec0c80d-598f-42cf-bd52-193b33e3d9c1,
.uuid-ed94de87-9086-4128-83bc-657baee69e55 {
  letter-spacing: .05em;
}



.uuid-01178419-6efa-40a5-a5ec-ff77a7c3cf9e {
  font-size: 70.68px;
  stroke-width: .66px;
}

.uuid-01178419-6efa-40a5-a5ec-ff77a7c3cf9e,
.uuid-37fbdef4-905c-4612-b90f-456e1010e283,
.uuid-86e8cb31-213c-4b95-af4e-b7a24c3f373d,
.uuid-48d2dd65-8407-4b86-a22c-f98d2208b78e,
.uuid-baa57283-e615-4976-a931-27e60d1426b7 {
  stroke-miterlimit: 10;
}

.uuid-01178419-6efa-40a5-a5ec-ff77a7c3cf9e,
.uuid-86e8cb31-213c-4b95-af4e-b7a24c3f373d,
.uuid-bdf83980-a804-4a90-8c9f-4542b7fafdac,
.uuid-48d2dd65-8407-4b86-a22c-f98d2208b78e,
.uuid-baa57283-e615-4976-a931-27e60d1426b7 {
  font-family: DINNextRoundedLTPro-Bold, 'DINNextRoundedLTPro Bold';
  font-weight: 700;
}

.uuid-01178419-6efa-40a5-a5ec-ff77a7c3cf9e,
.uuid-86e8cb31-213c-4b95-af4e-b7a24c3f373d,
.uuid-48d2dd65-8407-4b86-a22c-f98d2208b78e,
.uuid-baa57283-e615-4976-a931-27e60d1426b7 {
  stroke: #fff;
}



.uuid-bb8f2472-4871-4ffb-a87c-470f0f13ac54,
.uuid-09d341d0-796f-4e2a-afb9-230bf42f7440 {
  opacity: .57;
}

.uuid-474ca0da-cb9b-4bd9-a875-b6253e9f1f0f {
  letter-spacing: 0em;
}

.uuid-37fbdef4-905c-4612-b90f-456e1010e283 {
  fill: none;
  stroke: #666;
  stroke-linecap: round;
  stroke-width: 8.31px;
}



.uuid-86e8cb31-213c-4b95-af4e-b7a24c3f373d {
  font-size: 40px;
  stroke-width: .38px;
}



.uuid-bdf83980-a804-4a90-8c9f-4542b7fafdac {
  font-size: 80.53px;
}

.uuid-bdf83980-a804-4a90-8c9f-4542b7fafdac,
.uuid-15838f4c-fce5-4d5b-9477-9c88534681f8 {
  fill: #666;
}

.uuid-2d2ba52c-cd23-4a67-8b13-c667ec97c01c {
  letter-spacing: .05em;
}


.uuid-48d2dd65-8407-4b86-a22c-f98d2208b78e {
  font-size: 48px;
}


.uuid-01029098-270d-4b4e-9b2d-836cd9b9d8eb {
  letter-spacing: .05em;
}

.uuid-1d3bdd2e-3563-40f9-ba46-cb0586b6564d {
  fill: #ef5d2a;
}

.uuid-baa57283-e615-4976-a931-27e60d1426b7 {
  font-size: 50.18px;
  letter-spacing: .05em;
  stroke-width: .47px;
}
</style>
