<template>
    <div class="container-fluid my-5">
        <transition name="slide">
            <div v-if="isOpen" class="modal-overlay" @click.self="closeModal">
                <div class="modal-content position-relative px-5 pb-5 shadow">
                    <div class="d-flex justify-content-end m-0">
                        <tspan class="btn-atras textoAmarillo font-dinn-bold" @click="closeModal">X</tspan>
                    </div>
                    <div class="modal-body">
                        <slot />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>

import { ref, defineExpose } from 'vue';
const isOpen = ref(false);

const closeModal = () => {
    isOpen.value = false;
};

const openModal = () => {
    isOpen.value = true;
};
defineExpose({ closeModal, openModal, });

</script>

<style scoped>
.textoAmarillo {
    color: #feca65;
}
.text-gris {
    color: #6E6E6E !important;
}

.btn-back {
    font-size: 2.5rem;
    z-index: 2;
    text-decoration: none;
}

.btn-atras {
    font-size: 2.0rem;
    z-index: 2;
    text-decoration: none;
}

.btn-atras:hover {
    cursor: pointer;
}

/* Eliminando estilos adicionales de los botones al ser btn-link de Bootstrap */
.btn-back:focus,
.btn-atras:focus {
    outline: none;
    box-shadow: none;
}

/* Estilos del scrollbar personalizado */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.227);
    border-radius: 100px;
}

::-webkit-scrollbar-thumb {
    background: #feca65;
    border-radius: 100px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    max-width: 90%;
    width: 500px;
    border-radius: 90px 0 90px 0;
    background-color: #FFFFFF;
    overflow: hidden;
}

.modal-body {
    max-height: 80vh;
    overflow-y: auto;
}

.shadow {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.8);
}

/* Transiciones */
.slide-enter-active,
.slide-leave-active {
    transition: transform 0.5s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}
</style>
