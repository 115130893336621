<template>
    <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="-100 -10 394.45 224.59">

        <g id="Capa_1-2" data-name="Capa 1">
            <g>
                <path class="cls-1"
                    d="m159.88,0H30.9C13.88,0,0,13.88,0,30.9v128.98c0,17.02,13.88,30.9,30.9,30.9h128.98c17.02,0,30.9-13.88,30.9-30.9V30.9C190.78,13.88,176.9,0,159.88,0h0ZM30.93,11.2h129.15c10.76,0,19.51,9.36,19.51,20.87v55.66H11.2v-55.42c0-11.52,8.97-21.11,19.73-21.11h0Zm128.95,168.39H30.9c-10.75,0-19.48-8.73-19.48-19.48v-66.06h168.17v65.83c0,10.75-8.96,19.7-19.7,19.7h0Z" />
                <path class="cls-2"
                    d="m68.97,47.69c0,11.13-9.02,20.15-20.15,20.15s-20.15-9.02-20.15-20.15,9.02-20.15,20.15-20.15,20.15,9.02,20.15,20.15" />
                <path class="cls-2"
                    d="m90.24,39.63h68.07c3.13,0,5.6-2.46,5.6-5.6s-2.46-5.6-5.6-5.6h-68.07c-3.13,0-5.6,2.46-5.6,5.6s2.69,5.6,5.6,5.6Z" />
                <path class="cls-2"
                    d="m90.24,68.3h68.07c3.13,0,5.6-2.46,5.6-5.6s-2.46-5.6-5.6-5.6h-68.07c-3.13,0-5.6,2.46-5.6,5.6,0,2.91,2.69,5.6,5.6,5.6Z" />
            </g>
        </g>
    </svg>
</template>

<style scoped>
.cls-1 {
    fill: #737373;
}

.cls-2 {
    fill: #ffcb00;
}</style>