<template>
	<div class="text-center ">
		<text class="titulo-svg">
			GESTIÓN NACIONAL
		</text>
	</div>

	<div class="svg-container" id="divSvg" @mouseenter="scrollPrimeraEntrada('divSvg')" @mouseleave="primera_entrada = true" style="padding-block:5%">
		<svg class="rotate-3d p-5" version="1.1" id="Capa_CV" xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-120 -10 850 1834"
			style="enable-background:new 0 0 729.6 1834.2;" xml:space="preserve">
			<path @click="scrollPage('antofagasta')"
				@mouseenter="switchClass('card-antofagasta')"
				@mouseleave="switchClass('card-antofagasta')" id="antofagasta" class="cv_st0" d="M238.1,227.2c-1-1.4-3.5-2.9-5-3.7c-3.5-2-7.5-1.1-11.1,0.3l-4.4,1.6c-1.4,0.5-2.4,1.4-3.7,2
		c-3.7,2-8.2,2.6-12,4.3c-1.6,0.7-2.7,1.9-4.4,1.1s-2.9-2.8-3.6-4.2c-2.3-4.2-2.4-9-5.3-12.9s-4.1-5.6-5.9-8.5
		c-0.6-0.9-2.9-1.7-4-2.3s-1.5-2.3-2.6-3.4c-1-1-1.9-2.1-2.6-3.4c-2.3-3.8-5-7.5-7.9-10.9c-2-2.5-4.1-4.7-5.6-7.4s-2.7-8-4.2-12.1
		s-3.7-8.4-8.4-10c-1.4-0.4-2.7-0.8-4-1.4c-0.9-0.6-1.9-1-2.9-1.3c-1.3-0.4-3,0.3-4.3,0.1c0.3,0.5,0.2,1.1-0.1,1.6
		c-0.1,0.1-0.1,0.4-0.2,0.7l-0.3,0.9l-0.6,0.9l-0.3,0.6c-0.3,0.8-0.6,1.5-0.8,2.3c-0.1,0.6-0.3,1.2-0.7,1.7c-0.3,0.2-0.6,0.4-0.9,0.6
		c0,0.1,0,0.2-0.1,0.2c-0.1,0.3-0.2,0.7-0.3,1c-0.4,0.7-0.8,1.4-1.3,2l-0.9,1.4c-1,1.3-2,2.7-3.1,4l-2.4,2.5l-2,2
		c-0.6,0.7-1.3,1.3-2,1.8l-1.3,1.1c-0.4,0.4-0.8,0.8-1.1,1.3c-0.7,0.8-1.5,1.5-2.3,2.2c-1.8,1.4-3.8,2.8-5.7,4.1
		c-0.8,0.6-1.6,1-2.5,1.4c-1.1,0.5-2.1,1.1-3,1.9c-0.7,0.8-1.2,1.7-1.5,2.7c-0.9,2.9-2,5.9-3.7,7.9c-0.3,0.5-0.8,0.8-1.3,1h-0.7
		c-1.2,0-2.3-0.5-3-1.4l-0.4-0.3l-0.6-0.5l-0.9-0.2c-0.6-0.1-1.1-0.3-1.6-0.6l-1.3-0.5h-1.1c-0.8,0.1-1.5,0-2.3-0.1
		c-0.6-0.2-1.2-0.5-1.8-0.8l-0.4-0.3c-1-0.5-2.2-0.7-3.4-0.6h-2c-2.9-0.1-5.4-0.2-7.8,0.8H70c0.2,0.4,0.3,0.9,0.4,1.4
		c0.5,2.2,1.3,4.2,1.8,6.4c0.7,2.9,1.5,5.8,2.6,8.6c0.9,2.3,1.1,1.6,1.4,3.9c0.6,4.1,0.6,8,1,12.1c0.3,2.8,0.9,5.7,1.7,8.4
		c0.9,4,1.8,8.2,2.4,12.3s0.7,5.6,1.3,8.4c0.9,4.3,2.6,8.5,3.8,12.7c0.7,1.9,1,4,0.8,6c-0.5,4.6-3.6,3.6-7.2,3.7
		c-0.2,1.4-1,1.9-1.5,3.1c-0.4,1.8-0.4,3.6,0,5.4c0.7,2.8,1.6,5.7,2.4,8.5c0.4,1.6,1.4,2.9,2.7,3.9c1.3,0.8,1.6-0.1,3.1-0.4
		s2.3,1.6,2.9,3.5s0.3,4.1,0.9,6.2s0.6,3.2,1,4.8s2.2,6,3.3,9s1.7,5.7,2.4,8.5s1.8,5.9,2.4,8.9s0.8,9.1,2.8,13c1,1.9,2.2,3.7,3.4,5.5
		c2,2.5,5.1,3.8,6.9,6.4c4.3,6.2,2.2,16.4,5.7,22.9c0.7,1.6,0.9,3.4,0.6,5.2c-0.4,2.1-0.3,4.5-1,6.5s-0.6,3.5-1.2,5.3
		s-1.8,3.8-1.8,5.4c0.1,2.7,1.7,5.1,4.1,6.3c2.7,1.1,5.6,2.8,7,5.5c0.6-0.5,1.3-0.8,2-1.1l1-0.4c0.9-0.6,1.8-1.2,2.6-2
		c1-1,2.2-1.9,3.4-2.6c1.9-0.9,3.6-2.2,4.9-3.8l0.3-0.4c0.6-1.1,1.8-1.8,3.1-1.9c0.8-0.1,1.5,0,2.3,0.1h1.4c1.2-0.1,2.3,0,3.5,0.1
		c1.7,0.1,3.5,0,5.2-0.1c2.8-0.3,5.5-0.9,8.1-1.8c1.7-0.9,3.2-2.2,4.4-3.8c0.6-0.7,1.2-1.3,2-1.8l1-0.8c0.6-0.6,1.1-1.2,1.6-1.8
		l1-1.3c0.3-0.3,0.6-0.6,0.8-1c0.5-0.7,1.1-1.4,1.8-2c0.8-0.6,1.7-1,2.7-1.2l0.5-0.2c2.9-1,4.5-1.9,5.4-4.3c0.6-1.6,0.9-3.2,0.8-4.9
		c0-0.8,0-1.5,0.1-2.3c0.1-1.9-0.2-3.7-0.9-5.5v-0.4c-0.9-1.8-1.2-3.8-0.8-5.8c0.8-3.3,3.1-5.4,6.7-6.3c0.8-0.2,1.5-0.2,2.3-0.2h1.5
		l3.3-1.2l1-0.4c0.7-0.2,1.4-0.4,2.1-0.5c0.5,0,1-0.1,1.5-0.3h0.1c0.4-0.2,0.8-0.1,1.2,0.1c-0.4-1.2-0.5-2.4-0.5-3.6
		c-0.1-1.7-0.1-3.4,0-5.1c0.2-1.4,0.3-2.7,0.5-4.1s1.8-3.5,2.5-5.5c0.7-2,0.9-4.1,0.6-6.2c-0.4-2.5-1.7-3.3-4-4.3s-3.6-1.3-5.1-2.7
		s-3.7-3.4-3.9-4.8c-0.7-3.7,4.3-7.4,6.3-9.8s5.6-6.3,8.2-9.8c1.4-1.8,3.2-3.1,4.5-4.9c0.8-1.5,1.9-2.9,3.2-4.1c2.2-1.8,4.7-3,7-4.7
		s4.1-3.1,6.3-4.5c4-2.7,6.9-5.2,7.9-10c0.4-2,0.3-4.2-0.5-6.1c-0.7-2.5-1-5.1-1.6-7.6s-1.9-8.1-1.8-12.3c0.2-3.9,0.9-7.8,2.2-11.5
		c0.7-2.2,1.8-4.4,2.3-6.6s1-2.7,1.2-3.8C239.7,231.6,239.3,229.2,238.1,227.2z" />
			<path @click="scrollPage('biobio')" @mouseenter="switchClass('card-biobio')"
				@mouseleave="switchClass('card-biobio')" id="biobio" class="cv_st0" d="M312,996.6c-1.4-3.4-4.5-6.4-6.8-9.3s-2.2-6.2-3.4-9.4c-2.2-6-4.3-12-6.5-18.1
		c-0.6-1.8-1.3-3.6-2.1-5.4c-0.7-1.3-1.4-2.7-2-4.1l0,0l-2.8,1.4c-1.9,1.1-3.8,2-5.8,2.8l-1.4,0.4c-2,0.7-3.3,1.1-4.4,2.7
		c-0.6,0.8-1.1,1.6-1.5,2.4c-0.9,1.5-1.9,2.9-2.9,4.2c-0.9,1.2-1.6,2.5-2,3.9c-0.3,0.8-0.6,1.7-1,2.6c-0.7,1.8-2.4,3.1-4.3,3.5
		c-0.7,0.2-1.3,0.5-1.9,0.9c-0.3,0.2-0.4,0.5-0.7,0.9c-0.2,0.6-0.6,1.2-1.1,1.7c-0.6,0.5-1.4,0.9-2.2,1.1l-0.7,0.2
		c-0.8,0.4-1.5,0.9-2.2,1.4c-1.1,0.8-2.3,1.4-3.5,1.9l-2.1,0.5c-1.9,0.1-3.8,0-5.6-0.4l-1.9-0.3H242c-1.9-0.1-4-0.3-5.4-1.5
		c-1.3-1.3-2.4-2.8-3.4-4.4l-1.2-1.8c-2.1-2.9-4.7-5.8-7.1-8.6s-4.3-4.8-6.3-7.4l-1.4-1.9c-0.9-1.6-2.1-3-3.6-4.1
		c-0.1,0-0.1,0-0.1-0.1v0.3c0.2,3,0.4,6.1,0.4,9.1c0.1,5.1,0.1,10.1,0.1,15.1c0,6.5,1,12.9,0.4,19.4c-0.3,2.1-0.3,4.5-0.8,6.7
		s-0.7,3.9-1.8,4.9s-3.4-2-5.2-2.9s-3.3-0.4-4.6-0.7s-2.7-0.8-3.6,0.2s0.8,1.9,1.6,3s1.9,3.2,3.1,4.8s3.2,4.6,2.9,7.1
		c-0.3,1.1-0.3,2.3-0.2,3.5c0.2,1.9,0.6,3.7,1.2,5.5c0.5,1.6,1.1,3.2,1.9,4.7c0.4,0.7,0.8,1.4,1.2,2c0.7,0.9,0.4,0.5,1.5,0.9l3.8,1.5
		c2.2,0.9,2.8,1.9,4.2,4.1s3.7,5.6,5.9,8.6s2.7,3.9,2.4,6.7s-0.7,3,0,5.1l0.2,0.5c1.1-0.7,2.3-1.1,3.5-1.4c1.5-0.4,2.8-0.8,3.5-1.7
		v-0.4c0-0.2-0.1-0.4-0.1-0.6v-1.2c0-0.6,0-1.2-0.1-1.8c-0.1-0.9-0.3-1.7-0.6-2.5c-0.7-1.8,0.1-2.8,0.7-3.8s0.9-1.2,1.4-1.7
		s2.2-2.5,2-3.3c-0.2-0.8-0.6-1.6-1-2.3c-0.4-0.6-0.7-1.2-0.9-1.9c-0.1-0.4-0.3-0.8-0.5-1.2c-0.3-0.2-0.6-0.4-0.8-0.6
		c-0.5-0.5-0.9-1.2-1.2-1.9v-0.3c-0.1-0.3-0.3-0.7-0.5-1c-0.6-0.9-0.9-2-0.9-3.1c-0.1-0.9-0.1-1.7-0.1-2.6c-0.1-1.8-0.1-3.6-0.3-5.3
		l-0.3-0.8l-0.3-1c0-0.3-0.1-0.7-0.1-1v-0.5c-0.2-0.3-0.5-0.5-0.8-0.7l-1-0.8c-0.5-0.6-0.6-1.5-0.1-2.1c0.9-0.9,2.1-1.4,3.3-1.6
		l0.7-0.2l10.6-3.8l1.3-0.5c1.1-0.6,2.4-1,3.6-1.2c3.5-0.5,6.9,0.9,9.1,3.7c0.4,0.4,0.7,0.9,1,1.4c0.4,0.9,1,1.6,1.7,2.3l1.7,1.3
		c0.6,0.6,1.1,1.2,1.5,1.9c0.9,0.1,1.8-0.1,2.7-0.4l1.7-0.4l1.3-0.2l2.8-0.4h0.4c1-0.2,1.9-0.2,2.9-0.1c0.9,0.3,1.8,0.8,2.6,1.4
		c0.7,0.5,1.5,0.8,2.4,0.8c0.6,0,1.2-0.2,1.7-0.5l0.6-0.5c0.7-0.6,1.6-1,2.5-1.2c3-0.4,4.9,2.2,5.8,3.4l0.8,1.3
		c0.3,0.8,0.8,1.4,1.4,2c0.6,0.3,1.7-1.2,2.3-2l0.6-0.8l2.9-3.7l0.4-0.8c0.1-0.5,0.3-0.9,0.6-1.3c0.7-0.8,1.5-1.5,2.4-2.1
		c1.6-0.9,3.4-1.7,5.2-2.1l1.1-0.3l2-0.5c0.5,0,1-0.1,1.5-0.3h0.4l1.5-0.4h2.5c-0.2-0.7-0.5-1.3-0.7-2
		C314.9,1004.7,313.7,1000.5,312,996.6z" />
			<path @click="scrollPage('niuble')" @mouseenter="switchClass('card-niuble')"
				@mouseleave="switchClass('card-niuble')" id="niuble" class="cv_st0" d="M215.3,949.5c1.9,1.3,3.5,3,4.6,5l1.3,1.8c1.9,2.5,4.1,4.9,6.1,7.3s5.1,5.7,7.4,8.8l1.3,1.9
		c0.8,1.3,1.7,2.6,2.8,3.7c1.1,0.5,2.2,0.8,3.4,0.7l1.4,0.2l2,0.2c1.5,0.3,3.1,0.5,4.7,0.4c1.5-0.3,3-0.9,4.2-1.9
		c0.8-0.6,1.7-1.1,2.6-1.5l1.2-0.5c0.3,0,0.7-0.2,0.9-0.4l0.4-0.6c0.3-0.9,0.9-1.6,1.7-2.2c0.9-0.6,1.8-1,2.8-1.3
		c1.2-0.4,1.8-0.5,2.3-1.7c0.4-0.8,0.7-1.6,0.9-2.4c0.6-1.8,1.4-3.5,2.6-5c1-1.2,1.8-2.4,2.6-3.7l1.6-2.6c1.8-2.5,4-3.2,6.1-3.9
		l1.3-0.4c1.9-0.7,3.7-1.5,5.4-2.5l2.8-1.5l0.7-0.3c-0.2-0.7-0.4-1.5-0.4-2.3c0.3-0.8,0.7-1.7,1.2-2.4c1-2.1,1.5-4.5,1.3-6.8
		c-0.5-0.6-0.9-1.3-1.1-2l-0.5-1.2c-1.3,0.5-2.5,1.1-3.6,1.9c-0.8,0.6-1.7,1.1-2.6,1.6l-1.5,0.6c-1.1,0.3-2,0.9-2.8,1.7
		c-2.2,3-4,3.4-7.3,3.5l-2.9,0.3c-2.3,0.4-4.5,0.4-6.8,0h-0.7c-1-0.2-1.9-0.5-2.8-0.9c-1.2-0.7-2.1-1.9-2.6-3.2l-0.6-1.1
		c-0.6-0.8-2-0.6-4.5,0.7c-0.3,0.1-0.3,0.1-0.5,0.6c-0.2,0.4-0.4,0.8-0.7,1.2c-1.5,2.1-3.6,1.3-4.8,0.9l-0.9-0.3
		c-0.8-0.2-1.6-0.5-2.3-0.9l-0.6-0.4c-0.4-0.3-0.8-0.5-1.2-0.6h-0.4c-0.4,0.2-0.5,0.3-0.7,1.1v0.4c-0.1,1.2-0.9,2.2-2,2.7
		c-0.7,0.3-1.4,0.5-2.1,0.5h-1.2c-1,0.3-2.1,0.3-3.2,0.1c-1.1-0.3-2-0.9-2.8-1.7l-0.3-0.3c-0.9-1-2.2-1.5-3.6-1.5
		c-0.8,0-1.6,0.1-2.4,0.3l-2.6,0.2l-1.4-0.2h-1.3c-1.2,0.1-2.3,0.3-3.4,0.7c-0.3,0.1-0.5,0.1-0.8,0c0.6,1.6,0.9,3.3,0.9,5
		c0,1.2,0,2.3-0.2,3.5L215.3,949.5z" />
			<path @click="scrollPage('araucania')"
				@mouseenter="switchClass('card-araucania')"
				@mouseleave="switchClass('card-araucania')" id="araucania" class="cv_st0" d="M314.9,1014l-0.6,0.2l-0.6,0.2l-2,0.4c-0.5,0-1.1,0.1-1.6,0.3l-1.1,0.3c-1.5,0.4-2.9,1-4.2,1.7
		c-0.6,0.4-1.2,0.9-1.7,1.4l-0.3,0.6c-0.2,0.6-0.5,1.1-0.8,1.6c-1,1.2-2,2.4-2.9,3.7l-0.5,0.7c-1.1,1.4-3.3,4.4-6.2,3
		c-1.3-0.7-2.3-1.9-2.9-3.3c-0.2-0.4-0.4-0.7-0.6-1c-1-1.4-1.8-2.2-2.8-2.1c-0.3,0.1-0.6,0.3-0.9,0.5l-1.2,0.9
		c-0.9,0.4-1.8,0.7-2.7,0.8c-1.6,0.2-3.2-0.3-4.5-1.4c-0.5-0.4-1.1-0.7-1.7-0.9c-0.5,0-0.9,0-1.4,0.2h-0.5l-3,0.4l-1.3,0.2l-1.3,0.3
		c-1.8,0.5-4.4,1.1-5.9-0.5l-0.3-0.4c-0.2-0.4-0.5-0.9-0.8-1.2l-1.8-1.5c-0.9-0.8-1.7-1.8-2.3-2.8c-0.2-0.5-0.5-0.9-0.9-1.3
		c-1.4-1.9-3.8-2.8-6.1-2.4c-1,0.2-1.9,0.5-2.8,0.9l-1.4,0.6l-10.6,3.8l-0.9,0.2l0.6,0.9c0.2,0.5,0.3,1.1,0.3,1.7v0.4
		c0.1,0.2,0.2,0.5,0.2,0.7c0.2,0.4,0.3,0.9,0.4,1.4c0.2,1.9,0.4,3.7,0.4,5.6c0,0.9,0,1.7,0.1,2.6c0,0.6,0.2,1.2,0.5,1.7l0.6,1.2
		l0.2,0.5l0.4,0.7c0.4,0.3,0.8,0.7,1.2,1.1c0.4,0.6,0.7,1.3,0.9,2c0.1,0.4,0.3,0.8,0.5,1.2c0.7,1,1.2,2.1,1.5,3.3
		c0.5,2.4-1.2,4.5-2.7,6.1l-1.1,1.3l-0.5,0.8c0.4,1,0.7,2.1,0.9,3.2c0.1,0.8,0.1,1.7,0.1,2.5v1.4c0.1,0.9-0.2,1.8-0.7,2.6
		c-1.4,1.8-3.4,2.4-5.2,2.9c-0.9,0.1-1.7,0.4-2.5,0.9l-0.7,0.6l0.3,0.9c0.6,1.7,1,3.5,1.3,5.3c1.4,0.1,3.6,2.2,4.5,3.4s0.6,1.8,1,2.7
		s1,1.4,1.5,2.1s2.2,4.2,3.7,6c0.8,0.9,1.5,1.8,2.2,2.8c0.5,0.8,0.9,1.6,1.5,2.4c2,2.7,4.8,4.3,6.9,6.8c1.8,1.9,2.8,4.5,2.9,7.1
		c0.3-0.3,0.8-0.4,1.2-0.4c1.7,0.2,4-0.2,4.7-1.2s0.5-2.1,0.4-3.3v-1.3c0-1.4,0.5-2.8,1.3-3.9c1-1.6,1.6-2.5,2.7-2.9
		c1.1-0.4,2.8,0.4,3.5,2.5c0.1,0.2,0.1,0.4,0.2,0.6l1.3,2.5c0.5,1,1,1.4,1.2,1.4c0.6-0.2,1.2-0.4,1.7-0.7c0.7-0.4,1.5-0.7,2.3-0.8
		l1.1-0.2l1-0.2l0.8-0.6l1.2-0.9c1-0.6,2-1.2,3.1-1.7c1.2-0.7,2.5-1.1,3.9-1.1c0.6,0.1,1.2,0.2,1.7,0.5c0.1,0.1,0.2,0.2,0.4,0.2
		c1,0.1,2,0,2.9-0.4l2.3-0.7l1.7-0.2c1.7-0.1,3.4-0.5,5-1.3c1-0.6,1.2-1.3,1.6-2.6c0.2-0.6,0.4-1.3,0.7-1.9c1.9-4.3,6.4-3.7,9.4-3.3
		l2,0.2h0.7c1-0.1,2.1,0.1,3,0.6l1.2,0.5l1.1,0.5l1.4,0.8c0.3,0.3,0.7,0.5,1.1,0.6h0.5l1.5,0.6c1-1.5,1.8-3.2,2.3-4.9
		c0.8-3.1,0.2-6.4-1.6-9c-1-1.7-2.2-3.3-3.6-4.7c-1.6-1.6-3.2-3.1-3.7-5.5s0.1-5.4,0.3-7.8s3-6.8,5.5-7.7s2.7-0.2,3.9-0.6
		c0.7-0.4,1.4-0.9,2-1.4c1.4-0.8,1.6-1.4,2.3-3c1.1-2,1.7-4.3,1.7-6.6c-0.2-1.3-0.8-2.6-1.7-3.5c-0.7-1-1.7-3-2.8-3.8
		s-2.2-0.8-3.2-1.4c-3.2-1.9-4.6-5.3-5.8-8.7c-0.6-1.7-1.3-3.2-2-4.8c-0.8,0.2-1.7,0.3-2.5,0.3L314.9,1014z" />
			<path @click="scrollPage('region_metropolitana')"
				@mouseenter="switchClass('card-regionmetropolitana')"
				@mouseleave="switchClass('card-regionmetropolitana')" id="region_metropolitana" class="cv_st0"
				d="M266.9,760.9c-0.3,0.7-0.7,1.3-1.2,1.9c-1.1,1.3-2.4,2.5-3.8,3.6l-0.8,0.7
		c-1.5,1.2-3.3,2.7-5.3,3.1c-2,0.4-5-0.2-7.1-0.8c-1.3-0.4-2.5-0.9-3.6-1.6c-0.7-0.4-1.5-0.8-2.3-1.1c-1.1-0.3-2.3-0.4-3.4-0.2
		c-2.5,0.2-3.4,0.8-4.4,3l-0.2,0.3c-0.7,1.6-1.2,2.5-3.2,3.2h-0.1l-0.6,0.2c-0.8,0.3-0.8,0.3-1.1,0.7l-0.3,1.4c0,0.4-0.1,0.7-0.1,1.1
		l-0.2,0.9c-0.3,0.7-0.4,1.4-0.4,2.2c0.2,2.1,0.6,4.1,1.1,6.1c0.4,1.7,0.5,3.5,0.4,5.3c-0.1,1.2-0.1,2.3,0,3.5
		c0.2,1.1,0.5,2.2,0.7,3.3l0.2,0.8c0.2,0.8,0.5,1.5,0.8,2.2c0.3,1,0.7,1.9,0.9,2.9c0.2,1.5,0.3,3,0.1,4.5c-0.3,2.1-1.9,2.9-3,3.6
		l-0.9,0.5c-1.3,0.6-2.4,1.5-3.3,2.5c-0.2,0.3-0.6,1.2-0.9,4.2c0,0.3,0.1,0.7,0.1,1.1s0.1,0.3,0.1,0.4l0.7,0.5
		c0.4,0.2,0.9,0.2,1.4,0.2h1.5l1.2,0.3l1.3,0.2c0.3,0.1,0.6,0.1,0.9,0c1.1-0.1,2.2,0.1,3.2,0.5c2.1,1.1,4.4,1.7,6.8,2
		c0.3,0,0.5-0.1,1-0.5l1.2-0.7c0.5-0.3,1.1-0.5,1.7-0.6c0.4,0,0.8-0.2,1.1-0.4c0.2-0.1,0.3-0.3,0.5-0.5l1-0.9
		c0.3-0.3,0.7-0.5,1.1-0.7l0.5-0.3l0.3-0.2c2.4-1.5,2.7-2.1,2.7-2.3c-0.1-0.4-0.2-0.7-0.3-1.1c-0.7-1.4-0.6-3.1,0.4-4.4
		c0.7-0.7,1.6-1.2,2.6-1.4l0.7-0.3c0.7-0.3,1-0.8,1.5-1.9l0.3-0.5c0.7-1.4,1.7-2.8,2.8-3.9c0-0.1,4.9-4.6,7.8-3.8
		c0.4,0,0.7,0.2,0.9,0.5h0.6c0.8,0.4,1.3,1.1,1.5,1.9c0.1,0.5,0.2,1.1,0.3,1.6c0,0.4,0.1,0.8,0.2,1.2l0.2,0.6
		c0.4,1.1,0.6,1.7,1.3,2.1c0.5,0.4,1.1,0.7,1.8,0.7l0.3-0.3l1.1-1.1c2.6-1.7,4.2,0.4,4.9,1.4l0.5,0.6c0.9,1,2,1.8,3.1,2.6l1.1,0.7
		l1.8,1.2c1.1,0.4,2.3,0.4,3.5,0.2c0.8-0.1,1.5-0.5,2-1l0.5-0.5c0.5-0.7,1.2-1.2,1.9-1.6v-2.6c0.3-4,1.4-7.1-1.8-10.2
		c-1.7-1.5-3.7-1.7-4.6-3.9c-0.6-1.3-1.3-2.6-2.1-3.8c-1.9-3.6-1.6-8.9-1.7-12.9c0-1.2,0.1-2.3,0.3-3.5c0-0.4,0.7-2,0.6-2.6
		c-0.1-1.6-0.7-3.2-1.6-4.5c-1.6-2.3-5-6-8-5.5s-3,1.2-5,1.1s-2.1-1-3.4-1.8l-1.5-1.4c0,0.3,0,0.5-0.2,0.7L266.9,760.9z" />
			<path @click="scrollPage('maule')" @mouseenter="switchClass('card-maule', true)"
				@mouseleave="switchClass('card-maule')" id="maule" class="cv_st0" d="M288.1,857.3l-0.6,0.2c-0.7,0.3-1.3,0.6-1.9,1.1l-0.5,0.3c-1,0.7-2,1.2-3.1,1.6l-0.5,0.2
		c-1.4,0.4-2.9,0.2-4.1-0.6c-1.1-0.5-2.1-1.2-2.9-2.1l-1.1-0.9c-0.9-0.8-2.5-0.3-4.4,0.5c-0.9,0.3-1.6,0.8-2.1,1.6l-0.5,0.4
		c-0.8,1.2-2.1,2-3.6,2.2c-2,0.2-3.4-1-4.5-2l-1.4-1c-1.1-0.4-2.3-0.6-3.5-0.5h-1.2c-0.7-0.1-1.4-0.1-2.1,0c-0.8,0.2-1,1.1-1.2,3
		s-0.1,1.1-0.1,1.5c-0.3,1.3-1.4,2.3-2.7,2.6l-0.7,0.3c-0.5,0.3-0.9,0.7-1.3,1.2l-0.6,0.8c-0.9,0.9-1.9,1.7-3,2.4
		c-2.3,1.4-7.2,3.7-11,3.5c-1.8-0.1-3.1-1.2-4.3-2.1c-0.7-0.6-1.5-1.1-2.4-1.4l-1.1-0.3c-1.1-0.3-2.3-0.6-3.5-0.7h-0.8
		c-0.3,0.2-0.6,0.2-0.9,0.2c-0.9,3.7-1.6,7.4-2,11.2c-0.2,2,0.3,4,0.2,6.1c-0.1,1.9-0.4,3.8-0.8,5.7c-0.3,2.4-1.6,3.8-2.3,6.1
		c-1.4,4.3,0.4,8.6,0.5,12.9s1.1,8.3-0.1,12.6c-0.5,1.7-0.5,3.4-1.2,5s-1.7,2.3-0.9,4.1l2.1,4.7c0.2-0.3,0.5-0.5,0.8-0.6
		c1.4-0.5,2.8-0.7,4.2-0.8c0.7-0.1,1.3,0,2,0.1h1l2-0.2c1-0.2,2.1-0.2,3.1-0.2c2.1,0,4,0.8,5.5,2.2l0.4,0.4c0.4,0.4,0.8,0.7,1.3,0.9
		c0.7,0.1,1.3,0,2-0.1l1.4-0.2h1c0.1-0.1,0.2-0.2,0.2-0.4v-0.6c0.2-1.4,1.1-2.6,2.4-3.3c0.7-0.4,1.5-0.5,2.3-0.2h0.3
		c0.7,0.2,1.5,0.6,2.1,1l0.5,0.3c0.5,0.3,1,0.5,1.6,0.7l0.9,0.2l1.3,0.4l0.4-0.8c0.4-0.9,1.1-1.7,2-2.1c1.5-0.7,6.2-3.1,8.5,0.4
		c0.3,0.5,0.6,1,0.8,1.5c0.3,0.8,0.8,1.4,1.4,1.9c0.6,0.2,1.2,0.3,1.8,0.4l0.8,0.2c1.9,0.3,3.9,0.3,5.8-0.1l3.1-0.3
		c2.7-0.1,3.4-0.3,4.8-2.2c1.1-1.3,2.6-2.3,4.2-2.8l1.3-0.5l2.3-1.4c1.4-1,2.9-1.7,4.5-2.2h0.9l-0.2-0.5c-0.8-2.6-0.1-5,1.1-7.4
		s3.9-4,6.7-6.1s6-5,6.9-8.6s-0.7-4.3-2-6.6s-0.6-4.5-1-6.5c-0.5-2.5-1.2-4.9-2-7.3c-0.8-2.2-0.4-5.9-2.6-7.3s-4.3,0-6.5-0.2
		c0.3,0.7-2-0.3-1.6,0.4c-1.6-1.8-6.5-6.5-4.2-8.8s7.7-5.6,6.4-9.5l-1.6-4.5C289.6,856.8,288.9,857.1,288.1,857.3z" />
			<path @click="scrollPage('lib_bdo_ohiggins')"
				@mouseenter="switchClass('card-libohiggins')"
				@mouseleave="switchClass('card-libohiggins')" id="lib_bdo_ohiggins" class="cv_st0" d="M285,815l-1-0.6c-1.4-0.9-2.7-2-3.8-3.2l-0.6-0.8c-0.2-0.3-0.4-0.6-0.7-0.8
		c-0.1,0.2-0.2,0.4-0.4,0.6c-0.5,0.8-1.2,1.3-2.1,1.5c-1.7,0.3-3.5-0.8-4.2-1.2c-1.2-1-2.1-2.3-2.5-3.8l-0.2-0.5
		c-0.2-0.7-0.3-1.3-0.4-2c0-0.2,0-0.5-0.1-0.7h-0.5l-0.6-0.5c-1.6,0.7-3.2,1.7-4.5,2.9c-0.9,1-1.6,2-2.2,3.2l-0.2,0.4
		c-0.6,1.5-1.7,2.7-3.1,3.4l-1.1,0.4c-0.4,0.1-0.9,0.2-1,0.4s-0.2,0.4,0.1,1.4c0.2,0.6,0.4,1.2,0.5,1.9c0.1,2.5-2.6,4.2-4.2,5.2
		l-1,0.6l-0.7,0.4l-0.5,0.5c-0.4,0.5-0.8,0.9-1.3,1.2c-0.6,0.3-1.2,0.6-1.9,0.7l-1.1,0.3c-0.3,0.1-0.5,0.3-0.7,0.5
		c-0.8,0.7-1.9,1.1-3,1.1c-2.8-0.3-5.4-1-7.9-2.3c-0.6-0.2-1.3-0.2-1.9-0.2h-1.1c-0.7,0-1.4-0.1-2.1-0.4l-0.9-0.2h-0.9
		c-0.8,0-1.6-0.1-2.3-0.3l-0.4-0.3c-0.7,1.2-1.9,2.1-3.2,2.5c-1.7,0.6-3.4,0.8-5.2,0.8l-0.4,0.2c-1.3,0.4-2.8,0.7-3.8,0.1
		c-0.1,1.7-0.5,3.5-1.1,5.1c-1.3,2.9-3.7,6.4-2.8,9.7s1.2,2,3.1,2.6c1.4,0.7,2.6,1.9,3.3,3.3c0.8,1.6,0.4,4.4,0.5,6.1
		c-0.1,2.7-0.1,5.3,0.2,8c0.2,1.4,0.8,2.7,1.6,3.9c0.6-0.1,1.3-0.2,1.9-0.1l3.9,0.8l1,0.3c1.3,0.4,2.6,1.1,3.6,2
		c1,0.7,1.8,1.4,2.6,1.4c2.5,0.2,6.5-1.4,9.1-3c0.9-0.6,1.7-1.2,2.4-1.9l0.5-0.6c0.5-0.8,1.1-1.4,1.9-1.9c0.5-0.3,1-0.5,1.6-0.7
		l0.5-0.2c0-0.4,0-0.7,0.1-1.1c0.2-1.8,0.4-4.6,3.2-5.7h0.1c1-0.3,2.1-0.3,3.2-0.2h1c1.7-0.1,3.4,0.2,5,0.9c0.7,0.3,1.4,0.8,2,1.4
		c0.6,0.7,1.4,1.1,2.3,1.3c0.4-0.1,0.8-0.5,1.5-1.2l0.3-0.4c0.8-1.1,1.9-1.9,3.2-2.4c2.3-0.9,5.3-2,7.7-0.2l1.4,1.2
		c0.6,0.6,1.3,1.1,2,1.6c1.1,0.5,1.4,0.5,1.8,0.4c1-0.3,2-0.8,2.8-1.4l0.5-0.3c0.8-0.6,1.7-1.1,2.6-1.5l0.8-0.2c0.1,0,0.1,0,0.1-0.1
		c0.3-0.7,1.1-1,1.8-0.9h0.3c-0.3-1.4-0.4-2.9-0.7-4.4s-0.9-3.5-0.5-5.5c0.5-2.4,0.7-4.9,0.6-7.3c0-3.2-0.9-6.2-1-9.3s-0.7-3,0-4.7
		s1.1-2.9,3-4.4l0.8-0.6c-1.5,0.1-2.9-0.1-4.3-0.6L285,815z" />

			<path @click="scrollPage('tarapaca')" @mouseenter="switchClass('card-tarapaca')"
				@mouseleave="switchClass('card-tarapaca')" id="tarapaca" class="cv_st0" d="M78.4,194.6h1.8c1.7-0.1,3.5,0.2,5,1l0.6,0.4l0.8,0.4h3.1c0.8,0.1,1.6,0.3,2.3,0.7l1,0.5h0.6
		c0.8,0.2,1.6,0.6,2.2,1.2l0.4,0.3l0.6,0.5c1.4-2,2.4-4.2,3-6.6c0.4-1.5,1.1-2.9,2.2-4.1c1.2-1,2.5-1.8,3.9-2.4l2.2-1.2l5.5-4
		c0.6-0.5,1.2-1.1,1.7-1.8l1.4-1.5l1.6-1.3l1.6-1.4c0.6-0.8,1.3-1.5,2.1-2.2l2.2-2.3c1-1.2,1.9-2.4,2.9-3.8l1-1.4
		c0.4-0.5,0.7-1,0.9-1.5c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.4,0.3-0.8,0.5-1.2c0.3-0.4,0.7-0.7,1.1-0.9c-0.1,0-0.1-0.2,0-0.4l0.9-2.6
		l0.6-1.1l0.4-0.5c0-0.2,0.1-0.4,0.1-0.6c0.2-0.5,0.4-0.9,0.6-1.4c0.3-0.5,0.8-0.7,1.4-0.7c-1.1-1.5-0.9-4.6-1-6.1s0.1-4.6-1-6.7
		s-6.8-0.2-10-1.1c-7.7-2,1.2-13-4.3-16.8c-1.3-0.7-2.6-1.2-4-1.6c-2.9-1.4-5.4-3.5-7.4-6c-1.7-2.4-2.7-5.1-0.8-7.6
		s11.6-5.5,6.6-10.8c-0.8-0.9-1.7-1.7-2.7-2.4c-0.7-0.5-1.5-0.7-2.1-1.1c-2.5-1.9-0.5-5.1,0.6-7.5s3.5-5.7,2.8-8s-3.2-3.1-4.5-3.6
		c-2.6-0.8-5-2-7.2-3.6c-1.2-1.1-2.5-2.1-3.9-3c-2.9-1.6-6.5-1.4-9.4-3.1c-0.1-0.1-0.2-0.1-0.2-0.2c0,0.7-0.3,1.5-0.9,1.9
		c-0.6,0.4-1.1,0.9-1.5,1.5l-0.6,0.7c-1.1,1.1-2.3,2.1-3.6,3l-1.2,0.8c-2.1,1.7-4,1.9-6.1,2.2l-1.3,0.2c-0.9,0.2-1.8,0.4-2.7,0.6
		c-1,0.3-2.1,0.6-3.2,0.7c-1.2,0.2-2.4,0.5-3.6,1l-6,1.9l-6,1.9l-2.7,1l-4.7,1.7c-0.9,0.4-1.7,0.9-2.4,1.5l-1.2,0.9
		c-0.7,0.5-1.5,0.9-2.4,1.2c-0.5,0.2-1,0.4-1.5,0.7l-0.8,0.7l-0.7,0.7c-0.7,0.5-1.4,1-2.2,1.3l-0.7,0.3c-1.5,0.8-2.7,1.9-3.7,3.3
		l-0.5,0.6c-0.3,0.4-1.3,1.6-1.3,2c0.1,0.7-0.4,1.3-1,1.6c1.4,2.8,3.1,5.3,5.1,7.7c2.6,3.1,4.8,6.5,6.4,10.2c0.7,1.7,1.1,3.5,1.8,5.3
		c1,2.1,2.2,4.1,3.6,6c2.3,3.5,4.1,7.3,5.4,11.2c0.9,2.6,2.2,5.3,2.7,8s0.7,4.3,1.3,6.5c0.6,1.7,1.1,3.5,1.4,5.3
		c0.3,2.4,0.7,4.7,1.4,7c0.4,2,0.9,3.9,1.6,5.8c1.2,3.3,1.4,6.3,2.2,9.6s2.9,5.1,5,7.6c1.4,1.5,3,2.9,4.7,4c0.8,0.5,1.6,1.3,2.1,2.1
		c0.1,0.2,0.2,0.4,0.4,0.6l0.4-0.3C72.4,194.3,75.3,194.5,78.4,194.6z" />
			<path @click="scrollPage('los_lagos')" @mouseenter="switchClass('card-loslagos')"
				@mouseleave="switchClass('card-loslagos')" id="los_lagos" class="cv_st0" d="M331.6,1154.3c-0.1,0.8-0.2,1.6-0.5,2.4c-0.1,0.2-0.1,0.5-0.2,0.8c-0.2,1.1-0.7,2.2-1.4,3.1
		c-1.2,1.6-2.9,2.8-4.8,3.3l-0.9,0.3l-2.1,0.8c-0.4,0.2-0.4,0.2-0.6,1c0,0.3-0.1,0.6-0.2,0.9c-0.9,2.8-1.7,5.1-4.2,7.2
		c-1.5,1.2-3.4,2-5.3,2.1h-1c-1,0.3-1.9,0.7-2.8,1.2c-0.9,0.6-1.9,1-2.9,1.3c-1.1,0.3-2.3,0.2-3.4-0.2l-2.9-0.6
		c-4.4-0.8-8.5-0.6-11.4,0.6c-1.4,0.6-2.7,1.3-3.8,2.3l-1,1.1c-0.3,0.5-0.7,0.9-1.2,1.3c-1.5,1.2-3.2,2.2-5,2.9l-2.6,1.2
		c-1.3,0.7-2.7,1.3-4.1,1.7c-1.1,0.3-2.1,0.7-3.1,1.2l-1.4,0.6l-2.9,1.5c-0.5,0.5-0.9,1-1.3,1.6l-0.9,1.3c-0.1,0.2-0.2,0.2-0.4,0.3
		c0.4,0.7,0.9,1.3,1.5,1.9c1.3,1.3,2.4,2.8,3.1,4.5c0.1,0.8,0.4,1.7,0.8,2.4c0.6,0.6,1.2,1.2,1.9,1.7c1.8,1.4,4,1.7,5.9,3.1
		c2,1.4,3.9,3.2,5.4,5.1c0.6,0.9,1.1,1.8,1.6,2.8c0.7,1.1,1.9,1.9,2.6,3s1.4,3.2,1.8,5.4s1,3.3,0.2,4.9s-3.4,2.4-5.3,2.3
		c-1.6-0.3-3.1-0.9-4.4-1.9c-0.5-0.7-1.2-1.3-1.9-1.8c-2.9-0.8-1.5,2.4-0.8,3.7c1,2,1.9,4,2.7,6.1c0.6,1.8,1.3,3.7,2,5.6
		s1.3,5.7,2.4,8.5s1.6,6,2.6,9s1.4,5.8,2.9,8.5c1.3,2.4,2.2,5,2.7,7.6c0.7,2.8,1.8,5.9,2.1,8.8s-0.6,4.2-1.2,6.5
		c-0.4,1.8-0.7,3.6-0.9,5.5c-0.1,2,0.1,4.1,1.9,5.2c0.5,0.3,1,0.5,1.5,0.6c0.3,0.2,1.1,1.1,1.5,1.3c2.2,0.7,4.9-1.4,7-1.1
		s3.8,2.2,5.6,3s3.2,0.6,4.7,1s5.6,1,8.2-0.4s3.9-3.7,3.1-5.9s-2.8-3.3-4.3-4.2c-4-2.2-8.5-7.4-6.8-12.3c1.1-3.1,7.6-6,4.9-9.6
		c-1-1.3-2.6-1.5-3.7-2.7s-1.4-2.3-2.7-3.2s-4.9-1.9-4.6-4.2s2.1-2.7,2.9-3.7s1.1-1.8-0.4-3s-4.4-1.4-5.4-2.9s-0.5-3.3,0.2-4.7
		s0.5-2.8,1-4.3s1.8-2.2,2.4-3.5s0.3-5.7-0.6-7.5c-0.6-1.5-1.8-2.6-3.3-3.2c-1.1-0.5-2.6-0.3-3.5-0.9s-2.5-3.5-2.4-3.5
		c-2-3.4-2.8-6.6-0.8-10.3c2.1,1.2,5.2-1.8,7.1-2.5s4.5-1.8,5.2-4.2s-1.2-5-1.4-7.2c0-1.4,0.1-2.7,0.3-4.1c-0.3-1,0-2.1,0.6-2.9
		c1.4-0.8,3.3,0.7,4.5,1.1s2.8,0.3,4.1,0.8s5.7,2.5,6.9,4.2c0.7,1.5,1,3.1,1,4.7c-0.2,0.9-0.4,1.8-0.8,2.7c-0.1,1.1,0,2.3,0.3,3.4
		c0.2,1.5,0.2,4.2,1.2,5.4c2.8,3.4,5.4-2.1,7.4-2.9c1.2,0.9,0.7,2.4,1,3.6s0.8,2.1,1.1,3.1s2.3,4.7,2.5,7.1c-0.6,0.7-2-0.2-2.7-0.4
		c-0.7-0.1-1.4-0.3-2.1-0.6c-2.2-0.8-4-0.4-3.8,2.4s1.6,5.2,2.5,7.7s2.2,4.5,2.2,6.4s-2,0.5-2.7,1.2s-0.7,2.6-0.6,3.8
		c0.2,3.5,1.4,7.8,3.8,10.6c0.8,0.9,1.7,1.8,2.8,1.7s2.1-1,3.2-1.1s3.5,0,4.5,1.2s0.1,2.9-0.3,4.2c-0.3,0.9-0.6,1.7-1,2.5
		c-0.6,0.6-1.4,0.9-2.2,1c-1.1,0.6-1.3,2.3-1.3,3.5c-0.1,3,2.7,5.5,3.2,8.5s-0.9,3.4-2.3,5.4c-0.7,1.4-1.1,2.9-1.2,4.5
		c-0.2,3.2,5.6,9.6,7.4,11.9h0.7c-0.2-0.2-0.4-0.4-0.5-0.7c-0.3-0.8,0.1-1.8,0.9-2.1c0,0,0.1,0,0.1,0c0.8-0.3,1.5-0.7,2.2-1.2
		c1.2-0.9,2.6-1.5,4-1.9l0.9-0.2l1.7-0.3l0.5-0.2c0.6-0.4,1.2-0.6,1.9-0.6c1.2,0,2.4,0.7,3.1,1.7l0.7,0.7c1.5,0.9,3.5,1.3,4.1,0.6
		l0.9-1.3c0.4-0.8,1-1.6,1.7-2.2c1.6-1.4,3.9-1.8,5.9-1.2c0.9,0.5,1.7,1.2,2.4,2c0.3,0.3,0.6,0.6,0.9,0.9c1,0.9,2.1,1.6,3.2,2.3
		l1.3,0.8c0.7,0.5,1.5,0.8,2.4,1h0.4l1.5,0.5c0.6,0.3,1.2,0.4,1.8,0.3c1.4-0.2,2.8-0.2,4.2-0.1h0.6c0.9,0,1.8,0.3,2.6,0.7
		c0.3,0.2,0.6,0.3,1,0.4c1,0.1,2,0,2.9-0.5h0.1c-1-2.2-3-3.8-4.2-5.9s-0.6-3.1-0.8-4.3c-0.2-1.5-0.5-3-1-4.4
		c-0.5-1.1-0.7-2.5-1.6-3.4c-0.8-0.8-1.6-1.5-2.5-2.2c-0.7-0.8-1.4-1.5-2.2-2.2c-0.8-0.5-2.3-0.3-2.9-0.9c-0.7-1.1-1.2-2.3-1.4-3.6
		c0-0.6,0.6-1.3,0.8-1.9c1.4-3.3,1.5-6.6-0.4-9.7c-0.8-1.4-3-7.2-5.2-5.5s-1.2,5.8-4,6.4s-3.9-2.3-4.6-3.8c-1.4-3.1-3.4-6-5.8-8.4
		c-1.2-1.3-3.2-3-3-5.1s1.3-2.1,1.4-3.3s-0.6-2.6-0.8-3.6c-0.2-1.5-0.4-2.9-0.8-4.3c-0.8-3.7-2.4-7.2-4.6-10.2
		c-0.6-0.9-1.3-1.8-2.1-2.5c-0.5-0.5-2.1-1.2-2.4-1.7c-0.4-0.8-0.6-1.8-0.5-2.7c-0.1-3.1,2.4-1.7,4.4-1.7s6.1-1,6.9-3.4
		s-3.4-7.2-4.9-9.8c-0.3-0.5-1.1-1.9,0.1-1.9c0.2,0,0.3-0.1,0.4-0.2c-2.7-4.6-6.1-8.3-8.2-13.2c-0.9-2.3-1.5-4.7-1.9-7.1
		c-0.6-2-1.4-4-2.2-6c-1.3-3.8-3.5-7-5.2-10.5s-1.9-4.9-3.2-7.3c-0.8-1.8-1.5-3.6-2-5.5l-1.5-3.9c0,0.5-0.1,1.1-0.2,1.6
		C331.7,1153.2,331.6,1153.8,331.6,1154.3z" />
			<path @click="scrollPage('rios')" @mouseenter="switchClass('card-rios')"
				@mouseleave="switchClass('card-rios')" id="rios" class="cv_st0" d="M322.6,1089.5l-0.7-0.2c-0.7-0.2-1.3-0.5-1.9-1l-0.9-0.5l-0.9-0.4l-1.5-0.7c-0.5-0.2-1.1-0.3-1.7-0.2
		h-0.7l-2.3-0.3c-3.2-0.4-5.2-0.5-6.1,1.5l-0.6,1.5c-0.3,1.8-1.4,3.4-2.9,4.4c-2.1,1.3-4.3,1.5-6.4,1.8l-1.5,0.2l-1.8,0.5
		c-1.5,0.7-3.1,0.9-4.7,0.5l-1-0.4l-0.6-0.2c-0.7,0-1.4,0.3-2,0.7c-1.1,0.5-2.1,1-3.1,1.6l-0.7,0.6c-0.4,0.4-0.9,0.8-1.5,1.1
		c-0.7,0.3-1.4,0.5-2.1,0.5h-0.8l-1.6,0.6c-0.8,0.4-1.6,0.7-2.4,0.9c-1,0.2-3,0.1-4.6-3l-1.3-2.6l-0.3-0.8l-0.5,0.7
		c-0.5,0.7-0.9,1.5-0.9,2.4v1.1c0.1,1.7,0.2,3.9-1,5.4s-5.2,2.8-7.7,2.5l-0.6-0.2c0.2,2.6,0.3,5.3,0.4,8s-0.8,6.2-0.8,9.3
		c0,1.1,0.4,2.4,0.5,3.5c-0.1,1.2-0.2,2.3-0.4,3.5c-0.3,2.3-0.2,4.6-0.4,6.9c-0.2,3.9-0.2,7.6-1.1,11.5c-0.4,1.7-0.7,2.2-0.3,3.8
		s1.8,4.2,2.7,6.1c0.6,0.8,1,1.8,1.1,2.8c0.1,1.8-1,3.5-0.7,5.3s2.4,4.2,3,6.5c0.6,2.3-0.3,5.7-0.8,8.7c-0.6,2.8-0.7,5.7-0.4,8.6
		c0.5-0.9,1.1-1.6,1.9-2.2c1.1-0.8,2.3-1.4,3.6-1.9l1.3-0.6l3.4-1.3c1.2-0.3,2.4-0.8,3.5-1.4c0.9-0.5,1.9-1,2.9-1.4
		c1.5-0.6,2.9-1.4,4.2-2.4l0.9-1l1.3-1.4c1.4-1.2,3-2.1,4.7-2.8c4.6-1.9,10.2-1.4,13.1-0.9l3.1,0.7c1,0.3,1.3,0.4,1.8,0.2
		c0.8-0.2,1.6-0.6,2.3-1c1.1-0.7,2.4-1.2,3.7-1.5l1.2-0.2c1.3,0,2.7-0.5,3.7-1.3c1.8-1.5,2.3-3.2,3.2-5.8c0-0.2,0.1-0.5,0.2-0.7
		c0.1-1.4,1-2.6,2.3-3.1l2.4-0.9l0.9-0.3c1.3-0.4,2.4-1.2,3.3-2.2c0.4-0.6,0.7-1.3,0.8-2l0.3-1c0.1-0.6,0.2-1.2,0.3-1.8l0.3-1.6
		c0.2-1.2,0.1-2.4-0.4-3.5c-0.3-0.8,0.1-1.7,0.9-2.1l0,0c0.4-0.1,0.8-0.1,1.2,0.1c0-0.1,0-0.3-0.1-0.4c-0.5-2.5,0.1-4.6,0.7-6.9
		s2.1-5,1.7-6.9s-3.7-4.3-5.4-6.2s-1.4-4.8-0.1-7s3.6-3.8,1-6.8s-6.2-5.7-7.4-9.5c-0.5-1.6-0.7-3.8,0.6-5.1s2.1-0.9,2.6-1.8
		s0.1-3.8,0-4.8c-0.1-0.4-0.2-0.7-0.3-1.1l-0.5-0.6L322.6,1089.5z" />
			<path @click="scrollPage('arica_parinacota')"
				@mouseenter="switchClass('card-aricaparinacota')"
				@mouseleave="switchClass('card-aricaparinacota')" id="arica_parinacota" class="cv_st0" d="M23.7,93.4l0.4-0.5c1.2-1.8,2.9-3.2,4.8-4.2l0.7-0.3c0.5-0.2,1-0.5,1.5-0.9l0.5-0.5
		c0.4-0.4,0.9-0.9,1.4-1.2l2.1-1l1.7-0.8l1.1-0.8c0.9-0.8,2-1.4,3.1-1.9c1.6-0.7,3.2-1.3,4.9-1.8l2.6-0.9c2.1-0.8,4.1-1.4,6.2-2
		l5.8-1.9c1.4-0.5,2.8-0.9,4.3-1.1l2.8-0.7l3-0.7L72,72c1.7,0,3.3-0.5,4.6-1.5l1.3-0.9c1.1-0.7,2.1-1.6,3-2.5l0.5-0.6
		c0.6-0.9,1.3-1.6,2.1-2.2l0.8-0.5L83,62.5c-0.8-1.4-1.4-2.8-2-4.2c-1.3-2.3-2.9-4.5-4.2-6.9s-3.7-6.9-6.9-9.8s-3.5-2.8-4.7-4.7
		s-2.8-3.4-3.9-5.2s-1.1-5.1-1.1-7.6s1.3-4.4,0.2-6.3c-2.5-4.5-9.3-0.3-13.1-1.5c-1.4-0.4-4-3.6-4.8-4.7c-1.7-2.7-1-6.4-3-8.9
		s-6.2-4.5-7.7-1.1c-0.4,0.8,0.4,1.9,0.2,2.9c-0.2,0.9-0.5,1.8-1.1,2.5c-2.2,2-5.7-0.7-7.9-0.6S17.2,9,17.4,12s1.4,3.6,2.3,5.6
		c0.3,1.2,0.8,2.3,1.4,3.3c1.1,1.4,1.9,2,2.4,3.7s0.9,2.3,1.3,3.5c0.7,2.1,0.9,4.3,0.4,6.4c-0.2,1.6-0.1,3.3-0.3,4.9
		s-2.4,5.2-4.3,7.1C17.4,50,13,52.2,8.3,52.8c-1.4,0.2-3.2,0.9-4.6,0.9S1.3,52.8,0,53.1c0.2,1.2,1.8,1.6,2.4,2.6s0.6,2.2,1,3.2
		s2.2,2.3,3.3,3.7c0.7,1,1.3,2.1,1.8,3.2c1.6,2.9,3,6,4.1,9.1c1.2,3.4,3.5,6.1,4.8,9.5s2.9,7.6,4.7,11.3C22.5,94.8,23,94.1,23.7,93.4
		z" />
			<path @click="scrollPage('coquimbo')" @mouseenter="switchClass('card-coquimbo')"
				@mouseleave="switchClass('card-coquimbo')" id="coquimbo" class="cv_st0" d="M217.8,599l-0.8,0.2c-0.5,0.2-1.1,0.3-1.7,0.3h-0.4l-0.8,0.3c-0.5,0.3-1.1,0.4-1.7,0.4
		c-0.5,0.1-1,0-1.5-0.1h-0.4c-1.3-0.1-2.7,0-4,0.2l-2.4,0.2c-0.7,0-1.5,0.2-2.2,0.4c-1.4,0.4-2.9,0.5-4.3,0.3
		c-1.9-0.5-3.7-1.4-5.3-2.6c-0.7-0.6-1.5-1.1-2.3-1.5c-2.4-1.3-3-3-3.5-4.9l-0.2-0.7c-0.4-1.2-0.7-2.4-1-3.6c-0.4-2-1-3.9-1.8-5.7
		c-0.9-1.9-3.5-3.9-6.8-5.2s-3-0.4-3.7,0.7l-0.4,0.6c-0.5,0.8-1,1.7-1.5,2.4c-0.5,0.7-1.2,1.3-2,1.8l-0.7,0.6
		c-2.1,1.8-1.7,6.6-0.3,10.7l0.3,0.7c0.4,1,1.3,3-1.3,4.5s-6.8-0.2-8.4-1.5c-0.8-0.7-1.5-1.4-2.2-2.2c-1-1.1-1.7-2-3.1-2.3
		c-0.8-0.2-1.6-0.2-2.4-0.1c-0.8,0.1-1.6,0.1-2.4,0c0.6,1,1.2,2,1.6,3.1c0.6,1.4,0.8,3.3,1.8,4.5c1.3,1.7,2.8,3.2,4.6,4.4
		c1.4,0.8,2.8,1,4,2c1.5,1.1,2.4,2.8,2.6,4.7c0.1,1.8-1.3,2.3-1.6,3.9s0.5,4.3,0.5,6.3s-0.8,3.6-1.1,5.7c-0.4,1.7-0.6,3.5-0.4,5.3
		c0.3,1.1,0.4,2.3,0.5,3.4c-0.3,3.2-1.7,6.2-4,8.5c-1.4,1.2-4.8,1.4-5,3.5s4.1,5.4,5,7.8s0.4,5,1.3,7.4s1.8,3.1,2.4,4.8
		c1,2.7,2.1,5.4,3.4,8c0.6,1.3,1,3.2,2.1,4.2s2.5,1.3,3.7,3.3c0.8,1.5,1.5,3,2.1,4.6c0.8,1.9,1.3,3.9,1.6,6c0.3,2,1.4,2.3,2.5,3.9
		s2.5,5.5,3.9,8s3.8,7.2,5.4,9.9c2,3.3,3.7,6.7,5.1,10.3c0.8,2.2,1.4,4.8,2.8,6.8s1.2,1,1.8,1.6c0.9,1.1,1.8,2.3,2.5,3.6
		c0.6-0.6,1.5-0.6,2.1-0.1c1.1,0.6,5.8-1.9,6.5-3.2c0.3-0.9,0.5-1.8,0.5-2.8c0.1-0.9,0.2-1.7,0.4-2.6c0.2-0.7,0.5-1.4,0.8-2
		c0.1-0.3,0.2-0.6,0.4-0.8c0.3-0.7,0.5-1.5,0.8-2.3c0.5-2.1,1.4-4.1,2.8-5.8l1.1-1c0.4-0.3,0.7-0.7,1-1.1c0.9-1.6,2.6-2.5,4.4-2.5
		c1.5,0,3.1,0.3,4.5,0.9c1,0.4,2,0.9,3,1.5l0.3,0.2l0.7,0.8c0.4,0.1,0.8-0.1,1.9-0.9c1.1-0.9,2.4-1.4,3.8-1.5
		c2.6,0.1,4.3,1.8,5.6,3.2l1.1,1.1c0.4,0.5,1.2,0.7,1.8,0.5c-0.1-0.6-0.1-1.2,0.1-1.7c0.8-2.3,7.7-7.6,3.2-9.9
		c-1-0.5-2.5-0.3-3.6-0.7c-1.4-0.7-2.8-1.6-4-2.6c-0.5-0.4-2.9-4.9-3.7-4.9c-2-1.8-5.2-3.5-6.4-6.1s-1.2-3.6-1.8-5.3
		c-0.4-1.6-1.2-3.1-2.3-4.4c-1.8-1.8-4.1-2.9-5.7-4.7c-3.5-3.8,1.1-5.9,3-9s1.4-6.8,1-10c-0.2-1.6-0.5-3.1-0.9-4.6
		c-0.8-1.9-2.6-3-3.7-4.7s-3.7-6.2-1.6-9.5c3.7-5.9,19.4-11.9,13.9-20.6c-1.4-2.3-3.3-2.7-5.5-3.9c-1.6-1.2-2.5-3-2.7-4.9
		c-0.2-3.6,2.2-6.8,1-10.4c-0.5-1.2-1-2.3-1.7-3.4h-0.6C218.8,598.6,218.5,598.7,217.8,599z" />
			<path @click="scrollPage('atacama')" @mouseenter="switchClass('card-atacama')"
				@mouseleave="switchClass('card-atacama')" id="atacama" class="cv_st0" d="M203.8,369.1l-2.2,0.5c-0.5,0-1,0.1-1.5,0.3l-1,0.4l-3.4,1.2c-0.8,0.2-1.7,0.3-2.6,0.3h-1.4
		c-2.5,0.6-3.9,1.9-4.4,4s0.1,2.4,0.7,3.9v0.4c0.8,2.2,1.2,4.5,1.1,6.9c-0.1,0.7-0.1,1.4-0.1,2.1c0,2-0.3,4.1-1,6
		c-1.6,3.8-4.4,5.1-7.3,6.1l-0.7,0.2c-0.6,0.2-1.1,0.4-1.6,0.7c-0.4,0.4-0.9,0.9-1.2,1.4l-1,1.3l-0.9,1.1c-0.6,0.8-1.3,1.6-2.1,2.3
		l-1.1,0.8c-0.6,0.3-1.1,0.8-1.5,1.3c-1.6,1.8-3.1,3.7-5.6,4.7c-2.8,1-5.7,1.7-8.6,2c-2,0.2-4.1,0.3-6.1,0.1h-4.9
		c-0.6-0.1-1.2-0.2-1.8-0.1c-0.4,0-0.4,0-0.9,0.6l-0.3,0.4c-1.6,2-3.7,3.7-6,4.8c-1,0.6-1.9,1.3-2.7,2.1c-1,0.9-2.1,1.7-3.2,2.4
		l-1.3,0.6c-0.7,0.3-1.4,0.5-1.6,0.9c-0.2,0.3-0.5,0.5-0.8,0.6l0,0c0,1.9-0.3,3.9-0.2,5.6s-0.3,3.8,0,5.7s1.6,4.5,2.4,6.7
		s1.5,6.3,2.1,9.4c0.4,2.6,0.7,5.2,0.9,7.8c0.3,1.2,0.3,2.6,0,3.8c-0.5,1.5-1.4,2.6-2,4s-0.6,4.2-0.3,6.7c0,1.3,0.1,2.5,0.5,3.7
		l1.6,2.9c0.5,1,0.9,2,1.2,3.1c1.7,2.9,3.1,6,4.1,9.3c1.2,3.1,2,6.4,2.5,9.7c0.2,1.6,0.3,4.6-1.1,5.8s-1.7,0.7-2.1,2.3
		c-0.5,1.4-0.6,2.9-0.3,4.4c0.3,1.4,0.7,2.8,1.1,4.2c0.1,0.9,0,1.9-0.1,2.8c0.1,1.7,0.9,3.2,1,4.9c0.3,3.6,1.1,7.2,2.2,10.6
		c0.6,1.9,1.4,3.7,2.1,5.6s1.3,2.5,1,3.9s-2.7,3.9-3.8,5.4s-2.5,2.2-3,3.4s-1.4,0.9-1.4,1.2c1.1,3.3,2.2,6.1,1.7,9.5
		c-0.4,1.7-0.6,3.4-0.6,5.2c0.3,2.9,3.3,4.1,5.2,6.2s4.3,4.5,5.8,7.3c0.3-0.6,0.9-1,1.5-0.9c0.6,0,1.3,0,1.9,0
		c1.2-0.1,2.4-0.1,3.5,0.2c1.8,0.6,3.4,1.8,4.6,3.3c0.5,0.6,1.1,1.2,1.7,1.8c1.3,1,3.9,1.8,5,1.2l0,0c0-0.2-0.1-0.3-0.1-0.4
		c-0.2-0.3-0.3-0.7-0.4-1c-0.8-2.4-3.1-10.5,1.3-14.1l0.8-0.7c0.5-0.3,0.9-0.7,1.3-1.2c0.5-0.7,1-1.4,1.4-2.1l0.4-0.7
		c1.6-2.6,4.2-3.3,7.5-2s6.7,3,8.6,6.8c0.9,2.1,1.6,4.2,2,6.4l0.9,3.3l0.2,0.8c0.6,1.8,0.8,2.4,1.9,2.9l2.7,1.7
		c1.2,1,2.6,1.7,4.1,2.2c1,0.1,2,0,3-0.3c0.9-0.3,1.9-0.4,2.8-0.5l2.2-0.2c1.5-0.2,3.1-0.2,4.6-0.1h1.4l0.8-0.3
		c0.5-0.3,1.1-0.4,1.7-0.4h1.3l0.6-0.2l1.1-0.4c-2.3-3.5-5.7-6.4-8.3-9.8c-1.5-1.9-3.1-5.1-2.8-7.7s2.6-5.2,4.8-6.9s2.9-4.2,3.3-6.5
		s-0.9-6.1-2-9.3s-3-7.5-3.7-11.4s-0.1-8.3,1.1-12.4c0.6-1.7,1.4-3.2,2.1-4.9c0.6-1.9,1.1-3.9,1.2-5.9c0.5-3.2,1.5-6.4,2.3-9.6
		c0.5-3.1,0.7-6.2,0.4-9.3c-0.4-8.1,5.4-15.9,9-22.8c1-1.8,1.6-3.9,1.7-6c-0.3-1.5-0.7-2.9-1.4-4.3c-1.1-2.8-2.8-4.7-2.9-7.8
		s0-6.1,3-7.1c1.2-0.4,2.4-0.7,3.7-0.8c1.8-0.4,3.7-1.8,5.5-1.9s2.1,1.1,3.1,0.9s2.2-2.1,2.6-2.6s1.6-2.2,2.5-3.2s2.4-2.2,2.2-4.4
		c-0.5-3.1-1.4-6.1-2.9-8.8c-0.9-1.5-2.3-1.9-3.9-2.4c-1.5-0.4-3-1-4.3-1.8c-2.4-1.5-4.3-4.2-6-6.5s-3.4-3.4-4.5-5.5l-1.1-2.2
		c-1-1.6-2.5-4-1.5-5.8s1.7-1.8,2.3-2.8s0.2-0.7,0.6-1s0.6,0,0.8-0.2s1-1.6,1.5-2.3s1.1-1.1,1.2-1.5s-1-3-1.6-4.1s-1.6-3-2.3-4.6
		c-1.1-2.7-2.7-5.2-4.6-7.4c-2.2-2.6-4.9-4.2-7.3-6.5s-8.3-7.5-6.2-10c0.5,0.1-0.2-0.5-0.6-0.6C204.5,368.4,204.2,368.8,203.8,369.1z
		" />
			<path @click="scrollPage('valparaiso')"
				@mouseenter="switchClass('card-valparaiso')"
				@mouseleave="switchClass('card-valparaiso')" id="valparaiso" class="cv_st0" d="M237.3,721.7l-1.3-1.2c-1.1-1.2-2.1-2.2-3.4-2.3c-0.7,0.2-1.3,0.5-1.8,1
		c-1.3,0.9-3.2,2.3-5.6,1c-0.2-0.1-0.4-0.3-0.5-0.5l-0.5-0.6l-2.4-1.2c-1.1-0.4-2.2-0.6-3.3-0.6c-0.7,0-1.1,0.1-1.7,1.1
		c-0.5,0.7-1,1.2-1.7,1.7l-0.8,0.8c-1,1.4-1.7,2.9-2.1,4.5c-0.3,1-0.6,1.9-0.9,2.7c-0.2,0.3-0.3,0.6-0.4,0.9
		c-0.3,0.4-0.5,0.9-0.6,1.4c-0.2,0.7-0.3,1.4-0.3,2.1c0,1.3-0.3,2.7-0.8,3.9c-0.7,1.5-3.7,3.6-6.3,4.5l-1.5,0.4h-1.6l0.4,0.5
		c0.7,1,1.7,1.7,2.3,2.6s0.9,5.7,0.9,8.2c0.1,1.5,0,3-0.3,4.5c-0.3,1.2-0.6,1.5-0.3,2.9c0.7,2.4,1.5,4.7,2.5,7c1,2.7,2.8,5.9,3.2,8.9
		c-3.1,2.6-7.2-5.2-10.8-2.9c-0.3,0.8,0.6,1.1,1,1.7c0.7,1.1,1.3,2.3,1.8,3.5c1.4,2.9,1.8,6.1,2.6,9.2s2.2,8.8,4.9,12.8
		c1.7,2.4,3,5,4,7.7c1.3,3.9,0.3,8.6,0.5,12.6c0,0.9-0.1,1.9-0.3,2.8c0.5-0.2,1-0.2,1.5,0.1c0.3,0.2,0.5,0.5,0.7,0.8l0.4-0.2l1.2-0.2
		c2.6-0.1,5.7-0.6,6-2.3s-0.1-0.7-0.1-1.2c-0.1-0.6-0.2-1.1-0.1-1.7c0-2,0.5-4,1.4-5.8c1.2-1.4,2.6-2.6,4.3-3.5l0.9-0.5
		c1.1-0.6,1.4-0.8,1.4-1.3c0.2-1.1,0.1-2.2-0.1-3.3c-0.1-0.8-0.4-1.6-0.7-2.4c-0.3-0.9-0.7-1.8-0.9-2.6l-0.2-0.8
		c-0.2-1.2-0.5-2.4-0.7-3.6c-0.2-1.4-0.2-2.7-0.1-4.1c0.1-1.4,0-2.9-0.3-4.3c-0.6-2.2-0.9-4.5-1.1-6.8c-0.1-1.1,0-2.2,0.4-3.2
		l0.2-0.8c0-0.3,0.1-0.5,0.1-0.8c0.1-0.8,0.3-1.6,0.6-2.3c0.5-1.3,1.7-2.2,3-2.4l0.5-0.2c0.9-0.3,0.9-0.3,1.4-1.5l0.2-0.4
		c1.5-3.2,3.4-4.5,7-4.8c1.6-0.2,3.2-0.1,4.7,0.4c1,0.3,2,0.8,2.9,1.4c0.8,0.5,1.7,0.9,2.7,1.2c1.9,0.5,4.1,1.1,5.8,0.8
		c1.7-0.3,2.6-1.5,3.8-2.4l0.7-0.6c1.3-1,2.5-2.1,3.6-3.3c0.2-0.2,0.3-0.5,0.4-0.7c-0.5-0.7-0.3-1.6,0.3-2.2c0.7-0.4,1.5-0.7,2.3-0.6
		h0.1c-1.1-2.2-2-4.4-2.8-6.7c-1-3-1.7-6.8-3.6-9.3c-1.9-2.5-5-3.5-5.4-6.7s-2.3-7-5.6-8.5c-1.6-0.7-4.4-1.8-6.2-3.5l0,0
		C241,723.5,238.7,723.2,237.3,721.7z" />
			<g @click="scrollPage('magallanes_antartica')"
				@mouseenter="switchClass('card-magallanesantartica')"
				@mouseleave="switchClass('card-magallanesantartica')" id="magallanes_antartica">
				<path class="cv_st0" d="M728.8,1792.2c-0.8-2.1-2.9-4.7-5.2-5.1c-1.1,0-2.2,0-3.3-0.2c-2-0.3-4-0.4-6-0.3c-1.6,0.1-3.2,0.4-4.9,0.4
			s-4.1-0.1-6,0.7s-5,3.4-7.9,4.2c-2,0.7-4,1.2-6.1,1.6c-1.9,0.4-8.2-4.5-10.2-3.8L641.3,1685c-1.6,0.6-2.5-0.2-3.6-1.2s-1-0.9-2.7,0
			s-3,1.5-4.2,3.2s-3.7,3.9-6.1,4.1s-2.7-3.3-3.3-5.1s-1.4-4.6-4.2-4.3s-5.2,2.8-7.2,0.7s-0.9-5.1-0.1-6.6s2.6-2.7,3.8-4l3.9-4.1
			c-1-1.2-4.5-1.7-6.2-1.8s-4.8,0.2-7.2,0.1c-2-0.1-3.9-0.4-5.8-1.1c-2.4-0.8-4.5-0.5-7-0.1s-4.2,1.6-6.2,3.1c-2,1.5-4.3,2.5-6.7,3.2
			c-5.6,1.4-11.4,0.8-16.6-1.7v-0.2h-0.1l-0.9-0.5c-0.2,0.1,0.3,0.3,0.7,0.5c-0.9,0.2-1.7,0.5-2.5,0.9c-2.1,0.7-4.3,0.9-6.4,1.5
			c-3.7,1.2-6.9,3.1-10.4,4.6s-6.4,1.4-9.4,2.6s-4,2.9-6.2,4.3s-2.3,2.7-3.4,0.8s-0.1-2-0.3-2.8c-0.2-1.1-0.6-2.2-1.2-3.2
			c-0.5-1-1.2-1.9-2.1-2.6c-2.1-1.7-4.3-3.2-6.2-5s-5.4-4.4-7.1-7.4c-0.8-1.4-2.3-3.5-2-5.1c2.3,1-2.3-1.8-0.4-3l0.5-0.2
			c-0.1-2.8-0.9-5.6-2.5-7.9c-1.7-2.2-2.9-4.7-3.4-7.4c-0.4-2.3-0.6-5-2.4-6.8s-0.8-0.3-1.3-0.9s-0.3-1.2-0.6-1.7
			c-2.3-2.9-6.3-2.5-9.2-1.4s-3.9,2.7-5.7,5.2s-3.7,5.3-5.3,8.1s-0.8,1.4-2.2,0.6c-0.7-0.4-1.3-0.8-1.9-1.3c-1.2-1.2-2.1-2.6-2.9-4.1
			c-0.6-1.5-1.1-3-1.5-4.5c-0.7-1.7-2.2-2.4-3.4-3.7c-1.4-1.3-2.6-2.7-3.6-4.3c-0.9-1.6-1.9-3.2-3-4.7c-3.5-4-8.6-7.5-10.7-12.5
			c-1.2-3-1.9-6.2-3.2-9s-1.9-3.8-2.7-5.8c-1.8-4.3-6.3-6.7-2.3-11.1c1.4-1.5,3.3-2.1,5.1-2.9s1.5-0.8,1.6-2s-0.9-3.6-0.8-5.5
			c0-0.6,0-1.2,0.1-1.8c-0.8,0.2-1.6,0.3-2.4,0.3c-0.3-0.1-0.6,0-0.8,0.1h-0.9l-5,0.3l-3.5,0.2c-2.2,0.1-4.5-0.4-6.5-1.4l-0.9-0.3
			l-0.8-0.2l-1.3-0.3l-2.1-1.1l-1.5-0.8l-0.8-0.3l-1.7-0.7c-0.7-0.4-1.4-0.9-2-1.5l-0.5-0.6c-0.6-0.4-1.3-0.8-2-1.1l-1.1-0.5
			c-0.6-0.4-1.3-0.7-2-0.9c-1.4,0.1-2.7,0.5-3.8,1.3c-0.1,0.5-0.4,0.9-0.8,1.2l-1,0.3h-0.1c-0.1,0-0.1,0-0.1,0.1l-0.3,0.2h-0.1
			l-0.4,0.5c0,0.1-0.1,0.1-0.1,0.2h-0.1c0.3,2.6,0.7,5.1,1.2,7.6c0.5,4.2,1.4,8.4,2.9,12.4c0.8,2.2,1.9,4.4,2.5,6.7s0.5,3,0.9,4.5
			c0.1,0.6,0.3,1.1,0.5,1.7c0.5,0.5,1.1,0.4,1.6-0.3s2.8-1.6,4.5-1.7s-0.8,11.1,2.2,10c0.2,0.7-0.5,1.5-0.6,2.3
			c-0.4,2.5,1.9,2.5,2.5,4.7s0.2,2,0.4,2.9c0.3,0.8,0.5,1.6,0.6,2.4c0.3,2.4,0,4.8-0.8,7.1c-0.8,1.8-2.9,5.1-0.9,6.8s3.5,1.4,4.8,1.9
			l5.3,2.3c1,0.3,3.8,1,4.5,2.3s-0.9,2.4-2.1,3c-2.3,0.8-4.6,1.3-7,1.6c-1.2,0.3-3.1,0.3-3.6,1.6c0.9,2.1,2,4.1,3.2,6.1
			c0.9,1.2,2,2.2,2.8,3.4s1.3,2.1,2.5,2.4s2.1-0.4,3.1-1.1s0.5,4.4,0.6,5.5s-3.3,0-4.3-0.8s-2.1,0-3.3,1.3c-1.6,1.5-2.9,3.3-3.9,5.3
			c-0.9,1.8-2.7,3.6-3.3,5.4c0.9,1.5,2.7,2,4.1,2.7l4.4,2.2c1.6,0.7,3.1,0.9,4.4,2.2c0.8,0.9,1.8,1.7,2.9,2.3c1.3,0.4,2.5,1,3.6,1.7
			c1.7,1.2,3,2.8,4,4.6c0.5,0.8,1,2.5,2,2.5c0.7-1.7-0.2-4.3,1.2-5.7s1.3-0.6,1.9-1s1.8-2.1,3.5-2.5s2.2,0.9,3.8,1.5s2.6-0.4,3.7,0
			s1.4,1.2,1.6,3.1c0.2,1.9,0,3.8-0.7,5.6c-0.4,1.6-2.3,5.9-1,7.4c2-0.4,3.7-1.8,5.7-1.8s3.4,1.1,4.9,2.3s5.2,4,7.2,6.9
			c0.6,0.9,1.3,2.5,2.3,3s3.6-0.6,4.9-0.9c1.4-0.1,2.7,0,4.1,0.1c1.3,0.1,2.5-0.2,3.7-0.1s1.8,1.1,2.8,1.3s3.4-2.8,4.4-3.8
			s3.2-2.3,3.9-4.2s-0.6-3.1-0.4-4.9s2.4-4.6,3.1-5.7s3-4.1,5.2-2.3s2.9,9.9-1.5,8.1l-4.3,7.7c-1,1.4-2.2,2.4-3.3,3.7
			s-2.8,3.2-4.2,4.8s-1.3,2-2.6,2.7s-5,0.4-7.4-0.7s-2.6-1.8-4.2-1.4c-1.6,0.6-2.9,1.6-4,2.9c-1.5,2-2,4.5-1.4,6.9
			c0.2,0.7,1.2,4.1,2.1,4.4s2.9-1.2,4.2-1.6c1.1-0.4,2.4-0.6,3.6-0.4c2.8,0.9,4,4.3,5.4,6.4s4-0.6,6-1.3s2.5-0.2,3.8-0.6
			s2.7-2.5,3.5-4.6s3-6.7,5.3-5.5c0.8,0.8,1.5,1.8,2,2.8c0.1,0.9,0,1.7-0.4,2.5c-0.6,1.3-0.6,1-1.9,1.9c-0.4,0.3-0.9,0.8-1.2,1.2
			c-0.6,1-0.3,4-1.7,4.5s-3.7-3-3.4,0.3c0.2,1.6,1.1,2.8,1,4.4c-0.4,0.1-0.5,0.5-0.4,0.9c0,0.2,0.1,0.3,0.2,0.5
			c0.3,2.7,1.6,5.5,1.8,8.3c0.2,2.7,0.9,5.4,2,7.9c0.5,1.1,0.9,2,2.2,2.2c1.2-0.3,2.4-0.7,3.5-1.4l2.4-1.7c1.2-0.8,1.7-2.1,3.3-2.3
			s2.1,0.4,3.2,0.1c4.8-0.9,7.5-4.1,10.3-7.8s5.6-5.1,9.3-4.2c2.1,0.4,5.3,2,7.4,0.8c0.3-0.5,0.7-1.1,1.2-1.5l2.4-2.2
			c1.4-1.4,3-2.5,4.3-3.9c3-3,4.2-5.6,5.7-9.4s3.3-9,7.3-5.2s0.2,6.7-1.3,9.2s-3.3,6-6.2,8s-3.1,1.2-4.6,2s-2.8,2.7-3.5,4.9
			s-1.9,6.7-4.4,7.9s-3.9-2.1-6.1-3.4s-3.9,0.2-5.4,0.8s-1.5,1.6-2.5,3.3s-1.3,1.6-1.8,2.3s-1.4,1.1-2.9,0.9s-2.7-1.3-4.8-0.8
			s-5.7,2.2-8.5,3.1s-7.8,2.8-8,7.4s7.2,0.5,9.6,0.5s2.9,0.5,4.6,0.1c1.2-0.3,2.4-0.8,3.5-1.3c1.7-0.5,3.3-1.4,4.6-2.7
			c0.9-0.9,1.4-1.7,2.7-1.3s3.2,2.5,4.3,3.6s6.6,4.9,9.9,3.4c1.5-0.7,2.4-2,3.7-3c1.1-0.7,2.3-1.3,3.6-1.6c1.7-0.9,3.6-1.4,5.5-1.7
			c2.4-0.2,3.1,0.3,5.4,1.8s5.9,4.7,9.9,4.8c1.6,0.1,3.2-0.1,4.7-0.6c4.5-1.3,8.4-4.4,7.2-9.5s-2.8-6.8-2.6-10.6s0.8-4.4,0.7-6.8
			c-0.2-3.6-1.2-7.1-3.1-10.1c-1.8-2.6-3.7-5.1-5.8-7.5c-1.3-1.6-1.3-2.6-1.6-4.5c-0.7-4.1,3.3-6,5.6-8.6s3.4-6.2,4.9-9
			s2.4-6.2,4.6-8.7c1.3-1.4,2.9-2.5,4.6-3.2c1.7-0.8,4.4-2.3,6.4-2.1s4.6,1.2,5.3,3.3c0.3,1,0.4,2.1,0.5,3.1c0.4,3.9-2.9,6-6.8,5.7
			c-2-0.1-4.5-1.5-6.6-0.6c-0.5,0.3-1.8,1.3-1.8,2.1s7.5,5.1,5,8.2s-7.4-3.3-6.4,1.3c0.7,3,4,3.4,6.1,4.6s3.1,4.5,0.1,6.7
			s-4.6,2.4-4.9,5.3s1.8,6.1,3,8.6c1.1,2.5,2.4,4.9,3.9,7.1c2.2,2.7,4.3,0.7,6.5-0.9c4-2.9,7.1-7.3,10.4-10.9s6.4-9.6,11.3-6.8
			c2.2,1.2,3.3,5,3.7,7.2c0.2,1.6,0,3.3-0.8,4.8c-0.4,0.9-1,1.6-1.7,2.2c-0.9,1-1.9,2-2.8,3.1s-4.8,3-6.6,5.6c-1.6,2.4-3.6,4.4-5.9,6
			c-0.9,0.8-2.3,1.5-2.8,2.8c-1.3,3.2,2.6,7.8,4.4,10.1c1.4,2,3.3,3.5,5.5,4.5c3.2,1.3,6.7,2.8,10.2,3.2s9.5-1.3,14-2
			c1.6-0.3,6.7-2.3,8-1.5s0.9,2.4,0.8,3.1c-0.5,2.5-2.9,2.9-5.1,2.8c-4-0.2-8,1-12.1,1.2c-2.6,0.2-7.4-1.9-9.4,0.7s0.4,4-2,5.3
			s-3.8,2.4-6.5,2.2s-2.7-0.9-4.1-1.2c-1.2-0.2-2.5-0.3-3.7-0.2c-3.1,0.1-6.2-0.3-9.1-1.2c-2.1-0.7-3.3-1.5-5.4-0.9
			c-0.2,0.9,2,2.4,2.7,2.9c1.3,1.1,2.9,1.7,4.6,1.5c1.6,0,2.9-0.3,3.9,1.1s0.8,2.9,0.7,4.4s0.3,2.4-0.6,3.3c-1,1-2.4,1.6-3.9,1.5
			c-2.2-0.1-2.9-1.1-4.9,0s-6.8,3.3-10.1,5.1c-1,0.6-2,1.3-2.8,2.1c-1,1-3.6,3-3.9,4.4c0,1.5,0.3,2.9,1,4.2c1.5,2.6,6.6,0.2,8.7-0.3
			c1.6-0.2,3.2-0.3,4.8-0.3c2-0.2,3.9-0.7,5.7-1.4c3-1.2,5.7-0.6,8.8-1.2s6.9-2.1,9.8-1.5s4.5,3.1,7.7,1.3c1.1-0.5,1.5-1.2,2.8-1.4
			s2-0.1,3.1-0.9c0.7-0.5,1.3-1.1,1.8-1.8c0.6-0.7,0.7-1.9,1.3-2.5c1.2-0.7,2.5-1,3.9-0.8c0.7,0.3,0.6,0,0.8,0.9
			c-0.1,0.8-0.3,1.6-0.6,2.3c-0.4,2.1-0.2,4.3-0.4,6.5c-0.2,1.9-0.2,3.8-0.2,5.7c0.3,1.7,0.9,3.3,1.6,4.9c0.9,2.6,1.8,3.1,3.8,4.9
			s3.4,4.6,5.8,6.1c1.9,1.2,4,2.2,6.2,2.7c2.1,0.5,6.4,0.7,8.1-1.2s-3.7-4.5-5-5.9s-0.9-2.1-1-3.7s-0.6-3.1,0.3-4.2s2.5-0.9,3.7-1.1
			s3,0.4,4.3,0.1s3.9-3.5,6.2-4.5s5.8,1.1,8.3,3s5.3,5,8.4,7s4.4,2.1,6.5,3.4s2.4,1.5,4.1,1.1c1.7-0.4,2.7-1.9,2.5-3.6
			c-0.2-1.4-0.9-2.6-1.9-3.5c-1.1-1.3-2.5-2-3.5-3.4c-1.1-1.5-2.4-2.9-3.7-4.2c-3.1-3-7.7-4.1-11.5-6.1c-1-0.5-4.5-2.1-4.6-3.6
			s1.4-2,2.3-2.7s2.2-1.9,4.2-1.5c1.4,0.3,2.8,0.9,4.1,1.6c2,0.8,3.6,1.8,5.8,2.1s1.8-0.1,2.6,0c1.3,0.4,2.6,0.8,3.9,1.4
			c2.8,1,4.8,0.3,6.7-1.7s2.7-3.6,4.7-4.4c1.6-0.7,3.4-1,5.2-0.8c2.1,0.3,6.7,1.9,8.7,1S729.5,1794,728.8,1792.2z" />
				<path class="cv_st0" d="M601.5,1758.3c-0.6,1.5-1.4,2.9-2.4,4.1c-0.3,0.6-0.4,1.2-0.7,1.7c-0.8-0.8-2-0.9-2.9-0.2
			c-1.7,1.4-0.7,3.1,0.2,4.7c1.1,2,2.9,3.5,5,4.2l3.2,1.1c0.7,0.1,1.4,0.3,2,0.7c1.4,1.2,2.8,3.4,4.7,3.9s3.4-0.6,4.6-1.8
			c0.9-0.8,1-2.2,0.2-3.1c-0.2-0.3-0.5-0.5-0.8-0.6l-1.1-0.2l-2.3-0.7c-1.1-0.2-2.2-0.7-3.1-1.3c-2.4-1.9-0.7-5.4-0.5-7.8
			s-0.4-2-0.6-3.1c-0.3-1.4-0.8-2.8-1.3-4.2c-0.7-1.9-1.1-3.8-1.6-5.7s-0.9-1.1-1.5-1.5s-2.6-0.6-3.9,1c0.8,2.1,1.7,4.2,2.4,6.3
			S601.8,1757.3,601.5,1758.3z" />
				<path class="cv_st0" d="M626.7,1812.4c-0.3-0.1-0.5-0.4-0.7-0.5s-1-0.1-1.5-0.2l-0.2,0.4c-1.3-0.4-2.7-0.7-4.1-0.7
			c-0.8,0.1-2.1-0.2-2.9,0.2s-0.4,1.9,0.3,2.6c0.5,0.4,1.1,0.7,1.7,0.8h2.8c0.7-0.2,1.1-0.6,1.9-0.5s0.5,1.5,1,1.8s1,0.1,1.4,0
			s2.8-0.3,2.8-1.5s-0.7-1.2-0.9-1.5C627.8,1813,627.3,1812.7,626.7,1812.4z" />
				<path class="cv_st0" d="M629.9,1822.2c-0.4-0.1-0.8,0-1.2,0.1c-1.1,0.4-2.4,0-3.5-0.1c-0.7-0.2-1.4-0.4-2.1-0.6l-1.3-0.4
			c-0.7-0.3-1.4-0.5-2.2-0.6c-0.5,0.1-1.1,0.1-1.6,0c-1.2-0.2-1.8-1.2-0.8-1.8c-1.2-1.3-2.8-2.1-4.6-2.2c-1.3-0.1-2.7,0.4-3.9-0.2
			c-1.9-0.9-3.9-1.6-6-2c-1-0.1-2.1,0-3,0.5c-1,0.4-1.1,0.7-0.9,1.7s0.8,1.3,1.7,1.8c0.9,0.5,1.9,0.9,2.9,1.2
			c1.2,0.2,2.3,0.7,3.4,1.2c0.7,0.5,1.3,1.3,2.1,1.9s4.3,0.9,6.4,1.6c0.9,0.3,1.6,0.8,2.3,1.4c0.8,0.6,1.7,1.1,2.6,1.4
			c1.4,0.8,2.9,1.1,4.5,1.1c0.8,0,1.6-0.1,2.4-0.2c0.7-0.4,1.4-0.8,2-1.4c1.3-0.7,2-2.2,1.6-3.6
			C630.6,1822.3,630.6,1822.2,629.9,1822.2z" />
				<path class="cv_st0" d="M580.1,1791.8l1.8-0.5c0.6-0.2,0.5-0.2,0.7-0.8s0.4-2.6,1.4-3s1.2,1.1,2.1,1.4c0.9,0.2,1.7,0.6,2.4,1.3
			c1.1,1.1,1.7,2.7,3.1,3.4c0.4,0.3,1,0.4,1.5,0.1c0.9-0.3,1.8-0.7,2.6-1.2c0.6-0.3,0.9-0.3,1.2-0.9s-0.1-1.6-1.1-2.5l-0.9-1.7
			c-0.3-0.8-0.8-1.6-1.4-2.3c-1.2-0.7-2.9,0.4-4.1,0.4s-2.5-1.8-3.3-3.1s-0.8-1.7-1.3-2.6s-0.7-0.9-1.5-1.5s-0.8-0.7-1.5-0.8
			c-0.8-0.1-1.6-0.2-2.4-0.1c-1.6,0.1-3.2,0.2-4.8,0.1c-0.9,0-1.7,0-2.6,0c-0.6,0.2-1.2,0.1-1.7-0.1c-0.5-0.1-0.9-0.3-1.3-0.6
			c-0.7-0.5-0.8-0.6-1.7-0.4s-3.2,1.7-4.6,2.9c-1.1,1-2,2.2-2.7,3.5c-0.7,1.3-0.5,2.2,0.3,3.5c0.5,0.5,0.9,1.2,1.1,1.9
			c-0.1,1-0.3,2-0.8,2.9c-0.4,1.3-0.9,2.5-1.6,3.6c-0.3,0.6-0.6,1.2-0.8,1.9l-1,1.7c-1,1.7-1.5,3.8-1.2,5.8c0.2,1.4,0.9,3,2.6,2.7
			c0.6-2.8,0.8-5.4,2.3-7.8c0.6-0.8,1-1.6,1.4-2.5c0.3-0.9,0.1-1.9,0.4-2.7c0.3-0.7,0.7-1.3,1.2-1.9c0.4-0.6,0.9-1.1,1.5-1.5
			c1.5-1.2,2.7,1.7,3.1,2.8s0.7,3.7,1.7,5.2s2.1,2.8,3.4,2.7s0.9-2.7,0.8-3.5s0-2-0.2-3c-0.2-0.8-0.2-1.6,0.2-2.3
			c0.4,0,0.6,0.3,0.9,0.5c0.6,0.2,1.2,0.3,1.8,0.1L580.1,1791.8z" />
				<path class="cv_st0" d="M553,1790c0.5-0.7,0.6-1.6,0.4-2.4c-0.1-0.6-0.5-1.3-0.6-2c-0.2-0.5-0.3-1.1-0.3-1.6c0.2-0.5,0.7-0.6,1.2-0.8
			c0.4-0.1,0.8-0.3,1.1-0.6c0.3-0.4,0.2-0.7,0.4-1.1s0.4-0.5,0.4-1s-0.4-1.1,0.8-1.2s-0.1-1.1-0.4-1.6c-0.5-0.6-1.1-1.1-1.8-1.4
			c-0.7-0.4-1.4-0.5-2.2-0.4c-0.8,0.1-1.7,0.8-2.6,0.8s-1.9-0.9-2.4-1.5c-0.4-0.6-0.7-1.2-0.8-1.9c-0.2-0.6-0.2-1.6-0.6-2
			s-3.4,0.3-4.6,0.5c-1.2,0-2.4-0.4-3.3-1.2c-0.9-0.9-1.8-2.1-3.2-2.5l-3-1c-0.6-0.3-1.3-0.6-2-0.6c-0.6,0-1.3,0.1-1.9,0.3
			c-0.7,0.3-0.8,0.6-1.3,1.2c-0.4,0.4-0.9,0.7-1.4,1c-0.5,0.6-0.7,1.3-0.7,2.1c-0.1,0.9,1.4,2.7,0.1,3.2s-2.7-0.3-4-0.1
			c-0.7,0.2-1.5,0.4-2.3,0.4c-0.7,0-1.3-0.3-1.9-0.3c-0.8,0-1.5-0.2-2.1-0.7c-0.6-0.5-0.9-1.5-1.6-1.9c-0.9-0.3-1.8-0.2-2.7,0.1
			c-1.1,0.4-2.1,1-2.9,1.9c-1.5,1.9-2.7,4.1-3.4,6.5c-0.2,0.8-0.4,1.7,0.2,2.3s1.9,0.1,2.8-0.2c1.2-0.5,2.5-0.8,3.8-1.1
			c0.8-0.2,1.6-0.5,2.2,0.2c0.5,0.5,0.9,1,1.3,1.5c0.7,0.7,1.1,1.4,0.5,2.2s-2.8,2.4-2.7,4.2c0.9-0.2,1.7-0.6,2.4-1.2
			c0.8-0.7,1.8-1.3,2.9-1.6c1-0.2,2,0.1,2.9,0.6c0.4,0.2,0.4,0.4,0.7,0.7s1.2,0.4,1.9,0.4l2,0.3c1,0.2,1.3,0.7,1.8,1.6
			s0.8,0.7,1.1,1.3c0.3,0.7,0.5,1.3,0.7,2c0.5,1.4,0.4,3-0.3,4.4c-0.6,1.2-1.2,2.5-1.6,3.8c-0.3,0.9-0.4,1.8-0.5,2.7
			c0-0.1-0.1,1.5-0.1,1.8s0.5,0.6,1,0.7s2.1-0.8,3-1.8c0.8-0.8,1.5-1.7,2.2-2.6c0.4-0.4,0.8-0.8,1.1-1.2c1.1-1.5-0.5-4.1-1-5.6
			c-0.4-0.7-0.4-1.5,0-2.2l1.3-2.4c0.9-1.9,2.8-3.1,4.9-3c1.4-0.1,2.7,0.4,3.8,1.2c0.5,0.4,0.7,0.5,0.8,1.1s-0.5,1.3-0.8,1.9
			s-0.8,2.2-1.4,3.2s-0.9,1.6-1.3,2.5s-0.6,4.3,1.5,3.3c0.9-0.6,1.6-1.4,2.3-2.2c0.8-0.7,1.7-1.2,2.7-1.5c1.3-0.4,2.1-1.4,3.2-2
			s1.3-0.4,1.9-1C551.7,1793.2,552.5,1791.6,553,1790z" />
				<path class="cv_st0" d="M501,1765.8c1.4-0.5,1.9-1.8,3.2-3.5c-1.5-2.2-4.5-2.3-6.9-2.9l-1.5-0.3l-1.7,0.2l-1.3-0.2
			c-1.9-0.6-3.8-0.9-5.8-0.8l-3.3,0.4h-6.2c-1.4,0.2-2.4,0.6-3.4-0.6l-1.5-1.9l-1.5-1.3c-0.3-0.4-0.7-0.7-1.1-1
			c-1-0.7-3.3-0.7-3.5,0.7s0.8,2,1.4,2.6c1,1,2.1,2,3.3,2.9c1.5,1.1,3,2.1,4.7,2.9c1,0.7,2,1.4,2.8,2.3c0.5,0.6,1.2,0.9,1.9,0.9
			c1,0,2.1,0,3.1,0.2c0.3,0.2,0.7,0.3,1.1,0.3c1.3-0.1,2.1-1.5,3.3-2.1c0.7-0.5,1.5-0.7,2.4-0.7c1.2,0.3,2.3,0.7,3.4,1.2l1.7,0.4
			c0.7,0.3,1.4,0.6,2.2,0.8C498.9,1766.4,500,1766.2,501,1765.8z" />
				<path class="cv_st0" d="M486.8,1732.3c-0.2-0.3-0.4-0.6-0.6-0.9c-0.6-0.9-1.3-0.8-3-1c0.4,1.1,0.4,2.2,0.2,3.3c-0.4,0.8-1,1.6-1.6,2.3
			c-0.6,0.6-1.2,1.2-1.6,1.9c-0.5,0.6-0.4,1.1-0.3,1.9c0,0.9,0.2,1.9,0.6,2.7c0.5,0.5,1.2,0.8,1.9,0.7c1,0,1.8-0.6,2.8-0.7
			c1.2,0,2.4,0.1,3.6,0.4c1.2,0,2.4,0.4,3.4,1c0.4,0.4,0.5,0.6,1.1,0.6c0.6,0.1,1.2-0.1,1.6-0.6c0-0.2,0.1-0.4,0.3-0.6
			c0.1-0.2,0.4-0.4,0.6-0.6c0.3-0.5,0.4-1,0.5-1.5c0.1-0.8,0-1.7-0.3-2.5c-0.4-1.1-0.6-1.2-1.7-0.7c-0.8,0.5-1.7,0.5-2.6,0.2
			c-0.8-0.4-1.5-1.1-2.3-1.6c-0.7-0.3-1.3-0.8-1.8-1.3c-0.3-0.6-0.5-1.2-0.6-1.9C486.9,1733,487,1732.7,486.8,1732.3z" />
				<path class="cv_st0" d="M454.8,1720.9c0-0.9-1.3-1.8-1.3-2.9c-1.8-0.3-4-0.2-5.3-1.7s-1.5-3.1-2.4-4.6c-0.7-0.8-1.6-1.3-2.6-1.5
			c-1.1-0.2-2.4,0.1-3.4-0.7s-1.2-2.7-2.6-3.3s-2.2,1.4-2.3,2.6c-0.1,2.5,0.7,4.9,2.3,6.8c1.3,1.4,2.8,2.6,4.5,3.5
			c0.8,0.4,1.6,1,2.1,1.7l1.2,1.9c0.4,0.8,0.8,1.6,1.3,2.4c0.6,0.8,1.1,1.7,1.5,2.6c0.5,1.6,0.7,3.3,1.2,5s1,3.3,2.8,3.1
			c1.4-0.2,2.5-1,3.2-2.2c0.9-1.7,0.6-3.4,0.4-5.3c-0.1-0.9-0.3-1.8-0.6-2.6c-0.4-0.7-0.7-1.5-0.9-2.3
			C453.8,1722.6,454.8,1721.9,454.8,1720.9z" />
				<path class="cv_st0" d="M466.9,1708.3c-0.8-0.1-1.5-0.4-2.2-0.8c-1-0.6-1.5-1.5-2.7-1.8s-2.3-0.1-3.4-0.7c-0.4-0.3-0.8-0.5-1.3-0.6
			c-0.9-0.1-1.9,0-2.3-0.3c-1,2,1,3.3,2.3,4.6c0.7,0.9,1.5,1.8,2.4,2.6c1,0.9,2.3,1.3,3.6,0.9l2-0.6h1.1c1.2,0,2.3-0.2,3.4-0.7
			c0-0.7-0.2-1.4-0.6-2C468.8,1708.5,467.5,1708.5,466.9,1708.3z" />
				<path class="cv_st0" d="M466.4,1724.8c-0.2,0.8-0.3,1.6-0.2,2.4c0.1,0.8,0.3,1.6,0.7,2.3c0.3,0.6,0.4,1.1,1.1,1.2c0.5,0.1,1,0.1,1.5,0
			c1-0.4,1.3-1.8,1.2-2.9c-0.4-0.7-0.5-1.5-0.3-2.2c0.3-0.5,0.8-0.8,0.9-1.5c0-0.8-0.1-1.5-0.4-2.2c-0.2-0.9-0.5-1.7-1-2.5
			c-0.8-0.6-1.6,0.5-3.2,0C466.9,1721.2,466.8,1723,466.4,1724.8z" />
				<path class="cv_st0" d="M388.2,1624.9c1.2,0.3,2.4,0.7,3.6,1.2c1.1,0.4,2.3,0.7,3.5,0.7c0.8-0.1,2.2-0.2,2.7-0.8s0.2-2.6-0.2-3.6
			s-1.7-4.7-2.5-7.1c-0.8-1.6-1.3-3.2-1.7-4.9c-0.2-2-1-3.8-0.9-5.8l1.4-0.4c1.2,0,2.3,0.5,3,1.5c1.5,1.2,2.6,2.7,3.3,4.5
			c1.1,3.3,1.1,6.8,2.2,10c0.5,1.2,1,2.4,1.7,3.6c0.6,0.9,1,1.9,1.3,3c0.2,1.3-0.8,3.3,1.1,3.5s2.7-1.2,3.4-2.4s1.4-1.9,1.9-2.9
			c0.5-1,0.9-2,1.2-3c0.4-2.2-0.6-4.9-1-7c-0.3-2-0.8-3.9-1.6-5.8c-0.9-1.9-2.9-3-4.3-4.5s-1.1-1.2-1.6-1.9c-0.6-0.8-1.1-1.7-1.4-2.6
			l-1.6-3.4c-0.6-1.2-1.3-2.4-2.1-3.6c-2.4-4.2-2.9-9.2-4-13.8c-0.4-1.7-1.1-3.3-0.7-5c-0.1-0.1-0.2-0.1-0.1-1.4s-1.8-4.9-3.6-6.6
			l-0.3-0.3c0,0.6-0.3,1.1-0.8,1.4c-1.2,0.7-2.6,0.6-3.7-0.2l-0.4-0.3l-0.9,0.2l-1.3-0.2c-2.4-0.5-4.9,0-6.9,1.4
			c-0.6,0.4-1.1,0.8-1.5,1.3c-1.1,1.3-2.5,2.2-4.2,2.6h-3c-0.4,0.1-0.8,0.3-1.1,0.6l-1.3,0.8l-2.1,0.7c-1.2,0.4-2.1,0.6-2.7,1.4
			c-0.5,0.6-1,1.2-1.4,1.8c0.1,0.3,0.2,0.7,0.1,1.1c-0.2,0.7-0.7,1.4-1.4,1.7c1.1,1,2.2,2.2,3.1,3.4c2,2.5,2.9,5.7,4,8.7
			s1.9,4.9,3,7.3s2.8,4.7,3.8,7.3s2.5,5.6,1.7,8.6c-0.2,0.7-0.4,1.3-0.6,2c0,0.8,0,1.5,0.2,2.3c0.3,1.8,1.2,4.2,2.8,5.4
			s1.9,0.5,2.7,1.4s0.7,1.5,1.5,1.9s1.3-0.1,2.1-0.7c1.1-0.9,2.2-1.8,3.3-2.6C386.6,1625,387.4,1624.8,388.2,1624.9z" />
				<path class="cv_st0" d="M400.3,1651.7l3.6-1.2c1.2-0.5,1.5-2.5,1.7-3.7s0.5-3.8,1.6-5.3s1.8-1.2,2.4-2.2c0.6-1.3,0.7-2.7,0.3-4.1
			c-1-3.2-5.1-0.9-7.1-0.4c-1,0.3-2,0.3-3-0.1c-1.3-0.4-2-1.6-2.7-2.7s-1-1.8-2-1.8l-1.2,0.3c-0.6,0-1.1,0.3-1.5,0.7
			c0,3.2,0.2,6.5,0.6,9.7c0,1.4,0.3,2.8,0.9,4.1c0.2,0.7,0.6,1.3,1.1,1.8c0.4,0.4,1.1,0.5,1.4,1.1s-1.5,3.4-2.2,4.6s-0.5,2.6,1.1,2.3
			c0.8-0.3,1.6-0.8,2.2-1.4C398.3,1652.7,399.3,1652.1,400.3,1651.7z" />
				<path class="cv_st0"
					d="M401.3,1657.3c-2.4-0.5-1.3,5.3-1.1,6.2s0.8,2.6,1.1,3.9s0.4,2.1,0.8,3s2.7,2.8,4.2,1.5s-0.1-3.1-0.4-4.4
			c-0.5-1.5-0.2-3.1,0.8-4.3c0.6-0.4,1.1-1,1.5-1.6c0.4-0.8,0.7-1.7,0.9-2.6c0.3-1.2,0.9-3.1-0.6-3.6S405.6,1655.1,401.3,1657.3z" />
			</g>
			<g @click="scrollPage('aysen')" @mouseenter="switchClass('card-aysen')"
				@mouseleave="switchClass('card-aysen')" id="aysen">
				<path class="cv_st0" d="M329.9,1391.8c-0.4,1.3-1,2.4-1.9,3.4c-1.9,2-1.8,3.6-0.7,6.2c0.3,1.2,1.3,2,2.5,2.2l-0.2-0.5
			c1.4,0.2,2.7-0.1,3.8-1c0.5-0.7,0.7-1.5,0.7-2.3c0.2-1.3,0.2-2.6,0.1-3.9c-0.2-1.4-0.7-4.6-2.2-5.1
			C331.2,1390.6,330.3,1391,329.9,1391.8z" />
				<path class="cv_st0" d="M426.5,1322.4c-0.9-1.9-2.1-2.7-3.7-3.8c-1.2-0.9-2.5-1.5-4-1.7c-0.9-0.1-1.7-0.8-2.5-0.8
			c-2.4-0.1-6.5,4.5-8,6.1s-1.4,1.3-2.9,1.3s-2-0.1-3,0s-0.3,0.8-1.7,0.4s-3.1-3.6-3.8-4.8c-0.8-1.1-1.2-2.4-1.1-3.7
			c0.5-2.6,2.5-4.6,2.7-7.3c-1.3,0.6-2.8,0.8-4.2,0.5c-0.6-0.1-1.2-0.3-1.8-0.6c-0.4-0.3-0.8-0.4-1.3-0.4h-0.6
			c-1.2-0.1-2.3-0.1-3.5,0.1c-1.2,0.1-2.4-0.1-3.5-0.6l-1.2-0.3h-0.3c-1.2-0.2-2.4-0.7-3.5-1.4l-1.2-0.8c-1.2-0.8-2.5-1.6-3.6-2.5
			l-1.2-1.3c-0.5-0.5-0.9-1.1-1.3-1.2c-0.9-0.1-1.8,0.1-2.5,0.6c-0.4,0.5-0.8,1-1.1,1.6l-1.2,1.7c-2,2.2-5.5,1.6-8.1,0
			c-0.6-0.4-1.1-0.8-1.5-1.3l-0.6-0.7l-0.7,0.3l-0.8,0.4l-2.2,0.5h-0.8c-1,0.3-2,0.8-2.8,1.4c-0.9,0.7-1.9,1.3-3,1.7h-0.8
			c-0.5,1.6-0.2,3.2-0.5,4.9c-0.3,0.9-0.6,1.8-0.7,2.8c-0.1,1.5,0.1,2.7-0.6,4s-2.2,1.3-2.7,2.6s1,2.9,1.6,4.1s1.2,2.8,2.4,3.9
			c2.7,2.6,6.2,5,8.2,8.2s0.7,2.8-0.7,3.9s-1.1,1.6-1.9,3.1s-2,1.9-1.6,3.9c0.6,2.1,1.3,4.1,2.2,6.1c0.6,1.6,0.9,3.4,0.6,5.1
			c-0.4,2.4-1.3,3.6,0.1,6.1c0.4,0.7,1,1.3,1.7,1.7c1.1,0.9,2.1,2.2,3.5,2.9s9.7,0,9.4,2c-3.3,2.6-6.4,5.5-9,8.8
			c2.4-0.8,5.1-0.2,6.8,1.7c1.5,1.5,1.4,2.8,1.7,4.7c0.7,3.4,2,6-1.3,8.4s-5.5,1.8-7.9,2.9s-1.2,2.6-1.4,4.7s-0.8,1.9-1.2,3
			s0.7,2.7,1.1,3.9c0.2,0.9,0.3,1.8,0.3,2.7c0.4,1.2,0.9,2.3,1.6,3.3c1,1.9,1.3,4.4,2.5,6.1l1.7,1.6c0.3,0.5,0.6,1,0.8,1.6
			c1,1,2.3,0.8,3.3,1.4s1.8,2.9,2.3,5.1s1.1,4.4,1.6,6.6s1.3,3.9,0.7,4.9l-5.8-2c-1.5-0.4-2.8-0.5-3.7-1.7s-0.5-3.8,0.2-5.9
			c1-2.5,0.3-5.4-1.8-7.2c-1.6-1.3-2.1-0.1-3.6,0.8s-4.2,0.1-6.3-1.6c-0.8-0.5-1.5-1.1-2.1-1.8c-1.1-1.4-1-2.8-1.7-4.3
			s-4-6.5-6.4-6.6s-4.8,1.5-5.4,2.5s2.4,3.3,2.2,5.7c-0.2,1-0.4,1.9-0.8,2.8c-0.8,2.1-3.2,3.4-4.9,4.9s-3.4,3-6.2,3.5
			c-1.1,0.2-3.5-0.1-4.3,0.4s-1.8,4-1.9,4.2c-0.8,2-1.8,3.8-2.5,5.7s1.1,0.1,1.8,0.1l5-0.4c2.2-0.3,5.2-1.1,6.8,0.9s2.6,7.1,1.7,9.6
			s-2.8,3.7-4.7,4.9s-2.5,3.9-4,5.8s-3.3,3.5-3.3,6.4c0,2.8-0.8,5.5-2.1,7.9c-1,1.9-2.4,4-3.2,6c-0.3,0.8-0.4,1.6-0.2,2.4
			c0.2,1.7,0.7,3.3,1.6,4.7c1.4,1.9,4.2,3.2,6.5,3.8s4.7,0.2,5.4-1.8s-1.4-5.4-2.4-7s-5.3-4.8-4.8-8.6s4.4-7.3,6.6-9.7
			c1.9-2.3,4.5-4.1,7.3-5.1c0.6,0.9-0.6,3-0.8,3.9s-1.6,4.2-1.1,6.2c2.4,0.6,2.2,2.3,4,3.5s4.4,1.2,6.4,1.3s2.6,0.3,4,0.3
			s3.8-0.4,5.6,0.4s4.3,4.1,6,2.5c0.1-1.1-1.2-2.3-1.8-3.1c2.4-2.3,5.5-2.4,8.8-3.4c0.7-0.2-3.9-1.6-3.2-1.5c4.2,0.9,8.9,2,10.5,6.6
			c0.5,1.3,0,2,0,3.4s0.6,2.5-0.3,4.3c-2.1,3.9-5.9,6.8-8.1,10.6c-0.9,1.7-1.4,4-0.5,5.8s3.3,2.6,5,4s2.5,2.2,3.9,3.2
			c1.5,0.8,2.8,1.8,4,3c0.9,1.2,1.3,2.7,1,4.1c-0.3,1-1.7,1.6-1.9,2.9s0.6,1.9,0.6,3s-0.9,1.9-1.5,2.8c-1.6,2.1-2.3,4.7-2,7.3
			c0.4,2.9,1.6,5.6,3.4,7.8c1.1,1.4,3.3,2.1,4.7,3s5.1,2.2,7.9,3.1s4.3,5.6,5.6,9.2c0.6,1.7,1.4,3.3,2.4,4.8c0.7,0.8,1.7,1.3,2.8,1.4
			h0.5l0.5-0.2h0.1c0-0.4,0.2-0.9,0.5-1.2c1.6-1.4,4.9-3.2,7.4-2.9c1,0.2,1.9,0.6,2.8,1.1l1.2,0.6c1,0.5,1.9,1,2.8,1.6l0.6,0.7
			c0.4,0.4,0.8,0.7,1.3,1l1.3,0.5l0.9,0.4c0.7,0.2,1.3,0.5,1.9,0.9l1.7,0.9l0.9,0.2l1.1,0.3l0.9,0.4c1.8,0.6,3.5,1.3,5.2,1.1l3.5-0.2
			l5-0.3h0.5c0.6-0.1,1.1-0.2,1.7-0.1c0.8,0,1.7-0.1,2.4-0.5l0.2,0.5c0.2-0.7,0.4-1.4,0.5-2.1c0.3-1.1,0.8-2,1-3.1s-0.2-2.2,0.3-3.3
			s2.2-1.9,3.7-2.9c3.6-2.6,8.3-7.6,6.7-12.5c-0.9-2.4-3-4.5-3.4-7.1c0-1.1,0.2-2.3,0.4-3.4c0-2.3-0.7-4.8-0.6-7.1
			c0.2-2.4,0.7-4.8,1.7-7c1.1-2.9,2.7-5.5,3.8-8.5c0.4-1.3,0.9-2,0.4-3.3c-0.6-1.2-1.6-2.1-2.8-2.5c-2.3-1-5.1-0.1-7.2-1.9
			s-1.1-2.2-1.7-3.6c-0.3-0.9-0.8-1.7-1.4-2.4c-1.6-2.5-3.5-3.7-3.9-6.7s0.3-3.1,0.7-5.1c0.2-0.8,0.2-1.7,0.2-2.5
			c-0.1-0.8-0.9-2.4-0.7-3.3v-0.2c0.2,0.1,0.1-0.6-0.1-2.1s0-2.5,0-3.8c0.3-1.6,0.9-3.2,1.8-4.5c0.9-1.8,2.2-3.1,2.5-5s0.7-6.5-1-8.6
			s-4.1-2-4.4-5s0.9-4.1,1.7-5.8s2-4.4,1.8-7.1s1.9-10.5-0.4-10.7c-0.8,0.1-1.7,0.3-2.4,0.7c0.5-0.8,0.8-1.7,1-2.6
			c0.2-1.8-0.4-3.5-1.8-4.7c-1.1-1.1-2.2-1.7-3-3.1c-0.5-1.1-1-2.2-1.3-3.3c-0.7-1.1-0.7-2.4-0.2-3.6c0.7-1.6,2.1-3,2.2-4.7
			c0.3-4.3-3.3-7.3-6-10c-1.8-1.6-3-3.8-3.5-6.1c-0.5-2,0.7-4,0.5-6.1c-0.3-1.1-0.7-2.2-1.2-3.3c-0.8-2.2-0.4-5,0.3-7.2
			c0.5-1.8,1.2-3.5,2.2-5c1-1.1,2.1-2.1,3.2-3c2.4-2.4,1-7.6,0-10.3s-0.8-3.1-3.5-3.1c-1.6-0.1-3.2-0.2-4.8-0.5l-3.6-0.2
			c-1.7,0-3.4,0.2-5,0.6l-7.2,1.1c-2.1,0.4-5.8,1.4-7.7,0.4s-2.9-2.2-2-3.4s2.5-1,3.3-1.7s2-2.3,3.1-3.3s2.3-1.8,3.3-3.2
			c2.2-3,6.7-3.1,9.5-0.7s1.7,2,3.4,0.4c0.9-0.9,1.8-1.8,2.6-2.8c0.6-1.2,1.2-2.3,1.9-3.4c1.1-1.2,1.7-2.7,1.8-4.3
			C428,1327.1,427.5,1324.6,426.5,1322.4z M439.6,1426.4l-1.5,0.7c-1.8,0.5-3.3,0.4-4.9,1.4c-1.1,0.7-2.1,1.5-3.1,2.4
			c-2.4,2.2-4.3,4.8-5.6,7.8c-0.8,1.8-1,3.8-2,5.5s-3.8,5-5.3,4.6s0.6-5.1,1.5-6.3s3.4-3.3,4.6-5.2c0.9-1.7,1.8-3.3,2.9-4.8
			C428.4,1429.6,437.1,1420.1,439.6,1426.4z" />
				<path class="cv_st0" d="M358.6,1573.8c1.2-1.2,2.6-2,4.2-2.4l1.7-0.5l0.8-0.5c0.7-0.5,1.5-0.9,2.3-1.1h3c1-0.2,1.9-0.8,2.5-1.6
			c0.6-0.7,1.3-1.3,2-1.8c2.7-1.8,5.9-2.5,9.1-1.9l0.8,0.2c0.7-0.3,1.5-0.2,2.2,0.1l0.9,0.5l0.4,0.2l0.6-0.2c-0.5-0.4-0.8-1-0.7-1.6
			c0.1-1.9,0.9-4.5-1.2-5.5c-1.1-0.5-2.2-1.3-3-2.2c-0.8-0.7-1.7-1.2-2.5-1.8s-1.2-1.7-1.9-2.5s-1.3-1.1-1.7-2
			c-0.8-1.8-2.6-2.9-4.5-2.9l-2.7-0.2l-2.4-0.3c-0.9-0.2-1.7-0.8-2.1-1.6c-0.5-0.6-0.5-1.3-1.1-1.9s-1.7-0.6-3-0.3
			c-1.2,0.4-2.4,0.6-3.6,0.5c-0.6-2,2.2-3.7,4-3.3c0.9,0.3,1.9,0.4,2.9,0.1c1.2-0.4,1.4-2.2,1.4-3.3c0-1.4-0.1-2.7-0.3-4.1
			c-0.2-1.2-0.8-2.3-1-3.6c-0.1-0.6-0.2-1.2-0.5-1.7c-0.4-0.8-1-1.4-1.5-2.2s-1.1-2.9-2.2-3.8c-0.7,1.9-1.5,3.7-2,5.6
			s-0.5,2.2-0.7,3.2c-0.1,1.2-0.6,2.4-1.2,3.5c-0.5,0.7-1.2,0.9-1.9,1.4s-1.7,2.7-2.5,4.1s-3.2,1.8-4.8,2.8c-1.6,0.9-2.9,2.2-3.8,3.8
			c-0.9,1.4-0.2,3.2,0,4.8c0.2,1.9,0.5,3.8,1,5.7c0.6,1.5,1.3,3,2.2,4.3c1.3,2,2.4,4,2,6.5c-0.3,1.3-0.5,2.6-0.6,3.9
			c0.2,2.2,1.2,4.2,2.8,5.6c1.2,1,2.5,2,3.8,2.8C356,1577.4,356.6,1576,358.6,1573.8z" />
				<path class="cv_st0" d="M313.2,1373c0.7,1.6,0.7,2.6,2,3.9s2.4,2.8,3.9,2.3c0.6-0.3,1.2-0.8,1.7-1.3c0.5-0.5,0.7-1.3,1.3-1.8
			c0.6-0.5,1.3-0.9,2.1-1c1.2-0.5,3.2-1,3.8,0.5s-0.8,2.7-1.3,3.7s-1,3.1,1,4.9s4.4,2.3,6.8,3.1s4.1,1.3,5.4,3.7
			c1.5,2.4,2.2,5.2,2.1,8.1c-0.1,2.1,0.5,4.2,1.7,6h0.4c2.8,1,6.2-1.4,7.1-4.2s-0.6-5.4-1.4-7.8c-0.3-0.5-0.6-1-1-1.4
			c-0.3-0.6-0.6-1.2-0.9-1.8c-0.5-0.8-1-0.9-1-2s0.9-2.3,0.8-3.6s-1.8-2.1-2.7-3.2c-0.6-0.7-1.1-1.4-1.5-2.2c-1-2-1.4-4.2-2.6-6.1
			c-0.7-1.1-1-2.4-0.7-3.7c0.4-1,0.3-2.1-0.1-3c-0.7-0.9-1.6-1.2-2.1-2.3s-0.6-2-2.4-1.6s-4.1-1-4.9-3.5s-2-4.9-1.9-7.9
			c0.1-2.3-0.1-4.6-0.7-6.8c-0.9-2.9-2.3-4.1-4.6-5.8s-2.8-1.9-3.2,0.5c-0.3,2.4-0.1,4.8,0.7,7.1c0.8,2.4,1.2,4.8,2.1,7.2
			s2.5,5.6-0.5,7.3s-3.9-0.5-5.6-2.8s-1.5-2.3-3.1-1.7c-1.1,0.6-2.1,1.5-2.8,2.6c-1.4,2.9,4.3,4.1,2.5,7.3s-5.9,2.4-7.9,0.7
			c-1.5,0.5-2.9,1.5-3.8,2.8c-1.2,1.4-5,4.3-3.7,6.6c2.3,0.2,4.7,0.1,7-0.3C308,1374.9,310.5,1373.5,313.2,1373z" />
				<path class="cv_st0"
					d="M312,1327.7c1.5,0.3,3,0.5,4.5,0.6c1,0.1,2.6-0.6,3.6-0.4l1.3,1c0.5,0.3,1,0.4,1.6,0.3c0.5-0.1,0.9-0.1,1.4,0
			l0.8,0.6c0.5-0.1,1-0.2,1.5-0.5c1.9-0.9,1.4-4.4-0.5-5.3c-1.2-0.4-2.5-0.5-3.7-0.3c-1.5,0-2.9-0.4-4.3-0.9
			c-3.6-1.2-6.5-4.2-10.4-1.6c-0.1,1.1,0.2,2.2,0.6,3.2c0.5,0.9,1.9,1.4,2,2.5l-0.3,0.5C310.7,1327.5,311.4,1327.6,312,1327.7z" />
			</g>
		</svg>
	</div>
	<CardMapaChile id="card-aricaparinacota" class="cardMapaChileInvisibleDerecha imagenGrande">
		<template v-slot:contenidoCard>
			<img src="@/assets/image/arica.png" alt="Arica">
		</template>
	</CardMapaChile>

	<CardMapaChile id="card-antofagasta" class="cardMapaChileInvisibleDerecha imagenGrande">
		<template v-slot:contenidoCard>
			<img src="@/assets/image/antofagasta.png" alt="Antofagasta">
		</template>
	</CardMapaChile>

	<CardMapaChile id="card-calama" class="cardMapaChileInvisibleDerecha imagenGrande">
		<template v-slot:contenidoCard>
			<h1>CALAMA</h1>
		</template>
	</CardMapaChile>

	<CardMapaChile id="card-biobio" class="cardMapaChileInvisibleIzquierda imagenGrande">
		<template v-slot:contenidoCard>
			<img src="@/assets/image/biobio.png" alt="Biobio">
		</template>
	</CardMapaChile>

	<CardMapaChile id="card-araucania" class="cardMapaChileInvisibleIzquierda imagenGrande">
		<template v-slot:contenidoCard>
			<img src="@/assets/image/Araucanía.png" alt="Araucania">
		</template>
	</CardMapaChile>

	<CardMapaChile id="card-regionmetropolitana" class="cardMapaChileInvisibleDerecha imagenGrande">
		<template v-slot:contenidoCard>
			<img src="@/assets/image/metropolitana.png" alt="Metropolitana">
		</template>
	</CardMapaChile>

	<CardMapaChile id="card-niuble" class="cardMapaChileInvisibleIzquierda imagenGrande">
		<template v-slot:contenidoCard>
			<img src="@/assets/image/niuble.png" alt="Niuble">
		</template>
	</CardMapaChile>



	<CardMapaChile id="card-maule" class="cardMapaChileInvisibleDerecha imagenGrande">
		<template v-slot:contenidoCard>
			<img src="@/assets/image/maule.png" class="img-card" alt="Maule">

		</template>
	</CardMapaChile>

	<CardMapaChile id="card-libohiggins" class="cardMapaChileInvisibleDerecha imagenGrande">
		<template v-slot:contenidoCard>
			<img src="@/assets/image/libertador.png" class="img-card" alt="Libertador Bernardo Ohiggins">
		</template>
	</CardMapaChile>

	<CardMapaChile id="card-tarapaca" class="cardMapaChileInvisibleDerecha imagenGrande">
		<template v-slot:contenidoCard>
			<img src="@/assets/image/tarapaca.png" class="img-card" alt="Tarapaca">
		</template>
	</CardMapaChile>

	<CardMapaChile id="card-loslagos" class="cardMapaChileInvisibleIzquierda imagenGrande">
		<template v-slot:contenidoCard>
			<img src="@/assets/image/loslagos.png" class="img-card" alt="Los lagos">
		</template>
	</CardMapaChile>

	<CardMapaChile id="card-rios" class="cardMapaChileInvisibleIzquierda imagenGrande">
		<template v-slot:contenidoCard>
			<img src="@/assets/image/Losrios.png" class="img-card" alt="Los rios">
		</template>
	</CardMapaChile>

	<CardMapaChile id="card-coquimbo" class="cardMapaChileInvisibleDerecha imagenGrande">
		<template v-slot:contenidoCard>
			<img src="@/assets/image/coquimbo.png" class="img-card" alt="Coquimbo">
		</template>
	</CardMapaChile>

	<CardMapaChile id="card-atacama" class="cardMapaChileInvisibleDerecha imagenGrande">
		<template v-slot:contenidoCard>
			<img src="@/assets/image/atacama.png" class="img-card" alt="Atacama">
		</template>
	</CardMapaChile>

	<CardMapaChile id="card-valparaiso" class="cardMapaChileInvisibleDerecha imagenGrande">
		<template v-slot:contenidoCard>
			<img src="@/assets/image/valaraiso.png" class="img-card" alt="Valparaiso">
		</template>
	</CardMapaChile>

	<CardMapaChile id="card-aysen" class="cardMapaChileInvisibleIzquierda imagenGrande">
		<template v-slot:contenidoCard>
			<img src="@/assets/image/aysen.png" class="img-card" alt="Aysen">

		</template>
	</CardMapaChile>

	<CardMapaChile id="card-magallanesantartica" class="cardMapaChileInvisibleIzquierda imagenGrande">
		<template v-slot:contenidoCard>
			<img src="@/assets/image/magallanes.png" class="img-card" alt="Magallanes">
		</template>
	</CardMapaChile>
</template>

<script setup>
import { ref } from "vue";
import CardMapaChile from "./Componentes_Genericos/CardMapaChile.vue";

const mouse_over = ref(false);
const izquierda = ref(false);

const switchClass = (identificador) => {
	mouse_over.value = !mouse_over.value;


	const element = document.getElementById(identificador);

	if (mouse_over.value && (identificador === "card-araucania" || identificador === "card-biobio" || identificador === "card-niuble" || identificador === "card-loslagos" || identificador === "card-rios" || identificador === "card-aysen" || identificador === "card-magallanesantartica")) {

		element.classList.remove("cardMapaChileInvisibleIzquierda");
		element.classList.add("cardMapaChileVisibleIzquierda");
		izquierda.value = true;
	} else if (mouse_over.value && (identificador !== "card-araucania" || identificador !== "card-biobio" || identificador !== "card-niuble" || identificador === "card-maule" || identificador !== "card-loslagos" && identificador !== "card-rios" && identificador !== "card-aysen" && identificador !== "card-magallanesantartica")) {
		element.classList.remove("cardMapaChileInvisibleDerecha");
		element.classList.add("cardMapaChileVisibleDerecha");
		izquierda.value = false;

	} else {
		if (izquierda.value) {
			element.classList.remove("cardMapaChileVisibleIzquierda");
			element.classList.add("cardMapaChileInvisibleIzquierda");
		} else {
			element.classList.remove("cardMapaChileVisibleDerecha");
			element.classList.add("cardMapaChileInvisibleDerecha");
		}
	}
};

const primera_entrada = ref(true);

const scrollPrimeraEntrada = (id) => {
	console.log('entranding!', primera_entrada.value)
	if(!primera_entrada.value) return;

	const element = document.getElementById(id);
	if (element) {
		element.scrollIntoView({ behavior: "smooth", block: "start" });
		primera_entrada.value = false;
	} else {
		console.log('Elemento no encontrado');
	}

}

const scrollPage = (card) => {
	const element = document.getElementById(card);
	if (element) {
		element.scrollIntoView({ behavior: "smooth", block: "start" });
		primera_entrada.value = false;
	} else {
		console.log('Elemento no encontrado');
	}
}


</script>




<style scoped>
.img-card {
	z-index: 1;
}


.imagenMasPequenia {
	max-width: 600px;
	max-height: 500px;
}

.imagenGrande {
	/* margin-top: -400px; */
	/* max-width: 700px; */
}

.imagenPequenia {

	max-width: 700px;
	max-height: 800px;
}

img {
	width: 50%;
}

.cardMapaChileInvisibleDerecha {
	position: fixed;
	top: 30%;
	background-color: rgba(247, 247, 247, 0.85) !important;
	left: 60%;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	display: none !important;
}

.cardMapaChileVisibleDerecha {
	position: fixed;
	top: 20%;
	left: 10%;
	max-height: 100%;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent !important;
	animation: fadeIn 1s;
}

.cardMapaChileInvisibleIzquierda {
	position: fixed;
	top: 30%;
	background-color: rgba(247, 247, 247, 0.85) !important;
	/* right: 60%; */
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	display: none !important;
}

.cardMapaChileVisibleIzquierda {
	position: fixed;
	top: 30%;
	background-color: transparent !important;
	right: 30%;
	align-items: center;
	width: 100%;
	max-height: 80%;
	display: flex;
	justify-content: center;
	animation: fadeIn 1s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.svg-container {
	min-width: 80%;
	width: 100%;
	max-height: 50%;
	background-color: rgba(255, 255, 255, 0.3);
	border-radius: 100px 0 100px 0;
	/* padding: 0 150px 0 150px; */
}

.cv_st0 {
	fill: #ddd;
	transition: 1.1s ease-in-out;
}

#arica_parinacota:hover,
#coquimbo:hover,
#maule:hover,
#rios:hover {
	fill: #FFD100 !important;
	transition: 0.5s;
	transform: translate(0px, -5px);
	cursor: pointer;
	z-index: 1000;
}


#tarapaca:hover,
#valparaiso:hover,
#niuble:hover,
#los_lagos:hover {
	fill: #EF5D2A !important;
	transition: 0.5s;
	transform: translate(0px, -5px);
	cursor: pointer;
	z-index: 1000;
}


#region_metropolitana:hover,
#antofagasta:hover,
#magallanes_antartica:hover path,
#biobio:hover {
	fill: #91D63C !important;
	transition: 0.5s;
	transform: translate(0px, -5px);
	cursor: pointer;
	z-index: 1000;
}

#atacama:hover,
#araucania:hover,
#lib_bdo_ohiggins:hover,
#aysen:hover path {
	fill: #3CB5DB !important;
	transition: 0.5s;
	transform: translate(0px, -5px);
	cursor: pointer;
	z-index: 1000;
}

/* Tamaño por defecto, para pantallas muy pequeñas */
img {
	width: 55%;
}

@media all and (min-width: 1280px) {
	img {
		width: 30%;
	}

	.cardMapaChileVisibleDerecha {
		top: 30%;
		left: 45%;
		max-width: 70%;
	}

	.cardMapaChileVisibleIzquierda {
		top: 30%;
		right: 45%;
		max-width: 70%;
	}
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
	img {
		width: 25%;
	}

	.cardMapaChileVisibleDerecha {
		top: 40%;
		left: 40%;
		max-width: 90%;
	}

	.cardMapaChileVisibleIzquierda {
		top: 35%;
		right: 30%;
		max-width: 90%;
	}
}

@media all and (min-width: 768px) and (max-width: 1024px) {
	img {
		width: 30%;
	}

	.cardMapaChileVisibleDerecha {
		top: 25%;
		left: 45%;
		max-width: 90%;
	}

	.cardMapaChileVisibleIzquierda {
		top: 38%;
		right: 30%;
		max-width: 90%;
	}
}

@media all and (min-width: 480px) and (max-width: 768px) {
	img {
		width: 40%;
	}

	.cardMapaChileVisibleDerecha {
		top: 30%;
		left: 45%;
		max-width: 90%;
	}

	.cardMapaChileVisibleIzquierda {
		top: 30%;
		right: 30%;
		max-width: 90%;
	}
}

@media all and (max-width: 480px) {
	img {
		width: 50%;
	}

	.cardMapaChileVisibleDerecha {
		top: 40%;
		left: 50%;
		max-width: 90%;
	}

	.cardMapaChileVisibleIzquierda {
		top: 40%;
		right: 35%;
		max-width: 90%;
	}
}
</style>