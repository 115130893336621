<template>
  <div class="navbar-container">
    <nav ref="navbar" class="custom-nav">
      <div class="d-flex align-items-center justify-content-end">
        <button ref="burger" @click="toggleMenu" class="burger">
          &#9776;
        </button>
        <img @click="redirectHome" class="logo cursor" src="@/assets/svgs/Recurso4.svg" alt="Logo">
      </div>
      <transition name="slide">
        <div v-if="isMenuOpen" ref="menu" class="menu text-end z-3">
          <ul>
            <li class="li-navbar"><router-link class="routerlink" to="/">Inicio</router-link></li>
            <li class="li-navbar"><router-link class="routerlink" to="/inclusion">Inclusión</router-link></li>
            <li class="li-navbar"><router-link class="routerlink" to="/Incidencia">Incidencia y Colaboración</router-link></li>
            <li class="li-navbar"><span class="routerlink"><a href="/OrganizacionColaboracion">Organización</a></span></li>
            <li class="li-navbar"><router-link class="routerlink" to="/Sostenibilidad">Sostenibilidad y transparencia</router-link></li>
            <li class="li-navbar"><a class="routerlink" href="https://www.hogardecristo.cl/" target="_blank" rel="noopener">Página Web</a></li>
            <li class="li-navbar"><a class="routerlink" href="https://www.hogardecristo.cl/quiero-contactarme/" target="_blank" rel="noopener">Contáctanos</a></li>
          </ul>
        </div>
      </transition>
    </nav>
  </div>
</template>


<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { router } from '@/router/index.js';

const burger = ref(null);
const menu = ref(null);
const navbar = ref(null);
const isMenuOpen = ref(false);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const redirectHome = () => {
  router.push('/');
}

let lastScrollTop = 0;

const handleScroll = () => {
  const currentScrollTop = window.scrollY;

  if (currentScrollTop > lastScrollTop) {
    navbar.value.style.top = '-100%';
  } else {
    navbar.value.style.top = '0';
  }

  lastScrollTop = currentScrollTop;
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style scoped>
.cursor {
  cursor: pointer;
}

.li-navbar .router-link :hover {
  color: #91D63C;
}

.routerlink {
  text-decoration: none;
  color: inherit;
}

.routerlink:hover {
  color: #91D63C;
}

.routerlink a{
  text-decoration: none;
  color: inherit;
}

.routerlink:hover a{
  color: #91D63C;
}
.navbar-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  margin-right: 10px;
}

.custom-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  transition: top 0.3s;
}


.burger {
  font-size: 2rem;
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  z-index: 4;
}

.menu {
  position: absolute;
  top: calc(100% + 10px);
  /* Cambiar el valor de top para que el menú aparezca justo debajo del botón burger */
  right: 20%;
  min-width: 200px;
  min-height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 20% 0 20% 20%;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform-origin: left center;
}

.menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu li {
  cursor: pointer;
  /* Espacio entre los elementos del menú */
}

.logo {
  width: 30%;
  z-index: 3;
}

@media all and (min-width: 1280px)
{
  .logo {
    width: 50%;
    height: auto;
  }
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
  .logo {
    width: 50%;
    height: auto;
  }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .logo {
    width: 50%;
    height: auto;
  }

  .menu {
    width: 30%;
    /* Menú de ancho completo */
  }
}

@media all and (min-width: 480px) and (max-width: 768px) {
  .logo {
    width: 50%;
    height: auto;
  }

  .menu {
    width: 50%;
    /* Menú de ancho completo */
  }
}

@media all and (max-width: 480px) {}

.slide-enter-active {
  animation: slide-in 0.6s cubic-bezier(0.1, 0.7, 0.1, 1);
}

.slide-leave-active {
  animation: slide-in 0.6s cubic-bezier(0.1, 0.7, 0.1, 1) reverse;
}


@keyframes slide-in {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
