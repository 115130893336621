<template>
  <div class="text-center">
    <text style="color: #fff;" class="titulo-svg animated-text uuid-a482cd90-22f1-45c8-b95d-a6e088074421"
      transform="translate(454.33 82.05)">
      <tspan class="uuid-7bea3758-e8b9-4dad-9bd0-2c378fbd49e8" x="0" y="0">El </tspan>
      <tspan class="uuid-75e2377d-58b7-4aac-a1a2-ad7ee582a794" x="92.14" y="0">ADN</tspan>
      <tspan class="uuid-7bea3758-e8b9-4dad-9bd0-2c378fbd49e8" x="281.92" y="0" xml:space="preserve"> del </tspan>
      <tspan class="uuid-33684426-0204-49de-b9ab-6a84d696bf31" x="451.01" y="0">Hogar de </tspan>
      <tspan class="uuid-410ffd43-a348-4947-afe5-47b377c7938c" x="848.8" y="0">Cristo</tspan>
    </text>
  </div>
  <div class="container-custom">
    <div v-for="(column, index) in columns" :key="index" :id="column.id" class="column-custom col-4" @click="handleClick(index)"
      :style="{ borderRadius: column.borderRadius, height: column.height, minWidth: column.minWidth, maxWidth: column.maxWidth, flexGrow: column.flexGrow, opacity: column.opacity }">
      <span v-if="ground_state" class="text-white bigtext">
        {{ column.content }}
      </span>


      <span v-else class="text-black small-text">
        <span v-if="columna_activada === 0" v-html="visionString"></span>
        <span v-if="columna_activada === 1" v-html="misionString"> </span>
        <span v-if="columna_activada === 2" v-html="valoresString"> </span>
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed } from 'vue';
import anime from 'animejs';

const ground_state = ref(true);

const visionString = computed(() => {
  return '<div class="text-center bigtext-bold-white"><span class="fw-bold"> Visión </span> </div> <div> Nuestra labor nace de las últimas palabras del Padre Hurtado quien, poco antes de morir, nos expresó su último anhelo, el que nos moviliza en la construcción de un país con <span class="small-text-bold"> justicia, respeto y solidaridad.</span></div>';
});

const misionString = computed(() => {
  return '<div class="text-center bigtext-bold-white"><span class="fw-bold"> Misión </span> </div> <div> El Hogar de Cristo acoge con <span class="small-text-bold">amor y dignidad </span> a los más pobres entre los pobres, para ampliar sus oportunidades a una vida mejor. <span class="small-text-bold"> Convoca </span> con entusiasmo y vincula a la comunidad en su <span class="small-text-bold">responsabilidad con los excluidos de la sociedad.</span> Es una organización transparente, eficiente y eficaz que, animada por la espiritualidad de san Alberto Hurtado, promueve una cultura de respeto, justicia y solidaridad.</div>';
});

const valoresString = computed(() => {
  return '<div class="text-center bigtext-bold-white"><span class="fw-bold"> Valores </span> </div> <div> Porque no basta con hacer el bien, sino que hay que hacerlo bien, nuestro actuar está guiado por valores como la <span class="small-text-bold">Solidaridad</span>, el <span class="small-text-bold">Compromiso</span>, el <span class="small-text-bold">Espíritu Emprendedor</span>, el <span class="small-text-bold">Respeto</span>, la <span class="small-text-bold">Justicia</span>, la <span class="small-text-bold">Transparencia</span> y el <span class="small-text-bold">Trabajo en Equipo.</span></div>';
})

const columns = reactive([
  {
    id: 'column1',
    content: 'Visión',
    borderRadius: '150px 150px 0px',
    height: '300px',
    minWidth: '300px',
    maxWidth: '300px',
    flexGrow: 1,
    opacity: 1,
  },
  {
    id: 'column2',
    content: 'Misión\n',
    borderRadius: '150px 150px 0px',
    height: '300px',
    minWidth: '300px',
    maxWidth: '300px',
    flexGrow: 1,
    opacity: 1,
  },
  {
    id: 'column3',
    content: 'Valores\n',
    borderRadius: '150px 150px 0px',
    height: '300px',
    minWidth: '300px',
    maxWidth: '300px',
    flexGrow: 1,
    opacity: 1,
  },
]);

const expanded = ref(0);
const columna_activada = ref(-1);
const handleClick = (index) => {
  if (expanded.value !== -1) {
    if (expanded.value) {
      columns.forEach((column, i) => {
        if (i !== index) {
          anime({
            targets: column,
            opacity: 1,
            duration: 800,
            easing: 'easeInOutQuad',
          });
        }
      });

      // columna vuelve a su estado inicial

      const column = columns[index];

      anime({
        targets: column,
        borderRadius: '150px 150px 0px',
        height: '300px',
        maxWidth: '300px',
        minWidth: '300px',
        flexGrow: 1,
        duration: 800,
        easing: 'easeInOutQuad',
      });

      setTimeout(() => {
        expanded.value = 0;
        ground_state.value = true;
        columna_activada.value = -1;
      }, 100);

      expanded.value = -1;
    } else {

      // columna se expande

      columna_activada.value = index;

      columns.forEach((column, i) => {
        if (i !== index) {
          anime({
            targets: column,
            opacity: 0,
            width: 0,
            display: 'none',
            duration: 800,
            easing: 'easeInOutQuad',
          });
        }
      });

      const column = columns[index];
      anime({
        targets: column,
        borderRadius: '0px 75px 0px',
        flexGrow: 3,
        height: '450px',
        maxWidth: '450px',
        minWidth: '300px',
        duration: 800,
        easing: 'easeInOutQuad',
      });

      setTimeout(() => {
        ground_state.value = false;
        expanded.value = 1;
      }, 700);
      expanded.value = -1;
    }
  }
};
</script>

<style scoped>
.container-custom {
  display: flex;
  justify-content: center;
  height: 450px;
  margin: 0 20%;
  width: 60%;
}

.column-custom {
  flex: 1;
  padding: 20px;
  border-radius: 150px 150px 0px 150px;
  margin: 10px;
  height: 300px;
  width: 300px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1024px) {
  .container-custom {
    justify-content: space-evenly;
    align-items: center;
    height: 900px;
    margin: 0 20%;
    width: 60%;
  }

  .column-custom {
    padding: 20px;
    margin: 10px;
    min-height: 100px;
    width: 100px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
}

@media (max-width: 1024px) {
  .container-custom {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 900px;
    margin: 0 20%;
    width: 60%;
  }

  .column-custom {
    padding: 20px;
    margin: 10px;
    min-height: 100px;
    width: 100px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
}


#column1 {
  background-color: #EF5D2ADD;
}

#column2 {
  background-color: #91D63CDD;
}

#column3 {
  background-color: #3CB5DBDD;
}

.text-black {
  font-family: DINNextRoundedLTPro-Light, 'DINNextRoundedLTPro Light';
  font-weight: 300;
  color: rgb(0, 0, 0);
}

.text-white {
  font-family: DINNextRoundedLTPro-Light, 'DINNextRoundedLTPro Light';
  font-weight: 300;
  color: rgb(255, 255, 255);
}

.bigtext-bold-white {
  color: rgb(255, 255, 255);
  font-size: 28pt;
  font-family: DINNextRoundedLTPro-Bold, 'DINNextRoundedLTPro Bold';
  font-weight: 300;
}

.bigtext {
  font-size: 42pt;
  font-family: DINNextRoundedLTPro-Bold, 'DINNextRoundedLTPro Bold';
  font-weight: 300;
}

.small-text {
  font-size: 14pt;
  font-family: DINNextRoundedLTPro-Light, 'DINNextRoundedLTPro Light';
  font-weight: 300;
}

.small-text-bold {
  font-size: 14pt;
  font-family: DINNextRoundedLTPro-Bold, 'DINNextRoundedLTPro Bold';
  font-weight: 300;
}


svg {
  width: 100%;
  height: auto;
}

.burbujas:hover {
  cursor: pointer;
  animation: pulser 1s infinite;

}

/* pulser  animation with burbujas class */

@keyframes pulser {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.01);
  }

  100% {
    transform: scale(1);
  }
}

/* hover animation with burbujas class */


.uuid-8989505b-60bc-4da0-bf8c-438269183b70 {
  font-size: 98.24px;
}

.uuid-8989505b-60bc-4da0-bf8c-438269183b70,
.uuid-a325053f-60e6-4326-b9eb-19e542f6ec3d,
.uuid-89cf00d8-04ca-4391-933a-d42a0426069d,
.uuid-02e7dd86-5ddc-4219-a023-49cb031c4608,
.uuid-327ea7b0-247b-4ddc-8be9-091f701c30e6,
.uuid-a482cd90-22f1-45c8-b95d-a6e088074421,
.uuid-16b1c124-3304-4841-a814-0ec0a370cfd9 {
  fill: #fff;
}

.uuid-8989505b-60bc-4da0-bf8c-438269183b70,
.uuid-89cf00d8-04ca-4391-933a-d42a0426069d,
.uuid-02e7dd86-5ddc-4219-a023-49cb031c4608,
.uuid-88d3ee14-411e-40d8-99aa-58f2688f07c3 {
  font-family: DINNextRoundedLTPro-Bold, 'DINNextRoundedLTPro Bold';
  font-weight: 700;
}

.uuid-01698108-9327-44c8-8d82-be52343d2c48,
.uuid-fdd91b7c-083d-485a-af30-868c848f13d1 {
  fill: #84bd00;
}

.uuid-92f2e616-2e55-4dd2-bc48-ce75b9859920 {
  letter-spacing: -.07em;
}

.uuid-71c7fd47-a19f-4374-8b1f-15f327827f92 {
  letter-spacing: .01em;
}

.uuid-75e2377d-58b7-4aac-a1a2-ad7ee582a794 {
  letter-spacing: 0em;
}

.uuid-75e2377d-58b7-4aac-a1a2-ad7ee582a794,
.uuid-410ffd43-a348-4947-afe5-47b377c7938c,
.uuid-24d72457-eca6-4924-8a13-7fa227cad7b8,
.uuid-3f17dd81-19ce-4878-a79f-670208d08b24,
.uuid-70537a94-e536-4cec-96a5-352d4bd2598e,
.uuid-33684426-0204-49de-b9ab-6a84d696bf31,
.uuid-16b1c124-3304-4841-a814-0ec0a370cfd9 {
  font-family: DINNextRoundedLTPro-Medium, 'DINNextRoundedLTPro Medium';
  font-weight: 500;
}

.uuid-89cf00d8-04ca-4391-933a-d42a0426069d {
  font-size: 52.75px;
}

.uuid-410ffd43-a348-4947-afe5-47b377c7938c {
  letter-spacing: 0em;
}

.uuid-24d72457-eca6-4924-8a13-7fa227cad7b8 {
  letter-spacing: 0em;
}

.uuid-3f17dd81-19ce-4878-a79f-670208d08b24 {
  letter-spacing: 0em;
}

.uuid-48d38ea6-3f48-4a1a-8c9b-03ae74f3a64c {
  letter-spacing: -.02em;
}

.uuid-0f2eca66-9737-422d-b515-1a15f2bb6293 {
  clip-path: url(#uuid-1ddcb1a7-c4fd-4af8-a7d1-46623271c333);
}

.uuid-db41f0e8-16db-4c38-9bad-8ec47b005557 {
  letter-spacing: 0em;
}

.uuid-7bea3758-e8b9-4dad-9bd0-2c378fbd49e8 {
  font-family: DINNextRoundedLTPro-Light, 'DINNextRoundedLTPro Light';
  font-weight: 300;
}

.uuid-03aa3d0e-9a2f-4927-b335-b7e98507f3db {
  letter-spacing: 0em;
}

.uuid-4beca174-686c-4878-90bd-107ec40954bd {
  letter-spacing: 0em;
}

.uuid-02e7dd86-5ddc-4219-a023-49cb031c4608 {
  font-size: 90.64px;
}

.uuid-744e1e93-b228-497f-9dbf-0dee7bbfb433 {
  letter-spacing: 0em;
}

.uuid-cf5619e1-8469-48a1-8116-3e9195ab75ab {
  fill: #5bc2e7;
  filter: url(#uuid-cf418b67-4cf1-42ac-b689-8a521f14726f);
}

.uuid-cf5619e1-8469-48a1-8116-3e9195ab75ab,
.uuid-eaa89ac7-b6ca-411c-9935-e241704791d6,
.uuid-fdd91b7c-083d-485a-af30-868c848f13d1 {
  opacity: .72;
}

.uuid-253b7fc1-2402-4f34-86af-479ae3ea1ed0 {
  letter-spacing: -.03em;
}

.uuid-88d3ee14-411e-40d8-99aa-58f2688f07c3 {
  font-size: 38.89px;
  text-decoration: underline;
}

.uuid-88d3ee14-411e-40d8-99aa-58f2688f07c3,
.uuid-e411fd02-9c42-445d-85e9-379fbcad4599 {
  fill: #666;
}

.uuid-3b7a6542-58de-4ec1-8c40-9110e78dbb29,
.uuid-327ea7b0-247b-4ddc-8be9-091f701c30e6 {
  opacity: .57;
}

.uuid-327ea7b0-247b-4ddc-8be9-091f701c30e6 {
  filter: url(#uuid-61f571c5-5af5-4a98-9f00-c7c2bb51c96a);
}

.uuid-70537a94-e536-4cec-96a5-352d4bd2598e {
  letter-spacing: 0em;
}

.uuid-30a5f745-0b81-489e-851d-0f4540f97c87 {
  fill: none;
}

.uuid-a482cd90-22f1-45c8-b95d-a6e088074421 {
  font-size: 98.03px;
}

.uuid-31ab239c-af70-487e-b948-22ab7a383168 {
  letter-spacing: 0em;
}

.uuid-164b6c38-d7b3-48f2-a93e-3a0b06c91601 {
  letter-spacing: 0em;
}

.uuid-a1602b62-7aeb-41a4-af5e-9ed92d4a5f05 {
  letter-spacing: 0em;
}

.uuid-adf1b3c1-6e2f-40d1-a079-3b717ea571b5 {
  letter-spacing: 0em;
}

.uuid-eaa89ac7-b6ca-411c-9935-e241704791d6 {
  fill: #fe5000;
  filter: url(#uuid-f5266775-e8ee-499d-89f4-51b3b5eadb56);
}

.uuid-fdd91b7c-083d-485a-af30-868c848f13d1 {
  filter: url(#uuid-37d4513a-f4d5-45ca-8e35-54207dae7099);
}

.uuid-16b1c124-3304-4841-a814-0ec0a370cfd9 {
  font-size: 74.83px;
}

.uuid-7c47ee9a-3969-4e98-93ff-a86a1a5e918b {
  letter-spacing: 0em;
}

.uuid-c6ed81bd-3dee-436d-bdcc-7512f2081d12 {
  letter-spacing: 0em;
}

.uuid-caff4305-5db9-4e58-a1b9-402e5cb6be73 {
  letter-spacing: -.01em;
}

.titulo-svg {
  align-items: center;
  width: 100%;
  /* Hace que el título tenga un ancho flexible */
  height: auto;
  /* Mantiene la relación de aspecto al cambiar el tamaño */
  max-width: 800px;
  /* Establece un ancho máximo para el título */
  max-height: 100px;
  /* Establece una altura máxima para el título */
  animation: fadeIn 1s ease-in-out;
}
</style>