<template>
    <div class="container-fluid">
        <transition name="slide">

            <div v-if="isOpen" class="modal" @click.self="closeModal">
                <div :style="styleCard" class="styleCardClass shadow">
                    <div v-if="muestraNav" class="row p-5 row-height">
                        <div class="col-2 iconoModal">
                            <tspan class=" font-dinn-bold scroll-titulo"
                                :class="{ 'textoAmarillo': !tituloBlanco, 'text-white': tituloBlanco }"
                                @click="activarModalPrevio" x="0" y="0">&#60;</tspan>
                        </div>

                        <div class="col-8 cls-1 text-center font-dinn-bold card-titulo"
                            :class="{ 'textoAmarillo': !tituloBlanco, 'text-white': tituloBlanco }">
                            {{ props.titulo }}
                        </div>

                        <div class="col-2 iconoModal text-end">
                            <tspan @click="closeModal" class=" font-dinn-bold scroll-titulo"
                                :class="{ 'textoAmarillo': !tituloBlanco, 'text-white': tituloBlanco }" x="0" y="0">X
                            </tspan>
                        </div>
                    </div>
                    <div :style="styleDiv" :class="{ 'scrollbarblanco': scrollBlanco, 'scrollbaramarillo': !scrollBlanco }">
                        <slot />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
import { defineProps, computed, ref, defineExpose } from 'vue';

const props = defineProps({
    display: {
        type: String,
        default: 'flex'
    },
    flexDirection: {
        type: String,
        default: 'column'
    },
    titulo: String,
    minHeight: String,
    minWidth: String,
    maxWidth: String,
    maxHeight: String,
    padding: String,
    tituloBlanco: {
        type: Boolean,
        default: true
    },
    muestraNav: {
        type: Boolean,
        default: false
    },
    backgroundColor: {
        type: String,
        default: '#ffcb00'
    },
    otherModalRef: {
        type: Object,
        default: null
    },
    overflowY: {
        type: String,
        default: 'scroll'
    },
    scrollBlanco: {
        type: Boolean,
        default: true
    }
});

const isOpen = ref(false);

const closeModal = () => {
    isOpen.value = false;
};

const openModal = () => {
    isOpen.value = true;
};

const activarModalPrevio = () => {
    closeModal();
    if (props.otherModalRef != null) props.otherModalRef.openModal();
};

defineExpose({ closeModal, openModal, });

const styleCard = computed(() => {
    return {
        display: props.display,
        flexDirection: props.flexDirection,
        minHeight: props.minHeight,
        minWidth: props.minWidth,
        maxWidth: props.maxWidth,
        maxHeight: props.maxHeight,
        borderRadius: '90px 0 90px 0',
        backgroundColor: props.backgroundColor,
        padding: props.padding,
        margin: 0,
        overflowX: 'hidden',
    }
});

const styleDiv = computed(() => {
    return {
        display: 'flex',
        flexDirection: 'column',
        overflowY: props.overflowY ? props.overflowY : 'inherit',
        overflowX: 'hidden',
        alignItems: 'center'

    }
});

</script>

<style scoped>
.textoAmarillo {
    color: #feca05;
}
.shadow {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.8);
}
/* Esto afectará a todo el documento */
::-webkit-scrollbar {
    width: 10px;
    /* Ancho del scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.227);
    /* Color del track */
    border-radius: 100px;
}

/* Handle */
.scrollbarblanco::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.894);
    border-radius: 100px;
}

.scrollbaramarillo::-webkit-scrollbar-thumb {
    background: #feca05;
    border-radius: 100px;
}

.scroll-titulo {
    font-size: 2rem;
}

.iconoModal {
    transform: translateY(-20px);
}

.iconoModal:hover {
    cursor: pointer;

}

.overflow-div {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.cls-1 {
    fill: #f6f6f6;
    font-size: 20px;
    font-weight: 300;
}

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.5s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}

.styleCardClass {
    min-width: 40%;
    min-height: 40%;
    max-width: 90%;
    max-height: 90%;
}

@media all and (min-width: 1280px) {
    .card-titulo {
        font-size: 2rem;
    }

    .iconoModal {
        transform: translateY(-10px);
    }

    .styleCardClass {
        min-width: 50%;
        min-height: 40%;
        max-width: 50%;
        max-height: 90%;
    }

}

@media all and (min-width: 1024px) and (max-width: 1280px) {
    .card-titulo {
        font-size: 2rem;
    }

    .iconoModal {
        transform: translateY(-10px);
    }

    .styleCardClass {
        min-width: 60%;
        min-height: 40%;
        max-width: 60%;
        max-height: 90%;
    }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
    .card-titulo {
        font-size: 2rem;
    }
    .iconoModal {
        transform: translateY(-10px);
    }
}

@media all and (min-width: 480px) and (max-width: 768px) {
    .card-titulo {
        font-size: 2rem;
    }
}

@media all and (max-width: 480px) {
    .card-titulo {
        font-size: 1rem;
    }
}
</style>