<template>
  <svg id="uuid-6d6d493d-63c8-457b-9057-3fc1075ef96e" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1896.87 212.89">
    <g id="uuid-4ea21051-9e68-47b2-b792-ef6433747eb8" data-name="Capa 1">
      <g class="uuid-3da7ef51-c2ca-4056-ae5b-b28ad0415588">
        <g>
          <path class="uuid-120e081d-c224-44ab-b444-34ca146eed21" d="m0,212.89s842.74-479,1896.87,0" />
          <g class="uuid-7cfaeab7-dfd3-49c4-b4b3-51d3fbf8a8ac">
            <!-- facebook -->
            <a href="https://www.facebook.com/hdecristo" target="_blank">

              <g class="facebook">
                <path class="uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30"
                  d="m841.94,160.36c-.2,17.63-14.36,31.53-32.02,31.28-17.46-.25-31.41-14.83-30.88-32.41.51-16.97,14.66-30.81,32.21-30.47,16.89.32,30.88,14.63,30.69,31.6Zm-4.1-.23c-.1-15.25-12.44-27.48-27.75-27.28-15.26.19-27.35,12.86-26.97,28.05.37,14.97,12.79,26.84,27.74,26.68,14.85-.16,27.08-12.55,26.98-27.44Z" />
                <path class="uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30"
                  d="m806.31,160.12c-.91,0-1.66-.01-2.41,0-.6.01-.88-.25-.87-.87.02-1.28.02-2.56,0-3.83-.01-.69.3-.91.95-.89.72.03,1.44,0,2.34,0,0-1.51,0-2.94,0-4.38,0-1.63.54-3.04,1.7-4.2,1.05-1.05,2.32-1.63,3.79-1.68,1.73-.05,3.45,0,5.18-.04.65,0,.95.22.93.9-.03,1.31.03,2.63-.04,3.93-.01.26-.43.69-.7.71-.89.09-1.79.02-2.69.04-1.08.03-1.56.51-1.59,1.61-.02.99,0,1.98,0,3.09,1.39,0,2.66.03,3.92-.01.77-.03,1.06.23.96,1.03-.14,1.23-.23,2.47-.29,3.71-.03.61-.29.86-.9.85-1.17-.02-2.35,0-3.69,0,0,.44,0,.84,0,1.23,0,4.52-.03,9.05,0,13.57,0,.88-.2,1.25-1.15,1.2-1.45-.07-2.9-.06-4.35,0-.89.04-1.1-.3-1.09-1.14.03-4.52.02-9.05.02-13.57,0-.37,0-.75,0-1.3Z" />
              </g>
            </a>
            <!-- twitter -->
            <g class="twitter">
              <a href="https://twitter.com/Hdecristo" target="_blank">
                <path class="uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30"
                  d="m935.58,160.36c0,17.38-14.11,31.4-31.59,31.39-17.23,0-31.32-14.16-31.33-31.48,0-17.28,14.19-31.43,31.51-31.42,17.33.01,31.41,14.13,31.4,31.51Zm-31.4-27.41c-15.11,0-27.45,12.35-27.41,27.42.04,15.03,12.33,27.32,27.32,27.32,15.16,0,27.41-12.3,27.42-27.54,0-14.87-12.38-27.2-27.33-27.2Z" />
                <path class="uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30"
                  d="m889.09,156.35c.9.28,1.61.5,2.64.82-2.58-2.77-3.4-5.58-1.68-9.09,2.56,2.83,5.39,4.83,8.78,5.9,1.54.48,3.12.82,4.83,1.27,0-.47,0-.76,0-1.05-.09-2.96,1.2-5.15,3.82-6.46,2.6-1.31,5.07-.95,7.38.81.32.24.9.36,1.27.25,1.11-.32,2.19-.78,3.28-1.19.09.06.18.11.26.17-.66,1.03-1.32,2.05-2.05,3.18.89-.25,1.75-.49,2.61-.73.07.08.15.16.22.24-.8.85-1.54,1.76-2.42,2.52-.63.54-.9,1.06-.93,1.91-.16,4.63-1.23,8.99-4.36,12.56-4.24,4.84-9.74,6.55-16.02,6.1-2.79-.2-5.43-.95-7.88-2.33-.24-.14-.47-.28-.7-.44-.05-.03-.06-.11-.17-.31,3.44.24,6.55-.53,9.59-2.52-2.9-.73-5.15-1.89-6.08-4.85h2.05c-2.67-1.43-4.28-3.48-4.42-6.77Z" />
              </a>
            </g>
            <!-- ig -->
            <g class="instagram">
              <a href="https://www.instagram.com/hogardecristocl/?hl=es" target="_blank">
                <path class="uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30"
                  d="m1000.23,191.69c-17.09.11-31.23-14.08-31.29-31.38-.06-17.06,13.96-31.27,30.79-31.51,17.54-.25,31.84,13.64,32.08,30.7.26,18.09-13.61,32.08-31.58,32.2Zm.16-58.82c-15.02,0-27.38,12.37-27.38,27.38,0,15.11,12.29,27.34,27.48,27.35,14.89.01,27.25-12.3,27.25-27.15,0-15.24-12.23-27.58-27.36-27.59Z" />
                <path class="uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30"
                  d="m1000.39,144.01c2.18,0,4.35-.01,6.53,0,5.5.04,9.69,4.25,9.7,9.75,0,4.35.01,8.7,0,13.06-.02,5.44-4.22,9.66-9.66,9.69-4.39.02-8.77.01-13.16,0-5.17-.02-9.43-3.91-9.61-9.08-.17-4.79-.17-9.6,0-14.4.18-5.05,4.39-8.93,9.46-9.01,2.24-.03,4.49,0,6.74,0Zm13.02,16.2c0-2.07,0-4.14,0-6.21-.01-3.85-2.78-6.71-6.63-6.76-4.28-.05-8.56-.06-12.84,0-3.76.05-6.57,2.95-6.59,6.71-.02,4.21-.02,8.42,0,12.63.02,3.81,2.9,6.68,6.7,6.7,4.25.02,8.49.02,12.74,0,3.75-.02,6.6-2.89,6.62-6.65.01-2.14,0-4.28,0-6.42Z" />
                <path class="uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30"
                  d="m1008.5,160.04c-.01,4.46-3.51,7.94-7.95,7.92-4.37-.02-7.89-3.56-7.87-7.93.01-4.43,3.55-7.93,7.99-7.91,4.4.02,7.85,3.51,7.84,7.93Zm-12.93-.04c0,2.8,2.27,5.09,5.05,5.09,2.7,0,5.01-2.31,5.03-5.02.02-2.74-2.33-5.1-5.07-5.08-2.74.02-5.01,2.3-5.01,5.02Z" />
                <path class="uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30"
                  d="m1008.99,149.71c1.06,0,1.93.87,1.91,1.9-.02,1.04-.91,1.92-1.93,1.9-1.03-.02-1.92-.91-1.92-1.93,0-1.02.89-1.88,1.95-1.87Z" />
              </a>
            </g>
            <g>
              <!-- linkedin -->
              <g class="linkedin">
                <a href=" https://www.linkedin.com/company/hogar-de-cristo/" target="_blank">
                  <path class="uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30"
                    d="m1083.65,191.48c-17.1.1-31.2-14.1-31.3-31.4,0-17.1,14-31.3,30.8-31.5,17.5-.3,31.8,13.6,32.1,30.7.3,18.1-13.6,32.1-31.6,32.2Zm.2-58.8c-15,0-27.4,12.4-27.4,27.4s12.3,27.3,27.5,27.4c14.9,0,27.2-12.3,27.3-27.1,0-15.2-12.2-27.6-27.4-27.6v-.1Z" />

                  <path class="uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30"
                    d="m1086.65,155.68c.6-.5,1.1-1.1,1.7-1.5,1-.7,2.2-1,3.4-1.2,1.7-.2,3.3,0,4.9.6,1.6.7,2.7,2,3.3,3.7.6,1.5.8,3,.9,4.6v11.6c0,.9-.4,1.3-1.3,1.3h-4.5c-.8,0-1.2-.4-1.2-1.2v-9.9c0-.8-.1-1.6-.3-2.3-.4-1.5-1.4-2.3-2.8-2.4-1.5-.1-2.8.6-3.4,1.9-.5,1-.7,2-.7,3v9.7c0,.9-.4,1.3-1.3,1.3h-4.3c-.9,0-1.3-.4-1.3-1.3v-19c0-.8.4-1.2,1.2-1.2h4.5c.8,0,1.2.4,1.2,1.2v1.2-.1Z" />
                  <path class="uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30"
                    d="m1069.95,164.08v-9.5c0-.9.4-1.3,1.3-1.3h4.3c.8,0,1.2.4,1.2,1.2v18.8c0,1.1-.4,1.5-1.5,1.5h-4c-.9,0-1.3-.4-1.3-1.3v-9.5.1Z" />
                  <path class="uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30"
                    d="m1077.75,147.18c0,2.4-2,4.4-4.4,4.4s-4.4-1.9-4.4-4.4,2-4.4,4.4-4.4,4.4,2,4.4,4.4Z" />
                </a>
              </g>
            </g>
          </g>
          <g>
            <g @click="scrollToTop" class="cursor">
              <path class="uuid-e64a451f-ab1c-44f0-a8b5-99cb0fe07a5a"
                d="m972.19,59.59c0-.8.52-1.31,1.24-1.31s1.22.5,1.22,1.31v3.23c0,.07.03.1.1.1h3.72c.07,0,.1-.03.1-.1v-3.23c0-.8.52-1.31,1.22-1.31s1.24.5,1.24,1.31v8.98c0,.8-.52,1.31-1.24,1.31s-1.22-.5-1.22-1.31v-3.35c0-.07-.03-.1-.1-.1h-3.72c-.07,0-.1.03-.1.1v3.35c0,.8-.54,1.31-1.24,1.31s-1.22-.5-1.22-1.31v-8.98Z" />
              <path class="uuid-e64a451f-ab1c-44f0-a8b5-99cb0fe07a5a"
                d="m983.23,67.2c-.25-.8-.32-1.44-.32-3.12s.07-2.31.32-3.11c.6-1.84,2.16-2.76,4.15-2.76s3.55.92,4.15,2.76c.25.8.32,1.44.32,3.11s-.07,2.31-.32,3.12c-.6,1.84-2.16,2.76-4.15,2.76s-3.55-.92-4.15-2.76Zm5.94-.65c.13-.42.18-.99.18-2.46s-.05-2.04-.18-2.46c-.25-.77-.85-1.24-1.79-1.24s-1.54.47-1.79,1.24c-.13.42-.18.99-.18,2.46s.05,2.04.18,2.46c.25.77.85,1.24,1.79,1.24s1.54-.47,1.79-1.24Z" />
              <path class="uuid-e64a451f-ab1c-44f0-a8b5-99cb0fe07a5a"
                d="m993.42,64.08c0-1.67.07-2.31.32-3.11.59-1.84,2.16-2.76,4.18-2.76,1.84,0,3.11.75,3.72,1.78.13.23.22.47.22.74,0,.65-.5,1.11-1.12,1.11-.42,0-.75-.13-1.09-.6-.44-.6-.99-.84-1.72-.84-.97,0-1.57.49-1.82,1.27-.13.42-.18.95-.18,2.43s.05,2.03.18,2.45c.25.77.87,1.26,1.86,1.26.87,0,1.59-.45,1.82-1.17.1-.3.13-.69.13-1.12,0-.07-.03-.1-.1-.1h-.77c-.64,0-1.04-.39-1.04-.97s.4-.97,1.04-.97h2.34c.62,0,.97.35.97.97v.12c0,1-.08,1.94-.28,2.58-.59,1.83-2.13,2.85-4.15,2.85s-3.6-.92-4.18-2.76c-.25-.8-.32-1.44-.32-3.12Z" />
              <path class="uuid-e64a451f-ab1c-44f0-a8b5-99cb0fe07a5a"
                d="m1003.31,68.25l3.08-8.52c.32-.9.82-1.44,1.69-1.44s1.39.54,1.71,1.44l3.03,8.52c.05.13.08.3.08.47,0,.7-.57,1.16-1.19,1.16-.54,0-.97-.32-1.15-.87l-.47-1.39h-4.07l-.45,1.39c-.18.55-.62.87-1.15.87-.62,0-1.19-.45-1.19-1.16,0-.17.03-.34.08-.47Zm6.09-2.73l-1.31-3.99h-.05l-1.32,3.99h2.68Z" />
              <path class="uuid-e64a451f-ab1c-44f0-a8b5-99cb0fe07a5a"
                d="m1020.69,69.1l-1.91-3.67h-1.67c-.07,0-.1.03-.1.1v3.03c0,.79-.5,1.31-1.22,1.31s-1.24-.52-1.24-1.31v-9.09c0-.65.4-1.09,1.07-1.09h3.62c2.34,0,3.88,1.46,3.88,3.53,0,1.39-.7,2.5-1.87,3.08l1.66,3.01c.12.22.17.45.17.69,0,.64-.44,1.17-1.17,1.17-.5,0-.92-.23-1.21-.77Zm-.03-7.17c0-.87-.6-1.42-1.54-1.42h-2.01c-.07,0-.1.03-.1.1v2.63c0,.07.03.1.1.1h2.01c.94,0,1.54-.55,1.54-1.41Z" />
              <path class="uuid-e64a451f-ab1c-44f0-a8b5-99cb0fe07a5a"
                d="m972.19,75.12c0-.65.4-1.09,1.07-1.09h2.69c2.43,0,3.93.77,4.54,2.63.22.65.33,1.44.33,3.06s-.12,2.41-.33,3.06c-.6,1.86-2.11,2.63-4.54,2.63h-2.69c-.67,0-1.07-.42-1.07-1.07v-9.23Zm2.56,8.11h1.14c1.14,0,1.96-.39,2.24-1.29.12-.39.2-1.06.2-2.21s-.08-1.83-.2-2.21c-.28-.9-1.1-1.29-2.24-1.29h-1.14c-.07,0-.1.03-.1.1v6.8c0,.07.03.1.1.1Z" />
              <path class="uuid-e64a451f-ab1c-44f0-a8b5-99cb0fe07a5a"
                d="m982.71,75.12c0-.65.4-1.09,1.07-1.09h5.52c.69,0,1.14.45,1.14,1.07s-.45,1.06-1.14,1.06h-4.03c-.07,0-.1.03-.1.1v2.26c0,.07.03.1.1.1h3.23c.69,0,1.12.44,1.12,1.06s-.44,1.05-1.12,1.05h-3.23c-.07,0-.1.03-.1.1v2.36c0,.07.03.1.1.1h4.03c.69,0,1.14.45,1.14,1.05s-.45,1.07-1.14,1.07h-5.52c-.67,0-1.07-.44-1.07-1.09v-9.21Z" />
              <path class="uuid-e64a451f-ab1c-44f0-a8b5-99cb0fe07a5a"
                d="m995.81,79.73c0-1.74.08-2.33.32-3.08.6-1.86,2.08-2.8,4.07-2.8,1.71,0,2.96.79,3.53,1.81.13.23.22.47.22.74,0,.65-.5,1.11-1.12,1.11-.42,0-.75-.17-1.09-.64-.42-.57-.82-.84-1.54-.84-.92,0-1.46.49-1.71,1.26-.13.42-.18.82-.18,2.44s.05,2.03.18,2.45c.25.77.79,1.26,1.71,1.26.72,0,1.12-.27,1.54-.84.33-.47.67-.64,1.09-.64.62,0,1.12.45,1.12,1.11,0,.27-.08.5-.22.74-.57,1.02-1.82,1.81-3.53,1.81-1.99,0-3.46-.94-4.07-2.8-.23-.75-.32-1.34-.32-3.08Z" />
              <path class="uuid-e64a451f-ab1c-44f0-a8b5-99cb0fe07a5a"
                d="m1011.35,84.75l-1.91-3.67h-1.67c-.07,0-.1.03-.1.1v3.03c0,.79-.5,1.31-1.22,1.31s-1.24-.52-1.24-1.31v-9.09c0-.65.4-1.09,1.07-1.09h3.62c2.34,0,3.88,1.46,3.88,3.53,0,1.39-.7,2.5-1.87,3.08l1.66,3.01c.12.22.17.45.17.69,0,.64-.44,1.17-1.17,1.17-.5,0-.92-.23-1.21-.77Zm-.03-7.17c0-.87-.6-1.42-1.54-1.42h-2.01c-.07,0-.1.03-.1.1v2.63c0,.07.03.1.1.1h2.01c.94,0,1.54-.55,1.54-1.41Z" />
              <path class="uuid-e64a451f-ab1c-44f0-a8b5-99cb0fe07a5a"
                d="m1015.65,75.24c0-.79.52-1.31,1.24-1.31s1.22.52,1.22,1.31v8.98c0,.79-.52,1.31-1.22,1.31s-1.24-.52-1.24-1.31v-8.98Z" />
              <path class="uuid-e64a451f-ab1c-44f0-a8b5-99cb0fe07a5a"
                d="m1020.49,84.63c-.38-.25-.59-.64-.59-1.04,0-.6.47-1.09,1.1-1.09.27,0,.52.08.72.2.94.52,1.61.74,2.44.74,1.21,0,1.86-.55,1.86-1.34,0-.67-.4-1.09-1.76-1.27l-.6-.08c-2.33-.32-3.6-1.41-3.6-3.42s1.57-3.48,4.03-3.48c1.26,0,2.51.33,3.18.75.4.25.59.59.59.99,0,.62-.47,1.07-1.07,1.07-.23,0-.49-.07-.72-.18-.55-.27-1.26-.47-1.96-.47-1,0-1.54.5-1.54,1.22,0,.64.45,1.07,1.77,1.26l.6.08c2.36.33,3.58,1.39,3.58,3.45s-1.51,3.58-4.47,3.58c-1.61,0-2.83-.5-3.58-.97Z" />
              <path class="uuid-e64a451f-ab1c-44f0-a8b5-99cb0fe07a5a"
                d="m1032.61,84.21v-7.89c0-.07-.03-.1-.1-.1h-1.92c-.75,0-1.24-.45-1.24-1.09s.49-1.11,1.24-1.11h6.51c.75,0,1.24.45,1.24,1.11s-.49,1.09-1.24,1.09h-1.92c-.07,0-.1.03-.1.1v7.89c0,.79-.52,1.31-1.24,1.31s-1.22-.52-1.22-1.31Z" />
              <path class="uuid-e64a451f-ab1c-44f0-a8b5-99cb0fe07a5a"
                d="m1039.24,82.84c-.25-.8-.32-1.44-.32-3.12s.07-2.31.32-3.11c.6-1.84,2.16-2.76,4.15-2.76s3.55.92,4.15,2.76c.25.8.32,1.44.32,3.11s-.07,2.31-.32,3.12c-.6,1.84-2.16,2.76-4.15,2.76s-3.55-.92-4.15-2.76Zm5.94-.65c.13-.42.18-.99.18-2.46s-.05-2.04-.18-2.46c-.25-.77-.85-1.24-1.79-1.24s-1.54.47-1.79,1.24c-.13.42-.18.99-.18,2.46s.05,2.04.18,2.46c.25.77.85,1.24,1.79,1.24s1.54-.47,1.79-1.24Z" />
              <path class="uuid-e64a451f-ab1c-44f0-a8b5-99cb0fe07a5a"
                d="m973.27,98.25c-.67,0-1.05-.4-1.05-.99s.38-.99,1.05-.99h12.37c.69,0,1.07.4,1.07.99s-.38.99-1.07.99h-12.37Z" />
              <path class="uuid-33f0c0bf-b9db-4c37-91cb-850767b11c7e"
                d="m881.09,90.03v8.19c0,4.52,3.66,8.18,8.18,8.18s8.19-3.66,8.19-8.18v-8.19h-16.37Z" />
              <path class="uuid-2d3f1e78-19a6-46cf-a690-be56de55bb73"
                d="m896.29,22.31c.69-1.25,1.07-2.3,1.18-3.52,0-.32-.02-.5-.02-.5,0-4.45-3.46-8.25-7.9-8.39-4.5-.15-8.46,3.63-8.46,7.76,0,.13,0,32.39,0,32.39h0s15.2-27.74,15.2-27.74Z" />
              <path class="uuid-32fe6fa5-f412-4be9-a67f-88b2457ed6bf"
                d="m932.18,98.23c0,4.52-3.66,8.19-8.18,8.19s-8.18-3.66-8.18-8.19,3.66-8.19,8.18-8.19h8.19v8.19Z" />
              <path class="uuid-099e56fe-95a8-4214-8c32-c327b8710695"
                d="m915.81,58.24v8.19h8.18c4.52,0,8.18-3.66,8.18-8.19s-3.66-8.18-8.18-8.19h0c-4.52,0-8.18,3.67-8.18,8.19Z" />

              <path class="uuid-e64a451f-ab1c-44f0-a8b5-99cb0fe07a5a"
                d="m949.8,90.04h-17.62v-23.61h17.47c4.12,0,8.32-3.35,8.32-8.19s-3.46-8.19-8.23-8.19c0,0-13.45,0-25.76,0,4.52,0,8.18,3.67,8.18,8.19s-3.66,8.19-8.18,8.19h-8.18v-8.19c0-4.52,3.66-8.18,8.18-8.19-10.34,0-17.42,0-17.49,0,0,0,0,0,0,0h-9.04c0-8.95-.01-27.96-.01-31.26-.11,1.22-.48,2.27-1.17,3.52l-15.2,27.74h0s-9.88,0-9.88,0h0c-4.52,0-8.18,3.67-8.18,8.19s3.66,8.18,8.18,8.18c.07,0,9.89,0,9.89,0v23.61s16.37,0,16.37,0c0-.93,0-17.49,0-17.49,0-3.38,2.76-6.12,6.14-6.12h12.21s0,31.79,0,31.79h0c0-4.52,3.66-8.18,8.18-8.18h8.19s0,8.19,0,8.19c0,4.52-3.67,8.19-8.19,8.19h0s25.98,0,25.98,0c4.41,0,8.01-3.64,8.01-8.06v-.13c0-4.52-3.66-8.18-8.17-8.18Z" />
              <path class="uuid-6efd8158-2dba-474e-a179-b49386d0f9e9"
                d="m915.81,98.23s0,0,0-.01c0,0,0,0,0,0,0,0,0,0,0,.01Z" />
              <polygon class="uuid-6efd8158-2dba-474e-a179-b49386d0f9e9"
                points="915.81 98.22 915.81 98.21 915.81 98.21 915.81 98.22 915.81 98.22" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  methods: {
    redirectHome() {
      this.$router.push('/')
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  }
}
</script>

<style scoped>
.cursor {
  cursor: pointer;
}

.facebook .uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30 {
  fill: #ffffff;
  transition: fill 0.3s ease;
}

.facebook:hover .uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30 {
  fill: #3b5998;
}

.twitter .uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30 {
  fill: #ffffff;
  transition: fill 0.6s ease;
}

.twitter:hover .uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30 {
  fill: #1DA1F2;
}

.instagram .uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30 {
  fill: #ffffff;
  transition: fill 0.6s ease;
}

.instagram:hover .uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30 {
  fill: #C13584;
}

.linkedin .uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30 {
  fill: #ffffff;
  transition: fill 0.6s ease;
}

.linkedin:hover .uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30 {
  fill: #0077B5;
}

.uuid-6efd8158-2dba-474e-a179-b49386d0f9e9 {
  opacity: .5;
}

.uuid-6efd8158-2dba-474e-a179-b49386d0f9e9,
.uuid-e64a451f-ab1c-44f0-a8b5-99cb0fe07a5a {
  fill: #0097a1;
}

.uuid-7cfaeab7-dfd3-49c4-b4b3-51d3fbf8a8ac {
  opacity: .68;
}

.uuid-099e56fe-95a8-4214-8c32-c327b8710695 {
  fill: #80b800;
}

.uuid-33f0c0bf-b9db-4c37-91cb-850767b11c7e {
  fill: #fe5000;
}

.uuid-3da7ef51-c2ca-4056-ae5b-b28ad0415588 {
  opacity: .7;
}

.uuid-1cdf336e-7d2d-4ff1-8e7e-b5f08996ff30 {
  fill: #929292;
}

.uuid-2d3f1e78-19a6-46cf-a690-be56de55bb73 {
  fill: #5bbde2;
}

.uuid-32fe6fa5-f412-4be9-a67f-88b2457ed6bf {
  fill: #fc0;
}

.uuid-120e081d-c224-44ab-b444-34ca146eed21 {
  fill: #ebebeb;
  opacity: .85;
}
</style>