<template>
    <svg id="uuid-2dfebcd7-6f38-44c6-87d9-c77a396e3c06" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1653 923.96">
        <g id="uuid-b6d2f550-eb63-4a93-9e64-9cd96d267895" data-name="Capa 3">
            <g transform="translate(-100, -400)">
                <g class="uuid-0f2eca66-9737-422d-b515-1a15f2bb6293" transform="scale(1.8, 1) translate(-450, 0)">
                    <g>
                        <g class="uuid-3b7a6542-58de-4ec1-8c40-9110e78dbb29 ">
                            <path class="uuid-a325053f-60e6-4326-b9eb-19e542f6ec3d"
                                d="m-7.74,616.53c47.72,1.42,117.13.6,192-12.27,111.63-19.18,126.59-46.44,240.79-83.28,87.83-28.34,208.96-67.42,361.02-56.4,66.8,4.84,180.16,22.28,257.13,35.93,28.81,5.11,48.98,9.06,61.41,11.46,102.11,19.69,289.77,55.89,476.73,72.16,123.1,10.71,236.71,20.6,372.3,3,107.77-13.99,185.43-39.63,233.76-59.01-72.48,190.01-144.95,380.03-217.43,570.04-45.68-3.37-114.03-5.11-184.46,8.35-78.33,14.97-114.7,40.22-181.36,73.76-85.8,43.17-155.65,78.31-240.74,96.6-199.28,42.84-439.93-15.84-492.96-28.77-115.98-28.28-182.7-61.95-372.84-99.07-40.89-7.98-73.01-14.53-118.96-20.27-197.44-24.67-337.57-7.54-373.2-2.81-102.71,13.63-176.71,37.29-222.44,54.93C-147.25,992.76-77.49,804.64-7.74,616.53Z" />
                        </g>
                        <path class="uuid-327ea7b0-247b-4ddc-8be9-091f701c30e6"
                            d="m-6.1,683.62c56.53-6.47,125.66-17.76,201.46-37.99,69.25-18.48,126.93-39.85,172.67-59.53,187.32-72.91,339.65-86.56,444.28-85.51,249.34,2.51,346.41,89.74,639.48,72.79,129.98-7.52,252.71-32.88,274.5-37.45,122.61-25.74,217.65-58.6,272.46-77.74,29.65-10.35,54.03-19.53,71.17-26.15-8.81,205.18-17.62,410.35-26.43,615.53-49.89-1.21-126.89-.37-217.56,12.72-228.92,33.04-276.23,105.32-485.96,159.71-53.92,13.98-276.95,69.75-532.73,34.63-185.46-25.47-209.4-74.17-378.2-72.08-154.45,1.91-288.87,44.61-345.51,65.01-63.42,22.83-112.07,47.48-145.53,66.44,18.64-210.12,37.28-420.24,55.92-630.37Z" />
                    </g>
                </g>

                <g>
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m450.42,649.91l-3.19,9.65h-4.1l10.42-30.67h4.78l10.47,30.67h-4.23l-3.28-9.65h-10.88Zm10.06-3.09l-3-8.83c-.68-2-1.14-3.82-1.59-5.6h-.09c-.46,1.82-.96,3.69-1.55,5.55l-3,8.87h9.24Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m483.23,659.55v-18.97h-3.09v-3.05h3.09v-1.18c0-5.82,3.41-9.6,9.6-9.6,2.05,0,4.46.64,5.6,1.46l-1.14,3.05c-.96-.68-2.68-1.27-4.64-1.27-4.23,0-5.46,2.96-5.46,6.46v1.09h12.79v22.02h-3.96v-18.97h-8.83v18.97h-3.96Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m506.62,643.49c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m534.11,649.27c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m554.13,655.46c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m601.36,627.25v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.15,6.51,8.15,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m610.24,649.27c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m639.18,659.55v-2.46l3.14-3.05c7.55-7.19,10.97-11.01,11.01-15.47,0-3-1.46-5.78-5.87-5.78-2.68,0-4.91,1.36-6.28,2.5l-1.27-2.82c2.05-1.73,4.96-3,8.37-3,6.37,0,9.05,4.37,9.05,8.6,0,5.46-3.96,9.87-10.19,15.88l-2.37,2.18v.09h13.29v3.32h-18.88Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m682.19,644.45c0,10.06-3.73,15.61-10.28,15.61-5.78,0-9.69-5.41-9.78-15.2,0-9.92,4.28-15.38,10.28-15.38s9.78,5.55,9.78,14.97Zm-16.06.45c0,7.69,2.37,12.06,6.01,12.06,4.1,0,6.05-4.78,6.05-12.33s-1.87-12.06-6.01-12.06c-3.5,0-6.05,4.28-6.05,12.33Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m685.87,659.55v-2.46l3.14-3.05c7.55-7.19,10.97-11.01,11.01-15.47,0-3-1.46-5.78-5.87-5.78-2.68,0-4.91,1.36-6.28,2.5l-1.27-2.82c2.05-1.73,4.96-3,8.37-3,6.37,0,9.05,4.37,9.05,8.6,0,5.46-3.96,9.87-10.19,15.88l-2.37,2.18v.09h13.29v3.32h-18.88Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m709.22,659.55v-2.46l3.14-3.05c7.55-7.19,10.97-11.01,11.01-15.47,0-3-1.46-5.78-5.87-5.78-2.68,0-4.91,1.36-6.28,2.5l-1.27-2.82c2.05-1.73,4.96-3,8.37-3,6.37,0,9.05,4.37,9.05,8.6,0,5.46-3.96,9.87-10.19,15.88l-2.37,2.18v.09h13.29v3.32h-18.88Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m755.68,659.55l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m781.12,658.73c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.27,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m791.22,631.2v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m819.89,653.55c0,2.28.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m838.73,659.55l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m848.47,627.25h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m863.67,631.34c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m867.36,657.23l9.96-12.97c.96-1.18,1.87-2.23,2.87-3.41v-.09h-11.92v-3.23h16.79l-.05,2.5-9.83,12.79c-.91,1.23-1.82,2.32-2.82,3.46v.09h12.88v3.19h-17.88v-2.32Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m901.53,659.55l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m911.27,643.49c0-2.28-.05-4.14-.18-5.96h3.5l.18,3.55h.14c1.23-2.09,3.28-4.05,6.92-4.05,3,0,5.28,1.82,6.23,4.41h.09c.68-1.23,1.55-2.18,2.46-2.87,1.32-1,2.78-1.55,4.87-1.55,2.91,0,7.23,1.91,7.23,9.56v12.97h-3.91v-12.47c0-4.23-1.55-6.78-4.78-6.78-2.27,0-4.05,1.68-4.73,3.64-.18.55-.32,1.27-.32,2v13.6h-3.91v-13.2c0-3.5-1.55-6.05-4.6-6.05-2.5,0-4.32,2-4.96,4-.23.59-.32,1.27-.32,1.96v13.29h-3.91v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m969.16,648.36c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m973.66,655.46c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1001.88,643.49c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1045.56,653.55c0,2.28.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1054.44,649.27c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1074.46,655.46c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1097.85,631.2v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1108.09,644.4c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.37-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1134.94,659.55l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1156.56,649.27c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1176.58,655.46c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1199.97,631.2v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1210.21,644.4c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.37-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1237.06,659.55l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1251.44,631.2v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1263.64,649.27c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1303.18,637.53c-.09,1.59-.18,3.37-.18,6.05v12.79c0,5.05-1,8.14-3.14,10.06-2.14,2-5.23,2.64-8.01,2.64s-5.55-.64-7.33-1.82l1-3.05c1.46.91,3.73,1.73,6.46,1.73,4.1,0,7.1-2.14,7.1-7.69v-2.46h-.09c-1.23,2.05-3.59,3.69-7.01,3.69-5.46,0-9.37-4.64-9.37-10.74,0-7.46,4.87-11.69,9.92-11.69,3.82,0,5.92,2,6.87,3.82h.09l.18-3.32h3.5Zm-4.14,8.69c0-.68-.05-1.27-.23-1.82-.73-2.32-2.68-4.23-5.6-4.23-3.82,0-6.55,3.23-6.55,8.33,0,4.32,2.18,7.92,6.51,7.92,2.46,0,4.69-1.55,5.55-4.1.23-.68.32-1.46.32-2.14v-3.96Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1313.83,631.34c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1332.22,659.55l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1351.06,655.46c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1389.56,648.36c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1410.31,658.73c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.28,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1419.46,631.34c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1437.84,659.55l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m1447.58,627.25h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m351.62,682.58c-3.32,4.46-5.73,10.37-5.73,18.61s2.5,13.92,5.73,18.47h-3.14c-2.87-3.78-5.96-9.6-5.96-18.47.05-8.96,3.09-14.79,5.96-18.61h3.14Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m354.58,714.16v-2.46l3.14-3.05c7.55-7.19,10.97-11.01,11.01-15.47,0-3-1.46-5.78-5.87-5.78-2.68,0-4.91,1.36-6.28,2.5l-1.27-2.82c2.05-1.73,4.96-3,8.37-3,6.37,0,9.05,4.37,9.05,8.6,0,5.46-3.96,9.87-10.19,15.88l-2.37,2.18v.09h13.29v3.32h-18.88Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m397.58,699.06c0,10.06-3.73,15.61-10.28,15.61-5.78,0-9.69-5.41-9.78-15.2,0-9.92,4.28-15.38,10.28-15.38s9.78,5.55,9.78,14.97Zm-16.06.45c0,7.69,2.37,12.06,6.01,12.06,4.1,0,6.05-4.78,6.05-12.33s-1.87-12.06-6.01-12.06c-3.5,0-6.05,4.28-6.05,12.33Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m401.27,714.16v-2.46l3.14-3.05c7.55-7.19,10.97-11.01,11.01-15.47,0-3-1.46-5.78-5.87-5.78-2.68,0-4.91,1.36-6.28,2.5l-1.27-2.82c2.05-1.73,4.96-3,8.37-3,6.37,0,9.05,4.37,9.05,8.6,0,5.46-3.96,9.87-10.19,15.88l-2.37,2.18v.09h13.29v3.32h-18.88Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m444.27,699.06c0,10.06-3.73,15.61-10.28,15.61-5.78,0-9.69-5.41-9.78-15.2,0-9.92,4.28-15.38,10.28-15.38s9.78,5.55,9.78,14.97Zm-16.06.45c0,7.69,2.37,12.06,6.01,12.06,4.1,0,6.05-4.78,6.05-12.33s-1.87-12.06-6.01-12.06c-3.5,0-6.05,4.28-6.05,12.33Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m458.52,700.38v2.96h-11.24v-2.96h11.24Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m461.93,714.16v-2.46l3.14-3.05c7.55-7.19,10.97-11.01,11.01-15.47,0-3-1.46-5.78-5.87-5.78-2.68,0-4.91,1.36-6.28,2.5l-1.27-2.82c2.05-1.73,4.96-3,8.37-3,6.37,0,9.05,4.37,9.05,8.6,0,5.46-3.96,9.87-10.19,15.88l-2.37,2.18v.09h13.29v3.32h-18.88Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m504.93,699.06c0,10.06-3.73,15.61-10.28,15.61-5.78,0-9.69-5.41-9.78-15.2,0-9.92,4.28-15.38,10.28-15.38s9.78,5.55,9.78,14.97Zm-16.06.45c0,7.69,2.37,12.06,6.01,12.06,4.1,0,6.05-4.78,6.05-12.33s-1.87-12.06-6.01-12.06c-3.5,0-6.05,4.28-6.05,12.33Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m508.62,714.16v-2.46l3.14-3.05c7.55-7.19,10.97-11.01,11.01-15.47,0-3-1.46-5.78-5.87-5.78-2.68,0-4.91,1.36-6.28,2.5l-1.27-2.82c2.05-1.73,4.96-3,8.37-3,6.37,0,9.05,4.37,9.05,8.6,0,5.46-3.96,9.87-10.19,15.88l-2.37,2.18v.09h13.29v3.32h-18.88Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m549.62,687.95h-11.28l-1.14,7.6c.68-.09,1.32-.18,2.41-.18,2.27,0,4.55.5,6.37,1.59,2.32,1.32,4.23,3.87,4.23,7.6,0,5.78-4.6,10.1-11.01,10.1-3.23,0-5.96-.91-7.37-1.82l1-3.05c1.23.73,3.64,1.64,6.32,1.64,3.78,0,7.01-2.46,7.01-6.42-.05-3.82-2.59-6.55-8.51-6.55-1.68,0-3,.18-4.1.32l1.91-14.2h14.15v3.37Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m554.17,719.67c3.23-4.5,5.73-10.42,5.73-18.61s-2.46-13.97-5.73-18.47h3.09c2.91,3.73,5.96,9.56,5.96,18.52s-3.05,14.7-5.96,18.56h-3.09Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m594.86,713.34c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.27,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m619.2,702.97c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m624.26,698.1c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m661.39,703.88c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Zm.96-18.38l-5.55,6.51h-2.87l4-6.51h4.41Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m681.96,698.1c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m707.63,714.16v-18.97h-3.09v-3.05h3.09v-1.05c0-3.09.68-5.92,2.55-7.69,1.5-1.46,3.5-2.05,5.37-2.05,1.41,0,2.64.32,3.41.64l-.55,3.09c-.59-.27-1.41-.5-2.55-.5-3.41,0-4.28,3-4.28,6.37v1.18h5.32v3.05h-5.32v18.97h-3.96Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m732.7,714.16l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m741.89,710.07c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m764.92,685.95c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m770.56,710.07c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.41,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m801.01,703.88c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m821.03,710.07c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.41,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m839.6,699.33c0-2.82-.09-5.1-.18-7.19h3.59l.18,3.78h.09c1.64-2.68,4.23-4.28,7.83-4.28,5.32,0,9.33,4.5,9.33,11.19,0,7.92-4.82,11.83-10.01,11.83-2.91,0-5.46-1.27-6.78-3.46h-.09v11.97h-3.96v-23.84Zm3.96,5.87c0,.59.09,1.14.18,1.64.73,2.78,3.14,4.69,6.01,4.69,4.23,0,6.69-3.46,6.69-8.51,0-4.41-2.32-8.19-6.55-8.19-2.73,0-5.28,1.96-6.05,4.96-.14.5-.27,1.09-.27,1.64v3.78Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m867.72,703.88c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m903.99,713.34c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.27,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m916.82,682.63l-5.55,6.51h-2.87l4-6.51h4.41Zm-8.14,31.53v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m919.74,714.16v-18.97h-3.09v-3.05h3.09v-1.18c0-5.82,3.41-9.6,9.6-9.6,2.05,0,4.46.64,5.6,1.46l-1.14,3.05c-.96-.68-2.68-1.27-4.64-1.27-4.23,0-5.46,2.96-5.46,6.46v1.09h12.79v22.02h-3.96v-18.97h-8.83v18.97h-3.96Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m958.83,713.34c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.27,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m983.17,702.97c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m987.68,710.07c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1015.9,699.33c0-2.82-.09-5.1-.18-7.19h3.59l.18,3.78h.09c1.64-2.68,4.23-4.28,7.83-4.28,5.32,0,9.33,4.5,9.33,11.19,0,7.92-4.82,11.83-10.01,11.83-2.91,0-5.46-1.27-6.78-3.46h-.09v11.97h-3.96v-23.84Zm3.96,5.87c0,.59.09,1.14.18,1.64.73,2.78,3.14,4.69,6.01,4.69,4.23,0,6.69-3.46,6.69-8.51,0-4.41-2.32-8.19-6.55-8.19-2.73,0-5.28,1.96-6.05,4.96-.14.5-.27,1.09-.27,1.64v3.78Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1053.98,714.16l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1063.72,699.01c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.37-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1090.57,714.16l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1125.66,713.34c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.28,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1148.78,708.16c0,2.28.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1155.42,698.1c0-2.28-.05-4.14-.18-5.96h3.5l.18,3.55h.14c1.23-2.09,3.28-4.05,6.92-4.05,3,0,5.28,1.82,6.23,4.41h.09c.68-1.23,1.55-2.18,2.46-2.87,1.32-1,2.78-1.55,4.87-1.55,2.91,0,7.23,1.91,7.23,9.56v12.97h-3.91v-12.47c0-4.23-1.55-6.78-4.78-6.78-2.27,0-4.05,1.68-4.73,3.64-.18.55-.32,1.27-.32,2v13.6h-3.91v-13.2c0-3.5-1.55-6.05-4.6-6.05-2.5,0-4.32,2-4.96,4-.23.59-.32,1.27-.32,1.96v13.29h-3.91v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1193.38,699.33c0-2.82-.09-5.1-.18-7.19h3.59l.18,3.78h.09c1.64-2.68,4.23-4.28,7.83-4.28,5.32,0,9.33,4.5,9.33,11.19,0,7.92-4.82,11.83-10.01,11.83-2.91,0-5.46-1.27-6.78-3.46h-.09v11.97h-3.96v-23.84Zm3.96,5.87c0,.59.09,1.14.18,1.64.73,2.78,3.14,4.69,6.01,4.69,4.23,0,6.69-3.46,6.69-8.51,0-4.41-2.32-8.19-6.55-8.19-2.73,0-5.28,1.96-6.05,4.96-.14.5-.27,1.09-.27,1.64v3.78Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m1219.27,681.86h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1234.47,685.95c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1240.66,699.01c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.36-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1280.88,713.34c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.28,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1305.23,702.97c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1310.28,698.1c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1347.42,703.88c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m1367.99,681.86h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1388.38,698.1c0-2.28-.05-4.14-.18-5.96h3.5l.18,3.55h.14c1.23-2.09,3.28-4.05,6.92-4.05,3,0,5.28,1.82,6.23,4.41h.09c.68-1.23,1.55-2.18,2.46-2.87,1.32-1,2.78-1.55,4.87-1.55,2.91,0,7.23,1.91,7.23,9.56v12.97h-3.91v-12.47c0-4.23-1.55-6.78-4.78-6.78-2.27,0-4.05,1.68-4.73,3.64-.18.55-.32,1.27-.32,2v13.6h-3.91v-13.2c0-3.5-1.55-6.05-4.6-6.05-2.5,0-4.32,2-4.96,4-.23.59-.32,1.27-.32,1.96v13.29h-3.91v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1438.52,714.16l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1448.26,698.1c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1492.54,681.86v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.15,6.51,8.15,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1511.38,714.16l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1525.76,685.81v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1555.66,702.97c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m429.07,736.47v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.14,6.51,8.14,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m437.95,758.49c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.36-7.33,1.36-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m472.63,740.56c.05,1.37-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m478.82,752.71c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.09-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m519.77,767.95c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.27,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m524.46,736.47h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m553.63,762.77c0,2.27.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m559.73,764.68c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.41,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m582.76,740.56c.05,1.37-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m608.88,757.58c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Zm12.69-20.57l-5.55,6.51h-2.87l4-6.51h4.41Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m613.93,752.71c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.09-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m651.06,758.49c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.36-7.33,1.36-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m671.63,752.71c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.09-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m708.77,758.49c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.36-7.33,1.36-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m729.34,736.47h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m771.29,767.77c-1.46.73-4.37,1.46-8.1,1.46-8.65,0-15.15-5.46-15.15-15.52s6.51-16.11,16.02-16.11c3.82,0,6.23.82,7.28,1.36l-.96,3.23c-1.5-.73-3.64-1.27-6.19-1.27-7.19,0-11.97,4.6-11.97,12.65,0,7.51,4.32,12.33,11.78,12.33,2.41,0,4.87-.5,6.46-1.27l.82,3.14Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m776.12,736.47h4v13.74h.09c.64-1.14,1.64-2.14,2.87-2.82,1.18-.68,2.59-1.14,4.09-1.14,2.96,0,7.69,1.82,7.69,9.42v13.1h-4v-12.65c0-3.55-1.32-6.55-5.1-6.55-2.59,0-4.64,1.82-5.37,4-.23.55-.27,1.14-.27,1.91v13.29h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m805.83,740.56c.05,1.37-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m812.02,736.47h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m824.99,758.49c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.36-7.33,1.36-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m867.4,768.77l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.46c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m892.84,767.95c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.27,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m902.94,740.42v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m931.61,762.77c0,2.27.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m950.45,768.77l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.46c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m960.19,736.47h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m969.52,766.41c0-1.68,1.14-2.87,2.73-2.87s2.68,1.18,2.68,2.87-1.05,2.87-2.73,2.87c-1.59,0-2.68-1.23-2.68-2.87Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m994.64,759.13l-3.19,9.65h-4.1l10.42-30.67h4.78l10.47,30.67h-4.23l-3.28-9.65h-10.88Zm10.06-3.09l-3-8.83c-.68-2-1.14-3.82-1.59-5.6h-.09c-.46,1.82-.96,3.69-1.55,5.55l-3,8.87h9.24Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1016.58,764.68c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1043.29,737.24l-5.55,6.51h-2.87l4-6.51h4.41Zm-8.14,31.53v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1043.2,774.37c1-2.68,2.23-7.55,2.73-10.87l4.46-.46c-1.05,3.87-3.05,8.92-4.32,11.06l-2.87.27Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1067.09,758.49c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.36-7.33,1.36-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m1087.66,736.47h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1108.05,753.94c0-2.82-.09-5.1-.18-7.19h3.59l.18,3.78h.09c1.64-2.68,4.23-4.28,7.83-4.28,5.32,0,9.33,4.5,9.33,11.19,0,7.92-4.82,11.83-10.01,11.83-2.91,0-5.46-1.27-6.78-3.46h-.09v11.97h-3.96v-23.84Zm3.96,5.87c0,.59.09,1.14.18,1.64.73,2.78,3.14,4.69,6.01,4.69,4.23,0,6.69-3.46,6.69-8.51,0-4.41-2.32-8.19-6.55-8.19-2.73,0-5.28,1.96-6.05,4.96-.14.5-.27,1.09-.27,1.64v3.78Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1133.94,753.62c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.36-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1168.3,757.58c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1173.35,753.94c0-2.82-.09-5.1-.18-7.19h3.59l.18,3.78h.09c1.64-2.68,4.23-4.28,7.83-4.28,5.32,0,9.33,4.5,9.33,11.19,0,7.92-4.82,11.83-10.01,11.83-2.91,0-5.46-1.27-6.78-3.46h-.09v11.97h-3.96v-23.84Zm3.96,5.87c0,.59.09,1.14.18,1.64.73,2.78,3.14,4.69,6.01,4.69,4.23,0,6.69-3.46,6.69-8.51,0-4.41-2.32-8.19-6.55-8.19-2.73,0-5.28,1.96-6.05,4.96-.14.5-.27,1.09-.27,1.64v3.78Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1219.17,757.58c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Zm12.69-20.57l-5.55,6.51h-2.87l4-6.51h4.41Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1223.68,764.68c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1246.71,740.56c.05,1.37-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1257.72,740.42v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1287.62,757.58c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1321.34,736.47v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.14,6.51,8.14,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1330.22,758.49c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.36-7.33,1.36-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1360.43,752.71c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.09-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1404.12,762.77c0,2.27.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1412.99,758.49c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.36-7.33,1.36-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1433.02,764.68c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1456.41,740.42v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1466.65,753.62c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.37-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1493.5,768.77l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.46c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m382.41,807.32c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m426.09,817.38c0,2.28.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m434.97,813.1c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m457.08,801.36l4.32,12.38c.73,2,1.32,3.82,1.77,5.64h.14c.5-1.82,1.14-3.64,1.87-5.64l4.28-12.38h4.19l-8.65,22.02h-3.82l-8.37-22.02h4.28Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m489.44,823.38l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m511.06,813.1c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m531.08,819.29c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.41,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m554.47,795.03v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m564.71,808.23c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.45,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.36-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m591.56,823.38l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m605.94,795.03v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m618.14,813.1c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m657.68,801.36c-.09,1.59-.18,3.37-.18,6.05v12.79c0,5.05-1,8.14-3.14,10.06-2.14,2-5.23,2.64-8.01,2.64s-5.55-.64-7.33-1.82l1-3.05c1.46.91,3.73,1.73,6.46,1.73,4.1,0,7.1-2.14,7.1-7.69v-2.46h-.09c-1.23,2.05-3.59,3.69-7.01,3.69-5.46,0-9.37-4.64-9.37-10.74,0-7.46,4.87-11.69,9.92-11.69,3.82,0,5.92,2,6.87,3.82h.09l.18-3.32h3.5Zm-4.14,8.69c0-.68-.05-1.27-.23-1.82-.73-2.32-2.68-4.23-5.6-4.23-3.82,0-6.55,3.23-6.55,8.33,0,4.32,2.18,7.92,6.51,7.92,2.46,0,4.69-1.55,5.55-4.1.23-.68.32-1.46.32-2.14v-3.96Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m668.33,795.17c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m686.72,823.38l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m708.33,813.1c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m728.36,819.29c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m756.98,823.38v-18.97h-3.09v-3.05h3.09v-1.05c0-3.09.68-5.92,2.55-7.69,1.5-1.46,3.5-2.05,5.37-2.05,1.41,0,2.64.32,3.41.64l-.55,3.09c-.59-.27-1.41-.5-2.55-.5-3.41,0-4.28,3-4.28,6.37v1.18h5.32v3.05h-5.32v18.97h-3.96Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m782.05,823.38l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m792.98,801.36l4.32,12.38c.73,2,1.32,3.82,1.77,5.64h.14c.5-1.82,1.14-3.64,1.87-5.64l4.28-12.38h4.19l-8.65,22.02h-3.82l-8.37-22.02h4.28Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m832.79,812.19c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m837.85,808.23c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.37-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m854.5,813.1c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m890.77,822.56c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.27,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m897.42,813.1c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m917.99,808.23c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.37-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m942.52,791.08h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m965.45,823.38l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m974.64,819.29c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1007.68,795.03v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1017.92,808.23c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.36-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1044.77,823.38l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1055.6,801.36l4.82,13.01c.5,1.46,1.05,3.19,1.41,4.5h.09c.41-1.32.86-3,1.41-4.6l4.37-12.92h4.23l-6.01,15.7c-2.87,7.55-4.82,11.42-7.55,13.79-1.96,1.73-3.91,2.41-4.91,2.59l-1-3.37c1-.32,2.32-.96,3.5-1.96,1.09-.86,2.46-2.41,3.37-4.46.18-.41.32-.73.32-.96s-.09-.55-.27-1.05l-8.14-20.29h4.37Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1077.35,813.1c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1113.62,822.56c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.28,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1123.73,795.03v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1153.62,812.19c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1158.68,808.23c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.36-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1178.2,795.17c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1196.58,823.38l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1205.78,819.29c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1253.01,791.08v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.15,6.51,8.15,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1261.89,813.1c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1296.56,795.17c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1302.75,807.32c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1343.71,822.56c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.28,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m1348.4,791.08h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1377.57,817.38c0,2.28.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1383.67,819.29c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1406.69,795.17c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1432.81,812.19c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Zm12.69-20.57l-5.55,6.51h-2.87l4-6.51h4.41Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1437.87,807.32c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1491.79,791.08v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.15,6.51,8.15,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1500.67,813.1c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m394.83,845.69h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m417.76,877.99l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m426.96,873.9c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.41,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m455.17,863.16c0-2.82-.09-5.1-.18-7.19h3.59l.18,3.78h.09c1.64-2.68,4.23-4.28,7.83-4.28,5.32,0,9.33,4.5,9.33,11.19,0,7.92-4.82,11.83-10.01,11.83-2.91,0-5.46-1.27-6.78-3.46h-.09v11.97h-3.96v-23.84Zm3.96,5.87c0,.59.09,1.14.18,1.64.73,2.78,3.14,4.69,6.01,4.69,4.23,0,6.69-3.46,6.69-8.51,0-4.41-2.32-8.19-6.55-8.19-2.73,0-5.28,1.96-6.05,4.96-.14.5-.27,1.09-.27,1.64v3.78Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m483.3,867.71c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m503.87,862.84c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.45,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.36-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m518.2,873.9c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m556.7,866.8c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m561.75,861.93c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m599.2,877.99l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m608.4,873.9c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.41,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m623.87,883.59c1-2.68,2.23-7.55,2.73-10.88l4.46-.45c-1.05,3.87-3.05,8.92-4.32,11.06l-2.87.27Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m645.94,877.99v-18.97h-3.09v-3.05h3.09v-1.05c0-3.09.68-5.92,2.55-7.69,1.5-1.46,3.5-2.05,5.37-2.05,1.41,0,2.64.32,3.41.64l-.55,3.09c-.59-.27-1.41-.5-2.55-.5-3.41,0-4.28,3-4.28,6.37v1.18h5.32v3.05h-5.32v18.97h-3.96Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m671.01,877.99l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m680.75,861.93c0-2.28-.05-4.14-.18-5.96h3.5l.18,3.55h.14c1.23-2.09,3.28-4.05,6.92-4.05,3,0,5.28,1.82,6.23,4.41h.09c.68-1.23,1.55-2.18,2.46-2.87,1.32-1,2.78-1.55,4.87-1.55,2.91,0,7.23,1.91,7.23,9.56v12.97h-3.91v-12.47c0-4.23-1.55-6.78-4.78-6.78-2.28,0-4.05,1.68-4.73,3.64-.18.55-.32,1.27-.32,2v13.6h-3.91v-13.2c0-3.5-1.55-6.05-4.6-6.05-2.5,0-4.32,2-4.96,4-.23.59-.32,1.27-.32,1.96v13.29h-3.91v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m723.17,849.78c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m729.36,845.69h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m744.56,849.78c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m762.94,877.99l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m772.13,873.9c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m801.8,855.97l4.82,13.01c.5,1.46,1.05,3.19,1.41,4.5h.09c.41-1.32.86-3,1.41-4.6l4.37-12.92h4.23l-6.01,15.7c-2.87,7.55-4.82,11.42-7.55,13.79-1.96,1.73-3.91,2.41-4.91,2.59l-1-3.37c1-.32,2.32-.96,3.5-1.96,1.09-.86,2.46-2.41,3.37-4.46.18-.41.32-.73.32-.96s-.09-.55-.27-1.05l-8.15-20.29h4.37Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m847.13,877.17c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.27,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m871.47,866.8c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m876.53,861.93c0-2.28-.05-4.14-.18-5.96h3.5l.18,3.55h.14c1.23-2.09,3.28-4.05,6.92-4.05,3,0,5.28,1.82,6.23,4.41h.09c.68-1.23,1.55-2.18,2.46-2.87,1.32-1,2.78-1.55,4.87-1.55,2.91,0,7.23,1.91,7.23,9.56v12.97h-3.91v-12.47c0-4.23-1.55-6.78-4.78-6.78-2.28,0-4.05,1.68-4.73,3.64-.18.55-.32,1.27-.32,2v13.6h-3.91v-13.2c0-3.5-1.55-6.05-4.6-6.05-2.5,0-4.32,2-4.96,4-.23.59-.32,1.27-.32,1.96v13.29h-3.91v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m932.91,871.99c0,2.28.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m939.56,861.93c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m969.27,849.78c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m994.48,845.69v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.15,6.51,8.15,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1013.32,877.99l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1042.08,845.69v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.15,6.51,8.15,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1050.96,867.71c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1070.98,873.9c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1114.21,874.58h-.09c-1.18,2.18-3.64,3.91-7.19,3.91-5.14,0-9.33-4.46-9.33-11.15,0-8.24,5.32-11.88,9.96-11.88,3.41,0,5.69,1.68,6.78,3.82h.09l.14-3.32h3.78c-.09,1.87-.14,3.78-.14,6.05v24.98h-4v-12.42Zm0-9.69c0-.55-.05-1.18-.18-1.68-.59-2.46-2.73-4.55-5.73-4.55-4.14,0-6.64,3.5-6.64,8.46,0,4.37,2.09,8.19,6.51,8.19,2.59,0,4.82-1.59,5.73-4.37.18-.55.32-1.32.32-1.91v-4.14Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1143.25,871.99c0,2.28.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1152.12,867.71c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1181.79,873.9c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1202.59,867.71c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1235.04,867.71c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1255.61,861.93c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1296.56,877.17c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.28,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1319.68,871.99c0,2.28.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1328.56,867.71c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1349.13,861.93c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1379.02,849.64v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1389.26,862.84c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.37-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1416.11,877.99l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1425.85,861.93c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1462.99,867.71c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1483.56,861.93c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m317.69,928.51c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.41,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m340.72,904.39c.05,1.37-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m351.73,904.25v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m380.4,926.6c0,2.27.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m399.24,932.6l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.46c0-1.82-.5-5.1-5-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m424.68,931.78c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.27,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m433.83,904.39c.05,1.37-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m459.95,921.41c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Zm12.69-20.57l-5.55,6.51h-2.87l4-6.51h4.41Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m465,916.54c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.09-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m518.93,900.3v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.14,6.51,8.14,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m527.8,922.32c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.36-7.33,1.36-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m558.02,917.77c0-2.82-.09-5.1-.18-7.19h3.59l.18,3.78h.09c1.64-2.68,4.23-4.28,7.83-4.28,5.32,0,9.33,4.5,9.33,11.19,0,7.92-4.82,11.83-10.01,11.83-2.91,0-5.46-1.27-6.78-3.46h-.09v11.97h-3.96v-23.84Zm3.96,5.87c0,.59.09,1.14.18,1.64.73,2.78,3.14,4.69,6.01,4.69,4.23,0,6.69-3.46,6.69-8.51,0-4.41-2.32-8.19-6.55-8.19-2.73,0-5.28,1.96-6.05,4.96-.14.5-.27,1.09-.27,1.64v3.78Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m603.84,921.41c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m608.72,932.6c.09-1.5.18-3.73.18-5.69v-26.62h3.96v13.83h.09c1.41-2.46,3.96-4.05,7.51-4.05,5.46,0,9.33,4.55,9.28,11.24,0,7.87-4.96,11.78-9.87,11.78-3.19,0-5.73-1.23-7.37-4.14h-.14l-.18,3.64h-3.46Zm4.14-8.83c0,.5.09,1,.18,1.46.77,2.78,3.09,4.69,6.01,4.69,4.19,0,6.69-3.41,6.69-8.46,0-4.41-2.27-8.19-6.55-8.19-2.73,0-5.28,1.87-6.1,4.91-.09.46-.23,1-.23,1.64v3.96Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m634.79,917.45c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.37-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m651.45,922.32c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.36-7.33,1.36-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m669.51,930.28l9.96-12.97c.96-1.18,1.87-2.23,2.87-3.41v-.09h-11.92v-3.23h16.79l-.05,2.5-9.83,12.79c-.91,1.23-1.82,2.32-2.82,3.46v.09h12.88v3.19h-17.88v-2.32Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m703.69,932.6l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.46c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m724.53,910.58l4.82,13.01c.5,1.46,1.05,3.19,1.41,4.5h.09c.41-1.32.86-3,1.41-4.6l4.37-12.92h4.23l-6.01,15.7c-2.87,7.55-4.82,11.42-7.55,13.79-1.96,1.73-3.91,2.41-4.91,2.59l-1-3.37c1-.32,2.32-.96,3.5-1.96,1.09-.86,2.46-2.41,3.37-4.46.18-.41.32-.73.32-.96s-.09-.55-.27-1.05l-8.15-20.29h4.37Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m756.39,922.32c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.36-7.33,1.36-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m778.69,910.58l3.14,4.73c.82,1.23,1.5,2.37,2.23,3.59h.14c.73-1.32,1.46-2.46,2.18-3.64l3.09-4.69h4.32l-7.51,10.65,7.74,11.38h-4.55l-3.23-4.96c-.86-1.27-1.59-2.5-2.37-3.82h-.09c-.73,1.32-1.5,2.5-2.32,3.82l-3.19,4.96h-4.41l7.83-11.24-7.46-10.78h4.46Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m812.91,931.78c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.27,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m817.6,900.3h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m846.76,926.6c0,2.27.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m852.86,928.51c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.41,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m875.89,904.39c.05,1.37-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m902.01,921.41c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Zm12.69-20.57l-5.55,6.51h-2.87l4-6.51h4.41Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m907.06,916.54c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.09-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m941.42,928.51c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m979.92,921.41c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1000.67,931.78c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.28,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1009.82,904.39c.05,1.37-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1028.2,932.6l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.46c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m1037.94,900.3h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1045.63,938.2c1-2.68,2.23-7.55,2.73-10.87l4.46-.46c-1.05,3.87-3.05,8.92-4.32,11.06l-2.87.27Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1067.7,932.6v-18.97h-3.09v-3.05h3.09v-1.05c0-3.09.68-5.92,2.55-7.69,1.5-1.46,3.5-2.05,5.37-2.05,1.41,0,2.64.32,3.41.64l-.55,3.09c-.59-.27-1.41-.5-2.55-.5-3.41,0-4.28,3-4.28,6.37v1.18h5.32v3.05h-5.32v18.97h-3.96Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1099.92,921.41c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1104.98,916.54c0-2.28-.05-4.14-.18-5.96h3.5l.18,3.55h.14c1.23-2.09,3.28-4.05,6.92-4.05,3,0,5.28,1.82,6.23,4.41h.09c.68-1.23,1.55-2.18,2.46-2.87,1.32-1,2.78-1.55,4.87-1.55,2.91,0,7.23,1.91,7.23,9.56v12.97h-3.91v-12.47c0-4.23-1.55-6.78-4.78-6.78-2.27,0-4.05,1.68-4.73,3.64-.18.55-.32,1.27-.32,2v13.6h-3.91v-13.2c0-3.5-1.55-6.05-4.6-6.05-2.5,0-4.32,2-4.96,4-.23.59-.32,1.27-.32,1.96v13.29h-3.91v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1145.16,922.32c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.36-7.33,1.36-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1165.73,916.54c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.09-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1195.63,904.25v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1218.06,932.6l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.46c0-1.82-.5-5.1-5-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1227.8,916.54c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.09-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1272.08,900.3v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.14,6.51,8.14,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1298.66,921.41c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1331.79,926.6c0,2.27.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1338.43,916.54c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.09-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1375.57,922.32c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.36-7.33,1.36-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1411.83,931.78c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.28,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1436.18,921.41c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1440.69,928.51c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1463.71,904.39c.05,1.37-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1469.36,928.51c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1492.75,904.25v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1504.94,922.32c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.36-7.33,1.36-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1525.51,916.54c0-2.28-.05-4.14-.18-5.96h3.5l.18,3.55h.14c1.23-2.09,3.28-4.05,6.92-4.05,3,0,5.28,1.82,6.23,4.41h.09c.68-1.23,1.55-2.18,2.46-2.87,1.32-1,2.78-1.55,4.87-1.55,2.91,0,7.23,1.91,7.23,9.56v12.97h-3.91v-12.47c0-4.23-1.55-6.78-4.78-6.78-2.27,0-4.05,1.68-4.73,3.64-.18.55-.32,1.27-.32,2v13.6h-3.91v-13.2c0-3.5-1.55-6.05-4.6-6.05-2.5,0-4.32,2-4.96,4-.23.59-.32,1.27-.32,1.96v13.29h-3.91v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1575.66,932.6l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.46c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m427.44,954.91v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.15,6.51,8.15,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m436.31,976.93c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m465.98,983.12c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.41,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m486.78,976.93c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m507.35,972.06c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.37-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m524.91,965.19l4.32,12.38c.73,2,1.32,3.82,1.77,5.64h.14c.5-1.82,1.14-3.64,1.87-5.64l4.28-12.38h4.19l-8.65,22.02h-3.82l-8.37-22.02h4.28Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m549.72,959c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m571.6,986.39c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.27,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m580.75,959c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m606.87,976.02c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m611.38,983.12c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.41,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m639.05,983.12c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m677.54,976.02c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m698.3,986.39c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.27,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m707.45,959c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m725.83,987.21l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m735.57,954.91h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m748.54,976.93c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m768.56,983.12c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.41,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m801.6,958.86v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m813.8,976.93c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m834.37,972.06c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.37-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m849.43,972.06c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.37-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m868.95,959c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m879.96,958.86v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m909.86,976.02c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m914.92,972.06c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.37-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m934.44,959c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m952.82,987.21l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m962.56,954.91h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m975.53,976.93c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m995.55,983.12c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1042.79,954.91v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.15,6.51,8.15,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1051.67,976.93c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1097.58,986.39c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.28,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1114.46,987.21l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m1124.2,954.91h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1139.4,959c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1164.61,954.91v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.15,6.51,8.15,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1183.45,987.21l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5.01-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1212.21,954.91v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.15,6.51,8.15,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1215.81,992.81c1-2.68,2.23-7.55,2.73-10.88l4.46-.45c-1.05,3.87-3.05,8.92-4.32,11.06l-2.87.27Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1252.49,983.8h-.09c-1.18,2.18-3.64,3.91-7.19,3.91-5.14,0-9.33-4.46-9.33-11.15,0-8.24,5.32-11.88,9.96-11.88,3.41,0,5.69,1.68,6.78,3.82h.09l.14-3.32h3.78c-.09,1.87-.14,3.78-.14,6.05v24.98h-4v-12.42Zm0-9.69c0-.55-.05-1.18-.18-1.68-.59-2.46-2.73-4.55-5.73-4.55-4.14,0-6.64,3.5-6.64,8.46,0,4.37,2.09,8.19,6.51,8.19,2.59,0,4.82-1.59,5.73-4.37.18-.55.32-1.32.32-1.91v-4.14Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1281.52,981.21c0,2.28.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1290.4,976.93c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1320.61,972.38c0-2.82-.09-5.1-.18-7.19h3.59l.18,3.78h.09c1.64-2.68,4.23-4.28,7.83-4.28,5.32,0,9.33,4.5,9.33,11.19,0,7.92-4.82,11.83-10.01,11.83-2.91,0-5.46-1.27-6.78-3.46h-.09v11.97h-3.96v-23.84Zm3.96,5.87c0,.59.09,1.14.18,1.64.73,2.78,3.14,4.69,6.01,4.69,4.23,0,6.69-3.46,6.69-8.51,0-4.41-2.32-8.19-6.55-8.19-2.73,0-5.28,1.96-6.05,4.96-.14.5-.27,1.09-.27,1.64v3.78Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1348.74,976.93c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1369.31,972.06c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.37-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1384.37,971.15c0-2.28-.05-4.14-.18-5.96h3.5l.18,3.55h.14c1.23-2.09,3.28-4.05,6.92-4.05,3,0,5.28,1.82,6.23,4.41h.09c.68-1.23,1.55-2.18,2.46-2.87,1.32-1,2.78-1.55,4.87-1.55,2.91,0,7.23,1.91,7.23,9.56v12.97h-3.91v-12.47c0-4.23-1.55-6.78-4.78-6.78-2.27,0-4.05,1.68-4.73,3.64-.18.55-.32,1.27-.32,2v13.6h-3.91v-13.2c0-3.5-1.55-6.05-4.6-6.05-2.5,0-4.32,2-4.96,4-.23.59-.32,1.27-.32,1.96v13.29h-3.91v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1426.78,959c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1437.8,958.86v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1460.23,987.21l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1469.97,971.15c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m344.93,1026.67c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.45,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.36-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m361.59,1031.54c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m381.61,1037.73c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.41,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m405,1013.47v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m419.7,1013.61c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m430.71,1013.47v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m459.38,1035.82c0,2.28.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m470.49,1013.61c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m476.68,1026.67c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.37-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m502.66,1019.8l4.82,13.01c.5,1.46,1.05,3.19,1.41,4.5h.09c.41-1.32.86-3,1.41-4.6l4.37-12.92h4.23l-6.01,15.7c-2.87,7.55-4.82,11.42-7.55,13.79-1.96,1.73-3.91,2.41-4.91,2.59l-1-3.37c1-.32,2.32-.96,3.5-1.96,1.09-.86,2.46-2.41,3.37-4.46.18-.41.32-.73.32-.96s-.09-.55-.27-1.05l-8.14-20.29h4.37Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m532.29,1026.99c0-2.82-.09-5.1-.18-7.19h3.59l.18,3.78h.09c1.64-2.68,4.23-4.28,7.83-4.28,5.32,0,9.33,4.5,9.33,11.19,0,7.92-4.82,11.83-10.01,11.83-2.91,0-5.46-1.27-6.78-3.46h-.09v11.97h-3.96v-23.84Zm3.96,5.87c0,.59.09,1.14.18,1.64.73,2.78,3.14,4.69,6.01,4.69,4.23,0,6.69-3.46,6.69-8.51,0-4.41-2.32-8.19-6.55-8.19-2.73,0-5.28,1.96-6.05,4.96-.14.5-.27,1.09-.27,1.64v3.78Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m558.18,1026.67c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.45,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.36-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m592.54,1030.63c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m597.59,1025.76c0-2.28-.05-4.14-.18-5.96h3.5l.18,3.55h.14c1.23-2.09,3.28-4.05,6.92-4.05,3,0,5.28,1.82,6.23,4.41h.09c.68-1.23,1.55-2.18,2.46-2.87,1.32-1,2.78-1.55,4.87-1.55,2.91,0,7.23,1.91,7.23,9.56v12.97h-3.91v-12.47c0-4.23-1.55-6.78-4.78-6.78-2.27,0-4.05,1.68-4.73,3.64-.18.55-.32,1.27-.32,2v13.6h-3.91v-13.2c0-3.5-1.55-6.05-4.6-6.05-2.5,0-4.32,2-4.96,4-.23.59-.32,1.27-.32,1.96v13.29h-3.91v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m655.47,1030.63c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m661.76,1019.8l4.32,12.38c.73,2,1.32,3.82,1.77,5.64h.14c.5-1.82,1.14-3.64,1.87-5.64l4.28-12.38h4.19l-8.65,22.02h-3.82l-8.37-22.02h4.28Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m683.87,1031.54c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m704.44,1026.67c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.37-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m731.2,1031.54c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m751.77,1009.52h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m774.39,1031.54c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m789.59,1048.19c1.82-.14,3.41-.64,4.37-1.68,1.09-1.27,1.5-3,1.5-8.28v-18.43h4v19.98c0,4.28-.68,7.05-2.64,9.05-1.77,1.77-4.69,2.5-6.83,2.5l-.41-3.14Zm10.33-34.58c.05,1.32-.91,2.46-2.5,2.46-1.5,0-2.46-1.14-2.46-2.46,0-1.41,1.05-2.5,2.55-2.5s2.41,1.09,2.41,2.5Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m808.24,1031.54c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m828.81,1026.67c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.45,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.36-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m858.94,1041c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.27,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m868.09,1013.61c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m889.97,1041c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.27,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m899.12,1013.61c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m925.24,1030.63c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m958.96,1009.52v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.15,6.51,8.15,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m967.84,1031.54c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1017.08,1009.52v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.15,6.51,8.15,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1025.95,1031.54c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1046.52,1026.67c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.46,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.36-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1063.18,1031.54c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1099.45,1041c-1.05.55-3.37,1.27-6.32,1.27-6.64,0-10.97-4.5-10.97-11.24s4.64-11.69,11.83-11.69c2.37,0,4.46.59,5.55,1.14l-.91,3.09c-.96-.55-2.46-1.05-4.64-1.05-5.05,0-7.78,3.73-7.78,8.33,0,5.1,3.28,8.24,7.64,8.24,2.28,0,3.78-.59,4.91-1.09l.68,3Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1104.14,1009.52h4v13.74h.09c.64-1.14,1.64-2.14,2.87-2.82,1.18-.68,2.59-1.14,4.1-1.14,2.96,0,7.69,1.82,7.69,9.42v13.1h-4v-12.65c0-3.55-1.32-6.55-5.1-6.55-2.59,0-4.64,1.82-5.37,4-.23.55-.27,1.14-.27,1.91v13.29h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1149.32,1030.63c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1153.83,1037.73c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1182.45,1041.82v-18.97h-3.09v-3.05h3.09v-1.05c0-3.09.68-5.92,2.55-7.69,1.5-1.46,3.5-2.05,5.37-2.05,1.41,0,2.64.32,3.41.64l-.55,3.09c-.59-.27-1.41-.5-2.55-.5-3.41,0-4.28,3-4.28,6.37v1.18h5.32v3.05h-5.32v18.97h-3.96Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1213.76,1035.82c0,2.28.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1220.41,1025.76c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1264.68,1009.52v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.15,6.51,8.15,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1283.53,1041.82l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1293.27,1025.76c0-2.28-.05-4.14-.18-5.96h3.5l.18,3.55h.14c1.23-2.09,3.28-4.05,6.92-4.05,3,0,5.28,1.82,6.23,4.41h.09c.68-1.23,1.55-2.18,2.46-2.87,1.32-1,2.78-1.55,4.87-1.55,2.91,0,7.23,1.91,7.23,9.56v12.97h-3.91v-12.47c0-4.23-1.55-6.78-4.78-6.78-2.27,0-4.05,1.68-4.73,3.64-.18.55-.32,1.27-.32,2v13.6h-3.91v-13.2c0-3.5-1.55-6.05-4.6-6.05-2.5,0-4.32,2-4.96,4-.23.59-.32,1.27-.32,1.96v13.29h-3.91v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1333.45,1031.54c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1354.02,1025.76c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1383.92,1013.47v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1406.35,1041.82l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m1416.09,1009.52h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1429.06,1031.54c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1449.08,1037.73c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1478.76,1019.8l4.82,13.01c.5,1.46,1.05,3.19,1.41,4.5h.09c.41-1.32.86-3,1.41-4.6l4.37-12.92h4.23l-6.01,15.7c-2.87,7.55-4.82,11.42-7.55,13.79-1.96,1.73-3.91,2.41-4.91,2.59l-1-3.37c1-.32,2.32-.96,3.5-1.96,1.09-.86,2.46-2.41,3.37-4.46.18-.41.32-.73.32-.96s-.09-.55-.27-1.05l-8.14-20.29h4.37Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1526.81,1035.82c0,2.28.05,4.28.18,6.01h-3.55l-.23-3.59h-.09c-1.05,1.77-3.37,4.1-7.28,4.1-3.46,0-7.6-1.91-7.6-9.65v-12.88h4v12.19c0,4.19,1.27,7.01,4.91,7.01,2.68,0,4.55-1.87,5.28-3.64.23-.59.36-1.32.36-2.05v-13.51h4v16.02Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1533.46,1025.76c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m769.93,1064.13v26.62c0,1.96.05,4.19.18,5.69h-3.59l-.18-3.82h-.09c-1.23,2.46-3.91,4.32-7.51,4.32-5.32,0-9.42-4.5-9.42-11.19-.05-7.33,4.5-11.83,9.87-11.83,3.37,0,5.64,1.59,6.64,3.37h.09v-13.15h4Zm-4,19.25c0-.5-.05-1.18-.18-1.68-.59-2.55-2.78-4.64-5.78-4.64-4.14,0-6.6,3.64-6.6,8.51,0,4.46,2.18,8.15,6.51,8.15,2.68,0,5.14-1.77,5.87-4.78.14-.55.18-1.09.18-1.73v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m778.8,1086.15c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m798.83,1092.34c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.41,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m829.59,1096.43l-.32-2.78h-.14c-1.23,1.73-3.59,3.28-6.73,3.28-4.46,0-6.73-3.14-6.73-6.32,0-5.32,4.73-8.24,13.24-8.19v-.45c0-1.82-.5-5.1-5-5.1-2.05,0-4.19.64-5.73,1.64l-.91-2.64c1.82-1.18,4.46-1.96,7.23-1.96,6.73,0,8.37,4.6,8.37,9.01v8.24c0,1.91.09,3.78.36,5.28h-3.64Zm-.59-11.24c-4.37-.09-9.33.68-9.33,4.96,0,2.59,1.73,3.82,3.78,3.82,2.87,0,4.69-1.82,5.32-3.69.14-.41.23-.86.23-1.27v-3.82Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m839.33,1081.28c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.45,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.36-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m854.39,1081.28c0-2.59-.05-4.82-.18-6.87h3.5l.14,4.32h.18c1-2.96,3.41-4.82,6.1-4.82.45,0,.77.05,1.14.14v3.78c-.41-.09-.82-.14-1.36-.14-2.82,0-4.82,2.14-5.37,5.14-.09.55-.18,1.18-.18,1.87v11.74h-3.96v-15.15Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m888.75,1085.24c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m893.8,1064.13h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m904.54,1064.13h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m935.21,1085.24c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m949.36,1092.34c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.41,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m987.86,1085.24c0,8.14-5.64,11.69-10.97,11.69-5.96,0-10.56-4.37-10.56-11.33,0-7.37,4.82-11.69,10.92-11.69s10.6,4.6,10.6,11.33Zm-17.47.23c0,4.82,2.78,8.46,6.69,8.46s6.69-3.59,6.69-8.55c0-3.73-1.87-8.46-6.6-8.46s-6.78,4.37-6.78,8.55Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m992.37,1092.34c1.18.77,3.28,1.59,5.28,1.59,2.91,0,4.28-1.46,4.28-3.28,0-1.91-1.14-2.96-4.1-4.05-3.96-1.41-5.82-3.59-5.82-6.23,0-3.55,2.87-6.46,7.6-6.46,2.23,0,4.19.64,5.42,1.37l-1,2.91c-.86-.55-2.46-1.27-4.5-1.27-2.37,0-3.69,1.36-3.69,3,0,1.82,1.32,2.64,4.19,3.73,3.82,1.46,5.78,3.37,5.78,6.64,0,3.87-3,6.6-8.24,6.6-2.41,0-4.64-.59-6.19-1.5l1-3.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1015.76,1068.08v6.32h5.73v3.05h-5.73v11.88c0,2.73.77,4.28,3,4.28,1.05,0,1.82-.14,2.32-.27l.18,3c-.77.32-2,.55-3.55.55-1.87,0-3.37-.59-4.32-1.68-1.14-1.18-1.55-3.14-1.55-5.73v-12.01h-3.41v-3.05h3.41v-5.28l3.91-1.05Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1027.96,1086.15c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1048.53,1080.37c0-2.28-.05-4.14-.18-5.96h3.55l.23,3.64h.09c1.09-2.09,3.64-4.14,7.28-4.14,3.05,0,7.78,1.82,7.78,9.37v13.15h-4v-12.7c0-3.55-1.32-6.51-5.1-6.51-2.64,0-4.69,1.87-5.37,4.1-.18.5-.27,1.18-.27,1.87v13.24h-4v-16.06Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1078.24,1068.22c.05,1.36-.96,2.46-2.55,2.46-1.41,0-2.41-1.09-2.41-2.46s1.05-2.5,2.5-2.5,2.46,1.09,2.46,2.5Zm-4.46,28.21v-22.02h4v22.02h-4Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1084.25,1096.43c.09-1.5.18-3.73.18-5.69v-26.62h3.96v13.83h.09c1.41-2.46,3.96-4.05,7.51-4.05,5.46,0,9.33,4.55,9.28,11.24,0,7.87-4.96,11.78-9.87,11.78-3.19,0-5.73-1.23-7.37-4.14h-.14l-.18,3.64h-3.46Zm4.14-8.83c0,.5.09,1,.18,1.46.77,2.78,3.09,4.69,6.01,4.69,4.19,0,6.69-3.41,6.69-8.46,0-4.41-2.27-8.19-6.55-8.19-2.73,0-5.28,1.87-6.1,4.91-.09.46-.23,1-.23,1.64v3.96Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599" d="m1110.33,1064.13h4v32.31h-4v-32.31Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1123.29,1086.15c.09,5.41,3.55,7.64,7.55,7.64,2.87,0,4.6-.5,6.1-1.14l.68,2.87c-1.41.64-3.82,1.37-7.33,1.37-6.78,0-10.83-4.46-10.83-11.1s3.91-11.88,10.33-11.88c7.19,0,9.1,6.32,9.1,10.37,0,.82-.09,1.46-.14,1.87h-15.47Zm11.74-2.87c.05-2.55-1.05-6.51-5.55-6.51-4.05,0-5.82,3.73-6.14,6.51h11.69Z" />
                    <path class="uuid-e411fd02-9c42-445d-85e9-379fbcad4599"
                        d="m1142.36,1094.07c0-1.68,1.14-2.87,2.73-2.87s2.68,1.18,2.68,2.87-1.05,2.87-2.73,2.87c-1.59,0-2.68-1.23-2.68-2.87Z" />
                </g>

                <a href=" https://www.hogardecristo.cl/nuestra-historia/" target="_blank" rel="noopener noreferrer"><text
                        class="uuid-88d3ee14-411e-40d8-99aa-58f2688f07c3" transform="translate(805.71 1184.09)">
                        <tspan class="uuid-caff4305-5db9-4e58-a1b9-402e5cb6be73" x="-60" y="0">Conócela en detalle aquí
                        </tspan>
                    </text></a>
            </g>
        </g>
    </svg>
</template>

<style scoped>
.uuid-8989505b-60bc-4da0-bf8c-438269183b70 {
    font-size: 98.24px;
}

.uuid-8989505b-60bc-4da0-bf8c-438269183b70,
.uuid-a325053f-60e6-4326-b9eb-19e542f6ec3d,
.uuid-89cf00d8-04ca-4391-933a-d42a0426069d,
.uuid-02e7dd86-5ddc-4219-a023-49cb031c4608,
.uuid-327ea7b0-247b-4ddc-8be9-091f701c30e6,
.uuid-a482cd90-22f1-45c8-b95d-a6e088074421,
.uuid-16b1c124-3304-4841-a814-0ec0a370cfd9 {
    fill: #fff;
}

.uuid-8989505b-60bc-4da0-bf8c-438269183b70,
.uuid-89cf00d8-04ca-4391-933a-d42a0426069d,
.uuid-02e7dd86-5ddc-4219-a023-49cb031c4608,
.uuid-88d3ee14-411e-40d8-99aa-58f2688f07c3 {
    font-family: DINNextRoundedLTPro-Bold, 'DINNextRoundedLTPro Bold';
    font-weight: 700;
}

.uuid-01698108-9327-44c8-8d82-be52343d2c48,
.uuid-fdd91b7c-083d-485a-af30-868c848f13d1 {
    fill: #84bd00;
}

.uuid-92f2e616-2e55-4dd2-bc48-ce75b9859920 {
    letter-spacing: -.07em;
}

.uuid-71c7fd47-a19f-4374-8b1f-15f327827f92 {
    letter-spacing: .01em;
}

.uuid-75e2377d-58b7-4aac-a1a2-ad7ee582a794 {
    letter-spacing: 0em;
}

.uuid-75e2377d-58b7-4aac-a1a2-ad7ee582a794,
.uuid-410ffd43-a348-4947-afe5-47b377c7938c,
.uuid-24d72457-eca6-4924-8a13-7fa227cad7b8,
.uuid-3f17dd81-19ce-4878-a79f-670208d08b24,
.uuid-70537a94-e536-4cec-96a5-352d4bd2598e,
.uuid-33684426-0204-49de-b9ab-6a84d696bf31,
.uuid-16b1c124-3304-4841-a814-0ec0a370cfd9 {
    font-family: DINNextRoundedLTPro-Medium, 'DINNextRoundedLTPro Medium';
    font-weight: 500;
}

.uuid-89cf00d8-04ca-4391-933a-d42a0426069d {
    font-size: 52.75px;
}

.uuid-410ffd43-a348-4947-afe5-47b377c7938c {
    letter-spacing: 0em;
}

.uuid-24d72457-eca6-4924-8a13-7fa227cad7b8 {
    letter-spacing: 0em;
}

.uuid-3f17dd81-19ce-4878-a79f-670208d08b24 {
    letter-spacing: 0em;
}

.uuid-48d38ea6-3f48-4a1a-8c9b-03ae74f3a64c {
    letter-spacing: -.02em;
}

.uuid-0f2eca66-9737-422d-b515-1a15f2bb6293 {
    clip-path: url(#uuid-1ddcb1a7-c4fd-4af8-a7d1-46623271c333);
}

.uuid-db41f0e8-16db-4c38-9bad-8ec47b005557 {
    letter-spacing: 0em;
}

.uuid-7bea3758-e8b9-4dad-9bd0-2c378fbd49e8 {
    font-family: DINNextRoundedLTPro-Light, 'DINNextRoundedLTPro Light';
    font-weight: 300;
}

.uuid-03aa3d0e-9a2f-4927-b335-b7e98507f3db {
    letter-spacing: 0em;
}

.uuid-4beca174-686c-4878-90bd-107ec40954bd {
    letter-spacing: 0em;
}

.uuid-02e7dd86-5ddc-4219-a023-49cb031c4608 {
    font-size: 90.64px;
}

.uuid-744e1e93-b228-497f-9dbf-0dee7bbfb433 {
    letter-spacing: 0em;
}

.uuid-cf5619e1-8469-48a1-8116-3e9195ab75ab {
    fill: #5bc2e7;
    filter: url(#uuid-cf418b67-4cf1-42ac-b689-8a521f14726f);
}

.uuid-cf5619e1-8469-48a1-8116-3e9195ab75ab,
.uuid-eaa89ac7-b6ca-411c-9935-e241704791d6,
.uuid-fdd91b7c-083d-485a-af30-868c848f13d1 {
    opacity: .72;
}

.uuid-253b7fc1-2402-4f34-86af-479ae3ea1ed0 {
    letter-spacing: -.03em;
}

.uuid-88d3ee14-411e-40d8-99aa-58f2688f07c3 {
    font-size: 38.89px;
    text-decoration: underline;
}

.uuid-88d3ee14-411e-40d8-99aa-58f2688f07c3,
.uuid-e411fd02-9c42-445d-85e9-379fbcad4599 {
    fill: #666;
}

.uuid-3b7a6542-58de-4ec1-8c40-9110e78dbb29,
.uuid-327ea7b0-247b-4ddc-8be9-091f701c30e6 {
    opacity: .57;
}

.uuid-327ea7b0-247b-4ddc-8be9-091f701c30e6 {
    filter: url(#uuid-61f571c5-5af5-4a98-9f00-c7c2bb51c96a);
}

.uuid-70537a94-e536-4cec-96a5-352d4bd2598e {
    letter-spacing: 0em;
}

.uuid-30a5f745-0b81-489e-851d-0f4540f97c87 {
    fill: none;
}

.uuid-a482cd90-22f1-45c8-b95d-a6e088074421 {
    font-size: 98.03px;
}

.uuid-31ab239c-af70-487e-b948-22ab7a383168 {
    letter-spacing: 0em;
}

.uuid-164b6c38-d7b3-48f2-a93e-3a0b06c91601 {
    letter-spacing: 0em;
}

.uuid-a1602b62-7aeb-41a4-af5e-9ed92d4a5f05 {
    letter-spacing: 0em;
}

.uuid-adf1b3c1-6e2f-40d1-a079-3b717ea571b5 {
    letter-spacing: 0em;
}

.uuid-eaa89ac7-b6ca-411c-9935-e241704791d6 {
    fill: #fe5000;
    filter: url(#uuid-f5266775-e8ee-499d-89f4-51b3b5eadb56);
}

.uuid-fdd91b7c-083d-485a-af30-868c848f13d1 {
    filter: url(#uuid-37d4513a-f4d5-45ca-8e35-54207dae7099);
}

.uuid-16b1c124-3304-4841-a814-0ec0a370cfd9 {
    font-size: 74.83px;
}

.uuid-7c47ee9a-3969-4e98-93ff-a86a1a5e918b {
    letter-spacing: 0em;
}

.uuid-c6ed81bd-3dee-436d-bdcc-7512f2081d12 {
    letter-spacing: 0em;
}

.uuid-caff4305-5db9-4e58-a1b9-402e5cb6be73 {
    letter-spacing: -.01em;
}
</style>