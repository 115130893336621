<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<style>
html{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body { height: 100%; }



.texto-estrategia {
    font-size: 36pt;
    color: #fff;
    text-align: justify;
    margin-top: 20px;
}

.titulo-svg {
  font-family: DINNextRoundedLTPro-Medium;
  text-align: center;
  font-size: 4rem;
  font-weight: 500;
  color: white;
  animation: fadeIn 1s ease-in-out;
}

.titulo-svg.alt {
  color: white;
  font-size: 4rem;
  font-weight: 500;
  font-family: DINNextRoundedLTPro-Light !important;
}

.font-dinn-bold {
  font-family: DINNextRoundedLTPro-Bold !important;
}
.font-dinn-light {
  font-family: DINNextRoundedLTPro-Light !important;
}
.font-dinn-medium {
  font-family: DINNextRoundedLTPro-Medium !important;
}
.font-dinn-regular {
  font-family: DINNextRoundedLTPro-Regular !important;
}

.text-justify {
	text-align: justify;
}

.cursor {
  cursor: pointer;
}

</style>