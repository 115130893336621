<!-- Este modal se activa cuando hay que seleccionar las tres opciones, por ejemplo cuando se selecciona Infancia y adolescencia  -->


<template>
    <div class="container-fluid my-5">
        <transition name="slide">
            <div v-if="isOpen" class="modal-overlay" @click.self="closeModal">
                <div class="modal-content position-relative p-5 shadow">
                    <button class="btn-close position-absolute top-0 end-0 p-3" @click="closeModal"></button>
                    <slot />
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
import { defineExpose, ref } from 'vue';

const isOpen = ref(false);

const closeModal = () => {
    isOpen.value = false;
};

const openModal = () => {
    isOpen.value = true;
};

defineExpose({ closeModal, openModal });
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    max-width: 90%; /* Asegurarse de que no sea demasiado ancho en dispositivos móviles */
    width: 500px;   /* Ancho ideal en pantallas más grandes */
    border-radius: 90px 0 90px 0;
    background-color: white;
    overflow: hidden; /* Esto asegura que todo el contenido interior también respete el border-radius */
}

.shadow {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.8);
}

/* Media queries para adaptar según el tamaño del dispositivo */
@media (min-width: 768px) { /* Tablets */
    .modal-content {
        max-width: 70%;
    }
}

@media (min-width: 992px) { /* Desktops */
    .modal-content {
        max-width: 50%;
    }
}

.slide-enter-active, .slide-leave-active {
    transition: transform 0.5s;
}

.slide-enter-from, .slide-leave-to {
    transform: translateX(100%);
}
</style>
