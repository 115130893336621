<template>
  <div class="container">
    <div class="player">
      <div class="song">
        <div class="text font-dinn-bold text-audio-estrategia">Estrategia Social</div>
      </div>
    </div>
    <div class="time-display">
      <p class="current-time">{{ formatTime(currentTime) }} </p>
      <div class="progress-bar" ref="progressBar" @click="seek">
        <div class="progress" :style="{ width: progressBarWidth + '%' }"></div>
        <div class="circle" :style="{ left: progressBarWidth + '%' }" @mousedown="startDragging" @mousemove="drag"
          @mouseup="stopDragging" @mouseleave="stopDragging"></div>
      </div>
      <p class="total-time">-{{ formatTime(timeRemaining) }} </p>
    </div>
    <div>
      <button  @click="previousSong"><i class="fas fa-backward player-icon"></i></button>
      <button @click="togglePlay" v-html="isPlaying ? '<i class=\'fas fa-pause\'></i>' : '<i class=\'fas fa-play\'></i>'"
        class="player-icon"></button>
      <button @click="nextSong"><i class="fas fa-forward player-icon"></i></button>
    </div>
    <audio ref="audioPlayer" style="display:none;" controls preload="metadata" @timeupdate="updateTime"
      @loadedmetadata="updateTotalTime"></audio>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      song: {
        url: require('@/assets/audio/EstrategiaSocial.mp3'),
      },
      currentTime: 0,
      totalTime: 0,
      progressBarWidth: 0,
      isPlaying: false,
      isDragging: false,
      timeRemaining: 0,
    }
  },
  methods: {
    playSong(url) {
      if (!this.$refs.audioPlayer.src) {
        this.$refs.audioPlayer.src = url;
      }
      this.$refs.audioPlayer.play();
      this.isPlaying = true;
    },
    pauseSong() {
      this.$refs.audioPlayer.pause();
      this.isPlaying = false;
    },
    togglePlay() {
      if (this.isPlaying) {
        this.pauseSong();
      } else {
        this.playSong(this.song.url);
      }
    },
    updateTime() {
      this.currentTime = Math.floor(this.$refs.audioPlayer.currentTime);
      this.timeRemaining = Math.floor(this.$refs.audioPlayer.duration) - this.currentTime;
      this.progressBarWidth = (this.currentTime / this.$refs.audioPlayer.duration) * 100;
    },
    updateTotalTime() {
      this.totalTime = Math.floor(this.$refs.audioPlayer.duration);
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    },
    seek(event) {
      const progressBar = this.$refs.progressBar;
      const clickPosition = event.pageX - progressBar.getBoundingClientRect().left;
      const newTime = (clickPosition / progressBar.clientWidth) * this.$refs.audioPlayer.duration;
      this.$refs.audioPlayer.currentTime = newTime;
    },
    startDragging() {
      this.isDragging = true;
    },
    drag(event) {
      if (!this.isDragging) return;

      const progressBar = this.$refs.progressBar;
      const clickPosition = event.pageX - progressBar.getBoundingClientRect().left;
      const newTime = (clickPosition / progressBar.clientWidth) * this.$refs.audioPlayer.duration;
      this.$refs.audioPlayer.currentTime = newTime;
    },
    stopDragging() {
      this.isDragging = false;
    },
  },
}
</script>
  
<style scoped>
.text {
  color: #3CB5DB;
  font-weight: 500;
  font-size: 2vw;
}

.container {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  background-color: whitesmoke;
  width: 90vw;
  min-width: 200px;
  max-width: 600px;
  min-height: 75px;
  max-height: 200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.song {
  margin: 10px 0;
}

.time-display {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.progress-bar {
  flex-grow: 1;
  height: 10px;
  background-color: gray;
  border-radius: 2px;
  overflow: visible;
  margin: 0 30px;
  position: relative;
}

.progress {
  height: 100%;
  background-color: #3CB5DB;
}

.current-time,
.total-time {
  margin: 0;
}

.circle {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) translateX(-50%);
  width: 20px;
  height: 20px;
  background-color: #3CB5DB;
  border-radius: 50%;
  z-index: 1;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  color: #3CB5DB;
  font-size: 1.2vw;
  margin: 0 0.8vw;

}

.text-audio-estrategia {
  font-size: 2rem;
}

.player-icon {
  font-size: 1rem;
}


@media all and (min-width: 1024px) and (max-width: 1280px) {
  .text-audio-estrategia {
    font-size: 1.5rem;
  }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .text-audio-estrategia {
    font-size: 1.5rem;
  }

  .player-icon {
    font-size: 1.5rem;
  }

}

@media all and (min-width: 480px) and (max-width: 768px) {
  .text-audio-estrategia {
    font-size: 1.5rem;
  }

  .player-icon {
    font-size: 1.5rem;
  }
}

@media all and (max-width: 480px) {
  .text-audio-estrategia {
    font-size: 1.5rem;
  }

  .player-icon {
    font-size: 1.5rem;
  }
}
</style>
  
  