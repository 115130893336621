<template>
    <div class="svg-wrapper">
        <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="-50 0 282.34 199.16">
            <g id="Capa_1-2" data-name="Capa 1">
                <g class="cursor">
                    <circle class="cls-1" cx="91.17" cy="72.91" r="72.91" />
                    <path class="cls-5"
                        d="m91.17,15.86c-31.53,0-57.12,25.59-57.12,57.12s25.59,57.12,57.12,57.12,57.12-25.59,57.12-57.12S122.7,15.86,91.17,15.86h0Zm0,104.59c-26.18,0-47.47-21.29-47.47-47.47s21.29-47.47,47.47-47.47,47.47,21.29,47.47,47.47-21.29,47.47-47.47,47.47h0Z" />
                    <path class="cls-5"
                        d="m81.28,92.64c.7.35,1.4.47,2.09.47.81,0,1.74-.23,2.44-.81l23.5-16.06c1.16-.81,1.86-2.21,1.86-3.61s-.7-2.79-1.98-3.61l-23.5-15.59c-1.4-.93-3.02-.93-4.54-.23-1.4.81-2.33,2.21-2.33,3.84v31.64c.12,1.74.93,3.26,2.44,3.96h0Z" />
                    <text class="cls-2" transform="translate(-20 191.6)">
                        <tspan x="0" y="0">VER CAPÍTULO</tspan>
                    </text>
                </g>
            </g>
        </svg>
    </div>
</template>

<style scoped>
.svg-wrapper {
    width: 100%;
    max-width: 400px;
    /* You can set a max-width if you want */
}

#Capa_2 {
    width: 100%;
    height: auto;
}

/* Responsive text */
@media (max-width: 767px) {
    .cls-2 {
        font-size: 20px;
    }
}

.cls-1,
.cls-2 {
    fill: #fff;
}

.cls-2 {
    font-family: DINNextRoundedLTPro-Medium, 'DINNextRoundedLTPro Medium';
    font-size: 30.22px;
    font-weight: 500;
}

.cls-3 {
    letter-spacing: 0em;
}

.cls-4 {
    letter-spacing: -.04em;
}

.cls-5 {
    fill: #ffcb00;
}
</style>
