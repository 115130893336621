<template>
    <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1387.58 952.53">
        <g id="Capa_1-2" data-name="Capa 1">
            <g>
                <text class="cls-5" transform="translate(384.58 815.9)">
                    <tspan class="cls-21" x="0" y="0">P</tspan>
                    <tspan x="56.68" y="0">ublicaciones</tspan>
                    <tspan x="23.57" y="109.23">por </tspan>
                    <tspan class="cls-16" x="205.22" y="109.23">t</tspan>
                    <tspan x="240.85" y="109.23">em</tspan>
                    <tspan class="cls-22" x="387.2" y="109.23">á</tspan>
                    <tspan x="439.61" y="109.23">tica</tspan>
                </text>
                <g>
                    <path class="cls-7"
                        d="m137.98,204.94c-1.18,0-2.35-.45-3.25-1.35l-38.97-38.97c-.86-.86-1.35-2.03-1.35-3.25s.48-2.39,1.35-3.25l25.98-25.98c.86-.86,2.03-1.35,3.25-1.35s2.39.48,3.25,1.35l38.97,38.97c1.79,1.79,1.79,4.71,0,6.5l-25.98,25.98c-.9.9-2.07,1.34-3.25,1.34h0Zm-32.47-43.57l32.47,32.47,19.48-19.48-32.47-32.47-19.48,19.48Z" />
                    <path class="cls-7"
                        d="m163.96,178.96c-1.19,0-2.37-.46-3.25-1.35l-38.97-38.97c-1.32-1.32-1.71-3.31-.99-5.03l42.22-100.68c.59-1.41,1.85-2.44,3.35-2.73,1.5-.3,3.06.18,4.14,1.26l94.18,94.18c1.06,1.06,1.54,2.57,1.28,4.05-.26,1.48-1.23,2.73-2.58,3.37l-97.43,45.47c-.62.29-1.29.43-1.94.43h0Zm-33.54-44.64l34.51,34.51,88.56-41.33-84.69-84.69-38.38,91.51Z" />
                    <path class="cls-7"
                        d="m111.99,205.73c-4.91,0-9.52-1.91-12.99-5.38-3.47-3.47-5.38-8.09-5.38-12.99s1.91-9.52,5.38-12.99l6.49-6.49c.86-.86,2.03-1.35,3.25-1.35s2.39.48,3.25,1.35l19.49,19.49c1.79,1.79,1.79,4.71,0,6.5l-6.49,6.49c-3.47,3.47-8.09,5.38-12.99,5.38h0Zm-3.25-28.11l-3.24,3.24c-1.73,1.73-2.69,4.04-2.69,6.49s.96,4.76,2.69,6.49c3.58,3.58,9.4,3.58,12.98,0l3.24-3.24-12.98-12.98Z" />
                    <path class="cls-7"
                        d="m271.13,139.43c-3.53,0-7.06-1.34-9.75-4.03l-100.68-100.68c-2.6-2.6-4.04-6.06-4.04-9.75s1.43-7.14,4.04-9.75c2.6-2.6,6.06-4.04,9.75-4.04s7.14,1.43,9.75,4.04l100.68,100.68c5.37,5.37,5.37,14.12,0,19.49-2.69,2.68-6.22,4.03-9.75,4.03h0ZM170.45,20.39c-1.23,0-2.38.48-3.24,1.34-.87.87-1.34,2.02-1.34,3.24s.48,2.38,1.34,3.24l100.68,100.68c1.79,1.79,4.7,1.79,6.49,0,1.79-1.79,1.79-4.7,0-6.49L173.7,21.73c-.87-.87-2.02-1.34-3.25-1.34h0Z" />
                    <path class="cls-7"
                        d="m240.29,86.33c-1.18,0-2.35-.45-3.25-1.35-1.79-1.79-1.79-4.71,0-6.5,2.6-2.6,4.03-6.06,4.03-9.74s-1.43-7.14-4.03-9.74-6.06-4.03-9.74-4.03-7.14,1.43-9.74,4.03c-1.8,1.79-4.71,1.8-6.5,0-1.79-1.79-1.79-4.71,0-6.5,4.34-4.34,10.11-6.73,16.24-6.73s11.9,2.39,16.24,6.73c4.34,4.34,6.73,10.11,6.73,16.24s-2.39,11.9-6.73,16.24c-.9.9-2.07,1.35-3.25,1.35h0Z" />
                    <path class="cls-7"
                        d="m199.68,234.17c-.97,0-1.94-.3-2.76-.92l-51.96-38.97c-1.07-.8-1.73-2.02-1.83-3.35-.09-1.33.39-2.63,1.34-3.58l16.24-16.24c1.62-1.62,4.18-1.8,6.01-.43l51.96,38.97c1.07.8,1.73,2.02,1.83,3.35.1,1.33-.39,2.63-1.34,3.58l-16.24,16.24c-.89.89-2.07,1.35-3.25,1.35h0Zm-44.96-44.07l44.53,33.4,9.67-9.67-44.53-33.4-9.67,9.67Z" />
                    <path class="cls-7"
                        d="m263.52,32.16c-1.18,0-2.35-.45-3.25-1.35-1.79-1.79-1.79-4.71,0-6.5l13.78-13.78c1.8-1.79,4.71-1.79,6.5,0,1.8,1.79,1.79,4.71,0,6.5l-13.78,13.78c-.9.9-2.08,1.35-3.25,1.35h0Z" />
                    <path class="cls-7"
                        d="m235.96,27.57c-2.06,0-3.93-1.39-4.46-3.48l-4.59-18.37c-.62-2.46.88-4.96,3.35-5.58,2.46-.61,4.96.88,5.58,3.35l4.59,18.37c.62,2.46-.88,4.96-3.35,5.58-.38.1-.75.14-1.12.14h0Z" />
                    <path class="cls-7"
                        d="m291.27,59.61h-18.39c-2.54,0-4.6-2.06-4.6-4.6s2.06-4.6,4.6-4.6h18.39c2.54,0,4.6,2.06,4.6,4.6s-2.06,4.6-4.6,4.6Z" />
                    <path class="cls-7"
                        d="m214.47,155.32c-1.18,0-2.35-.45-3.25-1.35l-67.59-67.59c-1.79-1.79-1.79-4.71,0-6.5,1.79-1.79,4.71-1.79,6.5,0l67.59,67.59c1.79,1.79,1.79,4.71,0,6.5-.9.9-2.07,1.35-3.25,1.35h0Z" />
                </g>
                <text class="cls-8" transform="translate(440.61 148.36)">
                    <tspan x="0" y="0">2.751</tspan>
                </text>
                <rect class="cls-14" x="391.22" y="31.92" width="358.34" height="162.07" rx="54.41" ry="54.41" />
                <text class="cls-3" transform="translate(814.74 103.43)">
                    <tspan x="0" y="0">Notas publicadas</tspan>
                    <tspan x="0" y="54.42">en los medios 2022</tspan>
                </text>
                <g>
                    <g>
                        <text class="cls-9" transform="translate(626.03 406.23)">
                            <tspan x="0" y="0">2.109</tspan>
                        </text>
                        <rect class="cls-13" x="586.29" y="297.08" width="335.93" height="151.93" rx="51" ry="51" />
                    </g>
                    <g>
                        <text class="cls-9" transform="translate(1139.78 406.23)">
                            <tspan x="0" y="0">642</tspan>
                        </text>
                        <rect class="cls-13" x="1043.68" y="297.08" width="335.93" height="151.93" rx="51" ry="51" />
                    </g>
                    <text class="cls-1" transform="translate(0 372.48)">
                        <tspan x="0" y="0">Notas </tspan>
                        <tspan class="cls-11" x="134.55" y="0">c</tspan>
                        <tspan x="157.18" y="0">on </tspan>
                        <tspan class="cls-11" x="224.56" y="0">c</tspan>
                        <tspan x="247.19" y="0">obe</tspan>
                        <tspan class="cls-20" x="330.08" y="0">r</tspan>
                        <tspan x="348.05" y="0">tu</tspan>
                        <tspan class="cls-10" x="393.21" y="0">r</tspan>
                        <tspan class="cls-6" x="409.69" y="0">a</tspan>
                        <tspan class="cls-18" x="0" y="51.01">r</tspan>
                        <tspan x="16.23" y="51.01">e</tspan>
                        <tspan class="cls-12" x="41.88" y="51.01">g</tspan>
                        <tspan x="70.19" y="51.01">ional y nacional 2022</tspan>
                    </text>
                    <text class="cls-4" transform="translate(949.69 392.83)">
                        <tspan x="0" y="0">VS.</tspan>
                    </text>
                    <text class="cls-2" transform="translate(652.94 517.16)">
                        <tspan x="0" y="0">REGIONAL</tspan>
                    </text>
                    <text class="cls-2" transform="translate(1108.83 517.16)">
                        <tspan x="0" y="0">N</tspan>
                        <tspan class="cls-19" x="32.93" y="0">A</tspan>
                        <tspan class="cls-17" x="62.81" y="0">CIONAL</tspan>
                    </text>
                </g>
                <line class="cls-15" x1="191.95" y1="642.48" x2="1205.92" y2="642.48" />
            </g>
        </g>
    </svg>
</template>

<style scoped>
.uuid-f8afd306-b809-4b52-9dfb-c424df96bab9 {
    fill: #737373;
}

.cls-1 {
    font-size: 51.2px;
}

.cls-1,
.cls-2,
.cls-3,
.cls-4,
.cls-5 {
    fill: #fff;
}

.cls-1,
.cls-3 {
    font-family: DINNextRoundedLTPro-Regular, 'DINNextRoundedLTPro Regular';
}

.cls-6 {
    letter-spacing: 0em;
}

.cls-7,
.cls-8,
.cls-9 {
    fill: #f6f6f6;
}

.cls-10 {
    letter-spacing: 0em;
}

.cls-2 {
    font-size: 50.05px;
}

.cls-2,
.cls-4 {
    font-family: DINNextRoundedLTPro-Medium, 'DINNextRoundedLTPro Medium';
    font-weight: 500;
}

.cls-11 {
    letter-spacing: 0em;
}

.cls-3 {
    font-size: 54.61px;
}

.cls-4 {
    font-size: 54.66px;
}

.cls-12 {
    letter-spacing: 0em;
}

.cls-13 {
    stroke-width: 15.94px;
}

.cls-13,
.cls-14,
.cls-15 {
    fill: none;
    stroke: #f6f6f6;
    stroke-miterlimit: 10;
}

.cls-16 {
    letter-spacing: 0em;
}

.cls-14 {
    stroke-width: 17px;
}

.cls-8,
.cls-9,
.cls-5 {
    font-family: DINNextRoundedLTPro-Bold, 'DINNextRoundedLTPro Bold';
    font-weight: 700;
}

.cls-8,
.cls-5 {
    font-size: 109.63px;
}

.cls-17 {
    letter-spacing: 0em;
}

.cls-18 {
    letter-spacing: 0em;
}

.cls-19 {
    letter-spacing: -.01em;
}

.cls-9 {
    font-size: 102.77px;
}

.cls-20 {
    letter-spacing: .02em;
}

.cls-21 {
    letter-spacing: -.02em;
}

.cls-22 {
    letter-spacing: 0em;
}

.cls-15 {
    stroke-linecap: round;
    stroke-width: 9px;
}
</style>