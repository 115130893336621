import { createRouter, createWebHistory } from "vue-router";

import OrganizacionComponent from "../components/OrganizacionComponent.vue";
import SostenibilidadTransparencia from "../components/Capitulo_Sostenibilidad_Transparencia/SostenibilidadTransparencia.vue"
import IncidenciaComponent from "../components/Capitulo_Incidencia/IncidenciaComponent.vue";
import InclusionComponent from "../components/Capitulo_Inclusion/InclusionComponent.vue"; 
import LandingComponent from "../components/LandingComponent.vue";
const routes = [
  {
    path: "/",
    name: "Landing",
    component: LandingComponent,
  },
  {
    path: "/Incidencia",
    name: "incidenciaPge",
    component: IncidenciaComponent,
  },
  {
    path: "/Sostenibilidad",
    name: "SostenibilidadPge",
    component: SostenibilidadTransparencia,
  },
  {
    path: "/Organizacion",
    name: "SOrganizaciondPge",
    component: OrganizacionComponent,
  },
  {
    path: "/inclusion",
    name: "inclusionPge",
    component: InclusionComponent,
  },

  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
