<template>
    <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1309.37 1635">

        <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
            <feOffset dx="7" dy="7" />
            <feGaussianBlur result="blur" stdDeviation="5" />
            <feFlood flood-color="#000" flood-opacity=".35" />
            <feComposite in2="blur" operator="in" />
            <feComposite in="SourceGraphic" />
        </filter>

        <g id="Capa_1-2" data-name="Capa 1">
            <g>
                <text class="cls-10" transform="translate(178.2 157.83)">
                    <tspan class="cls-5">
                        <tspan x="0" y="0">Estado de </tspan>
                        <tspan x="506.48" y="0">resultado</tspan>
                    </tspan>
                    <tspan class="cls-16">
                        <tspan x="-41.21" y="63.23">Nota: En miles de pesos</tspan>
                        <tspan x="409.45" y="63.23">, actualizado a diciembre 2022</tspan>
                    </tspan>
                </text>
                <line class="cls-24" x1="194.74" y1="3" x2="1180.5" y2="3" />
                <g class="cls-33">
                    <path class="cls-25"
                        d="m24.71,338.15h1283.56v26.3c0,13.03-10.58,23.6-23.6,23.6H1.11v-26.3c0-13.03,10.58-23.6,23.6-23.6Z" />
                    <path class="cls-14"
                        d="m45.9,388.05h1262.37v134.91c0,24.72-20.07,44.79-44.79,44.79H1.11v-134.91c0-24.72,20.07-44.79,44.79-44.79Z" />
                    <path class="cls-25"
                        d="m24.71,567.75h1283.56v26.3c0,13.03-10.58,23.6-23.6,23.6H1.11v-26.3c0-13.03,10.58-23.6,23.6-23.6Z" />
                    <path class="cls-25"
                        d="m24.71,753.92h1283.56v26.3c0,13.03-10.58,23.6-23.6,23.6H1.11v-26.3c0-13.03,10.58-23.6,23.6-23.6Z" />
                    <path class="cls-25"
                        d="m24.71,1155.14h1283.56v26.3c0,13.03-10.58,23.6-23.6,23.6H1.11v-26.3c0-13.03,10.58-23.6,23.6-23.6Z" />
                    <path class="cls-12"
                        d="m40.05,617.66h1268.22v96.91c0,21.49-17.45,38.94-38.94,38.94H1.11v-96.91c0-21.49,17.45-38.94,38.94-38.94Z" />
                    <path class="cls-14"
                        d="m34.27,803.82h1274v65.36c0,18.3-14.86,33.16-33.16,33.16H1.11v-65.36c0-18.3,14.86-33.16,33.16-33.16Z" />
                    <path class="cls-15"
                        d="m30.19,902.34h1278.08v46.67c0,16.05-13.03,29.08-29.08,29.08H1.11v-46.67c0-16.05,13.03-29.08,29.08-29.08Z" />
                    <path class="cls-15"
                        d="m30.19,1076.61h1278.08v46.67c0,16.05-13.03,29.08-29.08,29.08H1.11v-46.67c0-16.05,13.03-29.08,29.08-29.08Z" />
                    <path class="cls-14"
                        d="m34.27,978.5h1274v65.36c0,18.3-14.86,33.16-33.16,33.16H1.11v-65.36c0-18.3,14.86-33.16,33.16-33.16Z" />
                    <line class="cls-27" x1="1000.05" y1="338.15" x2="1000.05" y2="1207.83" />
                    <line class="cls-27" x1="708.46" y1="338.15" x2="708.46" y2="1207.83" />
                    <text class="cls-3" transform="translate(80.59 374.94)">
                        <tspan class="cls-49" x="0" y="0">C</tspan>
                        <tspan x="18.13" y="0">ONCEP</tspan>
                        <tspan class="cls-8" x="113.86" y="0">T</tspan>
                        <tspan x="129.11" y="0">O</tspan>
                    </text>
                    <text class="cls-3" transform="translate(825.07 374.94)">
                        <tspan x="0" y="0">2021</tspan>
                    </text>
                    <text class="cls-3" transform="translate(1121.36 374.94)">
                        <tspan x="0" y="0">2022</tspan>
                    </text>
                    <text class="cls-3" transform="translate(80.54 606.74)">
                        <tspan x="0" y="0">INGRESOS NETOS RECURRENTES</tspan>
                    </text>
                    <text class="cls-3" transform="translate(785.07 606.74)">
                        <tspan x="0" y="0">58.899.403</tspan>
                    </text>
                    <text class="cls-3" transform="translate(1081.37 606.74)">
                        <tspan x="0" y="0">52.279.450</tspan>
                    </text>
                    <text class="cls-3" transform="translate(80.54 791.28)">
                        <tspan class="cls-8" x="0" y="0">TOTAL GASTOS RECURRENTES</tspan>
                    </text>
                    <text class="cls-3" transform="translate(80.54 1194.27)">
                        <tspan x="0" y="0">RESU</tspan>
                        <tspan class="cls-58" x="70.39" y="0">L</tspan>
                        <tspan class="cls-38" x="82.85" y="0">T</tspan>
                        <tspan x="96.54" y="0">ADO DEL </tspan>
                        <tspan class="cls-48" x="226.97" y="0">E</tspan>
                        <tspan x="243.52" y="0">JE</tspan>
                        <tspan class="cls-18" x="271.48" y="0">R</tspan>
                        <tspan x="289.03" y="0">CICIO</tspan>
                    </text>
                    <text class="cls-3" transform="translate(775.86 791.28)">
                        <tspan x="0" y="0">(55.962.823)</tspan>
                    </text>
                    <text class="cls-3" transform="translate(805.07 1194.63)">
                        <tspan x="0" y="0">428.734</tspan>
                    </text>
                    <text class="cls-3" transform="translate(1103.51 1194.63)">
                        <tspan x="0" y="0">589.329 </tspan>
                    </text>
                    <text class="cls-3" transform="translate(1072.16 791.28)">
                        <tspan x="0" y="0">(51.652.492)</tspan>
                    </text>
                    <text class="cls-2" transform="translate(80.54 444.5)">
                        <tspan class="cls-36" x="0" y="0">Ingresos provenientes de Privados</tspan>
                        <tspan class="cls-36" x="0" y="46.62">Ingreso Fuentes Propias y Recuperaciones</tspan>
                        <tspan class="cls-36" x="0" y="93.24">Ingresos provenientes del Estado</tspan>
                    </text>
                    <text class="cls-2" transform="translate(786.02 444.5)">
                        <tspan x="0" y="0">28.463.010</tspan>
                        <tspan x="8.32" y="46.62">9.338.424</tspan>
                        <tspan x="0" y="93.24">21.097.969</tspan>
                    </text>
                    <text class="cls-2" transform="translate(1086.95 444.5)">
                        <tspan x="0" y="0">26.073.041</tspan>
                        <tspan x="8.32" y="46.62">7.481.810</tspan>
                        <tspan x="0" y="93.24">18.724.599</tspan>
                    </text>
                    <text class="cls-2" transform="translate(80.54 671.48)">
                        <tspan class="cls-32" x="0" y="0">Gastos de Atención Social</tspan>
                        <tspan class="cls-32" x="0" y="46.62">Gastos de Administración</tspan>
                    </text>
                    <text class="cls-1" transform="translate(80.54 865.52)">
                        <tspan class="cls-40" x="0" y="0">MARGEN RECURRENTE</tspan>
                    </text>
                    <text class="cls-2" transform="translate(80.54 951)">
                        <tspan class="cls-53" x="0" y="0">Provisiones y depreciaciones</tspan>
                    </text>
                    <text class="cls-1" transform="translate(80.54 1040.22)">
                        <tspan x="0" y="0" xml:space="preserve"> RESULTADO RECURRENTE</tspan>
                    </text>
                    <text class="cls-2" transform="translate(80.54 1125.55)">
                        <tspan class="cls-36" x="0" y="0">Ingresos/Gastos No Recurrentes</tspan>
                    </text>
                    <text class="cls-2" transform="translate(776.81 671.48)">
                        <tspan x="0" y="0">(48.698.834)</tspan>
                        <tspan x="8.32" y="46.62">(7.263.989)</tspan>
                    </text>
                    <text class="cls-1" transform="translate(794.34 865.52)">
                        <tspan x="0" y="0">2.936.580</tspan>
                    </text>
                    <text class="cls-2" transform="translate(785.13 957.01)">
                        <tspan x="0" y="0">(2.836.226)</tspan>
                    </text>
                    <text class="cls-1" transform="translate(806.02 1040.58)">
                        <tspan x="0" y="0">100.354</tspan>
                    </text>
                    <text class="cls-2" transform="translate(806.02 1125.55)">
                        <tspan x="0" y="0">328.380</tspan>
                    </text>
                    <text class="cls-1" transform="translate(1106.95 865.52)">
                        <tspan x="0" y="0">626.958</tspan>
                    </text>
                    <text class="cls-2" transform="translate(1086.06 957.01)">
                        <tspan x="0" y="0">(1.929.362)</tspan>
                    </text>
                    <text class="cls-1" transform="translate(1086.06 1040.58)">
                        <tspan x="0" y="0">(1.302.404)</tspan>
                    </text>
                    <text class="cls-2" transform="translate(1095.27 1125.55)">
                        <tspan x="0" y="0">1.891.733</tspan>
                    </text>
                    <text class="cls-2" transform="translate(1077.74 671.48)">
                        <tspan x="0" y="0">(44.469.086)</tspan>
                        <tspan x="8.32" y="46.62">(7.183.406)</tspan>
                    </text>
                    <line class="cls-26" x1="1000.05" y1="338.15" x2="1000.05" y2="388.05" />
                    <line class="cls-26" x1="708.46" y1="338.15" x2="708.46" y2="388.05" />
                    <line class="cls-26" x1="1000.05" y1="567.75" x2="1000.05" y2="617.66" />
                    <line class="cls-26" x1="708.46" y1="567.75" x2="708.46" y2="617.66" />
                    <line class="cls-26" x1="1000.05" y1="753.92" x2="1000.05" y2="803.82" />
                    <line class="cls-26" x1="708.46" y1="753.92" x2="708.46" y2="803.82" />
                    <line class="cls-26" x1="1000.05" y1="1155.14" x2="1000.05" y2="1205.04" />
                    <line class="cls-26" x1="708.46" y1="1155.14" x2="708.46" y2="1205.04" />
                </g>
                <text class="cls-11" transform="translate(284.1 1506.68)">
                    <tspan x="0" y="0">*Estados financieros en proceso de auditoria</tspan>
                    <tspan x="146.99" y="59.03">p</tspan>
                    <tspan class="cls-9" x="170.36" y="59.03">r</tspan>
                    <tspan class="cls-52" x="183.38" y="59.03">ó</tspan>
                    <tspan x="205.36" y="59.03">ximame</tspan>
                    <tspan class="cls-65" x="342.88" y="59.03">n</tspan>
                    <tspan class="cls-64" x="365.51" y="59.03">t</tspan>
                    <tspan class="cls-22" x="378.86" y="59.03">e a desca</tspan>
                    <tspan class="cls-60" x="534.86" y="59.03">r</tspan>
                    <tspan x="547.97" y="59.03">ga</tspan>
                    <tspan class="cls-17" x="590.73" y="59.03">r</tspan>
                    <tspan x="601.98" y="59.03">.</tspan>
                </text>

                <path class="cls-13" d="m217.5,1320.08h902.89v73.71c0,15.74-12.78,28.52-28.52,28.52H188.98v-73.71c0-15.74,12.78-28.52,28.52-28.52Z" />
                <a href="Fundación de Beneficencia Hogar de Cristo y filiales (emisión).pdf" id="descargarEstadoFinanciero" download="Fundación de Beneficencia Hogar de Cristo y filiales (emisión).pdf">
    <text class="cls-4" transform="translate(254.68 1391.76)">
        <tspan x="0" y="0">DESCARGAR</tspan>
        <tspan class="cls-7" x="318.82" y="0">ESTADO FINANCIERO</tspan>
    </text>
                </a>
                
                <g>
                    <path class="cls-10"
                        d="m1112.56,1386.28c.1,0,.2,0,.31,0h-.31Zm4.06,34.06c0,1.02,0,2.04,0,3.06,0-1.02,0-2.04,0-3.06Zm7.57,18.61s0,0,0,0c0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0Zm0,0h0s0,0,0,0c.03,0,.06,0,.09.01-.03,0-.06,0-.09-.01Zm3.71-15.01s-.01-.02-.02-.02c0,0,0,.01,0,.02-.09,0-.18,0-.28,0,.09,0,.18,0,.28,0,0,0,0,0,0,0h0s0,.02,0,.03c0-.01,0-.02,0-.03,0,0,0,0,.01,0Zm3.95,15.03s0,0,0,0c0,0,0,0,.01,0,0,0,0,0-.01,0Zm-.18.27s0,.08,0,.13c0-.04,0-.08,0-.13,0,0,0,0,0,0Zm-3.76-15.7s0,0,0,0c0-.83,0-1.66,0-2.49,0,.83,0,1.66,0,2.49Zm-.03.38s0,.01,0,.02h0s0,0,0,0h0s0,.02,0,.03c0-.01,0-.02,0-.03,0,0,0,0,.01,0,0,0-.01-.02-.02-.02Zm-3.7,15.03h0s.06,0,.09.01c-.03,0-.06,0-.09-.01h0Zm0,0s0,0,0,0c0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0Zm-7.57-15.55c0-1.02,0-2.04,0-3.06,0,1.02,0,2.04,0,3.06Zm0,.54s-.01-.02-.02-.03c0,0,0,.02,0,.03,0,0,0,0,0,0h0s0,0,0,0c0,0,0,.02,0,.03,0,0,0-.02,0-.03h0s.01,0,.02,0Zm-7.55,3.69h0s0,0,0,0c0,0,0,0,0,0h0s0,0,0,0h0Zm-3.79,3.79s0,0,0,0h0s0,0,0,0c0,0,0,0,0,0h0Z" />
                    <path class="cls-29"
                        d="m1139.23,1412.75c0,1.16,0,2.33,0,3.49,0,.05-.01.09-.02.17h-15.03v7.51c.09,0,.17.01.25.01.77,0,1.53,0,2.3,0,.21,0,.41,0,.62,0,.18,0,.37,0,.55.01,0,0,0,0,0,0h0s0,.04,0,.07c0,.03,0,.05,0,.07,0,.05,0,.09,0,.14,0,2.31,0,4.62,0,6.92v.28h3.5c.1.16.19.32.27.5,0,2.24,0,4.48,0,6.73,0,.02,0,.05,0,.07,0,.02,0,.05,0,.07h0c-.04.06-.08.11-.11.16-.01,0-.02,0-.03,0-.01,0-.02,0-.03,0,0,0-.01,0-.02,0,0,0-.01,0-.02,0,0,0-.02,0-.03,0-2.34,0-4.67,0-7.01,0-.02,0-.04,0-.06,0-.03,0-.06,0-.09-.01-.03,0-.06,0-.09-.01,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0h0s0-.02,0-.04c0-.02,0-.05,0-.07,0-.02,0-.04,0-.05,0-.04,0-.08,0-.12,0-2.3,0-4.6,0-6.9v-.29h-3.75v.21c-.07-.08-.13-.16-.2-.25h.16v-7.5c-.08,0-.14,0-.21,0-1.1,0-2.19,0-3.29,0-.08-.16-.17-.31-.26-.46v-.06c0-1.02,0-2.04,0-3.06,0-.06-.01-.12-.02-.18h-3.74c0,1.22,0,2.41,0,3.61-.04.05-.09.1-.13.16,0,0-.02,0-.03,0,0,0-.02,0-.02,0,0,0-.01,0-.02,0-.01,0-.02,0-.03,0-1.11,0-2.22,0-3.33,0-.06,0-.13,0-.21.01,0,.41,0,.81,0,1.21,0,.83-.01,1.66-.02,2.49,0,0,0,0,0,0h0s0,0,0,0c-.02,0-.05.02-.07.02-.01,0-.02,0-.04,0,0,0,0,0,0,0-.01,0-.02,0-.03,0-.69,0-1.37,0-2.06,0h-1.54v.3c0,1.06,0,2.12,0,3.19,0,.05,0,.11-.02.16,0,.03-.01.06-.02.09h0s0,0,0,0c-1.23,0-2.45,0-3.68,0,0,0-.01,0-.02-.02v-52.71h3.72s0,.08,0,.12c0,.06,0,.11,0,.17,0,1.07,0,2.14,0,3.21v.29h3.77v3.75c.85,0,1.66,0,2.48,0,.23,0,.46,0,.69.01.2,0,.41.01.61.02v3.73h3.73s0,.08,0,.12c0,.06,0,.11,0,.17,0,1.07,0,2.15,0,3.22v.28h3.77v3.73h3.79c0,1.24,0,2.48,0,3.72,0,.01.01.03.03.06h3.7s0,.03,0,.05c0,.01,0,.03,0,.04,0,.02,0,.03,0,.05,0,.05,0,.1,0,.14,0,1.08,0,2.16,0,3.24,0,.19.05.25.25.25.62-.01,1.24,0,1.86,0,.55,0,1.11,0,1.66.02v3.73h3.79c0,1.24,0,2.48,0,3.72,0,.01.01.03.04.06h3.7s.01.08.01.11Z" />
                    <polygon points="1105.27 1431.43 1105.27 1431.43 1105.27 1431.43 1105.28 1431.43 1105.27 1431.43" />
                    <path
                        d="m1105.31,1435.1s0,.06-.02.13h-.33c-2.3,0-4.6,0-6.89,0-.1,0-.2,0-.29.01v-64.05h3.78v3.76h3.74v3.76h-3.73v52.71s.01.02.02.02c1.23,0,2.45,0,3.68,0,.01.05.03.09.03.14,0,.01,0,.03,0,.04,0,1.16,0,2.32,0,3.48Z" />
                    <polygon points="1127.89 1423.95 1127.89 1423.95 1127.89 1423.95 1127.89 1423.95" />
                    <path
                        d="m1143,1412.63v7.52c-1.56,0-3.11,0-4.67,0-2.7,0-5.41,0-8.11,0-.68,0-1.36,0-2.04,0h-.26v.9c0,.83,0,1.66,0,2.49,0,.04,0,.08,0,.11,0,.09-.02.18-.03.26,0,0,0,.01,0,.02h0c-.09,0-.18,0-.28,0-.09,0-.18,0-.28,0-.21,0-.41,0-.62,0-.77,0-1.53,0-2.3,0-.08,0-.16,0-.25-.01v-7.51h15.03c.01-.08.02-.13.02-.17,0-1.16,0-2.33,0-3.49,0-.04,0-.08-.01-.11,0,0,0,0,0,0h0c1.26,0,2.52,0,3.78,0Z" />
                    <polygon points="1116.59 1423.95 1116.59 1423.95 1116.59 1423.95 1116.59 1423.95 1116.59 1423.95" />
                    <path
                        d="m1120.37,1423.94v7.5h-3.76v-.28c0-2.31,0-4.62,0-6.92,0-.05,0-.09,0-.14,0,0,0-.02,0-.03,0-.03,0-.06,0-.09,0,0,0-.02,0-.03h0s.01,0,.02,0c.07,0,.15-.02.22-.02h.05c1.1,0,2.19,0,3.29,0,.06,0,.13,0,.21,0Z" />
                    <polygon points="1131.67 1438.95 1131.67 1438.95 1131.67 1438.95 1131.67 1438.95" />
                    <path
                        d="m1135.45,1431.47v7.5c-.07,0-.14.02-.2.02-1.12,0-2.24,0-3.36,0,0,0,0,0-.01,0,0,0,0,0-.01,0-.06,0-.12-.03-.18-.04,0-.05,0-.09.01-.14h0s0-.05,0-.07c0-.02,0-.05,0-.07,0-2.24,0-4.48,0-6.73v-.46h3.75Z" />
                    <polygon points="1124.2 1438.95 1124.19 1438.96 1124.19 1438.96 1124.19 1438.96 1124.2 1438.95" />
                    <polygon points="1131.68 1438.95 1131.67 1438.95 1131.67 1438.95 1131.67 1438.95 1131.68 1438.95" />
                    <path
                        d="m1131.69,1442.74h-7.52c0-.06-.01-.13-.01-.19,0-1.07,0-2.14,0-3.21v-.15c0-.08.02-.16.03-.23h0s.06,0,.09.01c.03,0,.06,0,.09.01.02,0,.04,0,.06,0,2.34,0,4.67,0,7.01,0,0,0,.02,0,.03,0,0,0,.01,0,.02,0,0,0,.01,0,.02,0,.01,0,.02,0,.03,0,.01,0,.02,0,.03,0,.03,0,.07-.01.1-.02,0,.1,0,.2.01.3,0,.04,0,.08,0,.13,0,.04,0,.08,0,.13,0,.03,0,.06,0,.08,0,.02,0,.04,0,.07,0,.03,0,.07,0,.1,0,.99,0,1.98,0,2.99Z" />
                    <path
                        d="m1124.19,1438.95h0c-.06.02-.12.04-.19.04h-.09c-1.13,0-2.27,0-3.4,0-.03,0-.06,0-.1-.01v-7.5h3.75v.29c0,2.3,0,4.6,0,6.9,0,.04,0,.08,0,.12,0,.02,0,.04,0,.05,0,.02,0,.05,0,.07,0,.01,0,.02,0,.04h0Z" />
                    <polygon
                        points="1124.2 1438.95 1124.19 1438.96 1124.19 1438.96 1124.19 1438.96 1124.19 1438.96 1124.19 1438.96 1124.2 1438.95" />
                    <polygon points="1127.89 1423.95 1127.89 1423.95 1127.89 1423.95 1127.89 1423.95 1127.89 1423.95" />
                    <path
                        d="m1131.66,1423.93v7.51h-3.74v-.28c0-2.31,0-4.62,0-6.92,0-.05,0-.09,0-.14,0-.03,0-.05,0-.07,0-.01,0-.02,0-.03,0-.01,0-.02,0-.03h0s0,0,.01,0c.1,0,.19,0,.29,0,.07,0,.14,0,.22,0,.07,0,.14,0,.22,0,.14,0,.29,0,.43,0,.87,0,1.73,0,2.6,0Z" />
                    <path
                        d="m1109.09,1378.72v3.78h-3.78v-.29c0-1.07,0-2.14,0-3.21,0-.06,0-.11,0-.17,0-.04,0-.08,0-.12,0,0,0,0,0,0h0c.71,0,1.43,0,2.14.01.54,0,1.07,0,1.64,0Z" />
                    <path
                        d="m1120.39,1390.02v3.79h-3.78v-.28c0-1.07,0-2.15,0-3.22,0-.06,0-.11,0-.17,0-.04,0-.08,0-.12,0,0,0,0,0,0h3.79Z" />
                    <path
                        d="m1131.7,1401.32v3.79h0c-.55,0-1.11-.01-1.66-.02-.62,0-1.24,0-1.86,0-.19,0-.25-.05-.25-.25,0-1.08,0-2.16,0-3.24,0-.05,0-.09,0-.14,0-.02,0-.03,0-.05,0-.01,0-.03,0-.04,0-.02,0-.03,0-.05,0,0,0,0,0,0h3.79Z" />
                    <polygon points="1105.28 1431.43 1105.28 1431.43 1105.27 1431.43 1105.28 1431.43" />
                    <rect x="1109.06" y="1427.64" width="0" height="0" />
                    <path
                        d="m1109.08,1431.17v.29c-.84,0-1.65,0-2.47,0-.45,0-.9-.01-1.34-.02,0-.03,0-.06.02-.09,0-.05.02-.11.02-.16,0-1.06,0-2.12,0-3.19v-.3h1.54c.69,0,1.37,0,2.06,0,.01,0,.02,0,.03,0,0,0,0,0,0,0,.01,0,.02,0,.04,0,.02,0,.05-.02.07-.02,0,.07,0,.13,0,.2,0,.07,0,.13,0,.2,0,.08,0,.16,0,.24,0,.07,0,.14,0,.21,0,.89,0,1.78,0,2.67Z" />
                    <path
                        d="m1112.87,1386.29h0c-.2,0-.41-.01-.61-.02-.23,0-.46-.01-.69-.01-.81,0-1.63,0-2.48,0v-3.76h3.78v.29c0,1.06,0,2.13,0,3.19,0,.1,0,.21.01.31,0,0,0,0,0,0Z" />
                    <polygon points="1109.07 1427.64 1109.07 1427.65 1109.06 1427.64 1109.07 1427.64" />
                    <path
                        d="m1112.89,1423.96c0,.07-.02.14-.03.2,0,0,0,.01,0,.02,0,.01,0,.02,0,.03,0,1.06,0,2.11,0,3.17v.31c-.32,0-.63,0-.93,0-.85,0-1.69,0-2.54,0-.03,0-.05,0-.08,0-.02,0-.05,0-.07-.01-.06-.01-.11-.02-.17-.03,0-.83.01-1.66.02-2.49,0-.4,0-.8,0-1.21.08,0,.15-.01.21-.01,1.11,0,2.22,0,3.33,0,.01,0,.02,0,.03,0,0,0,.01,0,.02,0,0,0,.02,0,.02,0,0,0,.02,0,.03,0,.05,0,.11.01.16.02Z" />
                    <polygon points="1112.89 1423.96 1112.89 1423.96 1112.89 1423.95 1112.89 1423.96" />
                    <path d="m1124.18,1397.54h-3.79v-3.74h3.78v.28c0,1.06,0,2.13,0,3.19,0,.09,0,.18.01.26,0,0,0,0,0,0Z" />
                    <path
                        d="m1127.91,1397.55v3.78h-3.71s-.03-.05-.03-.06c0-1.24,0-2.48,0-3.72h0s0,0,0,0c1.07,0,2.14,0,3.21.01.17,0,.34,0,.53,0Z" />
                    <path
                        d="m1135.48,1408.85h-3.79v-3.74h0c1.22,0,2.45,0,3.67,0,.02,0,.05.01.1.03v.26c0,1.06,0,2.13,0,3.19,0,.09,0,.18.01.26,0,0,0,0,0,0Z" />
                    <path
                        d="m1139.22,1408.86v3.78h-3.71s-.04-.05-.04-.06c0-1.24,0-2.48,0-3.72h0s0,0,0,0c.8,0,1.6.01,2.4.01.44,0,.88,0,1.34,0Z" />
                    <polygon points="1112.89 1423.95 1112.89 1423.95 1112.89 1423.96 1112.89 1423.95 1112.89 1423.95" />
                    <polygon points="1116.59 1423.95 1116.59 1423.95 1116.59 1423.95 1116.59 1423.95" />
                    <path
                        d="m1116.62,1423.41v.06c0,.08,0,.16,0,.24s-.02.16-.03.24c-.44,0-.87-.02-1.31-.02-.19,0-.37,0-.56,0-.19,0-.37,0-.56,0-.37,0-.75,0-1.12.01-.05,0-.09,0-.14,0-.01-.04-.03-.07-.03-.11v-.06c0-1.2,0-2.39,0-3.61h3.74c0,.06.02.12.02.18,0,1.02,0,2.04,0,3.06Z" />
                    <path
                        d="m1116.61,1386.3c0,1.24,0,2.48,0,3.71h0s-3.74,0-3.74,0v-3.74s0,0,0,0c.23,0,.46-.02.69-.02,1.01,0,2.02,0,3.02,0,.01.02.03.03.03.04Z" />
                </g>
            </g>
        </g>
    </svg>
</template>

<style scoped>
#descargarEstadoFinanciero {
        text-decoration: none; /* Quitar subrayado */
        color: inherit; /* Utilizar el color por defecto del texto */
    }
.cls-1,
.cls-2 {
    fill: #606060;
}

.cls-1,
.cls-2,
.cls-3 {
    font-size: 32.44px;
}

.cls-1,
.cls-3,
.cls-4,
.cls-5 {
    font-family: DINNextRoundedLTPro-Bold, 'DINNextRoundedLTPro Bold';
    font-weight: 700;
}

.cls-6 {
    letter-spacing: 0em;
}

.cls-7 {
    letter-spacing: 0em;
}

.cls-8 {
    letter-spacing: -.03em;
}

.cls-9 {
    letter-spacing: 0em;
}

.cls-10,
.cls-11,
.cls-3,
.cls-12,
.cls-13,
.cls-14,
.cls-15 {
    fill: #fff;
}

.cls-11 {
    font-size: 41.08px;
}

.cls-11,
.cls-2,
.cls-16 {
    font-family: DINNextRoundedLTPro-Regular, 'DINNextRoundedLTPro Regular';
}

.cls-17 {
    letter-spacing: -.05em;
}

.cls-18 {
    letter-spacing: 0em;
}

.cls-19 {
    letter-spacing: 0em;
}

.cls-20 {
    letter-spacing: 0em;
}

.cls-21 {
    letter-spacing: -.08em;
}

.cls-22 {
    letter-spacing: 0em;
}

.cls-23 {
    letter-spacing: 0em;
}

.cls-24 {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 6px;
}

.cls-24,
.cls-25,
.cls-26 {
    stroke: #fff;
}

.cls-24,
.cls-27,
.cls-26 {
    fill: none;
}

.cls-28 {
    letter-spacing: 0em;
}

.cls-25,
.cls-12,
.cls-13,
.cls-27,
.cls-14,
.cls-26,
.cls-15 {
    stroke-miterlimit: 10;
}

.cls-25,
.cls-4,
.cls-29 {
    fill: #ffcb00;
}

.cls-25,
.cls-27,
.cls-14,
.cls-26 {
    stroke-width: 2.21px;
}

.cls-30 {
    letter-spacing: 0em;
}

.cls-12 {
    stroke-width: 1.92px;
}

.cls-12,
.cls-13,
.cls-27,
.cls-14,
.cls-15 {
    stroke: #ffcb00;
}

.cls-31 {
    letter-spacing: .01em;
}

.cls-32 {
    letter-spacing: 0em;
}

.cls-33 {
    filter: url(#drop-shadow-1);
}

.cls-34 {
    letter-spacing: 0em;
}

.cls-35 {
    letter-spacing: 0em;
}

.cls-36 {
    letter-spacing: 0em;
}

.cls-37 {
    letter-spacing: -.07em;
}

.cls-38 {
    letter-spacing: -.07em;
}

.cls-16 {
    font-size: 44px;
}

.cls-39 {
    letter-spacing: 0em;
}

.cls-40 {
    letter-spacing: 0em;
}

.cls-41 {
    letter-spacing: 0em;
}

.cls-13 {
    stroke-width: 1.9px;
}

.cls-42 {
    letter-spacing: 0em;
}

.cls-43 {
    letter-spacing: 0em;
}

.cls-4 {
    font-size: 56.71px;
}

.cls-44 {
    letter-spacing: 0em;
}

.cls-45 {
    letter-spacing: -.02em;
}

.cls-46 {
    letter-spacing: 0em;
}

.cls-47 {
    letter-spacing: 0em;
}

.cls-48 {
    letter-spacing: .02em;
}

.cls-49 {
    letter-spacing: -.02em;
}

.cls-50 {
    letter-spacing: 0em;
}

.cls-51 {
    letter-spacing: -.02em;
}

.cls-5 {
    font-size: 109.63px;
}

.cls-52 {
    letter-spacing: -.01em;
}

.cls-53 {
    letter-spacing: -.02em;
}

.cls-54 {
    letter-spacing: 0em;
}

.cls-55 {
    letter-spacing: 0em;
}

.cls-56 {
    letter-spacing: -.01em;
}

.cls-57 {
    letter-spacing: 0em;
}

.cls-58 {
    letter-spacing: -.09em;
}

.cls-59 {
    letter-spacing: .02em;
}

.cls-60 {
    letter-spacing: 0em;
}

.cls-61 {
    letter-spacing: -.01em;
}

.cls-62 {
    letter-spacing: 0em;
}

.cls-15 {
    stroke-width: 1.94px;
}

.cls-63 {
    letter-spacing: -.03em;
}

.cls-64 {
    letter-spacing: 0em;
}

.cls-65 {
    letter-spacing: 0em;
}

.cls-66 {
    letter-spacing: 0em;
}</style>