<template>
    <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 815 134">
        <g id="Capa_2" data-name="Capa 2">
            <text class="cls-1" transform="translate(0 94)">
                <tspan x="0" y="0">MEMORIA 2022</tspan>
            </text>
            <g>
                <line class="cls-3-c-4" x1="15" y1="110.33" x2="135.32" y2="110.33" />
                <line class="cls-3-c-4" x1="15" y1="110.33" x2="135.32" y2="110.33" />
            </g>
        </g>
    </svg>
</template>


<style scoped>
.cls-1 {
    fill: #fff;
    font-family: DINNextRoundedLTPro-Medium, 'DINNextRoundedLTPro Medium';
    font-size: 112.3px;
    font-weight: 500;
    letter-spacing: .05em;
}


.cls-3-c-4 {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-width: 8.31px;
}
</style>