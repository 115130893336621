<template>
    <div class="p-2 card-landing col text-center" :style="style_div_card">
        <div class="row">
            <slot name="titulo"></slot>
        </div>
        <div class="row">
            <slot name="contenido"></slot>
        </div>
        <div class="row">
            <slot name="bottom"></slot>
        </div>
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
    minHeight: String,
    minWidth: String,
    maxWidth: {
        type: String,
        default: '400px',
    },
    backgroundColor: {
        type: String,
        default: '#FFF'
    },
    maxHeight: {
        type: String,
        default: '400px',
    },
    borderRadius: String,
});

// apply style
const style_div_card = computed(() => {
    return {
        minHeight: props.minHeight,
        minWidth: props.minWidth,
        maxWidth: props.maxWidth,
        maxHeight: props.maxHeight,
        borderRadius: props.borderRadius,
        backgroundColor: 'rgba(255, 255, 255, 0.815)',
        // backgroundColor: props.backgroundColor,
    }
})

</script>

<style scoped>
.card-landing {
    transition: transform 250ms;
}

.card-landing:hover {
    cursor: pointer;
    transform: translateY(-10px);
}


</style>