<template>
    <svg id="Capa_2" class="mb-2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="-110 -40 394.45 234.59">

        <g id="Capa_1-2" data-name="Capa 1">
            <g>
                <path class="cls-1"
                    d="m20.88,184.01c-3.21,0-6.61-.57-9.74-2.21C3.75,177.94.01,172.48.01,165.57V48.73c0-2.24,1.82-4.06,4.06-4.06h0c2.24,0,4.06,1.82,4.06,4.06v116.85c0,1.64,0,5.49,6.77,9.04,5.27,2.75,13.17.4,16.05-.84l38.2-23.15c1.92-1.16,4.41-.55,5.57,1.37,1.16,1.92.55,4.41-1.37,5.57l-38.42,23.28c-.13.08-.27.15-.41.22-.37.17-6.55,2.94-13.64,2.94Z" />
                <path class="cls-1"
                    d="m23.49,60.32c-3.95,0-8.31-.53-12.95-1.87-5.77-1.67-9.1-4.13-10.18-7.53-1.69-5.3,3.06-9.2,3.61-9.63.15-.12.3-.22.47-.31L74.29.55c1.94-1.12,4.42-.46,5.54,1.48,1.12,1.94.46,4.42-1.48,5.54L8.84,47.81c-.24.23-.48.53-.61.76.37.36,1.51,1.21,4.57,2.09,12.79,3.7,22.67-.22,25.12-1.89,4.14-2.82,65.22-38.92,67.82-40.46,1.25-.74,2.81-.75,4.08-.03,1.27.72,2.05,2.07,2.05,3.52v23.67c0,2.24-1.82,4.06-4.06,4.06s-4.06-1.82-4.06-4.06v-16.55c-20.43,12.09-58.33,34.57-61.27,36.56-3.04,2.06-9.92,4.84-18.99,4.84Z" />
                <path class="cls-1"
                    d="m92.2,18.87c-10.31,0-16.95-1.98-20.19-5.98-2.23-2.76-2.31-5.93-1.7-7.8.7-2.13,2.99-3.29,5.12-2.59,2.04.67,3.19,2.8,2.67,4.85,0,.08.06.3.41.64,1.04,1,5.91,4.12,24.95,2.09,2.23-.23,4.23,1.38,4.47,3.6.24,2.23-1.38,4.23-3.6,4.47-4.49.48-8.53.72-12.13.72Zm-14.16-11.28s0,.02-.01.03c0-.01,0-.02.01-.03Z" />
                <path class="cls-1"
                    d="m88.06,194.75c-3.21,0-6.61-.57-9.75-2.21-7.38-3.86-11.13-9.32-11.13-16.22V59.86c-.27-4.35,3.48-7.43,3.96-7.81.15-.12.3-.22.47-.31L141.46,11.3c1.94-1.12,4.42-.46,5.54,1.48,1.12,1.94.46,4.42-1.48,5.54l-69.52,40.24c-.24.23-.48.53-.61.75.37.36,1.51,1.2,4.57,2.09,12.79,3.7,22.67-.22,25.12-1.89,4.15-2.82,65.23-38.92,67.82-40.46,1.25-.74,2.81-.75,4.08-.03,1.27.72,2.05,2.07,2.05,3.52v120.76c0,1.43-.75,2.75-1.98,3.48l-74.97,44.81c-.13.08-.26.15-.4.21-.37.17-6.54,2.94-13.63,2.94Zm-12.75-126.35v107.92c0,1.64,0,5.49,6.77,9.03,5.27,2.76,13.19.4,16.06-.84l72.79-43.5V29.67c-20.43,12.09-58.33,34.57-61.27,36.56-4.36,2.96-16.64,7.4-31.94,2.97-.86-.25-1.66-.51-2.41-.8Z" />
                <path class="cls-1"
                    d="m159.37,29.63c-10.3,0-16.94-1.98-20.18-5.98-2.24-2.77-2.31-5.93-1.7-7.8.7-2.13,2.99-3.29,5.12-2.59,2.04.67,3.19,2.8,2.67,4.85,0,.08.06.31.41.64,1.04,1,5.91,4.12,24.95,2.09,2.23-.24,4.23,1.37,4.47,3.6.24,2.23-1.37,4.23-3.6,4.47-4.5.48-8.54.72-12.14.72Zm-14.16-11.26s0,0,0,.01c0,0,0,0,0-.01Zm0,0s0,0,0,0c0,0,0,0,0,0Zm.07-.29s0,0,0,0c0,0,0,0,0,0Z" />
                <path class="cls-2"
                    d="m103.9,174.67c-2.24,0-4.06-1.82-4.06-4.06v-91.15c0-2.24,1.82-4.06,4.06-4.06s4.06,1.82,4.06,4.06v91.15c0,2.24-1.82,4.06-4.06,4.06Z" />
                <path class="cls-2"
                    d="m37.66,165.84c-2.24,0-4.06-1.82-4.06-4.06v-88.97c0-2.24,1.82-4.06,4.06-4.06s4.06,1.82,4.06,4.06v88.97c0,2.24-1.82,4.06-4.06,4.06Z" />
            </g>
        </g>
    </svg>
</template>

<style scoped>
.cls-1 {
    fill: #737373;
}

.cls-2 {
    fill: #ffcb00;
}</style>