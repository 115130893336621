<template>
    <svg class="mb-2" id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="-90 0 394.45 204.59">
        <g id="Capa_1-2" data-name="Capa 1">
            <g>
                <path class="cls-1"
                    d="m20.09,40.59h-4.75c-4.07,0-7.97,1.61-10.85,4.49-2.88,2.88-4.49,6.78-4.49,10.85v133.31c0,4.07,1.61,7.97,4.49,10.85,2.88,2.88,6.78,4.5,10.85,4.5h173.77c4.07,0,7.97-1.62,10.85-4.5,2.88-2.88,4.49-6.78,4.49-10.85V55.93c0-4.07-1.61-7.97-4.49-10.85-2.88-2.88-6.78-4.49-10.85-4.49H52.5l92.03-30.62c2.68-.89,4.13-3.79,3.24-6.47-.89-2.68-3.79-4.13-6.47-3.24L20.1,40.59h0Zm.73,10.23h-5.48c-1.36,0-2.66.54-3.62,1.5-.96.96-1.5,2.26-1.5,3.62v133.31c0,1.35.54,2.66,1.5,3.62.96.96,2.26,1.5,3.62,1.5h173.77c1.36,0,2.66-.54,3.62-1.5.96-.96,1.5-2.26,1.5-3.62V55.93c0-1.36-.54-2.66-1.5-3.62s-2.26-1.5-3.62-1.5H21.03c-.07,0-.13,0-.2,0h0Zm163.38,16.04c0-2.82-2.29-5.11-5.12-5.11H25.36c-2.82,0-5.11,2.29-5.11,5.11v40.92c0,2.83,2.29,5.12,5.11,5.12h153.73c2.83,0,5.12-2.29,5.12-5.12v-40.92Zm-87.01,5.12v30.69h10.06v-11.54c0-2.82,2.29-5.11,5.11-5.11s5.12,2.29,5.12,5.11v11.54h10.25v-11.54c0-2.82,2.29-5.11,5.12-5.11s5.11,2.29,5.11,5.11v11.54h10.37v-11.54c0-2.82,2.29-5.11,5.11-5.11s5.11,2.29,5.11,5.11v11.54h15.41v-30.69h-76.78Zm-10.23,0H30.48v30.69h15.41v-11.54c0-2.82,2.29-5.11,5.11-5.11s5.12,2.29,5.12,5.11v11.54h10.25v-11.54c0-2.82,2.29-5.11,5.12-5.11s5.11,2.29,5.11,5.11v11.54h10.37v-30.69Z" />
                <path class="cls-2"
                    d="m49.19,122.97c-16.92,0-30.66,13.8-30.66,30.82s13.75,30.82,30.66,30.82,30.66-13.8,30.66-30.82-13.75-30.82-30.66-30.82Zm0,51.41c-11.29,0-20.43-9.23-20.43-20.59,0-9.58,6.51-17.65,15.32-19.94v9.72c0,2.82,2.29,5.11,5.11,5.11s5.11-2.29,5.11-5.11v-9.72c8.81,2.29,15.32,10.36,15.32,19.94,0,11.36-9.15,20.59-20.43,20.59Z" />
                <g>
                    <path class="cls-2"
                        d="m158.66,148.55h-30.75c-2.82,0-5.11,2.29-5.11,5.11s2.29,5.12,5.11,5.12h30.75c2.82,0,5.11-2.29,5.11-5.12s-2.29-5.11-5.11-5.11Z" />
                    <path class="cls-2"
                        d="m179.09,136.93h-4.89v-3.72c0-2.83-2.29-5.12-5.11-5.12h-51.61c-2.82,0-5.11,2.29-5.11,5.12v3.72h-4.89c-2.83,0-5.12,2.29-5.12,5.11v23.24c0,2.83,2.29,5.12,5.12,5.12h4.89v3.72c0,2.83,2.29,5.12,5.11,5.12h51.61c2.82,0,5.11-2.29,5.11-5.12v-3.72h4.89c2.83,0,5.12-2.29,5.12-5.12v-23.24c0-2.82-2.29-5.11-5.12-5.11Zm-5.11,23.24h-4.89c-2.83,0-5.12,2.29-5.12,5.11v3.73h-41.38v-3.73c0-2.82-2.29-5.11-5.11-5.11h-4.89v-13.01h4.89c2.83,0,5.11-2.29,5.11-5.12v-3.72h41.38v3.72c0,2.83,2.29,5.12,5.12,5.12h4.89v13.01Z" />
                </g>
            </g>
        </g>
    </svg>
</template>

<style scoped>
.cls-1 {
    fill: #737373;
}

.cls-1,
.cls-2 {
    fill-rule: evenodd;
}

.cls-2 {
    fill: #ffcb00;
}</style>