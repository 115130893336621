<template>
  <div class="container-fluid p-4">
    <div class="row justify-content-center">
      <div class="col-lg-10 col-md-12">
        <div class="texto-estrategia">
          En Hogar de Cristo contribuimos a la inclusión de las personas en pobreza y vulnerabilidad y al fortalecimiento del tejido social de sus comunidades a través del desarrollo e implementación de programas de calidad.
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
.texto-estrategia {
  font-size: 2.5rem; /* Es similar a 36pt, pero es una unidad relativa. */
  color: #fff;
  text-align: justify;
  margin-top: 20px;
  font-family: DINNextRoundedLTPro-Regular;
}

/* Ajustes para pantallas más pequeñas */
@media (max-width: 768px) {
  .texto-estrategia {
    font-size: 2rem;
  }
}

@media (max-width: 576px) {
  .texto-estrategia {
    font-size: 1.5rem;
  }
}
</style>
