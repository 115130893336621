<template>
    <div class="px-4">
        <div class="row">
            <div class="col-12">
                <h4 class="text-center textoAmarillo roundBordeDerechoAbajo bg-white p-3 my-4 fs-2">Crédito del BID Invest</h4>
            </div>
            <div class="cls-9 font-dinn-regular text-white">
                Con este préstamo senior de US $10 millones, Fondo Esperanza incrementará el financiamiento a
                microemprendedoras chilenas de sectores vulnerables y desarrollo de negocios para migrantes.
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12">
                <h4 class="text-center textoAmarillo roundBordeDerechoAbajo bg-white p-3 my-4 fs-2">Pepsico y la Escuela del Emprendimiento Digital</h4>
            </div>
            <div class="cls-9 font-dinn-regular text-white">
                La donación de USD90.000 de PepsiCo Foundation financió gran parte de la nueva Ruta Emprendedora de la
                Escuela de emprendimiento digital.
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12">
                <h4 class="text-center textoAmarillo roundBordeDerechoAbajo bg-white p-3 my-4 fs-2">Certificación de CAF América</h4>
            </div>
            <div class="cls-9 font-dinn-regular text-white">
                CAF América certificó a Fondo Esperanza validando ser una organización estructurada y que opera como una
                entidad sin fines de lucro y que los fondos que recibe como donación se utilizan exclusivamente con
                fines benéficos.
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12">
                <h4 class="text-center textoAmarillo roundBordeDerechoAbajo bg-white p-3 my-4 fs-2">Clasificación Feller Rate</h4>
            </div>
            <div class="cls-9 font-dinn-regular text-white">
                La Clasificadora de Riesgos, Feller Rate, volvió a confirmar la solvencia de Fondo Esperanza y le asignó
                la clasificación BBB y perspectivas estables.
            </div>
        </div>
    </div>
</template>

<style scoped>
.roundBordeDerechoAbajo {
    border-radius: 45px 0 45px 0;
}

.textoAmarillo {
	color: #ffcb00;
}
.cls-62 {
    fill: #ffcb00;
    font-family: DINNextRoundedLTPro-Bold, 'DINNextRoundedLTPro Bold';
    font-weight: 700;
}

.cls-9 {
    font-family: DINNextRoundedLTPro-Regular, 'DINNextRoundedLTPro Regular';
    font-size: 25px;
}
</style>