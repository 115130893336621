<template>
  <div class="text-center p-4">
    <text class="titulo-svg">
      <span class="titulo-svg alt">Nuestras</span> 
      <span class="titulo-svg">
        Principales cifras
      </span>
    </text>
  </div>
  <div class="svg-container">
  <svg id="uuid-c7928a87-c933-45ab-a6b9-cb152440517e" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1557.65 387.97">
    <g id="uuid-fda323aa-20f2-480c-8ec6-2102195a8711" data-name="Capa 3">
      <g>
        <text class="uuid-2d1f96d9-2554-4b23-a9ac-483fe78f8fb9 text-cifras" transform="translate(.07 60.06)"><tspan class="uuid-114a6baf-f4cf-452a-a9b3-c0124c6daffd" x="60" y="0">Personas atendidas</tspan><tspan x="100.46" y="86.11">en el año 2022</tspan></text>
        <text class="uuid-2d1f96d9-2554-4b23-a9ac-483fe78f8fb9 text-cifras" transform="translate(864.9 60.06)"><tspan class="uuid-114a6baf-f4cf-452a-a9b3-c0124c6daffd" x="55" y="0">Número de programas</tspan><tspan x="110.59" y="86.11">en el año 2022</tspan></text>
        <path class="uuid-7e1af63c-ae98-4f0c-a4ce-fef51bd3fc3e" d="m498.99,219.54H127.78c-30.82,0-55.89,24.99-55.89,55.7v57.02c0,30.72,25.07,55.7,55.89,55.7h371.22c30.82,0,55.89-24.99,55.89-55.7v-57.02c0-30.72-25.07-55.7-55.89-55.7Zm-371.22,14.95h371.22c22.55,0,40.89,18.28,40.89,40.75v57.02s0,.03,0,.04c-30.53,5.27-63.53,8.27-98.07,7.22-129.28-3.92-175.46-61.3-295.83-61.16-16.95.02-36.69,1.18-59.1,4.64v-7.77c0-22.47,18.34-40.75,40.89-40.75Z"/>
        <path class="uuid-7e1af63c-ae98-4f0c-a4ce-fef51bd3fc3e" d="m1398.01,219.54h-242.6c-26.3,0-47.7,21.33-47.7,47.54v73.35c0,26.21,21.4,47.54,47.7,47.54h242.6c26.3,0,47.7-21.33,47.7-47.54v-73.35c0-26.21-21.4-47.54-47.7-47.54Zm-242.6,14.95h242.6c18.03,0,32.7,14.62,32.7,32.59v68.93c-19.64,5-40.85,7.96-63.19,7.42-89.66-2.15-124.95-58.77-208.21-56.99-10.69.23-22.93,1.44-36.6,4.5v-23.85c0-17.97,14.67-32.59,32.7-32.59Z"/>
        <text class="uuid-8ede862e-ae91-470d-809d-b484cb9e13ff" transform="translate(136.08 345.24)"><tspan x="0" y="0">43.914</tspan></text>
        <text class="uuid-6c393c5b-c8e9-4070-8067-abc135734fb3" transform="translate(1175.53 348.23)"><tspan x="0" y="0">258</tspan></text>
      </g>
    </g>
  </svg>
  </div>  
</template>

<script>
export default {
  mounted() {
    const number1 = document.querySelector(".uuid-8ede862e-ae91-470d-809d-b484cb9e13ff").querySelector("tspan");
    const number2 = document.querySelector(".uuid-6c393c5b-c8e9-4070-8067-abc135734fb3").querySelector("tspan");

    const numberWithCommas = x => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    const animateNumbers = (target, start, end, duration) => {
      let startTime = null;
      const step = timestamp => {
        if (!startTime) startTime = timestamp;
        const progress = Math.min((timestamp - startTime) / duration, 1);
        target.textContent = numberWithCommas(Math.floor(progress * (end - start) + start));
        if (progress < 1) requestAnimationFrame(step);
      };
      requestAnimationFrame(step);
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          animateNumbers(number1, 42000, 43914, 2000);
          animateNumbers(number2, 200, 258, 2000);
        } else {
          number1.textContent = "0";
          number2.textContent = "0";
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.5
    });

    observer.observe(this.$el);
  }
};
</script>

<style scoped>
.svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

svg {
  width: 80%; 
  height: auto;
}
.uuid-7e1af63c-ae98-4f0c-a4ce-fef51bd3fc3e {
  fill: #84bd00;
}



.uuid-6c393c5b-c8e9-4070-8067-abc135734fb3, .uuid-8ede862e-ae91-470d-809d-b484cb9e13ff, .uuid-2d1f96d9-2554-4b23-a9ac-483fe78f8fb9,
.text-cifras {
  fill: #fff;
  font-family: DINNextRoundedLTPro-Bold, 'DINNextRoundedLTPro Bold';
  font-weight: 700;
  font-size: 4rem;
}

.uuid-6c393c5b-c8e9-4070-8067-abc135734fb3 {
  font-size: 133.65px;
}

.uuid-2371a6ae-b02e-427f-baaa-e340c278f654 {
  letter-spacing: 0em;
}

.uuid-8ede862e-ae91-470d-809d-b484cb9e13ff {
  font-size: 123.3px;
}

.uuid-a57f803f-d614-4b4d-afe3-378a47b62648 {
  letter-spacing: 0em;
}

.uuid-114a6baf-f4cf-452a-a9b3-c0124c6daffd {
  letter-spacing: -.07em;
}

.uuid-ad6155fe-91dc-42aa-8012-a4e2a6ff5ec4 {
  letter-spacing: 0em;
}

.uuid-2d1f96d9-2554-4b23-a9ac-483fe78f8fb9 {
  font-size: 71.76px;
}

.uuid-9059104a-eb74-4131-b117-835bb23680a7 {
  letter-spacing: 0em;
}

.uuid-d2c911c7-4b5f-4297-9380-0b40c72af892 {
  letter-spacing: 0em;
}

.vskip {
  height: 200px;
}

@media all and (min-width: 1024px) and (max-width: 1280px) { }
 
@media all and (min-width: 768px) and (max-width: 1024px) {
  .text-cifras{
    font-size: 4.5rem;
  }
 }
 
@media all and (min-width: 480px) and (max-width: 768px) {

  .text-cifras{
    font-size: 4.5rem;
  }
  .svg-container {
    margin-top: 100px;
  }

  svg {
    width: 75%;
  }

  .uuid-6c393c5b-c8e9-4070-8067-abc135734fb3 {
    font-size: 100px;
  }

  .uuid-8ede862e-ae91-470d-809d-b484cb9e13ff {
    font-size: 90px;
  }

  .vskip {
    height: 100px;
  }
 }
 
@media all and (max-width: 480px) { 
  .svg-container {
    margin-top: 100px;
  }

  svg {
    width: 100%;
  }

  .text-cifras{
    font-size: 4.5rem;
  }
}
</style>