<template>
  <div class="ratio ratio-16x9">
    <iframe
      src="https://www.youtube.com/embed/xJXUpKJd2WM"
      width="1920" height="1080" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
      title="VERSION FINAL HDC 2023" class="borde-hdc"></iframe>
  </div>
</template>


<style scoped>
.ratio iframe {
  object-fit: contain;
  width: 60%;
  height: 60%;
  /* center without absolute */
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
}

/* 16:9 aspect ratio */
.borde-hdc{
  border-radius: 30px 0 30px 0;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .ratio iframe {
    width: 90%;
    height: 90%;
  }
  .ratio{
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .ratio iframe {
    width: 100%;
  }
  .ratio{
    height: auto;
  }
  .borde-hdc{
    border-radius: 15px 0 15px 0;
  }
}

</style>
