<template>
  <div class="row max-height px-5 mb-2">
    <div class="col-9 h-50 pt-3">
      <SVG_TxtMemoria2022 />
    </div>
    <div class="col-3 h-50 pt-3">
      <nav class="custom-nav">
        <div class="d-flex align-items-center mx-0 px-0">
          <button ref="burger" @click="toggleMenu" class="burger">
            &#9776;
          </button>
          <img @click="redirectHome" class="logo cursor" src="../assets/svgs/Recurso4.svg" alt="ir-home">
        </div>
        <transition name="slide">
          <div v-if="isMenuOpen" ref="menu" class="menu text-end z-3">
            <ul>
              <li class="li-navbar"><router-link class="routerlink" to="/">Inicio</router-link></li>
              <li class="li-navbar"><router-link class="routerlink" to="/inclusion">Inclusión</router-link></li>
              <li class="li-navbar"><router-link class="routerlink" to="/Incidencia">Incidencia y Colaboración</router-link></li>
              <li class="li-navbar"><span class="routerlink"><a href="/OrganizacionColaboracion">Organización</a></span></li>
              <li class="li-navbar"><router-link class="routerlink" to="/Sostenibilidad">Sostenibilidad y transparencia</router-link></li>
              <li class="li-navbar"><a class="routerlink" href="https://www.hogardecristo.cl/" target="_blank" rel="noopener">Página Web</a></li>
              <li class="li-navbar"><a class="routerlink" href="https://www.hogardecristo.cl/quiero-contactarme/" target="_blank" rel="noopener">Contáctanos</a></li>
            </ul>
          </div>
        </transition>

      </nav>
    </div>
  </div>
</template>

  
<script setup>
import { ref } from 'vue';
import { router } from '@/router/index.js';
import SVG_TxtMemoria2022 from './SVG_Componentes/SVG_TxtMemoria2022.vue';

const burger = ref(null);
const menu = ref(null);
const isMenuOpen = ref(false);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const redirectHome = () => {
  router.push('/');
}
</script>
  
<style scoped>
.cursor {
  cursor: pointer;
}

.li-navbar .nav-link:hover {
  color: #91D63C;
}

.li-navbar .nav-link {
  text-decoration: none !important;
  color: grey;
}

.max-height {
  max-height: 20%;
}

.routerlink {
  text-decoration: none;
  color: inherit;
}

.routerlink:hover {
  color: #91D63C;
}

.routerlink a{
  text-decoration: none;
  color: inherit;
}

.routerlink:hover a{
  color: #91D63C;
}

.custom-nav {

  position: relative;
  /* padding: 0 3rem; */

}

.burger {
  font-size: 2rem;
  padding: 0 5px 0 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  z-index: 4;
}

.menu {
  position: absolute;
  top: calc(100% + 10px);
  /* Cambiar el valor de top para que el menú aparezca justo debajo del botón burger */
  right: 65%;
  min-width: 200px;
  min-height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 20% 0 20% 20%;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform-origin: left center;
}

.menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu li {
  cursor: pointer;
}

.logo {
  width: 30%;

}

@media all and (min-width: 1280px)
{
  .logo {
    width: 60%;
  }

  .menu {
    width: 50%;
    /* Menú de ancho completo */
  }
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
  .logo {
    width: 70%;
    height: auto;
  }

  .menu {
    width: 50%;
    /* Menú de ancho completo */
  }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .logo {
    width: 70%;
  }

  .menu {
    width: 50%;
    /* Menú de ancho completo */
  }
}

@media all and (min-width: 480px) and (max-width: 768px) {
  .logo {
    width: 100%;
  }

  .menu {
    width: 50%;
    /* Menú de ancho completo */
  }
}

@media all and (max-width: 480px) {
  .logo {
    width: 100%;
  }

  .menu {
    width: 50%;
    /* Menú de ancho completo */
  }
}


/* slide in from right to left */
.slide-enter-active {
  animation: slide-in 0.6s cubic-bezier(0.1, 0.7, 0.1, 1);
}

.slide-leave-active {
  animation: slide-in 0.6s cubic-bezier(0.1, 0.7, 0.1, 1) reverse;
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>