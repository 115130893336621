<template>
	<div class="row justify-content-center m-5">
		<div class="col-md-3 col-6 my-2 cursor tamagno-card card-landing">
			<svg @click="openModalInfanciaYadolescencia" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 259 274.6"
				style="enable-background:new 0 0 259 274.6;" xml:space="preserve">
				<path class="st0"
					d="M198.3,274.6H0V60.7C0,27.2,27.2,0,60.7,0H259v213.8C259,247.4,231.8,274.6,198.3,274.6z" />
				<polyline class="st4" points="117.1,241.4 130.3,255.5 141.9,241.4 " />
				<path class="st0"
					d="M198.3,274.6H0V60.7C0,27.2,27.2,0,60.7,0H259v213.8C259,247.4,231.8,274.6,198.3,274.6z" />
				<text transform="matrix(1 0 0 1 69.9127 48.5125)">
					<tspan x="0" y="0" class="st1 st2 st3">Infancia y</tspan>
					<tspan x="-22.3" y="37.6" class="st1 st2 st3">adolescencia</tspan>
				</text>
				<polyline class="st4" points="117.1,241.4 130.3,255.5 141.9,241.4 " />
				<g>
					<!-- ninia de pelo azul -->
					<g>
						<path class="st1" d="M122.9,211.8c-0.6-10.3-5.5-17.7-14.9-22c-1.9-0.9-3.9-1.3-5.9-1.9c0,0-0.1,0-0.1,0c2.6-1.6,4.8-3.7,6.6-6.4
				c0,0,0,0,0,0c1.6-2.5,2.7-5.4,3.1-8.5v-8.1c-1-0.2-2.6-0.7-4.6-1.8c0.3,0.2,0.7,0.4,1,0.7c0.3,0.2,0.7,0.5,0.6,0.7
				c-0.1,3.3,0.1,6.8-0.5,10c-1.5,8-9.2,13.7-17.2,13.4c-8.4-0.3-15.4-6.5-16.5-14.7c-0.4-2.9-0.2-5.8-0.2-8.7c0-0.2,0.3-0.6,0.6-0.8
				c-0.9,0.4-2.2,0.9-3.6,1v8.3c0.4,3.1,1.4,6,3.1,8.5c0,0,0-0.1,0-0.1c1.8,2.7,4,4.8,6.8,6.6c0,0-0.1,0-0.1,0
				c-0.5,0.1-0.8,0.2-1.1,0.2c-5.4,1.1-10,3.6-13.7,7.7c-0.9,1-1,1.8-0.3,2.5c0.7,0.7,1.5,0.5,2.5-0.5c0.8-0.8,1.5-1.6,2.3-2.3
				c3.3-2.7,7.1-4.3,11.4-4.8c0.4,2.4,1,4.6,2.7,6.4c1.7,1.9,3.9,2.6,6.4,2.6c1.9,0,3.7-0.4,5.3-1.6c2.5-1.8,3.4-4.5,3.9-7.4
				c0.2,0,0.3,0,0.4,0c8.1,0.8,15.4,7,17.9,15.1c0.9,2.9,1,5.9,1,8.9c0,1.1,0.6,1.7,1.5,1.7c0.9,0,1.5-0.7,1.5-1.7
				C123,213.9,123,212.9,122.9,211.8z M92.2,196.9c-3.9,0.2-6.2-1.9-6.7-6.1h12C97.6,194.1,95.3,196.7,92.2,196.9z" />
						<path class="st5" d="M122.7,183.2c-0.1-4-1.4-7.6-4.3-10.5c-0.2-0.2-0.2-0.6-0.1-0.9c0.6-1.8,0.5-3.6-0.2-5.4
				c-0.1-0.4-0.2-0.8-0.1-1.2c0.8-4.7,1.2-9.4,0.7-14.2c-1.2-10.9-8.1-18.8-18.7-21.3c-1.8-0.4-3.7-0.7-5.6-1H89
				c-0.4,0.1-0.8,0.2-1.2,0.2c-4.7,0.5-9.1,1.8-13.1,4.4c-2.3,1.5-4.3,3.4-5.9,5.8c-0.5,0.8-0.4,1.6,0.2,2.1c0.7,0.5,1.5,0.4,2.2-0.4
				c0.1-0.1,0.2-0.3,0.3-0.4c2-2.7,4.6-4.8,7.7-6.2c7.2-3.2,14.6-3.4,22-1.1c6.9,2.1,11.5,6.7,13.6,13.7c1.3,4.2,1.3,8.4,1,12.7
				c-0.1,1.1-0.3,2.2-0.4,3.2c-1.3-0.4-2.6-0.7-3.8-1c0.1,1,0.2,2,0.2,3.1v0.1c1.9,0.1,3.7,2,3.8,4.1c0.2,2.5-1.5,4.6-4,5.1V173
				c-0.4,3.1-1.4,6-3.1,8.5c2.1,5.3,6.2,9.2,11.4,10.9c1.2,0.4,2.1,0,2.2-1.3C122.5,188.5,122.8,185.9,122.7,183.2z M119.6,189
				c-5.2-1.9-9.1-7.4-8.5-11.9c1.9-0.1,3.7-0.8,5.2-2.2C120.2,178.9,120.1,183.9,119.6,189z" />
						<path class="st5"
							d="M71.5,173v0.9c-2.2-0.2-3.9-2.2-4-4.6c0-2.2,1.6-4.2,3.8-4.6v-0.1c0-1,0.1-2,0.2-2.9
				c-1.2,0.3-2.4,0.5-3.8,0.8c-0.1-1.3-0.4-2.7-0.4-4.2c-0.3-4.2,0-8.4,1.3-12.5c0.3-0.9-0.1-1.8-0.9-2c-0.8-0.2-1.6,0.2-1.9,1.1
				c-0.2,0.6-0.4,1.1-0.5,1.7c-1.6,6.2-1.2,12.3-0.2,18.5c0.1,0.4,0.1,0.9-0.1,1.2c-0.7,1.8-0.7,3.6-0.2,5.4c0.1,0.3-0.1,0.8-0.3,1.1
				c-1.5,1.6-2.6,3.4-3.3,5.4c-1.4,4.2-1.2,8.4-0.4,12.7c0.3,1.6,1.1,2,2.5,1.4c2.8-1.1,5.3-2.7,7.4-4.8c1.7-1.7,2.9-3.6,3.7-6
				C72.9,179,71.8,176.1,71.5,173z M63.6,188.9c-0.5-5-0.5-9.9,3.3-14.1c1.6,1.4,3.3,2.1,5.2,2.2C72.6,181.7,69,186.8,63.6,188.9z" />
						<path class="st1" d="M61.2,229.7c-0.8-0.5-1-1.2-1-2.1c0-5,0-10.1,0-15.1c0-3.6,0.7-7.1,2.3-10.4c0.5-1.1,1.2-1.5,2.1-1.1
				c0.8,0.4,1.1,1.2,0.6,2.2c-1.5,3.2-2,6.6-2,10.1c0,4.1,0,8.2,0,12.2c0,0.4,0,0.7,0,1.1c3,0,5.8,0,8.9,0c0-0.3,0-0.7,0-1
				c0-3.4,0-6.7,0-10.1c0-1.2,0.5-1.9,1.5-1.9c0.9,0,1.4,0.7,1.4,1.9c0,3.7,0,7.4,0,11.1c11,0,22.1,0,33.2,0c0-0.3,0-0.7,0-1
				c0-3.4,0-6.7,0-10.1c0-1.2,0.5-1.8,1.4-1.9c1,0,1.5,0.6,1.5,1.9c0,3.7,0,7.4,0,11.1c2.9,0,5.8,0,8.9,0c0-0.9,0-1.7,0-2.6
				c0-1,0-1.9,0-2.9c0-0.9,0.6-1.6,1.4-1.6c0.9,0,1.5,0.6,1.5,1.6c0,2.1,0,4.3,0,6.4c0,0.9-0.2,1.6-1,2.1
				C101.7,229.7,81.5,229.7,61.2,229.7z" />
						<path class="st5" d="M111.8,164.7v0.1c-0.1,0-0.1,0-0.1,0v0.1c-1-0.2-2.6-0.7-4.6-1.8c-0.1-0.1-0.3-0.2-0.4-0.3c0,0,0,0-0.1,0
				c-0.1-0.1-0.2-0.1-0.3-0.2c-3.2-2.1-2.8-2.3-2.8-2.3l-0.7,0.1c-0.2-0.2-0.4-0.3-0.7-0.5c-4.1-3-7.6-6.6-10.5-10.9
				c-1,1.3-1.9,2.5-2.8,3.6c-3.4,4.1-7.4,7.4-12,10.1c0,0,0,0,0,0c-0.6,0.3-1.1,0.6-1.7,0.9c-0.1,0-0.1,0.1-0.2,0.1
				c-0.9,0.4-2.2,0.9-3.6,1c-0.1,0-0.1,0-0.1,0v-0.1c0-1,0.1-2,0.2-2.9c0.3-0.1,0.7-0.1,1-0.2c0.3-0.1,0.6-0.2,0.9-0.4
				c6.9-3.8,12.7-8.8,16.8-15.6c0.8-1.3,2-1.3,2.8,0c4,6.8,9.8,11.7,16.6,15.5c0.6,0.3,1.3,0.4,1.9,0.6c0,0,0.1,0,0.1,0
				C111.8,162.6,111.8,163.6,111.8,164.7z" />
						<path class="st5" d="M71.5,164.7L71.5,164.7c-0.1,0-0.1,0-0.1,0C71.4,164.7,71.4,164.7,71.5,164.7z" />
						<path class="st5"
							d="M76.8,162.6c-0.2,0.1-0.8,0.6-1.8,1.1c0.1,0,0.1-0.1,0.2-0.1C75.7,163.3,76.3,163,76.8,162.6z" />
					</g>
					<!-- ninio con gorra -->
					<g>
						<path class="st1" d="M198.8,206c0-1.8-1-3-2.4-2.9c-1.4,0-2.2,1.1-2.2,2.9c0,5.2,0,10.5,0,15.7c0,2.8-0.3,3.1-3.2,3.1h-64.4v4.6
				c21.4-0.1,42.9,0,64.3,0c0.6,0,1.2,0,1.8,0c3.6-0.3,6-2.8,6-6.4C198.8,217.3,198.8,211.7,198.8,206z M135.4,193.3
				c0.5-0.2,1.1-0.2,1.5,0c1.7,0.7,3.4,1.5,5.1,2.4c0.3,0.2,0.8,0.6,0.8,0.9c0.1,2.4,0,4.8,0,7.2c-2.7,0.6-4.6,2-4.9,4.7
				c-0.2,2-0.2,4,0,5.9c0.2,2.5,2.3,4.3,4.8,4.5c1.5,0.1,3.1,0.1,4.6,0c2.8-0.1,4.9-2.2,5-5c0.1-1.1,0-2.2,0-3.4
				c0-4.2-0.9-5.5-4.9-6.8c0-1.9,0-3.8,0-5.4c3.8,0.8,7.5,2.3,11.3,2.3c3.7,0,7.5-1.5,11.2-2.4v5.5c-3.7,1.1-4.9,2.6-4.9,6.4
				c0,1.2,0,2.4,0,3.7c0.1,2.8,2.2,4.9,5,5c1.5,0.1,3.1,0.1,4.6,0c2.7-0.1,4.7-2,4.9-4.7c0.1-1.2,0.1-2.3,0.1-3.5c0-4.3-1-5.6-5-7
				c0-2.3,0-4.6,0-7c0-0.4,0.4-0.9,0.7-1.1c1.7-0.9,3.5-1.7,5.3-2.4c0.3-0.1,0.7-0.3,1-0.1c2.8,1.4,5.7,2.7,8.4,4.3
				c1.5,0.9,2.7,1.1,3.6,0c0.9-1.1,0.7-2.6-0.8-3.5c-1.8-1.2-3.6-2.4-5.6-3.1c-2.9-1-4.5-2.5-4.9-5.8c-0.3-3.4-3.9-5.5-7.3-4.8
				c-0.8,0.2-1.7,0.3-2.6,0.5c-0.2-2.6-0.5-5.2-0.6-7.7c0-0.4,0.3-0.8,0.6-1.1c3.4-3,5.9-6.8,7.5-11c0.5-1.2,1.1-1.8,2.4-2.1
				c4.2-1.1,7.6-3.5,9.1-7.8c1.6-4.6,0-8.4-4.3-10.6c-0.4-0.2-0.8-1-0.8-1.5c0-4.4,0.1-8.9,0.1-13.3c0-9.2-5-16.2-13.6-19.4
				c-6.3-2.4-12.9-2.5-19.6-2c-3.7,0.3-7.2,1.1-10.6,2.7c-1.4,0.7-1.9,2-1.3,3.2c0.5,1.1,1.8,1.6,3.1,1c6.3-3,13.1-2.9,19.8-2.2
				c2.7,0.3,5.4,1,7.9,2c5.4,2.2,8.6,6.4,9.4,12.2c0.4,3,0.1,6,0.2,9c0,2.5,0,5.1,0,8c-3.2-2-6.2-3.6-9-5.5c-0.6-0.4-0.8-1.5-0.8-2.3
				c-0.1-4,0-7.9-0.1-11.9c-0.1-4.1-2.8-6.9-7-6.9c-4,0-7.9,0-11.9,0c-5,0-7.6,2.6-7.7,7.6c0,3.8,0,7.5,0,11.3c0,0.7-0.3,1.8-0.8,2.2
				c-2.8,1.9-5.8,3.7-8.9,5.6c0-0.8-0.1-1.3-0.1-1.8c0-3.9,0-7.7,0-11.6c0-3.2,0.5-6.3,2-9.1c0.7-1.4,0.4-2.7-0.8-3.3
				c-1.2-0.7-2.5-0.2-3.3,1.2c-1.6,2.9-2.6,6-2.6,9.3c0,4.6,0,9.3,0.1,13.9c0,0.7,0,1.3-0.9,1.7c-3.7,1.7-5.3,4.9-4.5,8.9
				c1.1,5.6,5,8.4,10.2,9.6c0.9,0.2,1.3,0.6,1.7,1.4c1.6,4.3,4,8.1,7.4,11.2c0.3,0.3,0.7,0.7,0.6,1.1c-0.1,2.7-0.4,5.3-0.6,8.1
				c-0.6-0.2-1.1-0.3-1.6-0.5c-4.3-1.3-7.9,0.9-8.6,5.3c-0.1,0.7-0.1,1.4-0.4,2.1c-0.2,0.5-0.6,1.2-1.1,1.4c-1.7,0.9-3.5,1.6-5.3,2.4
				c-2,1-3.7,2.2-5,3.7c0.9,1.5,1.6,3,2.1,4.6c1.1-1.7,2.7-3,4.7-4.1C131.6,194.9,133.5,194.1,135.4,193.3z M183.5,144.5
				c2.9-0.4,4.6,1.4,4,4.3c-0.5,2.4-2.4,4.3-5,5C182.8,150.6,183.1,147.5,183.5,144.5z M130.3,149.1c-0.9-3.2,0.8-5,4.1-4.6
				c0.4,3.1,0.7,6.3,1.1,9.6C133,153.1,131,151.7,130.3,149.1z M147.8,211.4c0,3.1,0.4,2.9-2.6,2.9c-3,0-2.8,0.4-2.8-3
				c0-3.4-0.1-2.9,2.8-2.9C148.2,208.5,147.8,208.3,147.8,211.4z M175.1,211.4c0,3.4,0.2,2.9-2.9,3c-2.5,0-2.5,0-2.5-2.6
				c0-3.7-0.4-3.3,3-3.3C175.1,208.5,175.1,208.5,175.1,211.4z M150.1,120c0.2-1.2,0.9-1.9,2.2-1.9c4.3,0,8.7,0,13,0
				c1.3,0,2.1,0.8,2.1,2.1c0,0.7,0,1.4,0,2.3h-17.3C150.1,121.5,150,120.7,150.1,120z M167.4,127.2v4.1h-17.2v-4.1H167.4zM139.6,149.7c-0.2-1.7-0.4-3.4-0.4-5.2c0-0.6,0.4-1.3,0.9-1.7c2-1.3,4.2-2.5,6.2-3.8c0.7-0.5,1.5-0.9,2-1.6
				c0.8-1.3,1.8-1.5,3.2-1.4c2.4,0.1,4.9,0,7.3,0c2.7,0,5.5,0,8.2,0c0.5,0,1,0.1,1.4,0.4c1.7,1.3,3.4,2.7,5.1,3.9
				c0.7,0.5,1.4,1,2.2,1.3c2.5,0.9,3.3,2.6,2.9,5.3c-0.9,7.2-2.4,14.2-7.4,19.8c-7.3,8.1-18.3,7.8-25.2-0.6
				C142.2,161.3,140.4,155.7,139.6,149.7z M168.1,181.5c0,0.3-0.4,0.9-0.7,1.1c-2.5,1.2-5.1,2.3-7.7,3.3c-0.6,0.2-1.4,0.2-2,0
				c-2.4-0.9-4.8-1.9-7.2-2.9c-0.4-0.2-0.9-0.7-0.9-1.1c0.1-2.2,0.2-4.3,0.4-6.7c5.9,2.8,11.7,2.8,17.6,0.1
				C167.8,177.3,168,179.4,168.1,181.5z M139,187.9c0.5-3.7,1.1-4,4.5-2.7c4.1,1.6,8.2,3.2,12.3,4.9c2,0.8,3.9,0.8,5.9-0.1
				c4.3-1.8,8.6-3.5,13-5.1c2.7-1,4.6,1.1,3.9,3.9c-0.1,0.2-0.3,0.5-0.6,0.6c-5.7,2.6-11.4,5.3-17.6,6.7c-0.5,0.1-1.1,0.2-1.7,0.2
				c0,0.1-0.1,0.1-0.1,0.2c-2.2-0.6-4.5-1.1-6.7-1.9c-4.1-1.6-8.1-3.4-12.2-5.1C138.9,189.2,138.8,188.7,139,187.9z" />
					</g>
				</g>
				<path class="st5" d="M103.6,160.2l-0.7,0.1c1.2,0.8,2.4,1.5,3.5,2.3C103.2,160.5,103.6,160.2,103.6,160.2z" />
			</svg>
		</div>

		<div class="col-md-3 col-6 my-2 cursor tamagno-card card-landing">
			<svg @click="openModalAdultezMain" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 259 274.6"
				style="enable-background:new 0 0 259 274.6;" xml:space="preserve">

				<path class="st0"
					d="M198.3,274.6H0V60.7C0,27.2,27.2,0,60.7,0H259v213.8C259,247.4,231.8,274.6,198.3,274.6z" />
				<g>
					<text transform="matrix(1 0 0 1 79.111 62.9677)" class="st1 st2 st3">Adultez</text>
				</g>
				<polyline class="st4" points="120.8,241.4 134,255.5 145.6,241.4 " />

				<path class="st0"
					d="M198.3,274.6H0V60.7C0,27.2,27.2,0,60.7,0H259v213.8C259,247.4,231.8,274.6,198.3,274.6z" />
				<g>
					<text transform="matrix(1 0 0 1 79.111 62.9677)" class="st1 st2 st3">Adultez</text>
				</g>
				<polyline class="st4" points="120.8,241.4 134,255.5 145.6,241.4 " />
				<g>
					<g>
						<path class="st1" d="M180.7,198.5c0-1.7-0.1-3.4-0.4-5.1c-2.6-13.1-13.4-22.1-26.8-22.1c-3.5,0-7.1,0-10.6,0c-0.7,0-1-0.2-1-0.9
				c0.1-1.1,0.1-2.2,0-3.3c-0.1-0.8,0.3-1.3,0.9-1.7c6.8-4.6,10.6-10.9,11.1-19.1c0.1-0.9,0.3-1.3,1.2-1.2c2.3,0.1,4.6-0.2,6.6-1.6
				c3.4-2.4,4.7-5.7,4.2-9.7c-0.5-4.1-2.9-6.7-6.8-8c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.1,0.1-0.1,0.2c-0.1,1.7-0.9,3.1-1.5,4.6
				c0.1,0,0.2,0.1,0.3,0.2c0,0,0,0,0,0c2.5,0.6,3.8,2.5,3.5,5.1c-0.2,2.3-2,3.9-4.4,4c-0.8,0-1.5,0-2.3,0c0-2.6,0-5.1,0-7.7
				c0-0.5,0-2.1,0.1-2.3c0,0,0,0,0,0c-0.1-1-0.4-1-1.9-1.1c-0.8,0-1.5-0.2-2.2-0.5c-1-0.4-1.4-0.4-1.5,0.2c0,0.2,0,0.4,0,0.6
				c-0.1,4.8,0,11,0,15.8c0,0.4,0,0.9,0.1,1.3c-0.4,9.9-9,18-19.1,18.1c-10.1,0-18.8-8.1-19.2-18.1c0-4.6,0-9.1,0.1-13.7
				c0-0.8,0-1.2,0-1.5c-0.1-0.7-0.5-0.9-1.7-0.9c-0.7,0-1.4,0-2.1,0c-1.4,0-1.8,0.2-1.8,1.1c0,0,0,0,0,0c0,0,0,0,0,0
				c0.1,0.2,0.1,0.4,0.1,0.8c-0.1,2.6,0,5.3,0,7.9c-1,0-2,0.1-3,0c-2.2-0.3-3.7-2.1-3.7-4.4c-0.1-2.4,1.2-4.1,3.5-4.6
				c0,0,0.1,0,0.1,0c0,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0.1,0h0.1c0.4,0,0.9-0.1,1.3-0.1c-0.6,0-1-0.1-1.4-0.1c0-0.1,0-0.2-0.1-0.3
				c-1.1-1-1.4-2.3-1.4-3.7c0-0.3-0.1-0.5-0.3-0.6c-0.1-0.1-0.3-0.1-0.5-0.1c-0.4,0.2-0.9,0.3-1.3,0.5c-3.5,1.7-5.4,4.5-5.6,8.4
				c-0.2,4.1,1.4,7.3,5,9.3c1.8,1,3.8,1.3,5.9,1.2c1-0.1,1.2,0.3,1.2,1.2c0.4,7.3,3.4,13.4,9.2,17.7c2.4,1.8,3.1,3.6,2.8,6.3
				c-0.1,0.9-0.3,1.2-1.2,1.2c-4.3,0-8.7-0.2-13,0.1c-13.6,1-24.3,12.4-24.6,26c-0.1,5,0,10.1-0.1,15.1c0,1.6,0.5,2.7,2,3.3h98.3
				c1.5-0.6,2-1.7,2-3.3C180.6,207.9,180.7,203.2,180.7,198.5z M126,210.7c-4.1,0-8.1,0-12.2,0c-4.1,0-8.1,0-12.2,0c-0.8,0-1-0.2-1-1
				c0-3.7,0-7.3,0-11c0-0.9-0.2-1.8-0.9-2.4c-0.8-0.7-1.8-0.9-2.8-0.5c-1,0.5-1.6,1.3-1.6,2.4c0,3.7,0,7.5,0,11.2
				c0,0.9-0.2,1.2-1.2,1.2c-3.2-0.1-6.4-0.1-9.5,0c-0.8,0-1.1-0.3-1-1.1c0-3.9,0-7.7,0-11.6c0.2-11.9,9.6-21.3,21.6-21.4
				c1.3,0,2.6,0,3.9,0c0.7,0,0.9,0.1,1.1,0.9c1.9,8.6,7.2,13.9,15.8,15.8c0.7,0.1,0.8,0.4,0.8,1c0,5.2,0,10.3,0,15.5
				C126.9,210.6,126.6,210.7,126,210.7z M116.2,179.1c-0.9-2.5-0.9-2.5,1.7-2.5c4.2,0,4.6-0.4,4.6-4.6c0-1,0-1.9,0-2.9
				c0-0.6,0.2-0.9,0.8-0.7c4.2,1.1,8.4,1.1,12.6,0c0.6-0.2,0.8,0,0.8,0.6c0,0.8,0,1.7,0,2.5h0c0,0.8,0,1.7,0,2.5
				c0.1,1.4,1,2.4,2.4,2.4c1.1,0.1,2.3,0.1,3.5,0c1-0.1,1.1,0.3,0.8,1.1c-1.5,5.9-6.8,10.2-12.7,10.5
				C123.9,188.6,118.4,185.1,116.2,179.1z M175.4,209.7c0,0.8-0.2,0.9-1,0.9c-3.3,0-6.5,0-9.8,0c-0.9,0-1-0.3-1-1.1
				c0-3.6,0-7.2,0-10.9c0-1.9-1-3.1-2.6-3.1c-1.6,0-2.7,1.2-2.7,3.1c0,3.5-0.1,7.1,0,10.6c0,1.1-0.4,1.3-1.4,1.3
				c-3.9-0.1-7.9,0-11.8,0c-4,0-8,0-12,0c-0.8,0-1.1-0.2-1.1-1c0-5.1,0-10.2,0-15.3c0-0.7,0.2-1,1-1.1c7.9-1.4,14-7.4,15.6-15.4
				c0.2-0.9,0.5-1.2,1.4-1.2c4,0,8,0,11.7,1.6c8.4,3.7,13,10.2,13.5,19.4C175.6,201.6,175.4,205.7,175.4,209.7z" />
					</g>
					<g>
						<path class="st5" d="M141.3,98.8c4.9,0.8,8.3,3.1,10.5,7.1c1.6,3,1.9,5.9,2,6.4v13h-1c-5-4.8-10.8-5.3-13.1-5.3
				c-0.9,0-1.5,0.1-1.8,0.1l-0.5,0.1l-0.4,0.1c-1.6,0.5-3.2,0.8-4.7,0.8c-3.6,0-5.7-1.7-6.1-2l-2.1-2.4c-1-1.1-2.7-1-3.6,0.2l-2,2.8
				c-3.5,4.9-8.4,5.5-9,5.5h-4.2v-10.9c0.4-3.5,1.8-5.7,4.5-7.1c2.5-1.3,5.2-1.3,5.7-1.3c0,0,0.1,0,0.1,0l1.3,0.1
				c1,0.1,2-0.4,2.5-1.3l0.7-1.1c2.5-3.9,7.8-4.7,8.8-4.9H141.3 M141.7,93.7h-13.1c0,0-8.6,0.7-12.8,7.2c0,0-0.1,0-0.3,0
				c-2.1,0-14.2,0.5-15.3,13.3v13.4c0,1.6,1.3,2.8,2.8,2.8h6.6c0,0,7.7-0.2,13-7.7c0,0,3.4,3.4,9.7,3.4c1.9,0,4-0.3,6.3-1.1
				c0,0,0.4-0.1,1-0.1c2,0,6.5,0.5,10.4,4.7c0.4,0.4,0.9,0.7,1.5,0.7h5c1.4,0,2.5-1.1,2.5-2.5v-15.8
				C158.9,112.2,158.2,96.2,141.7,93.7L141.7,93.7z" />
					</g>
				</g>
			</svg>
		</div>

		<div class="col-md-3 col-6 my-2 cursor tamagno-card card-landing">
			<svg @click="openModalAdultoMayor" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 259 274.6"
				style="enable-background:new 0 0 259 274.6;" xml:space="preserve">
				<path class="st0"
					d="M198.3,274.6H0V60.7C0,27.2,27.2,0,60.7,0H259v213.8C259,247.4,231.8,274.6,198.3,274.6z" />
				<g>
					<text transform="matrix(1 0 0 1 39.9054 63.2226)" class="st1 st2 st3">Adulto mayor</text>
				</g>

				<path class="st0"
					d="M198.3,274.6H0V60.7C0,27.2,27.2,0,60.7,0H259v213.8C259,247.4,231.8,274.6,198.3,274.6z" />
				<g>
					<text transform="matrix(1 0 0 1 39.9054 63.2226)" class="st1 st2 st3">Adulto mayor</text>
				</g>
				<polyline class="st4" points="122,241.4 135.2,255.5 146.8,241.4 " />
				<g>
					<path class="st1"
						d="M166.6,210.4c-0.7-6.5-1.4-13-2.1-19.4c-0.9-8.1-5-13-13-15.3c-1.7-0.5-3.5-1-5.3-1.3
				c-3.1-0.5-5.7-1.9-8.2-3.6c-1.4-0.9-1.3-4.2,0.1-5.1c6.7-4.1,11.1-9.9,12.3-17.8c0.4-2.8,1-4.4,3.7-5.6c3.1-1.3,4-4.7,3.7-8.1
				c-0.2-2.1,0.5-4.4,0.7-6.5c0.7-5.7,0.7-11.3-1.8-16.6c-1.9-4-4.8-6.9-9.2-8.2c-0.6-0.2-1.2-0.5-1.7-0.9c-4.8-4-10.3-6.5-16.5-7.2
				c-0.2,0-0.5-0.2-0.7-0.3h-6.4c-0.2,0.1-0.5,0.2-0.7,0.3c-6.2,0.7-11.7,3.2-16.5,7.2c-0.5,0.4-1.1,0.7-1.7,0.9
				c-4.3,1.3-7.3,4.2-9.2,8.2c-2.4,5.3-2.5,10.9-1.8,16.6c0.3,2.2,0.9,4.4,0.7,6.5c-0.3,3.4,0.6,6.7,3.7,8.1c2.8,1.2,3.3,2.9,3.7,5.6
				c1.1,7.8,5.6,13.6,12.3,17.8c1.4,0.9,1.5,4.2,0.1,5.1c-2.5,1.7-5.1,3.1-8.2,3.6c-1.8,0.3-3.5,0.8-5.3,1.3c-8,2.3-12.1,7.2-13,15.3
				c-0.7,6.5-1.4,13-2.1,19.4c-0.2,1.5,1,2.9,2.6,2.9h1.6h11.9h4.5H146h4.5h11.9h1.6C165.6,213.3,166.8,211.9,166.6,210.4z M138.3,176.2c-2.1,3.5-4.2,6.9-6.2,10.4c-1.2-0.9-2.2-1.6-3.3-2.5c2.3-3.3,4.5-6.5,6.7-9.7C136.4,175.1,137.3,175.6,138.3,176.2z M118.7,168c2.4,0.6,4.7,0.9,7,0.9c2.3,0,4.6-0.3,7-0.9c-0.1,1.1,0.1,2.2-0.3,2.8c-2.1,3.3-4.4,6.5-6.6,9.8c0,0,0,0,0,0
				c0,0,0,0,0,0c-2.3-3.3-4.6-6.5-6.6-9.8C118.5,170.2,118.8,169.1,118.7,168z M153.3,136.1c-0.2,1.4-1.1,2.2-2.4,2.8v-6.2
				C152.8,133.5,153.6,134.6,153.3,136.1z M144.2,110.8c0.5-0.8,1.1-1.6,1.5-2.4c0.6-1.2,1.3-1.1,2.3-0.6c2.2,1.2,3.8,3,4.7,5.3
				c2.2,5.1,1.9,10.4,1.1,15.6c-2-0.3-3.5-1.1-3.1-3.4c0,0,0-0.1,0-0.1c-0.6-3.5-2.6-6-5.4-8C142.6,115.2,142.3,113.5,144.2,110.8z M100.4,138.9c-1.4-0.6-2.2-1.4-2.4-2.8c-0.2-1.6,0.6-2.7,2.4-3.4V138.9z M100.6,125.2c0,0,0,0.1,0,0.1c0.4,2.3-1.1,3.1-3.1,3.4
				c-0.8-5.3-1.1-10.5,1.1-15.6c1-2.3,2.6-4,4.7-5.3c1-0.6,1.7-0.6,2.3,0.6c0.4,0.8,1,1.6,1.5,2.4c1.9,2.8,1.6,4.4-1.1,6.4
				C103.2,119.1,101.1,121.7,100.6,125.2z M109,156.1c0,0-0.1-0.2-0.4-0.5c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1-0.1-0.1-0.1-0.2
				c-0.1-0.2-0.2-0.3-0.3-0.5c0,0,0,0,0,0c-0.8-1.2-1.8-3-2.4-5.1c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c-0.1-1-0.2-1.9-0.4-2.8v0
				c0-0.1,0-0.2,0-0.2c-0.2-1.1-0.2-2.2-0.2-3.3c0-0.4,0-0.8,0-1.2c0-4.8,0-9.7,0-14.5c0-2.7,1.2-4.6,3.2-6.3c1.4-1.1,2.7-2.4,3.7-3.9
				c1.4-2.3,1.3-4.9,0-7.3c-0.9-1.6-1.9-3-2.9-4.6c4-3.9,10.2-6.2,16.8-6.2c6.6,0,12.8,2.4,16.8,6.2c-1,1.5-2,3-2.9,4.6
				c-1.3,2.4-1.5,5,0,7.3c0.9,1.5,2.3,2.8,3.7,3.9c2.1,1.7,3.3,3.6,3.2,6.3c0,5.2,0,10.4,0,15.6c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2
				c0,0.2,0,0.4,0,0.6c0,0.9-0.1,1.7-0.2,2.6c0,0,0,0,0,0c-0.1,1-0.3,2-0.4,3c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0
				c-1,3.6-3.4,6.5-3.4,6.5v0c-4,5.2-9.2,8-15.5,8.3c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.6,0c0,0-0.1,0-0.1,0h-0.3c-0.2,0-0.3,0-0.5,0
				c-0.2,0-0.3,0-0.5,0C118.2,164,113.1,161.2,109,156.1z M115.9,174.5c2.2,3.2,4.4,6.4,6.7,9.7c-1.1,0.9-2.1,1.6-3.3,2.5
				c-2.1-3.5-4.1-6.9-6.2-10.4C114,175.6,114.8,175.1,115.9,174.5z M108.8,178c2.1,3.5,4.2,7,6.3,10.5c0.9,1.5,2,2.7,3.8,2.6
				c1.2-0.1,2.5-0.7,3.6-1.3c1.1-0.6,2.1-1.5,3.1-2.3c0,0,0,0,0,0c0,0,0,0,0,0c1.1,0.8,2,1.7,3.1,2.3c1.1,0.6,2.4,1.2,3.6,1.3
				c1.8,0.1,2.9-1.1,3.8-2.6c2.1-3.5,4.2-7,6.3-10.5c0.5,0.2,1,0.3,1.5,0.5c-2.2,3.5-4.2,7.1-6.4,10.4c-2.8,4.1-7.4,6.1-11.9,6.1
				c-4.5,0-9.1-2.1-11.9-6.1c-2.3-3.3-4.3-6.8-6.4-10.4C107.8,178.4,108.3,178.2,108.8,178z M151.5,209.3c-0.7,0-1.2-0.5-1.2-1.2
				c-0.1-1.9-0.2-3.7-0.3-5.6c-0.1-1.9-1-2.9-2.4-2.8c-1.4,0.1-2.2,1.1-2.1,2.9c0.1,1.8,0.2,3.6,0.3,5.3c0,0.7-0.5,1.3-1.2,1.3h-37.7
				c-0.7,0-1.3-0.6-1.2-1.3c0.1-1.8,0.2-3.6,0.3-5.3c0.1-1.8-0.7-2.8-2.1-2.9c-1.5-0.1-2.3,0.9-2.4,2.8c-0.1,1.9-0.2,3.7-0.3,5.6
				c0,0.7-0.6,1.2-1.2,1.2h-9.1c-0.7,0-1.3-0.6-1.2-1.4c0.5-4.7,1.1-9.3,1.6-14c0.3-2.5,0.6-5.2,1.6-7.5c1.8-4.2,5.7-5.9,10-6.8
				c0.3,0.5,0.6,1,0.9,1.4c2.2,3.5,4.2,7.1,6.5,10.5c3.7,5.4,9.6,8.1,15.6,8c6,0,11.9-2.7,15.6-8c2.3-3.4,4.4-7,6.5-10.5
				c0.3-0.5,0.6-0.9,0.9-1.4c4.3,0.9,8.2,2.6,10,6.8c1,2.3,1.3,4.9,1.6,7.5c0.5,4.7,1.1,9.3,1.6,14c0.1,0.7-0.5,1.4-1.2,1.4H151.5z" />
					<!-- VIGOTE -->
					<path class="st5" d="M145.7,150.3c-2.8-0.9-5.2-2.5-7.1-4.6c0,0,0,0,0,0c-0.6-0.5-1.2-0.9-1.8-1.4c-1.4-1.1-2.8-2-4.3-2.5
				c-2-0.7-4.1-0.8-6.4,0.1c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1c-2.3-0.9-4.5-0.8-6.4-0.1c-1.5,0.5-3,1.4-4.3,2.5
				c-0.6,0.5-1.2,1-1.8,1.4c0,0,0,0,0,0c-1.9,2.1-4.4,3.7-7.1,4.6c-0.8,0.3-1.7,0.5-2.6,0.6c1,1.2,2.2,2.3,3.5,3.2
				c1,0.7,2,1.2,3.1,1.6c1.1,0.4,2.1,0.7,3.1,0.9c1,0.2,1.9,0.3,2.8,0.3c0.1,0,0.2,0,0.3,0h0.1c2.5,0,4.9-0.5,7.1-1.6
				c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.3-0.2,0.5-0.3c0.1,0,0.1-0.1,0.2-0.1
				c0.2-0.2,0.5-0.3,0.7-0.5c0.2,0.2,0.5,0.3,0.7,0.5c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.5,0.3
				c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0.1,0.3,0.1c2.3,1.1,4.7,1.7,7.1,1.6h0.1c0.1,0,0.2,0,0.3,0c0.9,0,1.9-0.1,2.8-0.3
				c1-0.2,2-0.5,3.1-0.9c1.1-0.4,2.1-1,3.1-1.6c1.3-0.9,2.5-2,3.5-3.2C147.3,150.7,146.5,150.5,145.7,150.3z M129,150.6
				c-0.5-0.3-0.9-0.6-1.4-1c-0.8-0.6-1.4-0.9-2-0.9c-0.6,0-1.1,0.3-2,0.9c-0.5,0.4-0.9,0.7-1.4,1c-3,1.9-6.3,2.3-10.1,1.2
				c2.5-1.8,4.7-3.6,7.1-5.2c0.2-0.1,0.4-0.3,0.6-0.4c1.5-0.8,3-0.8,4.6,0.4c0.3,0.2,0.7,0.3,1.2,0.3c0.4,0,0.9-0.1,1.2-0.3
				c1.6-1.1,3.1-1.2,4.6-0.4c0.2,0.1,0.4,0.2,0.6,0.4c2.4,1.6,4.6,3.3,7.1,5.2C135.3,152.9,132,152.5,129,150.6z" />
				</g>
			</svg>
		</div>

		<div class="col-md-3 col-6 my-2 cursor tamagno-card card-landing">
			<svg @click="openModalCalle" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 259 274.6"
				style="enable-background:new 0 0 259 274.6;" xml:space="preserve">

				<g>
					<path class="st0"
						d="M198.3,274.6H0V60.7C0,27.2,27.2,0,60.7,0H259v213.8C259,247.4,231.8,274.6,198.3,274.6z" />
					<g>
						<text transform="matrix(1 0 0 1 51.0639 47.9313)">
							<tspan x="0" y="0" class="st1 st2 st3">Personas en</tspan>
							<tspan x="-33.9" y="37.6" class="st1 st2 st3">situación de calle</tspan>
						</text>
					</g>
					<polyline class="st4" points="109.1,243.8 122.3,257.9 133.9,243.8 	" />
					<g>
						<path class="st1" d="M165.4,98.2c1,0.9,2.2,1.6,3,2.6c5.1,5.9,10.1,11.8,15.2,17.7c0.6,0.7,1.2,1.5,1.4,2.4
				c0.5,1.8,0.1,3.4-1.3,4.7c-4.3,3.8-8.6,7.5-13,11.2c-1.8,1.5-3.7,1.2-6.1-1c-0.4,0.3-0.8,0.6-1.3,0.9c-3.2,2.1-6.3,4.3-9.5,6.3
				c-3.7,2.3-8,2.4-12.1,3.2c-4.7,0.8-9-0.4-13.4-1.7c-1.8-0.5-3.7-1.1-5.5-1.6c-2.9-0.7-5.4-1.9-6.4-5.3c-0.6,0.8-1.1,1.5-1.6,2.2
				c-2.9,3.4-5.9,6.8-8.8,10.2c-2,2.3-4.4,3.3-7.4,2.5c-2.9-0.8-4.5-2.8-5.1-5.7c-0.1-0.3-0.4-0.7-0.7-0.9c-5.3-2-6.6-7.7-2.6-11.7
				c0.6-0.6,1.3-1.2,2-1.6c8.6-5,17.3-9.9,26-14.9c3.5-2,7.1-2.2,10.9-1.3c3.8,1,7.6,2.2,11.5,3c3,0.6,5.7-0.4,8-2.6
				c-0.3-0.5-0.7-1-1-1.5c-0.8-1.7-0.4-3.5,1.1-4.8c4.3-3.8,8.7-7.5,13.1-11.3c0.5-0.4,1.1-0.7,1.7-1.1
				C164,98.2,164.7,98.2,165.4,98.2z M151.1,119.9c-0.3,0.2-0.5,0.3-0.6,0.5c-3.1,2.6-6.7,3.8-10.7,3c-3.8-0.7-7.5-1.9-11.2-2.9
				c-3.2-0.9-6.2-0.6-9.1,1.1c-8.3,4.8-16.6,9.5-24.8,14.2c-0.7,0.4-1.3,0.8-1.8,1.4c-1.7,1.8-1.2,4.6,1.1,5.1c0.8,0.2,1.8,0,2.6-0.3
				c6.5-2.6,13-5.3,19.5-8c0.4-0.2,0.8-0.4,1-0.7c1.5-3,4-3.5,7-3c2.3,0.4,4.6,0.6,6.8,0.9c1.6,0.2,3.1,0.4,4.7,0.7
				c1.1,0.2,1.5,1,1.4,2.1c-0.2,1-0.9,1.5-1.9,1.5c-0.6,0-1.1-0.1-1.7-0.2c-3.6-0.5-7.2-1-10.8-1.4c-1.6-0.2-2.4,0.7-2.4,2.4
				c0.1,1.5,1,2.2,2.3,2.6c3,0.8,6,1.6,9,2.6c3.4,1.1,6.8,1.5,10.3,1c4.1-0.6,8.2-1,11.8-3.4c2.7-1.8,5.3-3.7,8-5.5
				c0.3-0.2,0.5-0.4,0.8-0.7C158.5,128.4,154.8,124.2,151.1,119.9z M168.3,133.9c4.2-3.6,8.2-7,12.2-10.5c1.1-1,1.2-1.3,0.2-2.4
				c-4-4.7-8.1-9.4-12.1-14.1c-1.4-1.6-2.8-3.2-4.2-4.9c-4.5,3.9-9,7.8-13.5,11.7C156.7,120.5,162.5,127.2,168.3,133.9z M108.3,141.4
				c-0.1-0.1-0.1-0.1-0.2-0.2c-3.4,1.4-6.8,2.8-10.2,4.3c-0.3,0.1-0.5,0.8-0.4,1.2c0.6,2.3,3.6,2.9,5.3,1.1
				C104.6,145.7,106.4,143.6,108.3,141.4z" />
						<path class="st1" d="M82.4,194.9c3.7,4.3,7.4,8.6,11,12.7c1.8-1,3.5-2.1,5.3-2.8c2.9-1.1,5.9-0.8,8.8,0c2.7,0.7,5.4,1.5,8.1,2.3
				c1,0.3,1.8,0.8,1.9,2c0.1,1.4-1.2,2.2-2.8,1.8c-2.8-0.7-5.5-1.5-8.2-2.3c-2.1-0.6-4.2-0.8-6.3-0.1c-1.4,0.5-2.7,1.4-4,2.1
				c0.5,1,1,1.6,1.2,2.3c0.6,1.7,0.1,3.1-1.2,4.3c-4.3,3.8-8.7,7.6-13.1,11.3c-1.9,1.6-3.9,1.4-5.6-0.5c-4-4.5-7.9-9-11.9-13.6
				c-1.7-1.9-3.4-3.8-5.1-5.8c-2-2.3-1.9-4.4,0.4-6.4c4-3.5,8-6.9,12-10.4c2.7-2.3,4.4-2.3,7.1,0.3c1.4-1,2.7-2.1,4.2-3.1
				c3.5-2.3,6.8-5,11-5.9c3.7-0.9,7.6-1.6,11.4-1.6c3.2,0,6.4,1.1,9.6,1.8c1.7,0.4,3.4,1,5.2,1.4c3,0.8,5.9,1.7,7,5.4
				c0.6-0.8,1-1.4,1.5-2c3-3.5,6.1-7.1,9.1-10.6c1.9-2.2,4.3-3.1,7.1-2.3c2.8,0.8,4.6,2.7,5.1,5.6c0.1,0.4,0.5,0.8,0.8,0.9
				c5.5,2,6.6,8.4,2.1,12.2c-0.5,0.4-1,0.8-1.6,1.1c-8.5,4.9-17,9.7-25.5,14.6c-0.3,0.2-0.7,0.4-1,0.5c-1.4,0.7-2.4,0.5-3-0.5
				c-0.6-1.1-0.2-2.1,1.2-2.9c8.6-4.9,17.3-9.8,25.9-14.8c0.9-0.5,1.7-1.2,2.3-2c0.9-1,0.8-2.2,0.1-3.3c-0.7-1.1-1.7-1.6-3-1.3
				c-0.6,0.1-1.2,0.3-1.7,0.5c-6.3,2.6-12.5,5.1-18.8,7.8c-0.6,0.2-1.1,0.7-1.5,1.3c-1.3,2-3.1,3-5.5,2.7c-4.1-0.5-8.1-1.1-12.2-1.6
				c-1.5-0.2-2.3-1.1-2.1-2.2c0.2-1.2,1.2-1.8,2.6-1.6c3.8,0.5,7.6,1,11.4,1.5c1.5,0.2,2.2-0.2,2.6-1.4c0.4-1.3-0.1-2.6-1.3-3.1
				c-0.8-0.4-1.8-0.7-2.7-0.9c-3.8-1-7.5-2.4-11.4-3c-2.8-0.4-5.7,0.1-8.5,0.3c-5.5,0.3-9.8,3.1-14.1,6.1
				C85.1,192.8,83.8,193.8,82.4,194.9z M93.8,213.9c-5.8-6.7-11.6-13.4-17.4-20.2c-4.5,3.9-8.9,7.7-13.5,11.6
				c5.9,6.7,11.7,13.4,17.6,20.1C84.9,221.6,89.3,217.8,93.8,213.9z M136.5,186.1c0.1,0.1,0.1,0.2,0.2,0.3c3.4-1.4,6.8-2.8,10.2-4.3
				c0.2-0.1,0.4-0.8,0.3-1.1c-0.5-2.2-3.4-3-5.1-1.3C140.1,181.8,138.3,184,136.5,186.1z" />
						<path class="st5" d="M122.7,154.9c4.2-4.6,9.4-4,12.2-1.8c1.2,0.9,2.2,2.3,3,3.6c0.7,1,0.2,2.2-0.7,2.6c-0.9,0.5-2,0.2-2.6-0.9
				c-1-1.7-2.3-2.9-4.4-3.1c-1.7-0.2-3.2,0.5-4.3,1.8c-0.5,0.6-0.9,1.2-1.5,1.6c-1.4,1.1-3.2,0.7-4.5-1.1c-1.8-2.5-4.5-3-7.1-1.5
				c-2,1.2-2.8,3.7-2.1,6.6c0.8,3.5,2.8,6.4,5.5,8.7c1.8,1.5,3.8,2.7,5.8,4c0.3,0.2,0.9,0.2,1.2,0c4.2-2.3,7.8-5.1,10.2-9.4
				c0.7-1.3,1.8-1.7,2.8-1.1c1,0.6,1.2,1.7,0.4,3c-2.9,5.3-7.5,8.8-12.8,11.4c-0.7,0.3-1.7,0.4-2.3,0.1c-5.8-2.8-10.7-6.6-13.5-12.5
				c-1.5-3.1-2.1-6.4-0.9-9.7c2.2-6.2,10.3-7.9,14.9-3.1C122.3,154.4,122.4,154.6,122.7,154.9z" />
					</g>
					<path class="st0"
						d="M198.3,274.6H0V60.7C0,27.2,27.2,0,60.7,0H259v213.8C259,247.4,231.8,274.6,198.3,274.6z" />
					<g>
						<text transform="matrix(1 0 0 1 51.0639 47.9313)">
							<tspan x="0" y="0" class="st1 st2 st3">Personas en</tspan>
							<tspan x="-33.9" y="37.6" class="st1 st2 st3">situación de calle</tspan>
						</text>
					</g>
					<polyline class="st4" points="109.1,243.8 122.3,257.9 133.9,243.8 	" />
					<g>
						<path class="st1" d="M165.4,98.2c1,0.9,2.2,1.6,3,2.6c5.1,5.9,10.1,11.8,15.2,17.7c0.6,0.7,1.2,1.5,1.4,2.4
				c0.5,1.8,0.1,3.4-1.3,4.7c-4.3,3.8-8.6,7.5-13,11.2c-1.8,1.5-3.7,1.2-6.1-1c-0.4,0.3-0.8,0.6-1.3,0.9c-3.2,2.1-6.3,4.3-9.5,6.3
				c-3.7,2.3-8,2.4-12.1,3.2c-4.7,0.8-9-0.4-13.4-1.7c-1.8-0.5-3.7-1.1-5.5-1.6c-2.9-0.7-5.4-1.9-6.4-5.3c-0.6,0.8-1.1,1.5-1.6,2.2
				c-2.9,3.4-5.9,6.8-8.8,10.2c-2,2.3-4.4,3.3-7.4,2.5c-2.9-0.8-4.5-2.8-5.1-5.7c-0.1-0.3-0.4-0.7-0.7-0.9c-5.3-2-6.6-7.7-2.6-11.7
				c0.6-0.6,1.3-1.2,2-1.6c8.6-5,17.3-9.9,26-14.9c3.5-2,7.1-2.2,10.9-1.3c3.8,1,7.6,2.2,11.5,3c3,0.6,5.7-0.4,8-2.6
				c-0.3-0.5-0.7-1-1-1.5c-0.8-1.7-0.4-3.5,1.1-4.8c4.3-3.8,8.7-7.5,13.1-11.3c0.5-0.4,1.1-0.7,1.7-1.1
				C164,98.2,164.7,98.2,165.4,98.2z M151.1,119.9c-0.3,0.2-0.5,0.3-0.6,0.5c-3.1,2.6-6.7,3.8-10.7,3c-3.8-0.7-7.5-1.9-11.2-2.9
				c-3.2-0.9-6.2-0.6-9.1,1.1c-8.3,4.8-16.6,9.5-24.8,14.2c-0.7,0.4-1.3,0.8-1.8,1.4c-1.7,1.8-1.2,4.6,1.1,5.1c0.8,0.2,1.8,0,2.6-0.3
				c6.5-2.6,13-5.3,19.5-8c0.4-0.2,0.8-0.4,1-0.7c1.5-3,4-3.5,7-3c2.3,0.4,4.6,0.6,6.8,0.9c1.6,0.2,3.1,0.4,4.7,0.7
				c1.1,0.2,1.5,1,1.4,2.1c-0.2,1-0.9,1.5-1.9,1.5c-0.6,0-1.1-0.1-1.7-0.2c-3.6-0.5-7.2-1-10.8-1.4c-1.6-0.2-2.4,0.7-2.4,2.4
				c0.1,1.5,1,2.2,2.3,2.6c3,0.8,6,1.6,9,2.6c3.4,1.1,6.8,1.5,10.3,1c4.1-0.6,8.2-1,11.8-3.4c2.7-1.8,5.3-3.7,8-5.5
				c0.3-0.2,0.5-0.4,0.8-0.7C158.5,128.4,154.8,124.2,151.1,119.9z M168.3,133.9c4.2-3.6,8.2-7,12.2-10.5c1.1-1,1.2-1.3,0.2-2.4
				c-4-4.7-8.1-9.4-12.1-14.1c-1.4-1.6-2.8-3.2-4.2-4.9c-4.5,3.9-9,7.8-13.5,11.7C156.7,120.5,162.5,127.2,168.3,133.9z M108.3,141.4
				c-0.1-0.1-0.1-0.1-0.2-0.2c-3.4,1.4-6.8,2.8-10.2,4.3c-0.3,0.1-0.5,0.8-0.4,1.2c0.6,2.3,3.6,2.9,5.3,1.1
				C104.6,145.7,106.4,143.6,108.3,141.4z" />
						<path class="st1" d="M82.4,194.9c3.7,4.3,7.4,8.6,11,12.7c1.8-1,3.5-2.1,5.3-2.8c2.9-1.1,5.9-0.8,8.8,0c2.7,0.7,5.4,1.5,8.1,2.3
				c1,0.3,1.8,0.8,1.9,2c0.1,1.4-1.2,2.2-2.8,1.8c-2.8-0.7-5.5-1.5-8.2-2.3c-2.1-0.6-4.2-0.8-6.3-0.1c-1.4,0.5-2.7,1.4-4,2.1
				c0.5,1,1,1.6,1.2,2.3c0.6,1.7,0.1,3.1-1.2,4.3c-4.3,3.8-8.7,7.6-13.1,11.3c-1.9,1.6-3.9,1.4-5.6-0.5c-4-4.5-7.9-9-11.9-13.6
				c-1.7-1.9-3.4-3.8-5.1-5.8c-2-2.3-1.9-4.4,0.4-6.4c4-3.5,8-6.9,12-10.4c2.7-2.3,4.4-2.3,7.1,0.3c1.4-1,2.7-2.1,4.2-3.1
				c3.5-2.3,6.8-5,11-5.9c3.7-0.9,7.6-1.6,11.4-1.6c3.2,0,6.4,1.1,9.6,1.8c1.7,0.4,3.4,1,5.2,1.4c3,0.8,5.9,1.7,7,5.4
				c0.6-0.8,1-1.4,1.5-2c3-3.5,6.1-7.1,9.1-10.6c1.9-2.2,4.3-3.1,7.1-2.3c2.8,0.8,4.6,2.7,5.1,5.6c0.1,0.4,0.5,0.8,0.8,0.9
				c5.5,2,6.6,8.4,2.1,12.2c-0.5,0.4-1,0.8-1.6,1.1c-8.5,4.9-17,9.7-25.5,14.6c-0.3,0.2-0.7,0.4-1,0.5c-1.4,0.7-2.4,0.5-3-0.5
				c-0.6-1.1-0.2-2.1,1.2-2.9c8.6-4.9,17.3-9.8,25.9-14.8c0.9-0.5,1.7-1.2,2.3-2c0.9-1,0.8-2.2,0.1-3.3c-0.7-1.1-1.7-1.6-3-1.3
				c-0.6,0.1-1.2,0.3-1.7,0.5c-6.3,2.6-12.5,5.1-18.8,7.8c-0.6,0.2-1.1,0.7-1.5,1.3c-1.3,2-3.1,3-5.5,2.7c-4.1-0.5-8.1-1.1-12.2-1.6
				c-1.5-0.2-2.3-1.1-2.1-2.2c0.2-1.2,1.2-1.8,2.6-1.6c3.8,0.5,7.6,1,11.4,1.5c1.5,0.2,2.2-0.2,2.6-1.4c0.4-1.3-0.1-2.6-1.3-3.1
				c-0.8-0.4-1.8-0.7-2.7-0.9c-3.8-1-7.5-2.4-11.4-3c-2.8-0.4-5.7,0.1-8.5,0.3c-5.5,0.3-9.8,3.1-14.1,6.1
				C85.1,192.8,83.8,193.8,82.4,194.9z M93.8,213.9c-5.8-6.7-11.6-13.4-17.4-20.2c-4.5,3.9-8.9,7.7-13.5,11.6
				c5.9,6.7,11.7,13.4,17.6,20.1C84.9,221.6,89.3,217.8,93.8,213.9z M136.5,186.1c0.1,0.1,0.1,0.2,0.2,0.3c3.4-1.4,6.8-2.8,10.2-4.3
				c0.2-0.1,0.4-0.8,0.3-1.1c-0.5-2.2-3.4-3-5.1-1.3C140.1,181.8,138.3,184,136.5,186.1z" />
						<path class="st5" d="M122.7,154.9c4.2-4.6,9.4-4,12.2-1.8c1.2,0.9,2.2,2.3,3,3.6c0.7,1,0.2,2.2-0.7,2.6c-0.9,0.5-2,0.2-2.6-0.9
				c-1-1.7-2.3-2.9-4.4-3.1c-1.7-0.2-3.2,0.5-4.3,1.8c-0.5,0.6-0.9,1.2-1.5,1.6c-1.4,1.1-3.2,0.7-4.5-1.1c-1.8-2.5-4.5-3-7.1-1.5
				c-2,1.2-2.8,3.7-2.1,6.6c0.8,3.5,2.8,6.4,5.5,8.7c1.8,1.5,3.8,2.7,5.8,4c0.3,0.2,0.9,0.2,1.2,0c4.2-2.3,7.8-5.1,10.2-9.4
				c0.7-1.3,1.8-1.7,2.8-1.1c1,0.6,1.2,1.7,0.4,3c-2.9,5.3-7.5,8.8-12.8,11.4c-0.7,0.3-1.7,0.4-2.3,0.1c-5.8-2.8-10.7-6.6-13.5-12.5
				c-1.5-3.1-2.1-6.4-0.9-9.7c2.2-6.2,10.3-7.9,14.9-3.1C122.3,154.4,122.4,154.6,122.7,154.9z" />
					</g>
				</g>
			</svg>
		</div>
	</div>


	<div class="row font-dinn-bold text-center text-white fs-3">
		<h1> Fundaciones </h1>
	</div>
	<div class="row font-dinn-regular text-justify text-white fs-3">
		<span> Nuestras Fundaciones Súmate y Emplea suman su labor a nuestras líneas de acción de "Infancia y Adolescencia"
			y "Adultez", donde trabajan por la reinserción escolar y laboral, respectivamente. </span>
	</div>

	<div class="row justify-content-center m-5">
		<div class="col-6 tamagno-card" @click="openModalFundacioEmplea">
			<img class="img-redaccion-solidaria card-landing cursor my-2" src="../../assets/image/inclusion/Emplea.png" alt="Fundación emplea"/>
		</div>
		<div class="col-6 tamagno-card" @click="openModalFundacioSumate">
			<img class="img-redaccion-solidaria card-landing cursor my-2" src="../../assets/image/inclusion/Sumate.png" alt="Fundación sumate"/>
		</div>

	</div>

	<!-- MODAL PRINCIPAL INFANCIA Y ADOLESCENCIA -->
	<ModalOpciones ref="modalInfanciaYadolescencia">
		<div class="col">
			<div class="row d-flex text-info text-center">
				<h4><span class="font-dinn-bold text-celeste">Infancia y adolescencia</span></h4>
			</div>
			<div @click="openModalEducacioniniciaInfancia"
				class="py-2 cursor text-gris font-dinn-regular text-center hover-div">Educación Inicial</div>
			<div @click="openModalinfantoadolecenteInfancia"
				class="py-2 cursor text-gris font-dinn-regular text-center hover-div">Infanto Adolescente</div>
			<div @click="openModalReinsercioneducativaInfancia"
				class="py-2 cursor text-gris font-dinn-regular text-center hover-div">Reinserción Educativa</div>
		</div>
	</ModalOpciones>

	<!-- MODAL PRINCIPAL ADULTEZ -->
	<ModalOpciones ref="ModalAdultezMain">
		<div class="col justify-content-center align-items-center">
			<div class="row d-flex text-info text-center">
				<h4><span class="font-dinn-bold text-celeste">Adultez</span></h4>
			</div>
			<div @click="openModalConsumodeDrogas" class="py-2 cursor text-gris font-dinn-regular text-center hover-div">
				Consumo Problemático de Alcohol y otras Drogas</div>
			<div @click="openModalDiscapacidadmentalAdultez"
				class="py-2 cursor text-gris font-dinn-regular text-center hover-div">Discapacidad Mental</div>
			<div @click="openModalInclusionLaboralAdultez"
				class="py-2 cursor text-gris font-dinn-regular text-center hover-div">Inclusión Laboral</div>
		</div>
	</ModalOpciones>

	<!-- --------------------------------------------------------------- -->

	<!-- LANDING EDUCACIÓN INICIAL -->
	<ModalTematica ref="ModalEducacioniniciaInfancia" :other-modal-ref="modalInfanciaYadolescencia">
		<div>
			<h1 class="text-center text-white font-dinn-bold">Infancia y adolescencia</h1>
			<div class="colorceleste">
				<div class="text-white text-center sub-titulo-card">
					<div style="height:25px"></div>
					<h3>Educación Inicial</h3>
					<div class="text-gris text-justify d-flex">
						Ofrecemos educación inicial de calidad a los sectores más vulnerables del país, integrando un
						aprendizaje adecuado al nivel de cada niño y su entorno familiar
					</div>
					<div style="height:25px"></div>
					<div class="row">
						<div class="col">
							<span class="text-underline font-dinn-medium">Total N° de atendidos</span>
							<br>
							<span class="text-cifra">8.958</span>
						</div>
						<div class="col">
							<span class="text-underline font-dinn-medium">Total N° de programas</span>
							<br>
							<span class="text-cifra">35</span>
						</div>
					</div>
					<div>
						<span class="text-underline font-dinn-medium">Promedio de edad </span>
						<br>
						<span class="text-cifra">2 Años</span>
					</div>
				</div>
				<div @click="openModalGeneroEducacion"
					class=" py-2 cursor font-dinn-regular text-gris text-center hover-div2">Personas atendidas según
					género</div>
				<div @click="openModalAtencionEducacion"
					class=" py-2 cursor font-dinn-regular text-gris text-center hover-div2">Personas atendidas según
					nacionalidad</div>
				<div @click="openModalPobrezaEducacionInicial"
					class=" py-2 cursor font-dinn-regular text-gris text-center hover-div2">Atencion según tipo de
					pobreza</div>
				<div @click="openModalCostoEducacioninicial"
					class=" py-2 cursor font-dinn-regular text-gris text-center hover-div2">Costos por usuario</div>
				<div class="text-center py-2 cursor text-white text-underline ">
					<a href="https://www.hogardecristo.cl/educacion-inicial/" target="_blank">Ver más</a>
				</div>
			</div>
		</div>


	</ModalTematica>

	<!-- LANDING INFANTO ADOLESCENTE -->
	<ModalTematica ref="ModalinfantoadolecenteInfancia" :other-modal-ref="modalInfanciaYadolescencia">
		<h1 class="text-center text-white font-dinn-bold">Infancia y adolescencia</h1>
		<div class="text-white text-center sub-titulo-card">
			<h3>Infanto Adolescente</h3>
			<div class="text-gris text-justify d-flex">
				Contamos con cuatro residencias de protección a lo largo del país que buscan restablecer el derecho
				a
				vivir en un contexto familiar.
			</div>
			<div class="row">
				<div class="col">
					<span class="text-underline font-dinn-medium">Total N° de atendidos</span>
					<br>
					<span class="text-cifra">82</span>
				</div>
				<div class="col">
					<span class="text-underline font-dinn-medium">Total N° de programas </span>
					<br>
					<span class="text-cifra">4</span>
				</div>
			</div>
			<div>
				<span class="text-underline font-dinn-medium"> Promedio de edad</span>
				<br>
				<span class="text-cifra">15 Años</span>
			</div>
		</div>
		<div @click="openModalGeneroInfanto" class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">
			Personas atendidas según género</div>
		<div @click="openModalAtencionInfanto" class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">
			Personas atendidas según
			nacionalidad</div>
		<div @click="openModalPobrezaInfanto" class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">
			Atencion según tipo de pobreza
		</div>
		<div @click="openModalCostoInfanto" class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">
			Costos por usuario</div>
		<div class="text-center py-2 cursor text-underline text-white">
			<a href="https://www.hogardecristo.cl/mejor-ninez/" target="_blank">Ver más</a>
		</div>

	</ModalTematica>

	<!-- LANDING MAIN ADULTO MAYOR -->
	<ModalTematica ref="ModalAdultoMayor" :other-modal-ref="ModalAdultezMain">
		<div>
			<h3 class="text-center text-white font-dinn-bold">Adulto Mayor</h3>
			<div class="colorceleste">
				<div class="text-white text-center sub-titulo-card">
					<div style="height:25px"></div>
					<div class="text-gris text-justify d-flex">
						Reconocemos a los adultos mayores como sujetos de derechos, ciudadanos activos e incluidos. Nuestra
						red de servicios fomenta el desarrollo y mantención de las funcionalidades de los adultos mayores
						para, desde ahí, mejorar su nivel de inclusión social y calidad de vida.
					</div>
					<div style="height:25px"></div>
					<div class="row">
						<div class="col">
							<span class="text-underline font-dinn-medium">Total N° de atendidos</span>
							<br />
							<span class="text-cifra">3.236</span>
						</div>
						<div class="col">
							<span class="text-underline font-dinn-medium">Total N° de programas</span>
							<br />
							<span class="text-cifra">53</span>
						</div>
					</div>
					<div>
						<span class="text-underline font-dinn-medium">Promedio de edad </span>
						<br />
						<span class="text-cifra">75 Años</span>
					</div>
				</div>
				<div @click="openModalGeneroAdultoMayor"
					class=" py-2 cursor font-dinn-regular text-gris text-center hover-div2">Personas atendidas según
					género</div>
				<div @click="openModalAtencionAdultoMayor"
					class=" py-2 cursor font-dinn-regular text-gris text-center hover-div2">Personas atendidas según
					nacionalidad</div>
				<div @click="openModalPobrezaAdultoMayorr"
					class=" py-2 cursor font-dinn-regular text-gris text-center hover-div2">Atencion según tipo de
					pobreza</div>
				<div @click="openModalCostosAdultoMayor"
					class=" py-2 cursor font-dinn-regular text-gris text-center hover-div2">Costos por usuario</div>
				<div class="text-center py-2 cursor text-white text-underline">
					<a href="https://www.hogardecristo.cl/adulto-mayor/" target="_blank">Ver más</a>
				</div>
			</div>
		</div>
	</ModalTematica>

	<!-- LANDING MAIN DISCAPACIDAD MENTAL ADULTEZ -->
	<ModalTematica :other-modal-ref="ModalAdultezMain" ref="ModalDiscapacidadmentalAdultez">
		<div>
			<h1 class="text-center text-white font-dinn-bold">Adultez</h1>
			<div class="colorceleste">
				<div class="text-white text-center sub-titulo-card">
					<div style="height:25px"></div>
					<h3>Discapacidad mental</h3>
					<div class="text-gris text-justify d-flex">
						A través de tres programas -residencial, ambulatorio y domiciliario-, entregamos apoyo social a
						personas adultas en situación de pobreza y exclusión social con discapacidad mental (psíquica y/o
						intelectual) y sus familias, para favorecer el ejercicio de sus derechos fundamentales.
					</div>
					<div style="height:25px"></div>
					<div class="row">
						<div class="col">
							<span class="text-underline font-dinn-medium">Total N° de atendidos</span>
							<br>
							<span class="text-cifra">1.757</span>
						</div>
						<div class="col">
							<span class="text-underline font-dinn-medium">Total N° de programas</span>
							<br>
							<span class="text-cifra">43</span>
						</div>
					</div>
					<div>
						<span class="text-underline font-dinn-medium">Promedio de edad </span>
						<br>
						<span class="text-cifra">39 Años</span>
					</div>
				</div>
				<div @click="openModalGeneroDis" class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">
					Personas atendidas según género</div>
				<div @click="openModalAteDul" class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">
					Personas atendidas según nacionalidad</div>
				<div @click="openModalPobAdultez" class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">
					Atencion según tipo de pobreza</div>
				<div @click="openModalCosAdultez" class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">
					Costos por usuario</div>
				<div class="text-center py-2 cursor text-underline text-white">
					<a href="https://www.hogardecristo.cl/discapacidad-mental/" target="_blank">Ver más</a>
				</div>
			</div>
		</div>
	</ModalTematica>

	<!-- LANDING MAIN INCLUSIÓN LABORAL ADULTEZ -->
	<ModalTematica :other-modal-ref="ModalAdultezMain" ref="ModalInclusionLaboralAdultez">
		<div>
			<h1 class="text-center text-white font-dinn-bold">Adultez</h1>
			<div class="colorceleste">
				<div class="text-white text-center sub-titulo-card">
					<div style="height:25px"></div>
					<h3>Inclusión laboral</h3>
					<div class="text-gris text-justify d-flex">
						A través de Fundación Emplea, trabajamos por alcanzar la inclusión laboral de estas personas que se
						encuentran desempleadas e inactivas y que viven en situación de pobreza monetaria y
						multidimensional.
					</div>
					<div style="height:25px"></div>
					<div class="row">
						<div class="col">
							<span class="text-underline font-dinn-medium">Total N° de atendidos </span>
							<br>
							<span class="text-cifra">6.234</span>
						</div>
						<div class="col">
							<span class="text-underline font-dinn-medium">Total N° de programas</span>
							<br>
							<span class="text-cifra">10</span>
						</div>
					</div>
					<div>
						<span class="text-underline font-dinn-medium">Promedio de edad </span>
						<br>
						<span class="text-cifra">38 Años</span>
					</div>
				</div>
				<div @click="openModalGenInclusion" class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">
					Personas atendidas según género</div>
				<div @click="openModalnanInclusion" class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">
					Personas atendidas según nacionalidad</div>
				<div @click="openModalPobInclusion" class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">
					Atencion según tipo de pobreza</div>
				<div @click="openModalCosInclusion" class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">
					Costos por usuario</div>
				<div class="text-center py-2 cursor text-underline text-white">
					<a href="https://www.hogardecristo.cl/inclusion-laboral/" target="_blank">Ver más</a>
				</div>
			</div>
		</div>
	</ModalTematica>

	<!-- LANDING MAIN DROGAS Y ALCOHOLISMO -->
	<ModalTematica :other-modal-ref="ModalAdultezMain" ref="ModalConsumodeDrogas">
		<div>
			<h1 class="text-center text-white font-dinn-bold">Adultez</h1>
			<div class="colorceleste">
				<div class="text-white text-center sub-titulo-card">
					<div style="height:25px"></div>
					<h3>Consumo Problemático de Alcohol y <br> otras Drogas</h3>
					<div class="text-gris text-justify d-flex">
						Entregamos apoyo y acompañamiento terapéutico a este segmento de manera gratuita y especializada, ya
						que entendemos que este problema es una respuesta adaptativa a las continuas experiencias de
						carencias y exclusión.
					</div>
					<div style="height:25px"></div>
					<div class="row">
						<div class="col">
							<span class="text-underline font-dinn-medium">Total N° de atendidos</span>
							<br>
							<span class="text-cifra">493</span>
						</div>
						<div class="col">
							<span class="text-underline font-dinn-medium">Total N° de programas </span>
							<br>
							<span class="text-cifra">10</span>
						</div>
					</div>
					<div>
						<span class="text-underline font-dinn-medium">Promedio de edad <br></span>
						<span class="text-cifra">29 Años</span>
					</div>
				</div>
				<div @click="openModalGeneroDiscapacidadAdulez"
					class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">Personas atendidas según
					género</div>
				<div @click="openModalAtencionDiscapacidadAdultez"
					class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">Personas atendidas según
					nacionalidad</div>
				<div @click="openModalPobrezaDiscapacidadAdultez"
					class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">Atencion según tipo de
					pobreza</div>
				<div @click="openModalCostosDiscapacidadAdultez"
					class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">Costos por usuario</div>
				<div class="text-center py-2 cursor text-white text-underline ">
					<a href="https://www.hogardecristo.cl/consumo-problematico/" target="_blank">Ver más</a>
				</div>
			</div>
		</div>
	</ModalTematica>

	<!-- LANDING REINSERCIÓN EDUCATIVA -->
	<ModalTematica :other-modal-ref="modalInfanciaYadolescencia" ref="ModalReinsercioneducativaInfancia">
		<div>
			<h1 class="text-center text-white font-dinn-bold">Infancia y adolescencia</h1>
			<div class="colorceleste">
				<div class="text-white text-center sub-titulo-card">
					<div style="height:25px"></div>
					<h3>Reinserción Educativa</h3>
					<div class="text-gris text-justify d-flex">
						La exclusión es un problema multidimensional que requiere ser abordado desde varios ámbitos. A
						través de Fundación Súmate trabajamos desde hace 30 años en reinserción educativa.
					</div>
					<div style="height:25px"></div>
					<div class="row">
						<div class="col">

							<span class="text-underline font-dinn-medium">Total N° de atendidos </span>
							<br>
							<span class="text-cifra">2.591</span>
						</div>
						<div class="col">
							<span class="text-underline font-dinn-medium">Total N° de programas</span>
							<br>
							<span class="text-cifra">11</span>
						</div>
					</div>
					<div>
						<span class="text-underline font-dinn-medium">Promedio de edad </span>
						<br>
						<span class="text-cifra">16 Años</span>
					</div>
				</div>
				<div @click="openModalGeneroreinsersion"
					class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">Personas atendidas según género
				</div>
				<div @click="openModalNacionalidadreinsersion"
					class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">Personas atendidas según
					nacionalidad</div>
				<div @click="openModalPobrezareinsersion"
					class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">Atención según tipo de pobreza
				</div>
				<div @click="openModalCostoReinsersion"
					class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">Costos por usuario</div>
				<div class="text-center py-2 cursor text-white text-underline">
					<a href="https://www.hogardecristo.cl/reinsercion-educativa/" target="_blank">Ver más</a>
				</div>

			</div>
		</div>
	</ModalTematica>

	<!-- CARD MAIN DE Personas en situación de calle -->
	<ModalTematica ref="ModalCalle" :other-modal-ref="ModalAdultezMain">
		<div>
			<h3 class="text-center text-white font-dinn-bold">Personas en situación de calle</h3>
			<div class="colorceleste">
				<div class="text-white text-center sub-titulo-card">
					<div style="height:25px"></div>
					<div class="text-gris text-justify d-flex">
						Las personas en situación de calle son un grupo de atención prioritario para nuestras Fundaciones,
						por cuanto representan una de las manifestaciones más crudas de la exclusión social.
					</div>
					<div style="height:25px"></div>
					<div class="row">
						<div class="col">
							<span class="text-underline font-dinn-medium">Total N° de atendidos </span>
							<br />
							<span class="text-cifra">9.494</span>
						</div>
						<div class="col">
							<span class="text-underline font-dinn-medium"> Total N° de programas </span>
							<br />
							<span class="text-cifra">90</span>
						</div>
					</div>
					<div>
						<span class="text-underline font-dinn-medium">Promedio de edad </span>
						<br />
						<span class="text-cifra">42 Años</span>
					</div>
				</div>
				<div @click="openModalGenerocalle" class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">
					Personas atendidas según género</div>
				<div @click="openModalAtencioncalle" class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">
					Personas atendidas según nacionalidad</div>
				<div @click="openModalPobrezacalle" class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">
					Atencion según tipo de pobreza</div>
				<div @click="openModalCostoscalle" class="py-2 cursor font-dinn-regular text-gris text-center hover-div2">
					Costos por usuario</div>
				<div class="text-center py-2 cursor text-white text-underline">
					<a href="https://www.hogardecristo.cl/personas-en-situacion-de-calle/" target="_blank">
						Ver más

					</a>
				</div>
			</div>
		</div>
	</ModalTematica>

	<!-- INFANTO ADOLESCENTE -->

	<!-- genero INFANTO ADOLESCENTE -->
	<ModalTematica ref="ModalGeneroInfanto" :other-modal-ref="ModalinfantoadolecenteInfancia">
		<div class="d-flex justify-content-center align-items-center">
			<picture>
				<source :srcset="require('../../assets/Webp/InfanciaYadolescencia/infanto/genero.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/GeneroInfanto.jpg" class="imagencaptiva" alt="Género infanto" />
		</picture>
		</div>
	</ModalTematica>
	<!-- Card Atencion segun nacionalidad de INFANTO ADOLESCENTE -->
	<ModalTematica ref="ModalAtencionInfanto" :other-modal-ref="ModalinfantoadolecenteInfancia">
		<div class="d-flex justify-content-center align-items-center">
			<picture>
				<source :srcset="require('../../assets/Webp/InfanciaYadolescencia/infanto/Nacionalidad.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/InfantoAtencion.jpg" class="imagencaptiva" alt="Atención según nacionalidad infanto"/>
		</picture>
		</div>
	</ModalTematica>
	<!-- Card costps de INFANTO ADOLESCENTE -->
	<ModalTematica ref="ModalCostoInfanto" :other-modal-ref="ModalinfantoadolecenteInfancia">
		<div class="d-flex justify-content-center align-items-center">
			<picture>
				<source :srcset="require('../../assets/Webp/InfanciaYadolescencia/infanto/Costo.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/Costoinfanto.jpg" class="imagencaptiva" alt="Costo infanto"/>
		</picture>
		</div>
	</ModalTematica>
	<!-- Card Tipos de pobreza de INFANTO ADOLESCENTE -->
	<ModalTematica ref="ModalPobrezaInfanto" :other-modal-ref="ModalinfantoadolecenteInfancia">
		<picture>
			<source :srcset="require('../../assets/Webp/InfanciaYadolescencia/infanto/Tipodepobreza.webp' )" type="image/webp">
		<img src="../../assets/image/inclusion/AtencionSegunTipoPobrezaI_A.jpg" class="imagencaptiva" alt="Tipos de pobreza infanto"/>
	</picture>
		<h5 @click="openModalTiposPobrez" class="text-center cursor text-white">Tipos de pobreza</h5>
	</ModalTematica>
	<!--  FIN INFANTO ADOLESCENTE -->

	<!-- --------------------------------------------------------------- -->

	<!-- genero de REINSERCIÓN EDUCATIVA -->
	<ModalTematica ref="ModalGeneroreinsersion" :other-modal-ref="ModalReinsercioneducativaInfancia">
		<div class="d-flex justify-content-center align-items-center">
			<picture>
				<source :srcset="require('../../assets/Webp/InfanciaYadolescencia/Reinsercion/genero.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/Generoreinsersion.jpg" class="imagencaptiva" alt="Género reinserción educativa"/>
		</picture>
		</div>
	</ModalTematica>

	<!--  Atencion segun nacionalidad REINSERCIÓN EDUCATIVA -->
	<ModalTematica ref="ModalNacionalidadreinsersion" :other-modal-ref="ModalReinsercioneducativaInfancia">
		<div class="d-flex justify-content-center align-items-center">
			<picture>
				<source :srcset="require('../../assets/Webp/InfanciaYadolescencia/Reinsercion/Nacionalidad.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/Nacionalidadreisersion.jpg" class="imagencaptiva" alt="Atención según nacionalidad reinserción educativa"/>
		</picture>
		</div>
	</ModalTematica>

	<!-- Card costos REINSERCIÓN EDUCATIVA -->
	<ModalTematica ref="ModalCostoReinsersion" :other-modal-ref="ModalReinsercioneducativaInfancia">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/InfanciaYadolescencia/Reinsercion/Costo.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/Costosreinsersion.jpg" class="imagencaptiva" alt="Costo reinserción educativa"/>
		</picture>
		</div>
	</ModalTematica>

	<!-- Card Tipos de pobreza de REINSERCIÓN EDUCATIVA -->
	<ModalTematica ref="ModalPobrezareinsersion" :other-modal-ref="ModalReinsercioneducativaInfancia">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/InfanciaYadolescencia/Reinsercion/Tipodepobreza.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/Pobrezareinsersion.jpg" class="imagencaptiva" alt="Tipos de pobreza reinserción educativa"/>
		</picture>
		</div>
		<div>
			<h5 @click="openModalTiposPobrez" class="text-center cursor text-white">Tipos de pobreza</h5>
		</div>
	</ModalTematica>

	<!-- --------------------------------------------------------------- -->
	<!-- Card genero de EDUCACIÓN INICIAL -->
	<ModalTematica ref="ModalGeneroEducacion" :other-modal-ref="ModalEducacioniniciaInfancia">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/InfanciaYadolescencia/Educacion/genero.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/Generoeducacion.jpg" class="imagencaptiva" alt="Género educación"/>
		</picture>
		</div>
	</ModalTematica>

	<!-- Card Atencion segun nacionalidad de EDUCACIÓN INICIAL -->
	<ModalTematica ref="ModalAtencionEducacion" :other-modal-ref="ModalEducacioniniciaInfancia">
		<div class="d-flex justify-content-center align-items-center">
			<picture>
				<source :srcset="require('../../assets/Webp/InfanciaYadolescencia/Educacion/Nacionalidad.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/Nacionalidadeducacion.jpg" class="imagencaptiva" alt="Atención según nacionalidad educación inicial"/>
		</picture>
		</div>
	</ModalTematica>

	<!-- Card costps de EDUCACIÓN INICIAL -->
	<ModalTematica ref="ModalCostoEducacioninicial" :other-modal-ref="ModalEducacioniniciaInfancia">
		<div class="d-flex justify-content-center align-items-center">
			<picture>
				<source :srcset="require('../../assets/Webp/InfanciaYadolescencia/Educacion/Costo.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/Costoseducacion.jpg" class="imagencaptiva" alt="Costo educación inicial"/>
		</picture>
		</div>
	</ModalTematica>

	<!-- Card Tipos de pobreza EDUCACIÓN INICIAL -->
	<ModalTematica ref="ModalPobrezaEducacionInicial" :other-modal-ref="ModalEducacioniniciaInfancia">
		<div class="d-flex justify-content-center align-items-center">
			<picture>
				<source :srcset="require('../../assets/Webp/InfanciaYadolescencia/Educacion/Tipodepobreza.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/PobrezaEducacionInicial.jpg" class="imagencaptiva" alt="Pobreza educación inicial"/>
		</picture>
		</div>
		<div>
			<h5 @click="openModalTiposPobrez" class="text-center cursor text-white">Tipos de pobreza</h5>
		</div>
	</ModalTematica>

	<!-- --------------------------------------------------------------- -->

	<!-- TIPOS DE POBREZA -->
	<ModalTematica ref="ModalTiposPobrez">
		<div class="col center-content">
			<img src="../../assets/image/LogoHcMini.png" class="logomini" alt="Logo Fundación Súmate" />
		</div>
		<div class="col center-content">
			<h3 class="text-center text-white pt-3">Tipos de pobreza</h3>
		</div>
		<div class="col text-white pb-4">
			<div style="height:20px"></div>
			<span class="font-dinn-bold">Pobreza monetaria extrema:</span><br />
			Corresponde a hogares que cuentan con ingresos inferiores a 2/3 del valor de la línea de pobreza e implica que
			un hogar no cuenta con ingresos suficientes para cubrir las necesidades básicas esenciales.
			<br />
			<br />
			<span class="font-dinn-bold">Pobreza monetaria:</span><br />
			Es el porcentaje de hogares de nuestros usuarios, cuyos ingresos o consumos per cápita es inferiores al valor de
			una canasta mínima de alimentos. (Cifras Ministerio de Desarrollo Social; 2018).
			<br />
			<br />
			<span class="font-dinn-bold">Sin pobreza monetaria:</span><br />
			Es el porcentaje de hogares de nuestros usuarios, cuyos ingresos totales son mayores a la línea de pobreza según
			el tamaño del hogar. (Cifras Ministerio de Desarrollo Social; 2018).
			<br />
		</div>
	</ModalTematica>

	<!-- --------------------------------------------------------------- -->

	<!-- Card genero de calle -->
	<ModalTematica ref="ModalGenerocalle" :other-modal-ref="ModalCalle">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/SituacionDeCalle/genero.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/GeneroCalle.jpg" class="imagencaptiva" alt="Genéro personas en situación de calle"/>
		</picture>
		</div>
	</ModalTematica>
	<!-- Card Atencion de calle -->
	<ModalTematica ref="ModalAtencioncalle" :other-modal-ref="ModalCalle">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/SituacionDeCalle/Nacionalidad.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/NacionalidadCalle.jpg" class="imagencaptiva" alt="Atención personas en situación de calle"/>
		</picture>
		</div>
	</ModalTematica>
	<!-- Card Costos de calle -->
	<ModalTematica ref="ModalCostoscalle" :other-modal-ref="ModalCalle">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/SituacionDeCalle/Costo.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/CostosUsuarios.jpg" class="imagencaptiva" alt="Costo personas en situación de calle"/>
		</picture>
		</div>
	</ModalTematica>
	<!-- Card tipos de pobrezade calle -->
	<ModalTematica ref="ModalPobrezacalle" :other-modal-ref="ModalCalle">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/SituacionDeCalle/Tipodepobreza.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/PobrezaCalle.jpg" class="imagencaptiva" alt="Pobreza personas en situación de calle"/>
		</picture>
		</div>
		<div>
			<h5 @click="openModalTiposPobrezcalle" class="text-center cursor text-white">Tipos de pobreza</h5>
		</div>
	</ModalTematica>

	<ModalTematica ref="ModalTiposPobrezcalle">
		<div class="col center-content">
			<img src="../../assets/image/LogoHcMini.png" class="logomini" alt="Logo Fundación Súmate" />
		</div>
		<div class="col center-content">
			<h3 class="text-center text-white pt-3">Tipos de pobreza</h3>
		</div>
		<div class="col text-white pb-4">
			<div style="height:20px"></div>
			<span class="font-dinn-bold">Pobreza monetaria extrema:</span><br />
			Corresponde a hogares que cuentan con ingresos inferiores a 2/3 del valor de la línea de pobreza e implica que
			un hogar no cuenta con ingresos suficientes para cubrir las necesidades básicas esenciales.
			<br />
			<br />
			<span class="font-dinn-bold">Pobreza monetaria:</span><br />
			Es el porcentaje de hogares de nuestros usuarios, cuyos ingresos o consumos per cápita es inferiores al valor de
			una canasta mínima de alimentos. (Cifras Ministerio de Desarrollo Social; 2018).
			<br />
			<br />
			<span class="font-dinn-bold">Sin pobreza monetaria:</span><br />
			Es el porcentaje de hogares de nuestros usuarios, cuyos ingresos totales son mayores a la línea de pobreza según
			el tamaño del hogar. (Cifras Ministerio de Desarrollo Social; 2018).
			<br />
		</div>
	</ModalTematica>

	<!-- Card genero de Adulto Mayor -->
	<ModalTematica ref="ModalGeneroAdultoMayor" :other-modal-ref="ModalAdultoMayor" >
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/AdultoMayor/Genero.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/GeneroAdultoMayor.jpg" class="imagencaptiva" alt="Género adulto mayor"/>
		</picture>
		</div>
	</ModalTematica>
	<!-- Card Atencion de Adulto Mayor -->
	<ModalTematica ref="ModalAtencionAdultoMayor" :other-modal-ref="ModalAdultoMayor">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/AdultoMayor/Nacionalidad.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/nacionalidadAdultoMayor.jpg" class="imagencaptiva" alt="Atención adulto mayor"/>
		</picture>
		</div>
	</ModalTematica>
	<!-- Card Costos de Adulto Mayor -->
	<ModalTematica ref="ModalCostosAdultoMayor" :other-modal-ref="ModalAdultoMayor">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/AdultoMayor/Costo.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/CostosAdultoMayor.jpg" class="imagencaptiva" alt="Costos adulto mayor"/>
		</picture>
		</div>
	</ModalTematica>
	<!-- Card tipos de pobrezade Adulto Mayor -->
	<ModalTematica ref="ModalPobrezaAdultoMayor" :other-modal-ref="ModalAdultoMayor">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/AdultoMayor/Tipodepobreza.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/PobrezaAdultoMayor.jpg" class="imagencaptiva" alt="Tipos de pobreza adulto mayor"/>
		</picture>
		</div>
		<div>
			<h5 @click="openModalTiposPobrezAdultoMayor" class="text-center cursor text-white">Tipos de pobreza</h5>
		</div>
	</ModalTematica>

	<ModalTematica ref="ModalTiposPobrezAdultoMayor">
		<div class="col center-content">
			<img src="../../assets/image/LogoHcMini.png" class="logomini" alt="Logo Fundación Súmate" />
		</div>
		<div class="col center-content">
			<h3 class="text-center text-white pt-3">Tipos de pobreza</h3>
		</div>
		<div class="col text-white pb-4">
			<div style="height:20px"></div>
			<span class="font-dinn-bold">Pobreza monetaria extrema:</span><br />
			Corresponde a hogares que cuentan con ingresos inferiores a 2/3 del valor de la línea de pobreza e implica que
			un hogar no cuenta con ingresos suficientes para cubrir las necesidades básicas esenciales.
			<br />
			<br />
			<span class="font-dinn-bold">Pobreza monetaria:</span><br />
			Es el porcentaje de hogares de nuestros usuarios, cuyos ingresos o consumos per cápita es inferiores al valor de
			una canasta mínima de alimentos. (Cifras Ministerio de Desarrollo Social; 2018).
			<br />
			<br />
			<span class="font-dinn-bold">Sin pobreza monetaria:</span><br />
			Es el porcentaje de hogares de nuestros usuarios, cuyos ingresos totales son mayores a la línea de pobreza según
			el tamaño del hogar. (Cifras Ministerio de Desarrollo Social; 2018).
			<br />
		</div>
	</ModalTematica>

	<!-- FIN CARD MAIN ADULTO MAYOR -->

	<!-- Inicio Card Fundaciones -->
	<ModalTematica ref="modalFundaciones" background-color="#45C0E8" :muestra-nav="true" min-height="30%" min-width="35%"
		max-width="35%">

		<div class="col center-content mb-2 text-cifra text-white font-dinn-bold">
			Fundaciones
		</div>
		<div style="height:25px"></div>
		<div class="font-dinn-regular text-white text-bajada-fundacion text-justify d-flex">
			Nuestras Fundaciones Súmate y Emplea suman su labor a nuestras líneas de accion de "Infancia y Adolescencia" y
			"Adultez", donde trabajan por la reinserción escolar y laboral, respectivamente.
		</div>
		<div style="height:25px"></div>
		<div style="padding-left:10px" class="container col">
			<div @click="openModalFundacioSumate" class="row cursor-link l-img">
				<div class="col-5 p-3 d-flex" style="flex-direction:row-reverse;">
					<img alt="Fundación Súmate" src="@/assets/image/FundacionSumateColor.png" />
				</div>
				<div class="col-7 p-3 d-flex">
					<span class="font-dinn-bold text-fundacion"
						style="display: flex;flex-wrap: wrap;align-content: center;">
						Fundación Súmate
					</span>
				</div>
			</div>
			<div @click="openModalFundacioEmplea" class="row cursor-link l-img">
				<div class="col-5 p-3 d-flex" style="flex-direction:row-reverse;">
					<img alt="Fundación Emplea" src="@/assets/image/FundacionEmpleaColor.png" />
				</div>
				<div class="col-7 p-3 d-flex">
					<span class="font-dinn-bold text-fundacion"
						style="display: flex;flex-wrap: wrap;align-content: center;">
						Fundación Emplea
					</span>
				</div>
			</div>
		</div>
	</ModalTematica>

	<CardModalAzulCompleto ref="modalFundacioSumate" background-color="#84BD00" :muestra-nav="true" min-width="50%"
		max-width="50%" min-height="600px" max-height="600px" overflow-y="scroll" display="flex" flex-direction="column"
		padding="30px" :scrollBar="true">

		<div class="col center-content mb-2">
			<img src="../../assets/image/minilogoSumate.png" class="logomini" alt="Logo Fundación Súmate" />
		</div>
		<div>
			<div class="colorblanco">
				<div style="height:45px"></div>
				<h1 class="text-center font-dinn-bold " style="color: #84BD00">Visión</h1>
				<div class="text-white text-center sub-titulo-card">
					<div class="text-gris text-justify d-flex">
						Garantizar el derecho a finalizar la educación escolar y proyectar la educación continua de niñas,
						niños y jóvenes (NNJ) que viven en situación de pobreza y exclusión, otorgando oportunidades que
						aseguren su trayectoria educativa e inclusión social.
					</div>
				</div>
				<div style="height:45px"></div>
				<h1 class="text-center font-dinn-bold" style="color: #84BD00">Misión</h1>
				<div class="text-white text-center sub-titulo-card">
					<div class="text-gris text-justify d-flex">
						Lograr un país donde las niñas, niños y jóvenes en vulnerabilidad social vean reconocido su derecho
						a aprender en establecimientos educacionales que se perfeccionan permanentemente para su inclusión
						social. Esperamos ser un aporte al desarrollo social, económico y cultural del país
					</div>
				</div>
				<div style="height:45px"></div>
				<h1 class="text-center font-dinn-bold " style="color: #84BD00">Valores</h1>
				<div class="text-white text-center sub-titulo-card">
					<div class="text-gris text-center ">
						Solidaridad<br>
						Espíritu emprendedor<br>
						Respeto<br>
						Compromiso<br>
						Transparencia<br>
						Justicia<br>
						Trabajo en equipo<br>

					</div>
					<div style="height:20px"></div>
					<div class="text-info">__________________________</div>
				</div>
				<div style="height:45px"></div>
				<h1 class="text-center font-dinn-bold " style="color: #84BD00">Hechos Relevantes</h1>
				<div style="height:20px"></div>
				<div class="text-white text-center sub-titulo-card">
					<div class="text-gris text-justify">
						<span style="color:#000;">Aulas de reingreso, restituyendo el derecho a la educación:</span> En el
						segundo semestre de 2022,
						comenzamos el desarrollo de la oferta educativa de reingreso en las regiones Metropolitana, Maule,
						Biobío y Los Lagos. Esto se traduce en la apertura de 7 nuevas aulas permitiendo aumentar las
						oportunidades de (NNJ) excluidos del sistema escolar. Estas aulas surgen gracias a alianzas con los
						servicios locales de educación pública y de la convocatoria del fondo concursable “Súmate al
						Reingreso”.
					</div>
				</div>

				<!-- foto -->
				<div class="row mt-5">
					<picture>
					<div class="imgCard-container col">
						<source :srcset="require('../../assets/image/inclusion/FotosCardFundaciones/ProfeHc.webp')"   class="imgCard ">
						<img class="imgCard" src="../../assets/image/inclusion/FotosCardFundaciones/ProfeHc.png" alt="Aulas de reingreso"/>
						<br><br>
					</div>
				</picture>
				</div>
				<div class="text-white text-center sub-titulo-card">
					<div class="text-gris text-justify">
						<span style="color:#000;">Profesora Súmate gana el Global Teacher Prize:</span> Francisca Elgueta,
						profesora de historia de nuestro
						Colegio Betania La Granja, fue premiada con el mayor reconocimiento educativo del país conocido como
						el Nobel de la educación y que busca reconocer la labor e impacto que realizan las y los profesoras
						de Chile. De un total de 2.500 nominados, Francisca resultó ganadora de dicho galardón. "No nos
						olvidemos que los niños y las niñas de este país y del mundo entero se lo merecen todo”, expresó la
						profesora al recibir tan destacada distinción.
					</div>
				</div>
				<!-- foto -->
				<div class="row mt-5">
					<picture>
					<div class="imgCard-container col">
						<source :srcset="require('../../assets/image/inclusion/FotosCardFundaciones/Premiohc.webp')"   class="imgCard ">
						<img class="imgCard" src="../../assets/image/inclusion/FotosCardFundaciones/Premiohc.png" alt="Profesora súmate gana el global teacher prize"/>
						<br><br>
					</div>
				</picture>
				</div>

				<div class="text-white text-center sub-titulo-card">
					<div class="text-gris text-justify">
						<span style="color:#000;">Retorno a la presencialidad:</span> El año 2022 estuvo marcado por la
						reactivación de las actividades
						presenciales en nuestras escuelas y programas socioeducativos permitiendo brindar nuevas
						experiencias educativas a las y los jóvenes, así como también seguir fortaleciendo el vínculo con
						ellos. Entre las actividades retomadas de forma presencial se encuentran la formación en oficios y
						sus diferentes hitos, salidas pedagógicas, talleres recreativos y formativos, además de
						graduaciones.
					</div>
				</div>
				<!-- foto -->
				<div class="row mt-5">
					<picture>
					<div class="imgCard-container col">
						<source :srcset="require('../../assets/image/inclusion/FotosCardFundaciones/Licenciatura.webp')"   class="imgCard ">
						<img class="imgCard" src="../../assets/image/inclusion/FotosCardFundaciones/Licenciatura.png" alt="Retorno a la presencialidad"/>
						<br>
						<br>
					</div>
				</picture>
				</div>

			</div>
			<br>
			<br>
		</div>

	</CardModalAzulCompleto>

	<CardModalAzulCompleto ref="modalFundacioEmplea" background-color="#EF5D2A" :muestra-nav="true" min-width="50%"
		max-width="50%" min-height="600px" max-height="600px" overflow-y="scroll" display="flex" flex-direction="column"
		padding="30px" :scroll-bar="true">

		<div class="col center-content mb-2">
			<img src="../../assets/image/minilogoEmplea.png" class="logomini" alt="Logo Fundación Súmate" />
		</div>
		<div>
			<div class="colorblanco">
				<div style="height:45px"></div>
				<h1 class="text-center  font-dinn-bold " style="color: #EF5D2A">Visión</h1>
				<div class="text-white text-center sub-titulo-card">
					<div class="text-gris text-justify d-flex">
						Que en Chile todas las personas puedan transformar sus trayectorias de vida a través del trabajo.
					</div>
				</div>
				<div style="height:45px"></div>
				<h1 class="text-center  font-dinn-bold" style="color: #EF5D2A">Misión</h1>
				<div class="text-white text-center sub-titulo-card">
					<div class="text-gris text-justify d-flex">
						Impulsar la activación de personas excluidas del mercado del trabajo, fortaleciendo sus capacidades
						y competencias para facilitar y potenciar sus trayectorias laborales.
					</div>
				</div>
				<div style="height:45px"></div>
				<h1 class="text-center  font-dinn-bold " style="color: #EF5D2A">Valores</h1>
				<div class="text-white text-center sub-titulo-card">
					<div class="text-gris text-center ">
						Espíritu de superación<br>
						Respeto<br>
						Compromiso<br>
						Transparencia<br>
						Justicia<br>
						Trabajo en equipo<br>
					</div>
					<div style="height:20px"></div>
					<div class="text-info">__________________________</div>
				</div>
				<div style="height:45px"></div>
				<h1 class="text-center font-dinn-bold " style="color: #EF5D2A">Hechos Relevantes</h1>
				<div style="height:20px"></div>
				<div class="text-white text-center sub-titulo-card">
					<div class="text-gris text-justify">
						<span style="color:#000;">Programa Empléate:</span> 1.127 personas participaron en nuestro programa
						de intermediación laboral
						integral Empléate, el cual busca impulsar la activación y el empleo de personas en situación de
						vulnerabilidad socioeconómica. A través de un trabajo personalizado, online, basado en el
						fortalecimiento de competencias y habilidades, se busca adecuar el vínculo entre los perfiles
						laborales de los participantes y la demanda de los empleadores, asegurando el éxito de la inserción.
						Durante 2022 se trabajó una modalidad especial del programa en alianza con el Gore de la Región del
						Biobío que buscó potenciar la búsqueda de empleo en mujeres y jóvenes de 19 comunas de esta región a
						través de formación en modalidad mixta, basada en sesiones individuales y grupales, beneficiando a
						400 personas.
					</div>
				</div>

				<!-- foto -->
				<div class="row mt-5">
					<picture>
					<div class="imgCard-container col">
						<source :srcset="require('../../assets/image/inclusion/FotosCardFundaciones/paz.webp')"   class="imgCard ">
						<img class="imgCard" src="../../assets/image/inclusion/FotosCardFundaciones/paz.png" alt="Programa empléate"/>
						<br><br>
					</div>
				</picture>
				</div>

				<div class="text-white text-center sub-titulo-card">
					<div class="text-gris text-justify">
						<span style="color:#000;">Programa de Capacitación en Oficios:</span> 851 personas participaron en
						el Programa de Capacitación en
						Oficios que busca ampliar oportunidades y promover la inserción laboral mediante el fortalecimiento
						y desarrollo de competencias técnicas y personales. En 2022 se desarrollaron 52 cursos, cada uno con
						un promedio de 400 horas de capacitación, en 12 temáticas diferentes: desde operador logístico de
						grúa horquilla, hasta administrador informático. Los participantes recibieron formación online y
						presencial, además de realizar prácticas laborales de 360 horas, permitiendo conseguir la
						experiencia necesaria para ingresar con éxito al mundo laboral.
					</div>
				</div>
				<!-- foto -->
				<div class="row mt-5">
						<picture>
							<div class="imgCard-container col ">
							<source :srcset="require('../../assets/image/inclusion/FotosCardFundaciones/cartones.webp')"   class="imgCard ">
						<img class="imgCard " src="../../assets/image/inclusion/FotosCardFundaciones/cartones.png" alt="Programa capacitación en oficios"/>
					</div>
						<br><br>
					</picture>
				</div>

				<div class="text-white text-center sub-titulo-card">
					<div class="text-gris text-justify">
						<span style="color:#000;">Proyectos de Activación Laboral:</span> A través de alianzas con 24
						empresas, de diferentes rubros,
						desarrollamos 88 Proyectos de Activación Laboral (PAL) durante 2022. Llegamos a todas las regiones
						del país con cursos de capacitación que buscan generar impacto en las comunidades donde está inserta
						la organización. 1.716 personas participaron en estos proyectos, sumando más de 4.400 horas de
						capacitación en diferentes temáticas como Emprendimiento, Reciclaje y Habilitación Laboral. En 2022
						se sumaron siete nuevos partners que confiaron en Fundación Emplea (Mercado Libre, CPMC, Teck, SURA,
						SQM, Carey e IKEA), permitiendo así potenciar la empleabilidad.
					</div>
				</div>
				<!-- foto -->
				<div class="row mt-5">
					<picture>
					<div class="imgCard-container col">
						<source :srcset="require('../../assets/image/inclusion/FotosCardFundaciones/seguridad.webp')"   class="imgCard ">
						<img class="imgCard" src="../../assets/image/inclusion/FotosCardFundaciones/seguridad.png" alt="Proyectos de activación laboral"/>
						<br>
						<br>
					</div>
				</picture>
				</div>
			</div>
			<br>
			<br>
		</div>

	</CardModalAzulCompleto>
	<!-- Fin Card Fundaciones -->

	<!-- Card genero de adultez -->
	<CardModalAzulCompleto ref="ModalGeneroDis" :other-modal-ref="ModalDiscapacidadmentalAdultez" background-color="#45C0E8"
		:muestra-nav="true" max-width="50%" min-width="25%">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/Adultez/Discapacidadmental/genero.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/DisMentGenero.jpg" class="imagencaptiva" alt="Género adultez"/>
		</picture>
		</div>
	</CardModalAzulCompleto>
	<!-- Card Atencion de adultez -->
	<CardModalAzulCompleto ref="ModalAteDul" :other-modal-ref="ModalDiscapacidadmentalAdultez" background-color="#45C0E8"
		:muestra-nav="true" max-width="50%" min-width="25%">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/Adultez/Discapacidadmental/Nacionalidad.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/NanDultez.jpg" class="imagencaptiva" alt="Atención adultez"/>
		</picture>
		</div>
	</CardModalAzulCompleto>
	<!-- Card Costos de adultez -->
	<CardModalAzulCompleto ref="ModalCosAdultez" :other-modal-ref="ModalDiscapacidadmentalAdultez"
		background-color="#45C0E8" :muestra-nav="true" min-width="50%" max-width="50%">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/Adultez/Discapacidadmental/Costo.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/cosAdultez.jpg" class="imagencaptiva" alt="Costo adultez"/>
		</picture>
		</div>
	</CardModalAzulCompleto>
	<!-- Card tipos de pobreza de adultez -->
	<CardModalAzulCompleto ref="ModalPobAdultez" :other-modal-ref="ModalDiscapacidadmentalAdultez"
		background-color="#45C0E8" :muestra-nav="true" max-width="50%" min-width="25%">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/Adultez/Discapacidadmental/Tipodepobreza.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/PobAdultez.jpg" class="imagencaptiva" alt="Tipos de pobreza adultez"/>
		</picture>
		</div>
		<div>
			<h5 @click="openModalTiposPobrezAdultoMayor" class="text-center cursor text-white">Tipos de pobreza</h5>
		</div>
	</CardModalAzulCompleto>

	<CardModalAzulCompleto ref="ModalTiposPobrezAdultoMayor" :other-modal-ref="ModalDiscapacidadmentalAdultez"
		background-color="#45C0E8" :muestra-nav="true" max-width="50%" min-width="25%">
		<div class="col center-content">
			<img src="../../assets/image/LogoHcMini.png" class="logomini" alt="Logo Fundación Súmate" />
		</div>
		<div class="col center-content">
			<h3 class="text-center text-white pt-3">Tipos de pobreza</h3>
		</div>
		<div class="col text-white pb-4">
			<div style="height:20px"></div>
			<span class="font-dinn-bold">Pobreza monetaria extrema:</span><br />
			Corresponde a hogares que cuentan con ingresos inferiores a 2/3 del valor de la línea de pobreza e implica que
			un hogar no cuenta con ingresos suficientes para cubrir las necesidades básicas esenciales.
			<br />
			<br />
			<span class="font-dinn-bold">Pobreza monetaria:</span><br />
			Es el porcentaje de hogares de nuestros usuarios, cuyos ingresos o consumos per cápita es inferiores al valor de
			una canasta mínima de alimentos. (Cifras Ministerio de Desarrollo Social; 2018).
			<br />
			<br />
			<span class="font-dinn-bold">Sin pobreza monetaria:</span><br />
			Es el porcentaje de hogares de nuestros usuarios, cuyos ingresos totales son mayores a la línea de pobreza según
			el tamaño del hogar. (Cifras Ministerio de Desarrollo Social; 2018).
			<br />
		</div>
	</CardModalAzulCompleto>
	<!-- Fin discapacidad mental -->

	<!-- ADULTEZ -->

	<!-- Card genero de alcohol y drogas -->
	<ModalTematica ref="ModalGeneroDiscapacidadAdulez" :other-modal-ref="ModalConsumodeDrogas"
		>
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/Adultez/Drogas/genero.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/GeneroDiscapacidadMentealAdultez.jpg"  class="imagencaptiva" alt="Género alcohol y drogas"/>
			</picture>
		</div>
	</ModalTematica>
	<!-- Card Atencion de alcohol y drogas -->
	<ModalTematica ref="ModalAtencionDiscapacidadAdultez" :other-modal-ref="ModalConsumodeDrogas">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/Adultez/Drogas/Nacionalidad.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/NacionalidadDiscapacidadMentalAdultez.jpg" class="imagencaptiva" alt="Atención alcohol y drogas"/>
		</picture>
		</div>
	</ModalTematica>
	<!-- Card Costos de alcohol y drogas -->
	<ModalTematica ref="ModalCostosDiscapacidadAdultez" :other-modal-ref="ModalConsumodeDrogas">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/Adultez/Drogas/CostoUsuario.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/CostosDiscapacidadMentalAdultez.jpg" class="imagencaptiva" alt="Costo alcohol y drogas"/>
		</picture>
		</div>
	</ModalTematica>
	<!-- Card tipos de pobrezade alcohol y drogas -->
	<ModalTematica ref="ModalPobrezaDiscapacidadAdultez" :other-modal-ref="ModalConsumodeDrogas">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/Adultez/Drogas/Tipodepobreza.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/PobrezaDiscapacidadMentalAdultez.jpg" class="imagencaptiva" alt="Tipos de pobreza alcohol y drogas"/>
		</picture>
		</div>
		<div>
			<h5 @click="openModalTiposPobrezAdultoMayor" class="text-center cursor text-white">Tipos de pobreza</h5>
		</div>
	</ModalTematica>

	<CardModalAzulCompleto ref="ModalTiposPobrezAdultoMayor" :other-modal-ref="ModalDiscapacidadmentalAdultez"
		background-color="#45C0E8" :muestra-nav="true" max-width="50%" min-width="25%">
		<div class="col center-content">
			<img src="../../assets/image/LogoHcMini.png" class="logomini" alt="Logo Fundación Súmate" />
		</div>
		<div class="col center-content">
			<h3 class="text-center text-white pt-3">Tipos de pobreza</h3>
		</div>
		<div class="col text-white pb-4">
			<div style="height:20px"></div>
			<span class="font-dinn-bold">Pobreza monetaria extrema:</span><br />
			Corresponde a hogares que cuentan con ingresos inferiores a 2/3 del valor de la línea de pobreza e implica que
			un hogar no cuenta con ingresos suficientes para cubrir las necesidades básicas esenciales.
			<br />
			<br />
			<span class="font-dinn-bold">Pobreza monetaria:</span><br />
			Es el porcentaje de hogares de nuestros usuarios, cuyos ingresos o consumos per cápita es inferiores al valor de
			una canasta mínima de alimentos. (Cifras Ministerio de Desarrollo Social; 2018).
			<br />
			<br />
			<span class="font-dinn-bold">Sin pobreza monetaria:</span><br />
			Es el porcentaje de hogares de nuestros usuarios, cuyos ingresos totales son mayores a la línea de pobreza según
			el tamaño del hogar. (Cifras Ministerio de Desarrollo Social; 2018).
			<br />
		</div>
	</CardModalAzulCompleto>

	<!-- ADULTEZ -->

	<!-- Fin dicapadida mental de adultez -->

	<!-- Card genero de Inclusion laboral -->
	<ModalTematica  ref="ModalGenInclusion" :other-modal-ref="ModalInclusionLaboralAdultez">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/Adultez/InclusionLaboral/genero.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/GeneroInclusionLaboralAdultez.jpg" class="imagencaptiva" alt="Género inclusión laboral"/>
		</picture>
		</div>
	</ModalTematica >
	<!-- Card Atencion de Inclusion laboral -->
	<ModalTematica  ref="ModalnanInclusion" :other-modal-ref="ModalInclusionLaboralAdultez">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/Adultez/InclusionLaboral/Nacionalidad.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/NanInclusionLaboral.jpg" class="imagencaptiva" alt="Atención inclusión laboral"/>
		</picture>
		</div>
	</ModalTematica >
	<!-- Card Costos de Inclusion laboral -->
	<ModalTematica  ref="ModalCosInclusion" :other-modal-ref="ModalInclusionLaboralAdultez">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/Adultez/InclusionLaboral/Costo.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/cosInclu.jpg" class="imagencaptiva" alt="Costo inclusión laboral"/>
		</picture>
		</div>
	</ModalTematica >
	<!-- Card tipos de pobrezade Inclusion laboral -->
	<ModalTematica  ref="ModalPobInclusion" :other-modal-ref="ModalInclusionLaboralAdultez">
		<div>
			<picture>
				<source :srcset="require('../../assets/Webp/Adultez/InclusionLaboral/Tipodepobreza.webp' )" type="image/webp">
			<img src="../../assets/image/inclusion/PobInclu.jpg" class="imagencaptiva" alt="Tipos de pobreza inclusión laboral"/>
		</picture>
		</div>
		<div>
			<h5 @click="openModalTiposPobrezAdultoMayor" class="text-center cursor text-white">Tipos de pobreza</h5>
		</div>
	</ModalTematica >

	<CardModalAzulCompleto ref="ModalTiposPobrezAdultoMayor" background-color="#45C0E8" :muestra-nav="true" max-width="50%"
		min-width="25%">
		<div class="col center-content">
			<img src="../../assets/image/LogoHcMini.png" class="logomini" alt="Logo Fundación Súmate" />
		</div>
		<div class="col center-content">
			<h3 class="text-center text-white pt-3">Tipos de pobreza</h3>
		</div>
		<div class="col text-white pb-4">
			<div style="height:20px"></div>
			<span class="font-dinn-bold">Pobreza monetaria extrema:</span><br />
			Corresponde a hogares que cuentan con ingresos inferiores a 2/3 del valor de la línea de pobreza e implica que
			un hogar no cuenta con ingresos suficientes para cubrir las necesidades básicas esenciales.
			<br />
			<br />
			<span class="font-dinn-bold">Pobreza monetaria:</span><br />
			Es el porcentaje de hogares de nuestros usuarios, cuyos ingresos o consumos per cápita es inferiores al valor de
			una canasta mínima de alimentos. (Cifras Ministerio de Desarrollo Social; 2018).
			<br />
			<br />
			<span class="font-dinn-bold">Sin pobreza monetaria:</span><br />
			Es el porcentaje de hogares de nuestros usuarios, cuyos ingresos totales son mayores a la línea de pobreza según
			el tamaño del hogar. (Cifras Ministerio de Desarrollo Social; 2018).
			<br />
		</div>
	</CardModalAzulCompleto>
</template>

<script setup>
import { ref } from 'vue';
import CardModalAzulCompleto from './Componentes_genericos_Inclusion/CardModaAzulCompleto.vue';
import ModalOpciones from './Componentes_genericos_Inclusion/ModalOpciones.vue';
import ModalTematica from './Componentes_genericos_Inclusion/ModalTematica.vue';

// Funciones modal de Infancia Y adolescencia
const modalInfanciaYadolescencia = ref(null);
function openModalInfanciaYadolescencia() {
	modalInfanciaYadolescencia.value.openModal();
}
// educacion inicial
const ModalEducacioniniciaInfancia = ref(null);
function openModalEducacioniniciaInfancia() {
	modalInfanciaYadolescencia.value.closeModal();
	ModalEducacioniniciaInfancia.value.openModal();
}
// infanto adolecente
const ModalinfantoadolecenteInfancia = ref(null);
function openModalinfantoadolecenteInfancia() {
	modalInfanciaYadolescencia.value.closeModal();
	ModalinfantoadolecenteInfancia.value.openModal();
}
// reinsersion educativa
const ModalReinsercioneducativaInfancia = ref(null);
function openModalReinsercioneducativaInfancia() {
	modalInfanciaYadolescencia.value.closeModal();
	ModalReinsercioneducativaInfancia.value.openModal();
}
// Genero Infanto adolecente
const ModalGeneroInfanto = ref(null);
function openModalGeneroInfanto() {
	ModalinfantoadolecenteInfancia.value.closeModal();
	ModalGeneroInfanto.value.openModal();
}
// Atencion infanto
const ModalAtencionInfanto = ref(null);
function openModalAtencionInfanto() {
	ModalinfantoadolecenteInfancia.value.closeModal();
	ModalAtencionInfanto.value.openModal();
}
// costos infanto
const ModalCostoInfanto = ref(null);
function openModalCostoInfanto() {
	ModalinfantoadolecenteInfancia.value.closeModal();
	ModalCostoInfanto.value.openModal();
}
// pobreza infanto
const ModalPobrezaInfanto = ref(null);
function openModalPobrezaInfanto() {
	ModalinfantoadolecenteInfancia.value.closeModal();
	ModalPobrezaInfanto.value.openModal();
}
// tipos de pobreza infanto
const ModalTiposPobrez = ref(null);
function openModalTiposPobrez() {
	ModalinfantoadolecenteInfancia.value.closeModal();
	ModalTiposPobrez.value.openModal();
}
// genero de atendicos educacion inical
const ModalGeneroEducacion = ref(null);
function openModalGeneroEducacion() {
	ModalEducacioniniciaInfancia.value.closeModal();
	ModalGeneroEducacion.value.openModal();
}
// atendicos por nacionalidad educacion inical
const ModalAtencionEducacion = ref(null);
function openModalAtencionEducacion() {
	ModalEducacioniniciaInfancia.value.closeModal();

	ModalAtencionEducacion.value.openModal();
}
// Costo educacion inical
const ModalCostoEducacioninicial = ref(null);
function openModalCostoEducacioninicial() {
	ModalEducacioniniciaInfancia.value.closeModal();
	ModalCostoEducacioninicial.value.openModal();
}
// tipos de pobreza educacion inicial
const ModalPobrezaEducacionInicial = ref(null);
function openModalPobrezaEducacionInicial() {
	ModalEducacioniniciaInfancia.value.closeModal();
	ModalPobrezaEducacionInicial.value.openModal();
}
// genero reinserision educativa
const ModalGeneroreinsersion = ref(null);
function openModalGeneroreinsersion() {
	ModalReinsercioneducativaInfancia.value.closeModal();
	ModalGeneroreinsersion.value.openModal();
}
// NAcionalidad de reinserision educativa
const ModalNacionalidadreinsersion = ref(null);
function openModalNacionalidadreinsersion() {
	ModalReinsercioneducativaInfancia.value.closeModal();
	ModalNacionalidadreinsersion.value.openModal();
}
// Costos de reinserision educativa
const ModalCostoReinsersion = ref(null);
function openModalCostoReinsersion() {
	ModalReinsercioneducativaInfancia.value.closeModal();
	ModalCostoReinsersion.value.openModal();
}
// Costos de reinserision educativa
const ModalPobrezareinsersion = ref(null);
function openModalPobrezareinsersion() {
	ModalReinsercioneducativaInfancia.value.closeModal();
	ModalPobrezareinsersion.value.openModal();
}

// Fin funciones modal de Infancia Y adolescencia

const modalFundaciones = ref(null);


const ModalAdultezMain = ref(null);
function openModalAdultezMain() {
	ModalAdultezMain.value.openModal();
}


// personas en situacion de calle
const ModalCalle = ref(null);
function openModalCalle() {
	ModalCalle.value.openModal();
}
const ModalAtencioncalle = ref(null);
function openModalAtencioncalle() {
	ModalCalle.value.closeModal();
	ModalAtencioncalle.value.openModal();
}
const ModalGenerocalle = ref(null);
function openModalGenerocalle() {
	ModalCalle.value.closeModal();
	ModalGenerocalle.value.openModal();
}
const ModalCostoscalle = ref(null);
function openModalCostoscalle() {
	ModalCalle.value.closeModal();
	ModalCostoscalle.value.openModal();
}
const ModalPobrezacalle = ref(null);
function openModalPobrezacalle() {
	ModalCalle.value.closeModal();
	ModalPobrezacalle.value.openModal();
}
const ModalTiposPobrezcalle = ref(null);
function openModalTiposPobrezcalle() {
	ModalCalle.value.closeModal();
	ModalTiposPobrezcalle.value.openModal();
}

// Main function adulto mayor
const ModalAdultoMayor = ref(null);
function openModalAdultoMayor() {
	ModalAdultoMayor.value.openModal();
}
const ModalGeneroAdultoMayor = ref(null);
function openModalGeneroAdultoMayor() {
	ModalAdultoMayor.value.closeModal();
	ModalGeneroAdultoMayor.value.openModal();
}
const ModalAtencionAdultoMayor = ref(null);
function openModalAtencionAdultoMayor() {
	ModalAdultoMayor.value.closeModal();
	ModalAtencionAdultoMayor.value.openModal();
}
const ModalCostosAdultoMayor = ref(null);
function openModalCostosAdultoMayor() {
	ModalAdultoMayor.value.closeModal();
	ModalCostosAdultoMayor.value.openModal();
}
const ModalPobrezaAdultoMayor = ref(null);
function openModalPobrezaAdultoMayorr() {
	ModalAdultoMayor.value.closeModal();
	ModalPobrezaAdultoMayor.value.openModal();
}
const ModalTiposPobrezAdultoMayor = ref(null);
function openModalTiposPobrezAdultoMayor() {
	ModalTiposPobrezAdultoMayor.value.openModal();
}

// consumo de drogas adultez
const ModalConsumodeDrogas = ref(null);
function openModalConsumodeDrogas() {
	ModalAdultezMain.value.closeModal();
	ModalConsumodeDrogas.value.openModal();
}
// Discapacidad metnal de adultez
const ModalDiscapacidadmentalAdultez = ref(null);
function openModalDiscapacidadmentalAdultez() {
	ModalAdultezMain.value.closeModal();
	ModalDiscapacidadmentalAdultez.value.openModal();
}
// Inclusion laboral de adultez
const ModalInclusionLaboralAdultez = ref(null);
function openModalInclusionLaboralAdultez() {
	ModalAdultezMain.value.closeModal();
	ModalInclusionLaboralAdultez.value.openModal();
}
// Main adultez discapacidad mental	
const ModalGeneroDiscapacidadAdulez = ref(null);
function openModalGeneroDiscapacidadAdulez() {
	ModalConsumodeDrogas.value.closeModal();
	ModalGeneroDiscapacidadAdulez.value.openModal();
}
const ModalAtencionDiscapacidadAdultez = ref(null);
function openModalAtencionDiscapacidadAdultez() {
	ModalConsumodeDrogas.value.closeModal();
	ModalAtencionDiscapacidadAdultez.value.openModal();
}
const ModalCostosDiscapacidadAdultez = ref(null);
function openModalCostosDiscapacidadAdultez() {
	ModalConsumodeDrogas.value.closeModal();
	ModalCostosDiscapacidadAdultez.value.openModal();
}
const ModalPobrezaDiscapacidadAdultez = ref(null);
function openModalPobrezaDiscapacidadAdultez() {
	ModalConsumodeDrogas.value.closeModal();
	ModalPobrezaDiscapacidadAdultez.value.openModal();
}
////////////////////////////////////////////////////////
const ModalGeneroDis = ref(null);
function openModalGeneroDis() {
	ModalGeneroDis.value.openModal();
	ModalDiscapacidadmentalAdultez.value.closeModal();
}
const ModalAteDul = ref(null);
function openModalAteDul() {
	ModalDiscapacidadmentalAdultez.value.closeModal();
	ModalAteDul.value.openModal();
}
const ModalCosAdultez = ref(null);
function openModalCosAdultez() {
	ModalDiscapacidadmentalAdultez.value.closeModal();
	ModalCosAdultez.value.openModal();
}
const ModalPobAdultez = ref(null);
function openModalPobAdultez() {
	ModalDiscapacidadmentalAdultez.value.closeModal();
	ModalPobAdultez.value.openModal();
}
////////////////////////////////////////////////////////
const ModalGenInclusion = ref(null);
function openModalGenInclusion() {
	ModalInclusionLaboralAdultez.value.closeModal();
	ModalGenInclusion.value.openModal();
}
const ModalnanInclusion = ref(null);
function openModalnanInclusion() {
	ModalInclusionLaboralAdultez.value.closeModal();
	ModalnanInclusion.value.openModal();
}
const ModalCosInclusion = ref(null);
function openModalCosInclusion() {
	ModalInclusionLaboralAdultez.value.closeModal();
	ModalCosInclusion.value.openModal();
}
const ModalPobInclusion = ref(null);
function openModalPobInclusion() {
	ModalInclusionLaboralAdultez.value.closeModal();
	ModalPobInclusion.value.openModal();
}

const modalFundacioSumate = ref(null);
function openModalFundacioSumate() {
	modalFundaciones.value.closeModal();
	modalFundacioSumate.value.openModal();
}
const modalFundacioEmplea = ref(null);
function openModalFundacioEmplea() {
	modalFundaciones.value.closeModal();
	modalFundacioEmplea.value.openModal();
}

</script>

<style scoped>
.container-emplea-sumate {
	width: 22%;
}

.card-landing {
	transition: transform 250ms;
}

.card-landing:hover {
	cursor: pointer;
	transform: translateY(-20px);
}

.imgCard-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 200px;
}

.img-redaccion-solidaria {
	object-fit: cover;
}

.imgCard {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

a {
	color: #fff;
	text-decoration: none;
}

.tamagno-card {
	min-height: 276px;
	min-width: 261px;

	max-height: 276px;
	max-width: 261px;
}

.text-white {
	padding-inline: 10%;
}

.text-justify {
	text-align: justify;
}

.text-gris {
	color: #6E6E6E !important;
}

.text-underline {
	text-decoration: underline;
}

.text-celeste {
	color: #45C0E8 !important;
}

.text-cifra {
	font-size: 46pt;
}

.text-fundacion {
	font-size: 21pt;
}

.text-bajada-fundacion {
	font-size: 14pt !important;
}


.center-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.logomini {
	display: block;
	margin-left: auto;
	margin-right: auto;
	height: auto;
	width: 25%;
}

.imagencaptiva {
	display: block;
	margin-left: auto;
	margin-right: auto;
	height: auto;
	max-width: 100%;
}

.colorceleste {}

.colorblanco {
	border-radius: 50px 0 50px 0;
	background: #fff;
}

.hover-div {
	width: 100%;
	transition: background-color 0.1s ease;
}

.hover-div:hover {
	border-radius: 50px 0 50px 0;
	background-color: #45C0E8;
}

.hover-div2 {
	width: 100%;
	border-radius: 0 0 50px 0;
	transition: background-color 0.1s ease;
}

.hover-div2:hover {
	color: #45C0E8 !important;
	border-radius: 0 0 50px 0;
	background-color: #ffffff;
}

.imagen-adaptativa3 {
	margin-top: -320px;
	width: 25%;
	height: 50%;
	object-fit: cover;
	/* Asegura que la imagen cubra todo el contenedor sin deformarse */
	object-position: center;
	/* Alinea la imagen al centro del contenedor */
}

.cursor {
	cursor: pointer;
}

.cursor-link {
	cursor: pointer;
	color: #45C0E8 !important;
}

.l-img {
	-webkit-filter: grayscale(100%) brightness(2.0);
	filter: grayscale(100%) brightness(2.0);
}

.l-img:hover {
	background-color: white;
	border-radius: 0 0 90px 0;
	-webkit-filter: grayscale(0%);
}

.l-img img {
	align-items: end;
	width: 120px;
}

.main-scroll {
	margin-top: -200px;
	background-color: white;
	max-height: 500px;
	/* Establece la altura máxima del contenido en la que se activará el scroll */
	overflow-y: auto;
	/* Muestra un scroll vertical cuando el contenido excede la altura máxima */
	max-width: 600px;
}

.small-text {
	margin-top: -120px;
	text-align: justify;
	color: rgb(0, 0, 0);
	font-size: 12pt;
	font-family: DINNextRoundedLTPro-Light, 'DINNextRoundedLTPro Light';
	font-weight: 200;
}

.small-text1 {

	text-align: justify;
	color: rgb(0, 0, 0);
	font-size: 14pt;
	font-family: DINNextRoundedLTPro-Light, 'DINNextRoundedLTPro Light';
	font-weight: 300;

}

.svg-fundaciones {
	width: 60%;
	height: 100%;
}

.sub-titulo-card {
	font-family: DINNextRoundedLTPro-Medium, 'DINNextRoundedLTPro Medium';
	font-size: 18px;
	color: #fff;
	margin-top: 10px;
}

.svg-container2 {
	/* margin-top: -200px; */
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 25%;
}

/* Adultez */
.st0 {
	opacity: 0.54;
	fill: #FCFCFC;
}

.st1 {
	fill: #737373;
}

.st2 {
	font-family: 'DINNextRoundedLTPro-Medium';
}

.st3 {
	font-size: 31.3379px;
}

.st4 {
	fill: none;
	stroke: #FFFFFF;
	stroke-width: 1.5058;
	stroke-miterlimit: 10;
}

.st5 {
	fill: #45C0E8;
}

/* Infancia y adolencencia */
.st0 {
	opacity: 0.54;
	fill: #FFFFFF;
}

.st1 {
	fill: #737373;
}

.st2 {
	font-family: 'DINNextRoundedLTPro-Medium';
}

.st3 {
	font-size: 31.3379px;
}

.st4 {
	fill: none;
	stroke: #FFFFFF;
	stroke-width: 1.5058;
	stroke-miterlimit: 10;
}

.st5 {
	fill: #45C0E8;
}

/* FUNDACIONES */
.st0HOLA {
	fill: #45C0E8;
}

.st1HOLA {
	fill: none;
	stroke: #45C0E8;
	stroke-width: 0.7651;
	stroke-miterlimit: 10;
}

.st2HOLA {
	fill: #FCFCFC;
}

.st3HOLA {
	font-family: 'DINNextRoundedLTPro-Medium';
}

.st4HOLA {
	font-size: 46.6733px;
}

.st5HOLA {
	fill: none;
	stroke: #FFFFFF;
	stroke-width: 2.2427;
	stroke-miterlimit: 10;
}

.st6HOLA {
	fill: #737373;
}

.img-fundaciones,
img {
	width: 100%;
}
</style>