<template>
    <div class="container div-rounded text-style pt-2">
        <slot name="contenidoCard"></slot>
    </div>
</template>

<script setup>


</script>

<style scoped>
.div-rounded {
    margin-top: -50px;
    margin-left: -50px;
    background: rgba(236, 239, 239, 0.9);
    max-height: 500px;
    z-index: -1;
}

/* Si la pantalla es de al menos 600px de ancho */
@media screen and (min-width: 600px) {
    .div-rounded {
        margin-top: -100px;
        margin-left: -75px;
        max-height: 1000px;
    }
}

/* Si la pantalla es de al menos 900px de ancho */
@media screen and (min-width: 900px) {
    .div-rounded {
        margin-top: -150px;
        margin-left: -100px;
        max-height: 1500px;
    }
}

/* Si la pantalla es de al menos 1200px de ancho */
@media screen and (min-width: 1200px) {
    .div-rounded {
        margin-top: -200px;
        margin-left: -150px;
        max-height: 2000px;
    }
}

.text-style{
    flex-direction: column !important;
}
</style>