<template>
    <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="-80 0 394.45 224.59">

        <g id="Capa_1-2" data-name="Capa 1">
            <g>
                <path class="cls-1"
                    d="m25.28,101.14h30.34v65.73h10.11v-70.79c0-2.8-2.27-5.06-5.06-5.06H20.22c-2.79,0-5.06,2.26-5.06,5.06v70.79h10.11v-65.73Z" />
                <path class="cls-2"
                    d="m91.02,10.11h30.34v156.76h10.11V5.06c0-2.8-2.27-5.06-5.06-5.06h-40.45c-2.79,0-5.06,2.26-5.06,5.06v161.82h10.11V10.11Z" />
                <path class="cls-1"
                    d="m156.75,40.45h30.34v126.42h10.11V35.39c0-2.8-2.27-5.06-5.06-5.06h-40.45c-2.79,0-5.06,2.26-5.06,5.06v131.48h10.11V40.45Z" />
                <path class="cls-1" d="m0,176.99h212.38v10.11H0v-10.11Z" />
            </g>
        </g>
    </svg>
</template>

<style scoped>
.cls-1 {
    fill: #737373;
}

.cls-2 {
    fill: #ffcb00;
}</style>